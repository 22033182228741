import { useState } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faKey, faLaptop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, useViewport } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { DesktopOnlyAccessPopup } from 'src/features/room/components/desktop-only-access-popup';
import { useStartVm } from 'src/common/hooks/use-start-vm';
import { useAppSelector } from 'src/app/hooks';
import { StyledButtonBoxIcon, StyledButtonBoxTitle, StyledMachineBox } from '../../../commons/use-machine-box.styles';
import { StyledButtonVariant, StyledModalTitle } from './modal-info.styles';
import { ModalInfoProps } from './modal-info.type';
export const ModalActiveMachineInfo = ({
  roomCode
}: ModalInfoProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenDesktopOnlyAccessPopUp, setIsOpenDesktopOnlyAccessPopUp] = useState<boolean>(false);
  const vmType = useAppSelector(state => state.room.vmStartType);
  const {
    startVmHandler
  } = useStartVm(roomCode);
  const {
    width
  } = useViewport();
  const executeVmClickAction = async () => {
    if (width < BREAKPOINTS.VM) {
      setIsOpenDesktopOnlyAccessPopUp(true);
    } else {
      await startVmHandler(vmType);
    }
  };
  const handlerAttackBoxBtn = () => {
    // eslint-disable-next-line no-void
    void executeVmClickAction();
    setIsModalOpen(false);
  };
  return <>
      <Modal onOpenChange={setIsModalOpen} open={isModalOpen} triggerElement={<Button variant="outlined" color="secondary" data-testid="question-button">
            <FontAwesomeIcon icon={faQuestion} />
          </Button>} data-sentry-element="Modal" data-sentry-source-file="modal-info.tsx">
        <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="modal-info.tsx">
          <StyledModalTitle data-sentry-element="StyledModalTitle" data-sentry-source-file="modal-info.tsx">To access this machine, you need to either:</StyledModalTitle>
        </ModalHeader>
        <StyledMachineBox data-box="attack-box" data-sentry-element="StyledMachineBox" data-sentry-source-file="modal-info.tsx">
          <StyledButtonBoxIcon icon={faLaptop} data-sentry-element="StyledButtonBoxIcon" data-sentry-source-file="modal-info.tsx" />
          <div>
            <StyledButtonBoxTitle data-sentry-element="StyledButtonBoxTitle" data-sentry-source-file="modal-info.tsx">Use the AttackBox</StyledButtonBoxTitle>
            <p>Use a browser-based attack machine (recommended)</p>
          </div>

          <Button onClick={handlerAttackBoxBtn} data-sentry-element="Button" data-sentry-source-file="modal-info.tsx">Start AttackBox</Button>
        </StyledMachineBox>

        <StyledMachineBox data-sentry-element="StyledMachineBox" data-sentry-source-file="modal-info.tsx">
          <StyledButtonBoxIcon icon={faKey} data-sentry-element="StyledButtonBoxIcon" data-sentry-source-file="modal-info.tsx" />
          <div>
            <StyledButtonBoxTitle data-sentry-element="StyledButtonBoxTitle" data-sentry-source-file="modal-info.tsx">Use a VPN (Advanced)</StyledButtonBoxTitle>
            <p>Connect to our network via a VPN</p>
          </div>
          <StyledButtonVariant target="_blank" as={Link} to="/access " variant="solid" color="secondary" data-sentry-element="StyledButtonVariant" data-sentry-source-file="modal-info.tsx">
            See Instructions
          </StyledButtonVariant>
        </StyledMachineBox>
      </Modal>
      <DesktopOnlyAccessPopup open={isOpenDesktopOnlyAccessPopUp} onOpenChange={setIsOpenDesktopOnlyAccessPopUp} data-sentry-element="DesktopOnlyAccessPopup" data-sentry-source-file="modal-info.tsx" />
    </>;
};