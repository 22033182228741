import styled from 'styled-components';

import { StyledTitle } from 'src/common/styles';

import { StyledSectionBox } from '../commons';

export const StyledQuestionsSection = styled(StyledSectionBox)`
  padding: 2.4rem 0 0 0;
`;

export const StyledQuestionsTitle = styled(StyledTitle)`
  margin: 0 2.4rem;
`;

export const StyledQuestionsSubtitle = styled.p`
  font-size: 1.4rem;
  margin: 0 2.4rem;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledGraphWrapper = styled.div`
  margin-top: 1.6rem;
  height: 12.3rem;
  width: 100%;
`;
