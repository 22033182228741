import type { SetupWorker } from 'msw';

export const initMock = (): void => {
  // npm run start:mock
  if (process.env.REACT_APP_MOCK) {
    const serviceWorkerConfig = {
      serviceWorker: {
        url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`,
      },
    };
    // Dynamic import, to exclude the code from production
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-assignment, global-require, unicorn/prefer-module
      const { worker } = require('./development/browser') as { worker: SetupWorker };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      worker.start(serviceWorkerConfig);
    }
  }
};
