import { useState } from 'react';

export const useInputFocus = (): {
  isInputFocused: boolean;
  handleInputFocus: () => void;
  handleInputBlur: () => void;
} => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return {
    isInputFocused,
    handleInputFocus,
    handleInputBlur,
  };
};
