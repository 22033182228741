import { useEffect, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';

import { DispatchState } from '../interfaces';
import { calculateExpiryTimeLeft } from '../helpers/date/date';

export const useExpiredVmTime = (
  expiredTime: string | Date | undefined,
  setOpenExpiredMachineModal: DispatchState<boolean>,
) => {
  const [formattedTime, setFormattedTime] = useState<string>('0m 0s');

  // Calculate expires time
  useEffect(() => {
    let interval: NodeJS.Timer;

    if (expiredTime) {
      // On first render, do not show the modal
      if (differenceInMilliseconds(new Date(expiredTime), new Date()) <= 0) {
        setFormattedTime('0m 0s');
      } else {
        interval = setInterval(() => {
          if (differenceInMilliseconds(new Date(expiredTime), new Date()) <= 0) {
            setOpenExpiredMachineModal(true);
            setFormattedTime('0m 0s');
            clearInterval(interval);
          } else {
            const { hours, minutes, seconds } = calculateExpiryTimeLeft(new Date(), new Date(expiredTime));
            const hoursString = hours ? `${hours}h` : null;
            const timeFormatted = hoursString
              ? `${hoursString} ${minutes}min ${seconds}s`
              : `${minutes}min ${seconds}s`;
            setFormattedTime(timeFormatted);
          }
        }, 1000);
      }
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [expiredTime, setOpenExpiredMachineModal]);

  return { formattedTime };
};
