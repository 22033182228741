/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom';
import { Button, Image, StyledButton } from '@tryhackme/thm-ui-components';
import { StyledList } from '../common.styles';
import { VpnContentProps } from '../../../access-networks.types';
export const WindowsContent: React.FC<VpnContentProps> = ({
  isLoading,
  downloadHandler
}) => <StyledList data-testid="windows-content" data-sentry-element="StyledList" data-sentry-component="WindowsContent" data-sentry-source-file="windows-content.tsx">
    <li>
      <Button variant="text" color="info" isLoading={isLoading} loadingMsg="Downloading..." onClick={downloadHandler} data-testid="download-button" data-sentry-element="Button" data-sentry-source-file="windows-content.tsx">
        Download
      </Button>{' '}
      your OpenVPN configuration pack.
    </li>
    <li>
      <StyledButton to="https://openvpn.net/community-downloads" as={Link} variant="text" color="info" data-link="button" target="_blank" data-sentry-element="StyledButton" data-sentry-source-file="windows-content.tsx">
        Download
      </StyledButton>{' '}
      the OpenVPN GUI application.
    </li>
    <li>Install the OpenVPN GUI application. Then open the installer file and follow the setup wizard.</li>
    <li>
      Open and run the OpenVPN GUI application as Administrator.
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/win_ran_admin.png" alt="OpenVPN GUI application as Administrator" width="292" data-sentry-element="Image" data-sentry-source-file="windows-content.tsx" />
      </div>
    </li>

    <li>
      The application will start running in the system tray. It's at the bottom of your screen, near the clock. Right
      click on the application and click Import File.
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/win_import.png" alt="Import file" data-sentry-element="Image" data-sentry-source-file="windows-content.tsx" />
      </div>
    </li>
    <li>Select the configuration file you downloaded earlier.</li>
    <li>
      Now right click on the application again, select your file and click Connect
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/win_connect.png" alt="Connect" data-sentry-element="Image" data-sentry-source-file="windows-content.tsx" />
      </div>
    </li>
  </StyledList>;