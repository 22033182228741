/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/no-unescaped-entities */
import { useCallback, useState } from 'react';
import { NotificationDispatch } from '@tryhackme/thm-ui-components';
import { ReactComponent as WindowsIcon } from 'src/images/svgs/brands/windows.svg';
import { ReactComponent as AppleIcon } from 'src/images/svgs/brands/apple.svg';
import { ReactComponent as LinuxIcon } from 'src/images/svgs/brands/linux.svg';
import { useLazyDownloadVpnConfigQuery } from 'src/features/room/room.slice';
import { useAppSelector } from 'src/app/hooks';
import { useTrackDownloadOVPNMutation } from 'src/common/slices/analytics/download-ovpn';
import { StyledTitle } from '../access-networks.styles';
import { StyledVpnOptions, StyledLink, StyledWrapperButtons, StyledButton } from './open-vpn-options.styles';
import { HavingProblems, LinuxContent, MacOsContent, WindowsContent } from './components';
export const OpenVpnOptions = () => {
  const [activeContent, setActiveContent] = useState('linux'); // Set the default active content

  const [download] = useLazyDownloadVpnConfigQuery();
  const [trackDownloadOVPN] = useTrackDownloadOVPNMutation();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const networkId = useAppSelector(state => state.room.networkDetails?._id);
  const handleDownloadVpnConfig = useCallback(async () => {
    try {
      setIsDownloadLoading(true);
      const result = await download(networkId, false);
      await trackDownloadOVPN('access_machines_modal');
      if (!result.data?.data?.url) {
        throw new Error('Error downloading VPN config');
      }
      window.location.href = result.data.data.url;
    } catch {
      NotificationDispatch('error', 'Error downloading VPN config');
    } finally {
      setIsDownloadLoading(false);
    }
  }, [download, networkId, setIsDownloadLoading, trackDownloadOVPN]);
  const handleContentChange = useCallback((newContent: string) => {
    setActiveContent(newContent);
  }, []);
  return <StyledVpnOptions data-testid="open-vpn-screen" data-sentry-element="StyledVpnOptions" data-sentry-component="OpenVpnOptions" data-sentry-source-file="open-vpn-options.tsx">
      <StyledLink to="/access" data-link="main" target="_blank" rel="noopener noreferrer" data-sentry-element="StyledLink" data-sentry-source-file="open-vpn-options.tsx">
        View the dedicated OpenVPN access page for more information
      </StyledLink>

      <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="open-vpn-options.tsx">What Operating System are you using?</StyledTitle>
      <StyledWrapperButtons data-sentry-element="StyledWrapperButtons" data-sentry-source-file="open-vpn-options.tsx">
        <StyledButton onClick={() => handleContentChange('windows')} data-active={activeContent === 'windows' ? 'open' : 'close'} variant="outlined" data-sentry-element="StyledButton" data-sentry-source-file="open-vpn-options.tsx">
          <WindowsIcon data-sentry-element="WindowsIcon" data-sentry-source-file="open-vpn-options.tsx" />
          Windows
        </StyledButton>

        <StyledButton onClick={() => handleContentChange('linux')} data-active={activeContent === 'linux' ? 'open' : 'close'} variant="outlined" data-sentry-element="StyledButton" data-sentry-source-file="open-vpn-options.tsx">
          <LinuxIcon data-sentry-element="LinuxIcon" data-sentry-source-file="open-vpn-options.tsx" />
          Linux
        </StyledButton>

        <StyledButton onClick={() => handleContentChange('mac')} data-active={activeContent === 'mac' ? 'open' : 'close'} variant="outlined" data-sentry-element="StyledButton" data-sentry-source-file="open-vpn-options.tsx">
          <AppleIcon data-sentry-element="AppleIcon" data-sentry-source-file="open-vpn-options.tsx" />
          MacOS
        </StyledButton>
      </StyledWrapperButtons>

      {activeContent === 'windows' && <WindowsContent isLoading={isDownloadLoading} downloadHandler={handleDownloadVpnConfig} />}
      {activeContent === 'linux' && <LinuxContent isLoading={isDownloadLoading} downloadHandler={handleDownloadVpnConfig} />}
      {activeContent === 'mac' && <MacOsContent isLoading={isDownloadLoading} downloadHandler={handleDownloadVpnConfig} />}
      <HavingProblems data-sentry-element="HavingProblems" data-sentry-source-file="open-vpn-options.tsx" />
    </StyledVpnOptions>;
};