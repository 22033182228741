import { RoomProps } from 'src/common/interfaces/my-rooms';

import { Room } from '../my-learning.types';

export const transformApiDataToRoom = (data: RoomProps): Room => ({
  title: data.title ?? '',
  description: typeof data.description === 'string' ? data.description : '',
  imageURL: data.imageURL ?? '',
  code: data.code ?? '',
});
