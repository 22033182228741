import { useLocation } from 'react-router-dom';
import { Image } from '@tryhackme/thm-ui-components';
import { ResultItemProp } from '../search-results-popup/search-results-popup.types';
import { StyledItem, StyledImageContainer } from './result-item.styles';
export const ResultItem = ({
  title,
  image = '',
  itemUrl = '/'
}: ResultItemProp) => {
  const location = useLocation();
  return <StyledItem to={itemUrl} target={location.pathname.includes('/room/') ? '_blank' : '_self'} data-sentry-element="StyledItem" data-sentry-component="ResultItem" data-sentry-source-file="result-item.tsx">
      <StyledImageContainer data-sentry-element="StyledImageContainer" data-sentry-source-file="result-item.tsx">
        <Image src={image} alt={title} data-sentry-element="Image" data-sentry-source-file="result-item.tsx" />
      </StyledImageContainer>
      <div>
        <p> {title}</p>
      </div>
    </StyledItem>;
};