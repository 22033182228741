export const getFirstPartLevelTitle = (levelTitle: string): string => {
  const startIndex = levelTitle.indexOf('[');
  const endIndex = levelTitle.indexOf(']', startIndex);

  return startIndex !== -1 && endIndex !== -1 ? levelTitle.slice(startIndex, endIndex + 1) : '';
};

export const getLastPartLevelTitle = ({ level, levelTitle }: { level: number; levelTitle: string }) => {
  if (level === 997 || level === 998 || level === 999 || level === 1337) {
    return levelTitle;
  }

  const parts = levelTitle.split('[').map((part) => part.split(']')[0]);

  if (parts.length > 2 && parts[2] !== undefined) {
    return `[${parts[2]}]`;
  }

  return '';
};
