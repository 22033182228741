import { useMemo } from 'react';
import { ItemProps } from '@tryhackme/thm-ui-components/dist/components/atoms/desktop-nav/desktop-nav.types';
import { useLocation } from 'react-router-dom';
export const UseIsNavItemActive = (item: ItemProps) => {
  const location = useLocation();
  const activeSubItem = useMemo(() => item.subItems?.filter(subItem => subItem.href === location.pathname)?.[0], [item.subItems, location.pathname]);
  const isNavItemActive = useMemo(() => {
    const isUserInRoom = location.pathname.startsWith('/room/');
    const isLearnSection = item.name === 'Learn';
    if (isUserInRoom && !isLearnSection) return Boolean(activeSubItem);
    if (isUserInRoom) return isLearnSection;
    if (location.pathname === '/paths' && item.name === 'Learn') return true;
    return Boolean(activeSubItem);
  }, [activeSubItem, item.name, location.pathname]);
  return isNavItemActive;
};