import React, { useEffect, useMemo, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import * as Dialog from '@radix-ui/react-dialog';
import { useLocation } from 'react-router-dom';
import { Button } from '@tryhackme/thm-ui-components';
import { useAppSelector } from 'src/app/hooks';
import { invalidateCachedRunningVms } from 'src/features/room/room.slice';
import { useShouldSeeLoadVmBackgroundIOSV2Experiment } from 'src/features/room/components/tasks/tasks.helpers';
import { SplitScreenNavigation } from './components/split-screen-navigation';
import { StyledDialogContent, StyledDialogContentWrapper, StyledSplitScreenContent, StyledSplitScreenLeft, StyledSplitScreenLeftWrapper, StyledSplitScreenRight, StyledSplitScreenWrapper } from './split-screen.styles';
import { SplitScreenProps } from './split-screen.types';
import { SplitScreenContent } from './components/split-screen-content';
const SplitScreen: React.FC<SplitScreenProps> = ({
  children
}) => {
  const location = useLocation();
  const isIOSRoom = location.pathname.includes('offensivesecurityintro');
  const {
    open: isSplitScreenOpen,
    tabs,
    currentTab
  } = useAppSelector(state => state.splitScreen);
  const shouldSeeLoadVMInBackgroundV2 = useShouldSeeLoadVmBackgroundIOSV2Experiment(isIOSRoom ? 'offensivesecurityintro' : '');
  const [bootTimeRemaining, {
    start: startBootTimeCountdown,
    reset: resetBootTimeCountdown
  }] = useCountDown(0, 1000);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  useEffect(() => {
    const bootTimeRemainingSeconds = currentTab?.vm?.remote.bootTimeRemainingSeconds;
    if (bootTimeRemainingSeconds && bootTimeRemainingSeconds > 0) {
      startBootTimeCountdown(bootTimeRemainingSeconds * 1000);
    } else {
      resetBootTimeCountdown();
    }
  }, [currentTab?.vm?.remote.bootTimeRemainingSeconds, startBootTimeCountdown, resetBootTimeCountdown]);
  useEffect(() => {
    if (bootTimeRemaining === 0 && currentTab?.vm?.remote.bootTime !== 0) {
      setTimeout(() => invalidateCachedRunningVms(), 1000);
    }
  }, [bootTimeRemaining, currentTab?.vm?.remote.bootTime]);
  const bootProgress = useMemo(() => {
    const bootTime = currentTab?.vm?.remote.bootTime || 2;
    const bootTimeSeconds = 60 * bootTime;
    return Math.round((bootTimeSeconds - bootTimeRemaining / 1000) / bootTimeSeconds * 100);
  }, [currentTab?.vm?.remote, bootTimeRemaining]);
  const handleCloseOverlay = () => {
    setIsOverlayVisible(false);
  };
  return <StyledSplitScreenWrapper open={isSplitScreenOpen} data-sentry-element="StyledSplitScreenWrapper" data-sentry-component="SplitScreen" data-sentry-source-file="split-screen.tsx">
      <StyledSplitScreenLeftWrapper open={isSplitScreenOpen} data-sentry-element="StyledSplitScreenLeftWrapper" data-sentry-source-file="split-screen.tsx">
        <StyledSplitScreenLeft open={isSplitScreenOpen} data-sentry-element="StyledSplitScreenLeft" data-sentry-source-file="split-screen.tsx">{children}</StyledSplitScreenLeft>
      </StyledSplitScreenLeftWrapper>
      <StyledSplitScreenRight open={isSplitScreenOpen} data-testid="splitScreenRight" data-sentry-element="StyledSplitScreenRight" data-sentry-source-file="split-screen.tsx">
        {shouldSeeLoadVMInBackgroundV2 && isSplitScreenOpen && bootProgress >= 98 && <Dialog.Root modal={false} open={isOverlayVisible}>
            <StyledDialogContentWrapper>
              <StyledDialogContent>
                <h5>Your machine is ready!</h5>
                <p>
                  This is your virtual machine, designed for practicing your cyber security skills in a safe
                  environment. Be sure to follow the task 2 instructions closely to successfully complete the room.{' '}
                </p>
                <Button variant="solid" onClick={handleCloseOverlay}>
                  Access Machine
                </Button>
              </StyledDialogContent>
            </StyledDialogContentWrapper>
          </Dialog.Root>}
        <StyledSplitScreenContent id="split-screen-right-side" open={isSplitScreenOpen} data-sentry-element="StyledSplitScreenContent" data-sentry-source-file="split-screen.tsx">
          {currentTab && <SplitScreenContent currentTabData={currentTab} bootProgress={bootProgress} />}
        </StyledSplitScreenContent>
        <SplitScreenNavigation currentTab={currentTab} tabs={tabs} open={isSplitScreenOpen} bootTimeRemaining={bootTimeRemaining} resetBootTimeCountdown={resetBootTimeCountdown} data-sentry-element="SplitScreenNavigation" data-sentry-source-file="split-screen.tsx" />
      </StyledSplitScreenRight>
    </StyledSplitScreenWrapper>;
};
export { SplitScreen };