import { Title, Container } from '@tryhackme/thm-ui-components';
import ImagePath from 'src/images/pngs/banned.png';
import ImagePath2x from 'src/images/pngs/banned@2x.png';
import { StyledErrorWrapper } from 'src/features/error-pages/error-pages.styles';
export const Banned = () => <Container data-sentry-element="Container" data-sentry-component="Banned" data-sentry-source-file="banned.tsx">
    <StyledErrorWrapper data-sentry-element="StyledErrorWrapper" data-sentry-source-file="banned.tsx">
      <div>
        <Title titleText="You’ve been banned" subTitleText={<>
              Your account has been banned because you may have violated our terms of use. If you think this is a
              mistake, please contact us at{' '}
              <a href="mailto:support@tryhackme.com" target="_blank" rel="noreferrer">
                support@tryhackme.com
              </a>
            </>} isLeftAlign data-sentry-element="Title" data-sentry-source-file="banned.tsx" />
      </div>
      <figure>
        <img src={ImagePath} alt="Banned user" width="784" height="759" srcSet={`${ImagePath2x} 2x`} />
      </figure>
    </StyledErrorWrapper>
  </Container>;