import { RTKQueryTag } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';
import { apiSlice } from 'src/features/api/api-slice';
import { RunningInstance } from 'src/common/interfaces/instances';
import { StartVMRequestBody } from 'src/common/interfaces/vms';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postStartVm: builder.mutation<ApiResponse<RunningInstance>, StartVMRequestBody>({
      query: ({ ...body }) => ({
        url: 'vms/start',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (!error && result ? [RTKQueryTag.RunningInstances] : []),
    }),
  }),
});

export const { usePostStartVmMutation } = extendedApiSlice;
