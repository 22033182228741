import styled, { css } from 'styled-components';
import { StyledBox, StyledButton, StyledFlex, Tab } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledTab = styled(Tab)`
  svg {
    margin-right: 0.6rem;
  }
`;

export const StyledMyLearning = styled(StyledBox)<{ $maxHeight?: string }>`
  width: 100%;
  padding: 2.4rem;
  height: auto;
  transition: all 0.3s linear;
  overflow: hidden;
  max-height: ${({ $maxHeight }) => $maxHeight ?? 'auto'};
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: '2.4rem';

  h2 {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    flex-wrap: wrap;
    gap: 2rem;
  }
`;

export const StyledLearningRoomsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  animation: fadeIn 0.3s linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const StyledViewMoreButton = styled(StyledButton)`
  margin-top: 1.6rem;
`;

export const StyledButtonsWrapper = styled(StyledFlex)(() =>
  css({
    marginTop: '2.4rem',
  }),
);

export const StyledPlayIcon = styled(FontAwesomeIcon)(() =>
  css({
    height: '1.8rem !important',
  }),
);

export const StyledResumeLearningButton = styled(StyledButton)(() =>
  css({
    width: '21rem',
    gap: '0.5rem',
  }),
);

export const StyledRoomTitle = styled.span<{ $isDarkMode: boolean }>(({ theme: { fonts }, $isDarkMode }) =>
  css({
    fontWeight: fonts.weight.bold,
    fontSize: fonts.sizes.small,
    ...($isDarkMode && { color: '#f9f9fb' }),
  }),
);

export const ProgressBarWrapper = styled.span(() =>
  css({
    width: '6.5rem',
  }),
);

export const StyledPathTitle = styled.span<{ $isDarkMode: boolean }>(({ theme: { fonts }, $isDarkMode }) =>
  css({
    fontWeight: fonts.weight.bold,
    fontSize: '2.4rem',
    lineHeight: '3rem',
    whiteSpace: 'nowrap',
    ...($isDarkMode && { color: '#f9f9fb' }),
  }),
);

export const StyledPathProgress = styled(StyledFlex)(() =>
  css({
    paddingLeft: '1.6rem',
    paddingBottom: '1.6rem',
  }),
);
