import { apiSlice } from 'src/features/api/api-slice';
import { LOCAL_STORAGE_REACT_NOTIFICATION_ID, RTKQueryTag } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';

import { NotificationResult, UserNotificationResult } from './notifications.types';

export const userNotificationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserNotifications: builder.query<ApiResponse<UserNotificationResult>, void>({
      query: () => '/notifications',
      providesTags: [RTKQueryTag.Notifications],
      transformResponse: (response: ApiResponse<UserNotificationResult>) => {
        // Static React Notification.
        const isReactNotificationRead = localStorage.getItem(LOCAL_STORAGE_REACT_NOTIFICATION_ID) === 'true';

        const reactNotification: NotificationResult = {
          _id: LOCAL_STORAGE_REACT_NOTIFICATION_ID,
          description: `We're making improvements to TryHackMe, and some pages may look slightly different than usual. Click <a href="/resources/blog/tryhackme-improvements">here</a> to learn more.`,
          read: isReactNotificationRead,
          icon: 'default',
          created: '2023-03-03T00:00:00.000Z',
        };

        // Add the react notification to the beginning of the notifications array
        response.data?.notifications.unshift(reactNotification);

        // Update notification count if React notification has not been read yet
        if (!isReactNotificationRead && typeof response.data?.unreadNotificationsCount === 'number') {
          response.data.unreadNotificationsCount += 1;
        }

        return response;
      },
    }),
    updateReadNotifications: builder.mutation<unknown, void>({
      query: () => ({
        method: 'POST',
        url: '/notifications/read',
      }),
      onQueryStarted: () => {
        localStorage.setItem(LOCAL_STORAGE_REACT_NOTIFICATION_ID, 'true');
      },
      invalidatesTags: [RTKQueryTag.Notifications],
    }),
  }),
});

export const { useGetUserNotificationsQuery, useUpdateReadNotificationsMutation } = userNotificationsSlice;
