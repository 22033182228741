import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { usePricing } from 'src/common/hooks';
import { useAnnouncementStorage } from 'src/features/announcements/hooks/use-announcement-storage';
import { Countdown } from '../countdown/countdown';
import { Benefits } from './components/benefits';
import { StyledContent, StyledCenteredContent, StyledHeading, StyledMessage, StyledSubTitle, StyledText } from './flash-sale-modal.styles';
export const FlashSaleModal: FC<AnnouncementComponentProps> = ({
  timeLeft,
  announcement
}) => {
  const theme = useTheme();
  const {
    isSeen,
    markAsSeen
  } = useAnnouncementStorage(announcement);
  const [open, setOpen] = useState(!isSeen);
  const {
    price,
    symbol
  } = usePricing(true);
  const discountedPrice = Number.parseFloat(price) * (100 - (announcement?.amount ?? 0)) / 100;
  const discounted = discountedPrice.toFixed(Number.isInteger(discountedPrice) ? 0 : 2);
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) return;
    markAsSeen();
    setOpen(false);
  };
  return <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }} data-sentry-element="ThemeProvider" data-sentry-component="FlashSaleModal" data-sentry-source-file="flash-sale-modal.tsx">
      <Modal open={open && !!timeLeft} onOpenChange={onOpenChange} preventCloseOnOutsideClick data-sentry-element="Modal" data-sentry-source-file="flash-sale-modal.tsx">
        <StyledContent data-sentry-element="StyledContent" data-sentry-source-file="flash-sale-modal.tsx">
          <StyledCenteredContent data-sentry-element="StyledCenteredContent" data-sentry-source-file="flash-sale-modal.tsx">
            <StyledHeading data-sentry-element="StyledHeading" data-sentry-source-file="flash-sale-modal.tsx">FLASH SALE: {announcement?.amount}% off</StyledHeading>
            <StyledSubTitle data-sentry-element="StyledSubTitle" data-sentry-source-file="flash-sale-modal.tsx">TryHackMe annual subscription</StyledSubTitle>
          </StyledCenteredContent>
          <Countdown timeLeft={timeLeft} data-sentry-element="Countdown" data-sentry-source-file="flash-sale-modal.tsx" />
          <Benefits data-sentry-element="Benefits" data-sentry-source-file="flash-sale-modal.tsx" />
          <StyledCenteredContent data-sentry-element="StyledCenteredContent" data-sentry-source-file="flash-sale-modal.tsx">
            <StyledMessage data-sentry-element="StyledMessage" data-sentry-source-file="flash-sale-modal.tsx">
              Subscribe for{' '}
              <span>
                {symbol}
                {price}
              </span>{' '}
              {symbol}
              {discounted}/year
            </StyledMessage>
            <StyledText data-sentry-element="StyledText" data-sentry-source-file="flash-sale-modal.tsx">
              Join <span>100,000s+</span> of professionals who have advanced their security careers.
            </StyledText>
          </StyledCenteredContent>
          <StyledButton as={Link} role="button" to="/why-subscribe" aria-label="Subscribe now" variant="solid" color="primary" onClick={() => onOpenChange(false)} data-sentry-element="StyledButton" data-sentry-source-file="flash-sale-modal.tsx">
            Subscribe Now
          </StyledButton>
        </StyledContent>
      </Modal>
    </ThemeProvider>;
};