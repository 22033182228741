import { AnnouncementName } from 'src/common/enums/announcements';
import { AnnouncementConfig } from 'src/common/interfaces/announcements';

import { FlashSaleBar } from './components/flash-sale-bar';
import { FlashSaleModal } from './components/flash-sale-modal';

export const currentAnnouncementBar: AnnouncementConfig = {
  name: AnnouncementName.FLASH_SALE_2024,
  pathsToExclude: ['/onboarding', '/welcome', '/room/:roomCode', '/payment/pending', '/feedback/subscription'],
  Component: FlashSaleBar,
};

export const currentAnnouncementModal: AnnouncementConfig = {
  name: AnnouncementName.FLASH_SALE_2024,
  pathsToInclude: ['/dashboard'],
  Component: FlashSaleModal,
};
