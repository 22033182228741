import { useState } from 'react';
import { NewRoomCard, StyledFlex } from '@tryhackme/thm-ui-components';
import { StyledLoading } from 'src/common/components';
import { StyledTitle } from 'src/common/styles';
import { StyledSectionBox } from '../commons';
import { LIMIT_STEP, MAX_NEW_ROOMS } from './new-rooms.constants';
import { StyledNewRoomsGrid, StyledViewMore } from './new-rooms.styles';
import { useGetNewRoomsQuery } from './new-rooms.slice';
export const NewRooms = () => {
  const [limit, setLimit] = useState(LIMIT_STEP);
  const {
    data,
    isFetching
  } = useGetNewRoomsQuery(limit);
  const rooms = data?.data?.docs ?? [];
  const hasMore = data?.data?.hasNextPage && limit < MAX_NEW_ROOMS;
  const viewMoreHandler = () => setLimit(prev => prev + LIMIT_STEP);
  return <StyledSectionBox data-sentry-element="StyledSectionBox" data-sentry-component="NewRooms" data-sentry-source-file="new-rooms.tsx">
      <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="new-rooms.tsx">New Rooms</StyledTitle>
      <StyledNewRoomsGrid data-sentry-element="StyledNewRoomsGrid" data-sentry-source-file="new-rooms.tsx">
        {rooms.map(({
        imageURL,
        title,
        difficulty,
        code,
        type
      }) => <NewRoomCard data-testid="new-room-card" aria-label="New room card" key={title} imageProps={{
        src: imageURL,
        alt: title
      }} title={title} difficulty={difficulty} url={`/room/${code}`} roomType={type} />)}
        {isFetching && <>
            <StyledLoading data-testid="new-room-loading" />
            <StyledLoading data-testid="new-room-loading" />
            <StyledLoading data-testid="new-room-loading" />
            <StyledLoading data-testid="new-room-loading" />
          </>}
      </StyledNewRoomsGrid>
      {hasMore && <StyledFlex justifyContent="center">
          <StyledViewMore color="info" variant="text" onClick={viewMoreHandler}>
            View more
          </StyledViewMore>
        </StyledFlex>}
    </StyledSectionBox>;
};