/* eslint-disable no-console */
import { useContext, useEffect, useRef } from 'react';
import { SwitchThemeContext } from '@tryhackme/thm-ui-components';

import { useUpdateThemeMutation } from '../slices/theme';
import { useGetUserQuery } from '../slices';
import { displayToast } from '../helpers/display-toast';
import { FeatureFlagName } from '../constants';

import { useFeatureFlag } from './use-feature-flag';

export const useThemeObserver = () => {
  const { theme, setTheme } = useContext(SwitchThemeContext);
  const [updateTheme] = useUpdateThemeMutation();
  const prevThemeRef = useRef(theme);
  const { data, isSuccess: isUserLoggedIn } = useGetUserQuery();
  const { enabled: isDarkModeEnabled } = useFeatureFlag(FeatureFlagName.REACT_DARK_MODE);

  const savedTheme = data?.data?.user.local?.theme;

  const canModifyTheme = isUserLoggedIn && isDarkModeEnabled && setTheme;

  useEffect(() => {
    if (canModifyTheme && savedTheme) {
      setTheme(savedTheme);
    }
  }, [canModifyTheme, savedTheme, setTheme]);

  useEffect(() => {
    const update = async () => {
      if (canModifyTheme && theme !== prevThemeRef.current) {
        try {
          prevThemeRef.current = theme;
          await updateTheme(theme).unwrap();
          // eslint-disable-next-line unicorn/prefer-optional-catch-binding
        } catch (error) {
          displayToast('error', 'Failed to save this theme');
        }
      }
    };

    // eslint-disable-next-line no-void
    void update();
  }, [canModifyTheme, theme, updateTheme]);
};
