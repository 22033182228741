import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Button, FormInput } from '@tryhackme/thm-ui-components';
import { useDebouncedCallback } from 'use-debounce';
import { useGetHacktivitiesSearchQuery } from 'src/features/hacktivities/search/content/content.slice';
import { SearchResults } from '../search-results/search-results';
import { StyledSearchResultsPopUp, StyledSearchInput, StyledSearchInfoBox } from './search-results-popup.styles';
import { hacktivitiesBasePath } from './constants';
export const SearchResultsPopUp = ({
  setIsSearchResultsPopUpOpen
}: {
  setIsSearchResultsPopUpOpen: (arg: boolean) => void;
}) => {
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('newest');
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data
  } = useGetHacktivitiesSearchQuery({
    kind: 'all',
    order,
    searchText
  });
  const {
    data: dataRooms,
    isFetching: isRoomsFetching
  } = useGetHacktivitiesSearchQuery({
    kind: 'rooms',
    order,
    searchText,
    newLimit: 3
  });
  const {
    data: dataPaths,
    isFetching: isPathsFetching
  } = useGetHacktivitiesSearchQuery({
    kind: 'paths',
    order,
    searchText,
    newLimit: 3
  });
  const {
    data: dataModules,
    isFetching: isModulesFetching
  } = useGetHacktivitiesSearchQuery({
    kind: 'modules',
    order,
    searchText,
    newLimit: 3
  });
  const {
    data: dataNetworks,
    isFetching: isNetworksFetching
  } = useGetHacktivitiesSearchQuery({
    kind: 'networks',
    order,
    searchText,
    newLimit: 3
  });
  const handleSearchValue = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setHasStartedTyping(true);
    setOrder('relevance');
  }, 350);
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        focusNextElement();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        focusPreviousElement();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const focusNextElement = () => {
    if (containerRef.current !== null) {
      const focusableElements: NodeListOf<HTMLElement> = containerRef.current.querySelectorAll('a[href], input');
      const {
        activeElement
      } = document;
      const currentIndex: number = Array.prototype.indexOf.call(focusableElements, activeElement);
      const nextIndex: number = (currentIndex + 1) % focusableElements.length;
      focusableElements[nextIndex]?.focus();
    }
  };
  const focusPreviousElement = () => {
    if (containerRef.current !== null) {
      const focusableElements: NodeListOf<HTMLElement> = containerRef.current.querySelectorAll('a[href], input');
      const {
        activeElement
      } = document;
      const currentIndex: number = Array.prototype.indexOf.call(focusableElements, activeElement);
      const prevIndex: number = (currentIndex - 1) % focusableElements.length;
      focusableElements[prevIndex]?.focus();
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newPath = `${hacktivitiesBasePath}all&searchText=${searchText}`;
      const newPathFromRoom = `/r${hacktivitiesBasePath}all&searchText=${searchText}`;
      setIsSearchResultsPopUpOpen(false);
      if (location.pathname.includes('/room/')) {
        window.open(newPathFromRoom, '_blank');
      } else {
        navigate(newPath, {
          replace: true
        });
      }
    }
  };
  const handleClearClick = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchText('');
    setHasStartedTyping(false);
  };
  return <StyledSearchResultsPopUp data-testid="search-results" ref={containerRef} id="popUp" data-sentry-element="StyledSearchResultsPopUp" data-sentry-component="SearchResultsPopUp" data-sentry-source-file="search-results-popup.tsx">
      <StyledSearchInput data-sentry-element="StyledSearchInput" data-sentry-source-file="search-results-popup.tsx">
        <FormInput leftAdornment={<FontAwesomeIcon icon={faSearch} />} rightAdornment={hasStartedTyping && searchText?.length >= 1 ? <Button data-testid="clear-btn" variant="text" color="secondary" onClick={handleClearClick}>
                <FontAwesomeIcon icon={faClose} />
              </Button> : <span />} onChange={handleSearchValue} onKeyDown={handleKeyDown} data-testid="search-input" ref={searchRef} data-sentry-element="FormInput" data-sentry-source-file="search-results-popup.tsx" />
      </StyledSearchInput>

      {hasStartedTyping && searchText ? <>
          {data?.data?.docs?.length === 0 && <StyledSearchInfoBox>
              <p data-content="title">No results found</p>
              <p data-content="subtitle">You may want to try different keywords or check for any possible typos.</p>
            </StyledSearchInfoBox>}
          {data?.data && data?.data?.docs.length >= 1 && <>
              <SearchResults title="Rooms" items={dataRooms?.data?.docs} itemsLength={dataRooms?.data?.totalDocs} link={`${hacktivitiesBasePath}rooms&searchText=${searchText}`} itemKind="room" isLoading={isRoomsFetching} />
              <SearchResults title="Modules" items={dataModules?.data?.docs} itemsLength={dataModules?.data?.totalDocs} link={`${hacktivitiesBasePath}modules&searchText=${searchText}`} itemKind="module" isLoading={isModulesFetching} />
              <SearchResults title="Learning paths" items={dataPaths?.data?.docs} itemsLength={dataPaths?.data?.totalDocs} link={`${hacktivitiesBasePath}paths&searchText=${searchText}`} itemKind="path" isLoading={isPathsFetching} />

              <SearchResults title="Networks" items={dataNetworks?.data?.docs} itemsLength={dataNetworks?.data?.totalDocs} link={`${hacktivitiesBasePath}networks&searchText=${searchText}`} itemKind="room" isLoading={isNetworksFetching} />
            </>}
        </> : <SearchResults title="Recently released" items={dataRooms?.data?.docs} itemsLength={dataRooms?.data?.totalDocs} link={`${hacktivitiesBasePath}rooms&order=newest`} itemKind="room" isLoading={isRoomsFetching} isThisWeekSection />}
    </StyledSearchResultsPopUp>;
};