import { useState } from 'react';
import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';
import { StyledButton, StyledCookieBanner, StyledLink, StyledText } from 'src/features/cookie-consent/cookie-consent.styles';
export const CookieConsent = () => {
  const cookies = new Cookies();
  const [showBanner, setShowBanner] = useState(!cookies.get('cookieconsent_status'));
  const handleDismissCookieConsent = () => {
    const expirationDate = addDays(new Date(), 365);
    cookies.set('cookieconsent_status', 'dismiss', {
      path: '/',
      expires: expirationDate
    });
    setShowBanner(false);
  };
  return <StyledCookieBanner showBanner={showBanner} data-sentry-element="StyledCookieBanner" data-sentry-component="CookieConsent" data-sentry-source-file="cookie-consent.tsx">
      <StyledText data-sentry-element="StyledText" data-sentry-source-file="cookie-consent.tsx">
        We use cookies to ensure you get the best user experience. For more information contact us.
      </StyledText>
      <StyledLink target="_blank" to="/legal/terms-of-use" data-sentry-element="StyledLink" data-sentry-source-file="cookie-consent.tsx">
        Read more
      </StyledLink>
      <StyledButton variant="solid" color="primary" onClick={handleDismissCookieConsent} data-sentry-element="StyledButton" data-sentry-source-file="cookie-consent.tsx">
        Got it!
      </StyledButton>
    </StyledCookieBanner>;
};