import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ModalStateType {
  restrictedService: boolean;
}

const initialState: ModalStateType = {
  restrictedService: false,
};

const modalStatesSlice = createSlice({
  name: 'modalStates',
  initialState,
  reducers: {
    setIsRestrictedServiceModalOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      restrictedService: action.payload,
    }),
  },
});

export const { setIsRestrictedServiceModalOpen } = modalStatesSlice.actions;

const modalStatesReducer = modalStatesSlice.reducer;
export { modalStatesReducer };
