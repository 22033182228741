import { useContext } from 'react';
import { StyledFlex, SwitchThemeContext } from '@tryhackme/thm-ui-components';
import { Radar } from 'react-chartjs-2';
import { ThemeColorMode } from 'src/common/enums';
import { StyledSectionBox } from '../commons';
import { SkillsMatrix } from './skill-matrix.types';
import { lightColorScheme, darkColorScheme } from './skill-matrix.constants';
import { StyledBetaTag, StyledQuestionTitle, StyledRadarChartWrapper, StyledRadarWrapper } from './skill-matrix.styles';
import { useGetUserSkillsMatrixQuery } from './skill-matrix.slice';
import { getGraphOptions, getRadarChartData } from './skill-matrix.helper';
import { SkillMatrixFallback } from './skill-matrix-fallback';
export const SkillMatrix = () => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkMode = theme === ThemeColorMode.DARK;
  const currentColorSettings = isDarkMode ? darkColorScheme : lightColorScheme;
  const {
    data,
    isLoading
  } = useGetUserSkillsMatrixQuery();
  const skillsMatrixData = (data?.data as SkillsMatrix)?.skills ?? [];
  const graphOptions = getGraphOptions(currentColorSettings);
  const radarChartData = getRadarChartData(skillsMatrixData, currentColorSettings);
  return <StyledSectionBox data-sentry-element="StyledSectionBox" data-sentry-component="SkillMatrix" data-sentry-source-file="skill-matrix.tsx">
      <StyledFlex gap="8px" justifyContent="flex-start" alignItems="center" data-sentry-element="StyledFlex" data-sentry-source-file="skill-matrix.tsx">
        <StyledQuestionTitle data-sentry-element="StyledQuestionTitle" data-sentry-source-file="skill-matrix.tsx">Skills Matrix</StyledQuestionTitle>
        <StyledBetaTag data-sentry-element="StyledBetaTag" data-sentry-source-file="skill-matrix.tsx">Beta</StyledBetaTag>
      </StyledFlex>
      <StyledRadarChartWrapper data-sentry-element="StyledRadarChartWrapper" data-sentry-source-file="skill-matrix.tsx">
        {!isLoading && skillsMatrixData.length ? <StyledRadarWrapper>
            <Radar aria-label="Radar Skills Matrix" data={radarChartData} options={graphOptions} />
          </StyledRadarWrapper> : <SkillMatrixFallback isLoadingChart={isLoading} fallbackText="Error Loading Skills Matrix" />}
      </StyledRadarChartWrapper>
    </StyledSectionBox>;
};