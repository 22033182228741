import { Button, Image, StyledButton } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { StyledList } from '../common.styles';
import { VpnContentProps } from '../../../access-networks.types';
export const MacOsContent: React.FC<VpnContentProps> = ({
  isLoading,
  downloadHandler
}) => <StyledList data-testid="mac-content" data-sentry-element="StyledList" data-sentry-component="MacOsContent" data-sentry-source-file="mac-content.tsx">
    <li>
      <Button variant="text" color="info" isLoading={isLoading} loadingMsg="Downloading..." onClick={downloadHandler} data-testid="download-button" data-sentry-element="Button" data-sentry-source-file="mac-content.tsx">
        Download
      </Button>{' '}
      your OpenVPN configuration pack.
    </li>
    <li>
      <StyledButton to="https://openvpn.net/client-connect-vpn-for-mac-os/" as={Link} variant="text" color="info" data-link="button" target="_blank" data-sentry-element="StyledButton" data-sentry-source-file="mac-content.tsx">
        Download
      </StyledButton>{' '}
      the OpenVPN for MacOS.
    </li>

    <li>
      Install the OpenVPN GUI application, by opening the dmg file and following the setup wizard.
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/mac_installer.png" alt="OpenVPN GUI application" width="324" data-sentry-element="Image" data-sentry-source-file="mac-content.tsx" />
      </div>
    </li>
    <li>Open and run the OpenVPN GUI application.</li>
    <li>
      The application will start running and appear in your top bar. Right click on the application and click Import
      File {'->'} Local file.
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/mac_import.png" alt="Import File" data-sentry-element="Image" data-sentry-source-file="mac-content.tsx" />
      </div>
    </li>
    <li>Select the configuration file you downloaded earlier.</li>
    <li>
      Right click on the application again, select your file and click connect.
      <div>
        <Image src="https://assets.tryhackme.com/img/connect/mac_connect.png" alt="Connect" data-sentry-element="Image" data-sentry-source-file="mac-content.tsx" />
      </div>
    </li>
  </StyledList>;