import type ReactAxe from '@axe-core/react';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import TagManager from '@sooro-io/react-gtm-module';
import Hotjar from '@hotjar/browser';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import './index.css';
import { initMock } from './mocks';
import { App } from './app';
import { reportWebVitals } from './report-web-vitals';
import { getCookieValue } from './common/helpers/cookies';
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console
  console.log(`%c------------------
< Happy Hacking! >
------------------
        \\   ^__^
         \\  (oo)\\_______
            (__)\\       )\\/\\
                ||----w |
                ||     ||

version: ${process.env.REACT_APP_VERSION ?? 'unknown'}`, 'font-family:monospace;color: #2a2;font-weight: bold;font-size: 14px;');
  const environment = (process.env.REACT_APP_ENVIRONMENT as 'production' | 'staging' | 'development' | (string & object) | undefined);
  Sentry.init({
    environment,
    release: process.env.REACT_APP_VERSION,
    dsn: 'https://175180b5f191796714d2f9138c06c76a@o4507096022450176.ingest.de.sentry.io/4507096429756496',
    integrations: [Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      enableInp: true
    }), Sentry.replayIntegration({
      beforeErrorSampling: event => {
        const doesErrorMessageContain = (regex: RegExp) => event.exception?.values?.some(exception => regex.test(exception.value ?? ''));
        if (doesErrorMessageContain(/lottie-player/gi)) return false;
        if (doesErrorMessageContain(/t\.sizes|sizes\.(sm|md|lg)/gi) || doesErrorMessageContain(/reading|evaluating/gi) && doesErrorMessageContain(/sm|md|lg/)) return false;
        if (doesErrorMessageContain(/object not found matching id/gi)) return false;
        return true;
      }
    })],
    // Performance Monitoring
    tracesSampleRate: environment === 'production' ? 0.0027 : 1,
    //  Fraction of captured "transactions"
    // Control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/[\w.-]*tryhackme\.site\/api/,
    // Matches any subdomain of tryhackme.site for API calls
    /^https:\/\/tryhackme\.com\/api/ // Production API
    ],
    // Session Replay
    replaysSessionSampleRate: 0.0001,
    replaysOnErrorSampleRate: 1 // If we're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  const userIdFromCookie = getCookieValue('logged-in-hint');
  if (userIdFromCookie) Sentry.setUser({
    id: userIdFromCookie,
    ip_address: '{{auto}}'
  });
} else {
  // This is also not working as expected on first load, issue with React Router v6 and react axe core
  // Issue on GitHub: https://github.com/dequelabs/axe-core-npm/issues/92
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-assignment, import/no-extraneous-dependencies, global-require, unicorn/prefer-module
  const axe = (require('@axe-core/react') as typeof ReactAxe);
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  axe(React, ReactDOM, 1000);
}
if (process.env.REACT_APP_HOTJAR_SITE_ID && process.env.REACT_APP_HOTJAR_VERSION) {
  const hotjarSiteId = Number.parseInt(process.env.REACT_APP_HOTJAR_SITE_ID, 10);
  const hotjarVersion = Number.parseInt(process.env.REACT_APP_HOTJAR_VERSION, 10);
  Hotjar.init(hotjarSiteId, hotjarVersion);
}
initMock();
TagManager.initialize({
  gtmId: 'GTM-WPFM5LPL'
});
const root = ReactDOM.createRoot((document.querySelector('#root') as HTMLElement));
root.render(<React.StrictMode>
    <App />
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();