import { useState } from 'react';

import {
  IS_CONTRIBUTE_TO_COMMUNITY_DISMISSED_KEY,
  SHOW_CONTRIBUTE_TO_COMMUNITY_POINTS_THRESHOLD,
} from 'src/features/dashboard/dashboard.constants';
import { HookOutput, UserOrUndefined } from 'src/common/components/community-section';

export const useShowContributeToCommunity = (user: UserOrUndefined): HookOutput => {
  const userPoints = user?.local?.totalPoints || 0;
  const isEligibleToContribute = userPoints > SHOW_CONTRIBUTE_TO_COMMUNITY_POINTS_THRESHOLD;
  const isPreviouslyDisabled = localStorage.getItem(IS_CONTRIBUTE_TO_COMMUNITY_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState(false);
  const isShowContributeToCommunity = !isClosed && isEligibleToContribute && !isPreviouslyDisabled;

  const hideContributeToCommunity = () => {
    setIsClosed(true);
    localStorage.setItem(IS_CONTRIBUTE_TO_COMMUNITY_DISMISSED_KEY, true.toString());
  };

  return [isShowContributeToCommunity, hideContributeToCommunity];
};
