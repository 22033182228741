import { useEffect, useRef, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { ONE_MIN_IN_MS } from 'src/common/constants';
import { ActiveMachineProps } from '../active-machine-info/active-machine-info.types';
import { MachineExpiringPopup } from './machine-expiring-popup';
const MachineExpiringWrapper = ({
  activeMachine
}: Pick<ActiveMachineProps, 'activeMachine'>) => {
  const [openMachineExpiringPopUp, setOpenMachineExpiringPopUp] = useState<boolean>(false);
  const hasExpireMachinePopUpAlreadyShown = useRef(false);
  useEffect(() => {
    let interval: NodeJS.Timer;
    const checkForExpiringMachines = (runningInstance: typeof activeMachine | undefined) => {
      if (runningInstance && differenceInMilliseconds(new Date(runningInstance.expires).getTime(), Date.now()) < ONE_MIN_IN_MS * 15 &&
      // open popup 15 minutes before machine expires
      !hasExpireMachinePopUpAlreadyShown.current) {
        setOpenMachineExpiringPopUp(true);
        hasExpireMachinePopUpAlreadyShown.current = true;
        if (interval) clearInterval(interval);
      }
    };
    if (differenceInMilliseconds(new Date(activeMachine.expires).getTime(), Date.now()) > ONE_MIN_IN_MS * 15) interval = setInterval(() => {
      checkForExpiringMachines(activeMachine);
    }, 1000);
    return () => {
      if (interval) clearInterval(interval);
      hasExpireMachinePopUpAlreadyShown.current = false;
    };
  }, [activeMachine]);
  return <MachineExpiringPopup open={openMachineExpiringPopUp} onOpenChange={setOpenMachineExpiringPopUp} data-sentry-element="MachineExpiringPopup" data-sentry-component="MachineExpiringWrapper" data-sentry-source-file="machine-expiring-wrapper.tsx" />;
};
export { MachineExpiringWrapper };