import { Button } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { StyledCookieBannerProps } from 'src/features/cookie-consent/cookie-consent.types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledCookieBanner = styled.div<StyledCookieBannerProps>`
  position: fixed;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  height: 17.2rem;
  padding: 1.9rem;
  border-radius: 5px;
  background: #3c404d;
  opacity: 1;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;

  ${({ showBanner }) =>
    !showBanner &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  ${({ showBanner }) =>
    showBanner &&
    css`
      animation: ${fadeIn} 1s ease-in;
    `}

  @media screen and (min-width: 415px) {
    max-width: 38.4rem;
    bottom: 2rem;
    right: 2rem;
  }
`;

export const StyledText = styled.p`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: #d6d6d6;
`;

export const StyledButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  width: 14rem;
  align-self: center;
  margin-top: auto;

  &&:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  width: fit-content;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.grey[400]};

  &:hover {
    color: #d6d6d6;
  }
`;
