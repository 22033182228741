import React, { useEffect, useRef, useState } from 'react';
import { StyledCopyButton, StyledCopyText, StyledCopyTextWrapper, StyledPlaceholder } from 'src/common/components/copy-to-clipboard/copy-to-clipboard.styles';
type TimeoutRefType = ReturnType<typeof setTimeout> | null;
const copyDefaultText = 'Copy';
const copiedDefaultText = 'Copied!';
export type CopyToClipboardProps = {
  copyText?: string;
  copiedText?: string;
  contentToCopy: string;
  className?: string;
};
const clearCustomTimeOut = (timeOutRef: TimeoutRefType) => {
  if (timeOutRef) clearTimeout(timeOutRef);
};
export const CopyTextToClipboard: React.FC<CopyToClipboardProps> = ({
  copyText = copyDefaultText,
  copiedText = copiedDefaultText,
  contentToCopy,
  className
}) => {
  const [copyButtonText, setCopyButtonText] = useState(copyText);
  const timeoutRef = useRef<TimeoutRefType>(null);
  useEffect(() => () => {
    clearCustomTimeOut(timeoutRef.current);
  }, []);
  const handleOnCopy = async () => {
    await navigator.clipboard.writeText(contentToCopy);
    setCopyButtonText(copiedText);
    clearCustomTimeOut(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setCopyButtonText(copyText);
    }, 2000);
  };
  return <StyledCopyTextWrapper className={className} data-sentry-element="StyledCopyTextWrapper" data-sentry-component="CopyTextToClipboard" data-sentry-source-file="copy-to-clipboard.tsx">
      <StyledPlaceholder data-sentry-element="StyledPlaceholder" data-sentry-source-file="copy-to-clipboard.tsx">
        <StyledCopyText data-sentry-element="StyledCopyText" data-sentry-source-file="copy-to-clipboard.tsx">{contentToCopy}</StyledCopyText>
      </StyledPlaceholder>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <StyledCopyButton variant="solid" color="add" onClick={handleOnCopy} data-sentry-element="StyledCopyButton" data-sentry-source-file="copy-to-clipboard.tsx">
        {copyButtonText}
      </StyledCopyButton>
    </StyledCopyTextWrapper>;
};