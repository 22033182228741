import { createBrowserRouter, redirect, RouterProvider as ReactRouterRouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SwagStoreURL } from 'src/common/constants';
import { Error500 } from 'src/features/error-pages';
import { Layout } from 'src/common/components/layout';
import { routes } from './routes';
import { backToTheLegacyLoader } from './route-protector';
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([{
  element: <Layout />,
  errorElement: <Error500 />,
  children: [...routes, {
    path: '/swag-shop',
    loader: () => redirect(SwagStoreURL),
    element: null
  }, {
    path: '*',
    loader: backToTheLegacyLoader,
    element: null
  }]
}], {
  basename: process.env.PUBLIC_URL
});
export const RouterProvider = () => <ReactRouterRouterProvider router={router} data-sentry-element="ReactRouterRouterProvider" data-sentry-component="RouterProvider" data-sentry-source-file="index.tsx" />;