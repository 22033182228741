import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.icon.lighter};
  margin-left: 1rem;
  height: 1.6rem;
  width: 1.6rem;
  transition: color 0.2s ease-in-out;

  :hover:not(:disabled) {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

export const StyledProgressBarMessage = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.tiny};
  color: ${({ theme }) => theme.colors.text.lighter};
  margin: 0.6rem 0 1.4rem 0;
`;

export const StyledShowHidePasswordButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 0.6rem;
  padding: 0;
  height: auto;

  svg {
    transition: fill 0.2s ease-in-out;
  }

  &:hover:not(:disabled) {
    svg {
      transition: fill 0.2s ease-in-out;
      color: ${({ theme }) => theme.colors.grey[600]};
    }
  }
`;

export const StyledValidationArea = styled.div<{ isVisible: boolean }>`
  width: 100%;
  overflow: hidden;
  ${({ isVisible }) =>
    isVisible
      ? css`
          margin-top: 1.4rem;
          max-height: 50rem;
          opacity: 1;
          visibility: visible;
        `
      : css`
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          visibility: hidden;
        `}
  transition:  margin-top 0.5s ease, max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
`;

export const StyledPasswordInputContainer = styled.div`
  :not(:focus-within) {
    ${StyledValidationArea} {
      display: none;
    }
  }
`;
