import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

const transitionDuration = '0.5s';

export const StyledSplitScreenWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  overflow: ${({ open }) => (open ? 'hidden' : 'visible')};
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: ${({ open }) => (open ? '100vh' : '')};
  position: relative;
`;

export const StyledDialogContentWrapper = styled(Dialog.Content)`
  position: absolute;
  text:
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(184, 190, 201, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 12;
`;

export const StyledSplitScreenRight = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ open }) => (open ? '50%' : '0')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: opacity ${transitionDuration} ease-in-out, transform ${transitionDuration} ease-in-out;
  background-color: ${({ theme }) => theme.colors.black};

  ${({ open }) => open && `height: 100vh; display: flex;flex-direction: column;z-index:11;`}
`;

export const StyledSplitScreenContent = styled.div<{ open: boolean }>`
  ${({ open }) => (open ? `flex-grow: 1` : 'display: none')};

  iframe {
    border: none;
  }
`;

export const StyledSplitScreenLeft = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSplitScreenLeftWrapper = styled.div<{ open: boolean }>`
  overflow-x: ${({ open }) => (open ? 'hidden' : 'visible')};
  width: ${({ open }) => (open ? '50%' : '100%')};
  margin-left: ${({ open }) => (open ? '-50%' : '')};
  transition: width ${transitionDuration} ease-in-out;
`;

export const StyledDialogContent = styled.div`
  padding: 2.4rem;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary.main}};
  border-radius: ${({ theme }) => theme.radius.small};

   h5 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    margin-bottom: 1rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.grey[200]};
  }

  p {
    max-width: 47rem;
    margin-bottom: 2.4rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey[200]};
  }

  button {
    &:hover:not(:disabled) {
      color: white;
    }
  }
`;
