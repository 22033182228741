import { css } from 'styled-components';

export const StyledAppearAnimation = css`
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
