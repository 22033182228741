import { FC } from 'react';
import { Link } from 'react-router-dom';
import { StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { Countdown } from '../countdown';
import { StyledBar, StyledCtaTimer, StyledHeading, StyledHighlight } from './flash-sale-bar.styles';
export const FlashSaleBar: FC<AnnouncementComponentProps> = ({
  timeLeft,
  announcement
}) => <StyledBar data-sentry-element="StyledBar" data-sentry-component="FlashSaleBar" data-sentry-source-file="flash-sale-bar.tsx">
    <StyledHeading data-sentry-element="StyledHeading" data-sentry-source-file="flash-sale-bar.tsx">
      FLASH SALE: <StyledHighlight data-sentry-element="StyledHighlight" data-sentry-source-file="flash-sale-bar.tsx">{announcement?.amount}% off</StyledHighlight> TryHackMe annual subscription!
    </StyledHeading>
    <StyledCtaTimer data-sentry-element="StyledCtaTimer" data-sentry-source-file="flash-sale-bar.tsx">
      <StyledButton as={Link} to="/why-subscribe" variant="solid" color="primary" data-sentry-element="StyledButton" data-sentry-source-file="flash-sale-bar.tsx">
        Subscribe Now
      </StyledButton>
      <Countdown timeLeft={timeLeft} data-sentry-element="Countdown" data-sentry-source-file="flash-sale-bar.tsx" />
    </StyledCtaTimer>
  </StyledBar>;