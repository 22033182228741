import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPowerOff, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { StyledFlex, Tooltip, ButtonGroup } from '@tryhackme/thm-ui-components';
import { setCurrentTab, setSplitScreenOpen, SplitScreenTab } from 'src/app/reducers/split-screen.reducer';
import { useExpiredVmTime, useExtendVmTime } from 'src/common/hooks';
import { useAppDispatch } from 'src/app/hooks';
import { ExpiredMachineModal } from 'src/features/room/components/active-machine-info/components/expired-machine-modal';
import { MachineExpiringWrapper } from 'src/features/room/components/machine-expiring';
import { TerminateMachineModal } from 'src/features/room/components/active-machine-info/components';
import { WebBasedMachineInfoPopUp } from '../web-based-machine-info-popup';
import { AccessVmCountDown } from '../access-vm-count-down';
import { SplitNavigationProps } from './split-screen-navigation.types';
import { StyledScreenNavigation, StyleActionButton, StyledTooltip, StyledTimeLeft, StyledButtonTab } from './split-screen-navigation.styles';
export const SplitScreenNavigation = ({
  tabs,
  currentTab,
  open = false,
  bootTimeRemaining,
  resetBootTimeCountdown
}: SplitNavigationProps) => {
  const [isOpenTerminateMachineModal, setIsOpenTerminateMachineModal] = useState(false);
  const [openWebMachinePopUp, setOpenWebMachinePopUp] = useState(false);
  const [openExpiredMachineModal, setOpenExpiredMachineModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    formattedTime
  } = useExpiredVmTime(currentTab?.vm?.expires ?? undefined, setOpenExpiredMachineModal);
  const {
    handleExtendVmTime
  } = useExtendVmTime();
  const handleChangeTab = useCallback((tab: SplitScreenTab) => {
    dispatch(setCurrentTab(tab));
    resetBootTimeCountdown();
  }, [dispatch, resetBootTimeCountdown]);
  return <>
      {currentTab?.vm && <>
          <ExpiredMachineModal open={openExpiredMachineModal} onOpenChange={setOpenExpiredMachineModal} />
          <MachineExpiringWrapper activeMachine={currentTab.vm} />
        </>}

      <StyledScreenNavigation data-testid="screen-navigation" open={open} data-sentry-element="StyledScreenNavigation" data-sentry-source-file="split-screen-navigation.tsx">
        <StyledFlex justifyContent="flex-start" data-sentry-element="StyledFlex" data-sentry-source-file="split-screen-navigation.tsx">
          {currentTab?.vm && <>
              <AccessVmCountDown remote={currentTab?.vm.remote} bootTimeRemaining={bootTimeRemaining} />

              <StyleActionButton aria-label="extend-time" type="button" onClick={() => {
            handleExtendVmTime(currentTab.id).catch(() => {});
          }}>
                <FontAwesomeIcon icon={faPlus} />
                <StyledTooltip data-tooltip="extend-machine">
                  <Tooltip arrowPosition="bottom-center">
                    <p>Extend machine timer</p>
                  </Tooltip>
                </StyledTooltip>
              </StyleActionButton>

              <StyleActionButton aria-label="terminate-machine" type="button" onClick={() => {
            setIsOpenTerminateMachineModal(true);
          }}>
                <FontAwesomeIcon icon={faPowerOff} />
                <StyledTooltip data-tooltip="terminate-machine">
                  <Tooltip arrowPosition="bottom-center">
                    <p>Terminate Machine</p>
                  </Tooltip>
                </StyledTooltip>
              </StyleActionButton>
            </>}

          <StyleActionButton onClick={() => dispatch(setSplitScreenOpen(false))} aria-label="exit-split-view" type="button" data-sentry-element="StyleActionButton" data-sentry-source-file="split-screen-navigation.tsx">
            <FontAwesomeIcon icon={faMinus} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="split-screen-navigation.tsx" />
            <StyledTooltip data-tooltip="exit-split-view" data-sentry-element="StyledTooltip" data-sentry-source-file="split-screen-navigation.tsx">
              <Tooltip arrowPosition="bottom-center" data-sentry-element="Tooltip" data-sentry-source-file="split-screen-navigation.tsx">
                <p>Exit split view</p>
              </Tooltip>
            </StyledTooltip>
          </StyleActionButton>
          {currentTab?.vm && <StyleActionButton aria-label="info" type="button" onClick={() => setOpenWebMachinePopUp(true)}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </StyleActionButton>}

          <div>
            <ButtonGroup isDarkVersion data-sentry-element="ButtonGroup" data-sentry-source-file="split-screen-navigation.tsx">
              {[...tabs].map(tab => <StyledButtonTab key={tab.id} role="tab" onClick={() => handleChangeTab(tab)} aria-selected={tab.id === currentTab?.id}>
                  {tab.title}
                </StyledButtonTab>)}
            </ButtonGroup>
          </div>
        </StyledFlex>

        {currentTab?.vm && <>
            <StyledTimeLeft>
              <p>{formattedTime}</p>
              <StyledTooltip data-tooltip="time-left">
                <Tooltip arrowPosition="bottom-right">
                  <p>Time left until your AttackBox expires</p>
                </Tooltip>
              </StyledTooltip>
            </StyledTimeLeft>
            <WebBasedMachineInfoPopUp open={openWebMachinePopUp} onOpenChange={setOpenWebMachinePopUp} tabData={currentTab} />
            <TerminateMachineModal open={isOpenTerminateMachineModal} onOpenChange={setIsOpenTerminateMachineModal} runningInstance={currentTab?.vm} />
          </>}
      </StyledScreenNavigation>
    </>;
};