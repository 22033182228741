import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { GhostContentResponse, GhostPostOptions, MergeArgs } from 'src/common/interfaces';
import { BASE_GHOST_CONTENT_URL, GhostContentOpts } from 'src/common/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_GHOST_CONTENT_URL,
  prepareHeaders(headers) {
    headers.set('Accept-Version', GhostContentOpts.version);
    return headers;
  },
});

const baseQueryWithErrorHandling: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (Number(result?.error?.status) >= 400) {
    window.location.replace('/r/500');
  }
  return result;
};

export const ghostContentApiSlice = createApi({
  reducerPath: 'ghostContentApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    getPosts: builder.query<GhostContentResponse, GhostPostOptions>({
      query: (postOptions) => ({
        url: 'posts',
        params: {
          include: 'authors,tags',
          ...postOptions,
          key: GhostContentOpts.key,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCacheData, responseData, args: MergeArgs) {
        if (args?.arg?.page > 1) {
          // eslint-disable-next-line no-param-reassign
          currentCacheData.meta = responseData.meta;
          currentCacheData.posts.push(...responseData.posts);
          return currentCacheData;
        }
        return responseData;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getAllPosts: builder.query<GhostContentResponse, GhostPostOptions>({
      query: (postOptions) => ({
        url: `/posts`,
        params: {
          include: 'authors,tags',
          limit: 'all',
          ...postOptions,
          key: GhostContentOpts.key,
        },
      }),
    }),
    getPost: builder.query<GhostContentResponse, GhostPostOptions & { slug: string }>({
      query: (postOptions) => ({
        url: `/posts/slug/${postOptions.slug}`,
        params: {
          include: 'authors,tags',
          ...postOptions,
          key: GhostContentOpts.key,
        },
      }),
    }),
  }),
});

export const { useGetPostsQuery, useGetAllPostsQuery, useGetPostQuery } = ghostContentApiSlice;
