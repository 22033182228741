import { FC } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import keyCardImage from 'src/images/pngs/flash-sale-keycard.png';
import { StyledBenefit, StyledContainer, StyledImage, StyledTextIcon } from './benefits.styles';
const benefits = ['Access to all 13 Learning Paths', 'Unlock over 880+ Rooms', 'Get your own browser-based Kali Machine', 'Private OpenVPN servers', 'Start machines faster'];
export const Benefits: FC = () => {
  const checkMarkIcon = <StyledTextIcon icon={faCheck} />;
  return <StyledContainer data-sentry-element="StyledContainer" data-sentry-component="Benefits" data-sentry-source-file="benefits.tsx">
      <div>
        {benefits.map(benefit => <StyledBenefit key={benefit}>
            {checkMarkIcon} <span>{benefit}</span>
          </StyledBenefit>)}
      </div>
      <StyledImage src={keyCardImage} data-sentry-element="StyledImage" data-sentry-source-file="benefits.tsx" />
    </StyledContainer>;
};