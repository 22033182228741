import { useLocation } from 'react-router-dom';
import { StyledLoading } from 'src/common/components';
import { ResultItem } from '../result-item';
import { StyledLoadingWrapper } from '../search-results-popup/search-results-popup.styles';
import { SectionResultsProps } from '../search-results-popup/search-results-popup.types';
import { StyledItemList, StyledNotFound, StyledResultsSection, StyledSectionHeader, StyledLink } from './search-results.styles';
export const SearchResults = ({
  title,
  items,
  link,
  itemsLength,
  isLoading,
  itemKind,
  isThisWeekSection
}: SectionResultsProps) => {
  const location = useLocation();
  return <StyledResultsSection data-sentry-element="StyledResultsSection" data-sentry-component="SearchResults" data-sentry-source-file="search-results.tsx">
      <StyledSectionHeader data-sentry-element="StyledSectionHeader" data-sentry-source-file="search-results.tsx">
        <p data-testid="section-title">
          {title}
          {!isThisWeekSection && <span> ({itemsLength ?? 0})</span>}
        </p>
        <StyledLink to={link} target={location.pathname.includes('/room/') ? '_blank' : '_self'} data-sentry-element="StyledLink" data-sentry-source-file="search-results.tsx">
          View all
        </StyledLink>
      </StyledSectionHeader>
      {isLoading ? <StyledLoadingWrapper>
          <StyledLoading height="4.6rem" />
          <StyledLoading height="4.6rem" />
          <StyledLoading height="4.6rem" />
        </StyledLoadingWrapper> : <StyledItemList>
          {items && items?.length < 1 && <StyledNotFound> No results found.</StyledNotFound>}
          {items?.map(item => {
        const pathUrl = `/path/outline/${item.code}`;
        let url;
        if (item.moduleURL && itemKind === 'module') {
          url = `${itemKind}/${item.moduleURL}`;
        } else if (itemKind === 'path') {
          url = pathUrl;
        } else {
          url = `${itemKind}/${item.code}`;
        }
        const image = item.imageURL || item.image;
        return <div key={itemKind === 'module' ? item.moduleURL : item.code}>
                <ResultItem title={item.title} code={item.code} image={image} itemUrl={url} />
              </div>;
      })}
        </StyledItemList>}
    </StyledResultsSection>;
};