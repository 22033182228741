import { FC } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useGetUserQuery } from 'src/common/slices';
import { AnnoucementsProps } from './announcements.types';
import { useAnnouncements } from './hooks/use-announcements';
export const Announcements: FC<AnnoucementsProps> = ({
  config
}) => {
  const {
    name,
    pathsToInclude,
    pathsToExclude,
    Component
  } = config;
  const {
    pathname
  } = useLocation();
  const {
    timeLeft,
    announcement,
    announcements
  } = useAnnouncements(name);
  const {
    data: userQueryData
  } = useGetUserQuery();
  const hasSubscriptions = !!userQueryData?.data?.user?.subscriptions?.length;
  const pageMatches = (pathsToInclude || pathsToExclude!).some(path => matchPath(path, pathname));
  const shouldShow = pathsToInclude ? pageMatches : !pageMatches;
  return timeLeft && shouldShow && !hasSubscriptions ? <Component timeLeft={timeLeft} announcement={announcement} announcements={announcements} /> : null;
};