import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

import { SkillsMatrix } from './skill-matrix.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserSkillsMatrix: builder.query<ApiResponse<SkillsMatrix>, void>({
      query: () => ({
        method: 'GET',
        url: 'users/skills',
      }),
    }),
  }),
});

export const { useGetUserSkillsMatrixQuery } = extendedApiSlice;
