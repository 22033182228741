import { useState } from 'react';
import { CodeTag, Image } from '@tryhackme/thm-ui-components';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { WebBasedMachineInfoPopUpProps } from 'src/common/components/split-screen/split-screen.types';
import { StyledInfoWrapper, StyledInfoItem, StyledItem, StyledWrapperImage, StyledArrowIcon } from './web-based-machine-info-popup.styles';
export const WebBasedMachineInfoPopUp = ({
  open,
  onOpenChange,
  tabData
}: WebBasedMachineInfoPopUpProps) => {
  const [isIPOpen, setIsIPOpen] = useState(false);
  const [isClickBoardImageOpen, setIsClickBoardImageOpen] = useState(false);
  return <StandardActionModal modalTitleText="Web-Based Machine Information" modalBodyText="Use the web-based machine to attack other target machines you start on TryHackMe." dismissButtonTitle="Close" showIcon open={open} onOpenChange={onOpenChange} showActionButton={false} data-sentry-element="StandardActionModal" data-sentry-component="WebBasedMachineInfoPopUp" data-sentry-source-file="web-based-machine-info-popup.tsx">
      <StyledInfoWrapper data-sentry-element="StyledInfoWrapper" data-sentry-source-file="web-based-machine-info-popup.tsx">
        <StyledInfoItem data-sentry-element="StyledInfoItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          Public IP:
          <button onClick={() => setIsIPOpen(true)} type="button">
            <CodeTag data-sentry-element="CodeTag" data-sentry-source-file="web-based-machine-info-popup.tsx">{isIPOpen ? tabData?.vm?.internalIP || '-' : 'Reveal IP'}</CodeTag>
          </button>
        </StyledInfoItem>
        <StyledInfoItem data-sentry-element="StyledInfoItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          Private IP: <CodeTag data-testid="private-ip" data-sentry-element="CodeTag" data-sentry-source-file="web-based-machine-info-popup.tsx">{tabData?.vm?.remote?.privateIP || '-'}</CodeTag>
          (Use this for your reverse shells)
        </StyledInfoItem>
        <StyledInfoItem data-sentry-element="StyledInfoItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          UserName: <CodeTag data-testid="username" data-sentry-element="CodeTag" data-sentry-source-file="web-based-machine-info-popup.tsx">{tabData?.vm?.credentials?.username || 'N/a'}</CodeTag>
        </StyledInfoItem>
        <StyledInfoItem data-sentry-element="StyledInfoItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          Password: <CodeTag data-testid="password" data-sentry-element="CodeTag" data-sentry-source-file="web-based-machine-info-popup.tsx">{tabData?.vm?.credentials?.password || 'N/a'}</CodeTag>
        </StyledInfoItem>
        <StyledInfoItem data-sentry-element="StyledInfoItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          Protocol: <CodeTag data-testid="protocol" data-sentry-element="CodeTag" data-sentry-source-file="web-based-machine-info-popup.tsx">{tabData?.vm?.remote?.protocol || 'N/a'}</CodeTag>
        </StyledInfoItem>
      </StyledInfoWrapper>
      <ul>
        <StyledItem data-sentry-element="StyledItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          To copy to and from the browser-based machine, highlight the text and press CTRL+SHIFT+C{' '}
        </StyledItem>
        <StyledItem data-item="clickable" onClick={() => setIsClickBoardImageOpen(!isClickBoardImageOpen)} data-sentry-element="StyledItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          Or use the clipboard <StyledArrowIcon icon={faAngleDown} $isClickBoardImageOpen={isClickBoardImageOpen} data-sentry-element="StyledArrowIcon" data-sentry-source-file="web-based-machine-info-popup.tsx" />
        </StyledItem>
        {isClickBoardImageOpen && <StyledWrapperImage data-testid="clipboard-img">
            <Image src="https://tryhackme.com/img/tutorials/clipboard.gif" alt="Clipboard example" />
          </StyledWrapperImage>}
        <StyledItem data-sentry-element="StyledItem" data-sentry-source-file="web-based-machine-info-popup.tsx">
          When accessing target machines you start on TryHackMe tasks, make sure you’re using the correct IP (it should
          not be the IP of your AttackBox)
        </StyledItem>
      </ul>
    </StandardActionModal>;
};