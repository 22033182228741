import { Button, darkColors } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: ${darkColors.info.main};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &[data-link='main'] {
    margin-bottom: 3rem;
    display: inline-block;
  }
`;

export const StyledVpnOptions = styled.section`
  color: ${({ theme }) => theme.colors.grey[100]};
  font-size: 1.6rem;
`;

export const StyledWrapperButtons = styled.div`
  display: flex;
  margin: 2.4rem 0 4.6rem;
  gap: 1.6rem;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.grey[100]};

  svg {
    fill: ${({ theme }) => theme.colors.grey[100]};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
    svg {
      fill: ${({ theme }) => theme.colors.secondary.main};
    }
  }

  &[data-active='open'] {
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.secondary.main};

    svg {
      fill: ${({ theme }) => theme.colors.secondary.main};
    }
  }
`;
