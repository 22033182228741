import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { StyledModalIcon } from 'src/common/components/standard-action-modal/standard-action-modal.styles';
import { TaskType } from 'src/common/enums/task';
import { OptionsModalProps } from 'src/features/room/room.types';
import { StyledModalBodyText } from '../commons';
const iconComponent = <StyledModalIcon data-icon="warning" icon={faWarning} />;
const modalBodyText = <StyledModalBodyText>
    Seems like you&apos;re on a smaller screen or a mobile device.
    <br />
    For the best experience, switch to a desktop or laptop with a minimum width of 960 pixels (9 inches).
  </StyledModalBodyText>;
export const DesktopOnlyAccessPopup = ({
  open,
  onOpenChange,
  closeAction,
  splitScreenType = TaskType.VM
}: Omit<OptionsModalProps, 'roomCode'>) => {
  const actionButtonTitle = splitScreenType === TaskType.VM ? 'Start machine anyway' : 'View site anyway';
  return <StandardActionModal modalTitleText="Large screen recommended" modalBodyText={modalBodyText} dismissButtonTitle="OK" showIcon iconComponent={iconComponent} open={open} onOpenChange={onOpenChange} handleModalAction={closeAction} showDismissButton={false} actionButtonColor="secondary" actionButtonTitle={actionButtonTitle} actionButtonVariant="outlined" data-sentry-element="StandardActionModal" data-sentry-component="DesktopOnlyAccessPopup" data-sentry-source-file="desktop-only-access-popup.tsx" />;
};