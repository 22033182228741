import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

const commonStyles = css`
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  color: ${({ theme }) => theme.colors.paragraph};
`;

export const StyledInfoWrapper = styled.ul`
  padding: 0 4.2rem;
  margin: 2.4rem 0;
`;

export const StyledInfoItem = styled.li`
  ${commonStyles};
  align-items: center;
  display: flex;
  gap: 0.6rem;
  margin-bottom: 0.8rem;

  & > span {
    margin-left: 0.4rem;
  }
`;

export const StyledItem = styled.li`
  ${commonStyles};
  list-style: disc;
  margin-left: 2rem;
  margin-bottom: 2.4rem;

  &[data-item='clickable'] {
    border-bottom: 0.2rem solid ${({ theme }) => theme.colors.secondary.lighter};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 2.4rem;
    margin-left: 0;
    padding-bottom: 0.7rem;
  }
`;

export const StyledWrapperImage = styled.div`
  display: grid;
  margin-bottom: 2.4rem;
  place-items: center;

  img {
    width: 30rem;
    height: auto;
  }
`;

export const StyledArrowIcon = styled(FontAwesomeIcon)<{ $isClickBoardImageOpen: boolean }>`
  transition: all 0.3s ease-in-out;
  transform: ${({ $isClickBoardImageOpen }) => ($isClickBoardImageOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;
