import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { Accordion, CircularProgress, darkColors, StyledFlex, theme } from '@tryhackme/thm-ui-components';
import { ThemeProvider } from 'styled-components';
import { DismissUserDashboardNotificationItems, WelcomeTasksData } from 'src/common/interfaces/user';
import { useUpdateDashboardNotificationMutation } from 'src/common/slices';
import { StyledSectionBox } from '../commons';
import { TOTAL_NUMBER_OF_WELCOME_TASKS } from './welcome-tasks.constants';
import { StyledWelcomeSectionTitle, StyledWelcomeSectionSubTitle, StyledFontAwesomeInfoIcon, StyledCompletionMessage, StyledWelcomeTasksFooter, StyledCloseWelcomeSection } from './welcome-tasks.styles';
export const WelcomeTasks = ({
  data
}: {
  data: WelcomeTasksData;
}) => {
  const [dismissNotification] = useUpdateDashboardNotificationMutation();
  const dismiss = () => {
    const notification = DismissUserDashboardNotificationItems.HAS_DISMISSED_WELCOME_TASKS;
    dismissNotification({
      notification
    }).catch(() => {});
  };
  const {
    hasAnyAnsweredQuestions,
    hasUserCompletedARoom,
    hasAtLeastTwoDayStreak
  } = data;
  let counter = 1;
  if (hasAtLeastTwoDayStreak) counter = 4;else if (hasUserCompletedARoom) counter = 3;else if (hasAnyAnsweredQuestions) counter = 2;
  const CheckedIcon = <StyledFontAwesomeInfoIcon icon={faCircleCheck} />;
  const UnCheckedIcon = <StyledFontAwesomeInfoIcon icon={faCircle} />;
  const isCompleted = counter === TOTAL_NUMBER_OF_WELCOME_TASKS;
  const progressPercentage = counter / TOTAL_NUMBER_OF_WELCOME_TASKS * 100;
  const customColors = {
    ...darkColors,
    border: {
      light: darkColors.background.lighter
    },
    background: {
      dark: darkColors.background.lighter
    }
  };
  return <StyledSectionBox isDarkVersion data-sentry-element="StyledSectionBox" data-sentry-component="WelcomeTasks" data-sentry-source-file="welcome-tasks.tsx">
      <StyledFlex gap="0" alignItems="start" flexWrap="no-wrap" data-sentry-element="StyledFlex" data-sentry-source-file="welcome-tasks.tsx">
        <StyledFlex flexWrap="no-wrap" gap="0.8rem" flexDirection="column" alignItems="start" data-sentry-element="StyledFlex" data-sentry-source-file="welcome-tasks.tsx">
          <StyledWelcomeSectionTitle data-sentry-element="StyledWelcomeSectionTitle" data-sentry-source-file="welcome-tasks.tsx">Welcome to TryHackMe!</StyledWelcomeSectionTitle>
          <StyledWelcomeSectionSubTitle data-sentry-element="StyledWelcomeSectionSubTitle" data-sentry-source-file="welcome-tasks.tsx">
            Complete these first steps to kickstart your cyber learning
          </StyledWelcomeSectionSubTitle>
        </StyledFlex>
        <CircularProgress fontSize="1.6rem" size={60} progressValue={progressPercentage} strokeWidth={3} isDarkVersion data-sentry-element="CircularProgress" data-sentry-source-file="welcome-tasks.tsx">
          {counter}/{TOTAL_NUMBER_OF_WELCOME_TASKS}
        </CircularProgress>
      </StyledFlex>
      <ThemeProvider theme={{
      ...theme,
      colors: (customColors as typeof darkColors)
    }} data-sentry-element="ThemeProvider" data-sentry-source-file="welcome-tasks.tsx">
        <StyledFlex flexDirection="column" gap="1.6rem" data-sentry-element="StyledFlex" data-sentry-source-file="welcome-tasks.tsx">
          <Accordion title="Enroll in a path" content="Choose the learning path most aligned with your goal" icon={CheckedIcon} data-sentry-element="Accordion" data-sentry-source-file="welcome-tasks.tsx" />
          <Accordion title="Answer a question" content="Access your learning path and answer your first question" icon={hasAnyAnsweredQuestions ? CheckedIcon : UnCheckedIcon} data-sentry-element="Accordion" data-sentry-source-file="welcome-tasks.tsx" />
          <Accordion title="Complete a room" content="Access your learning path and complete your first room" icon={hasUserCompletedARoom ? CheckedIcon : UnCheckedIcon} data-sentry-element="Accordion" data-sentry-source-file="welcome-tasks.tsx" />
          <Accordion title="Get a 2-day streak" content="Answer a question on two consecutive days to build your streak" icon={hasAtLeastTwoDayStreak ? CheckedIcon : UnCheckedIcon} data-sentry-element="Accordion" data-sentry-source-file="welcome-tasks.tsx" />
        </StyledFlex>
      </ThemeProvider>
      {isCompleted && <StyledWelcomeTasksFooter justifyContent="center" flexDirection="column" gap="1.6rem">
          <StyledCompletionMessage>Well done! You&apos;re on your way to becoming a cyber pro!</StyledCompletionMessage>
          <StyledCloseWelcomeSection onClick={dismiss} aria-label="close">
            Close
          </StyledCloseWelcomeSection>
        </StyledWelcomeTasksFooter>}
    </StyledSectionBox>;
};