import styled from 'styled-components';
import { StyledLoadingPlaceholder } from '@tryhackme/thm-ui-components';

export const StyledLoading = styled.div<{ width?: string; height?: string }>`
  ${StyledLoadingPlaceholder}
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '15.4rem'};
`;

export const StyledLoadingPagination = styled.div`
  display: grid;
  place-items: center;
  gap: 1.9rem;
`;
