// eslint-disable-next-line no-restricted-imports -- this file is where we extend RHF
import {
  useForm as originalUseForm,
  FieldValues,
  UseFormProps,
  FieldPath,
  RegisterOptions,
  UseFormRegister as OriginalUseFormRegister,
  InternalFieldName,
} from 'react-hook-form';

// eslint-disable-next-line no-restricted-imports -- this file is where we extend RHF
export * from 'react-hook-form';

const patchRegister =
  <TFieldValues extends FieldValues>(register: OriginalUseFormRegister<TFieldValues>) =>
  <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>,
  ) => {
    const { ref, ...rest } = register(name, options);
    return {
      ...rest,
      innerRef: ref,
    };
  };

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: UseFormProps<TFieldValues, TContext> = {},
) => {
  const returnValue = originalUseForm<TFieldValues, TContext, TTransformedValues>(props);
  return {
    ...returnValue,
    register: patchRegister(returnValue.register),
  };
};

// types below are written to infer all outputs based on inferred patchRegister return

export type UseFormRegister<TFieldValues extends FieldValues> = ReturnType<typeof patchRegister<TFieldValues>>;
// workaround while https://github.com/microsoft/TypeScript/issues/40179 is not possible in TS
export type UseFormRegisterReturn<TFieldName extends InternalFieldName = InternalFieldName> = ReturnType<
  UseFormRegister<FieldValues>
> & { name: TFieldName };

export declare type UseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = ReturnType<typeof useForm<TFieldValues, TContext, TTransformedValues>>;

export declare type FormProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = {
  children: React.ReactNode | React.ReactNode[];
} & UseFormReturn<TFieldValues, TContext, TTransformedValues>;

export declare const FormProvider: <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: FormProviderProps<TFieldValues, TContext, TTransformedValues>,
) => React.JSX.Element;

// useFormContext always pulls methods from context,
// so assuming our patched useForm was used for context values we just need to patch the types
export declare const useFormContext: <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>() => UseFormReturn<TFieldValues, TContext, TTransformedValues>;
