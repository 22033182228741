import { useState } from 'react';

import { IS_AOC_BANNER_DISMISSED_KEY } from 'src/features/dashboard/dashboard.constants';
import { HookOutput, UserOrUndefined } from 'src/common/components/community-section';
import { useFeatureFlag } from 'src/common/hooks';
import { FeatureFlagName } from 'src/common/constants';
import { AOC_BANNER_DISMISS_DATE } from 'src/features/christmas-page-2024/christmas-page-2024.constants';

export const useShowAoCBanner = (user: UserOrUndefined): HookOutput => {
  const isPreviouslyDisabled = localStorage.getItem(IS_AOC_BANNER_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { enabled: isChristmasBannerEnabled } = useFeatureFlag(FeatureFlagName.REACT_CHRISTMAS_BANNER);
  const isEventRunning = new Date(AOC_BANNER_DISMISS_DATE) > new Date();
  const userEligibleForAoCBanner = user && isChristmasBannerEnabled;
  const isShowAoCBanner = !isClosed && !isPreviouslyDisabled && userEligibleForAoCBanner && isEventRunning;

  const hideAoCBanner = () => {
    setIsClosed(true);
    localStorage.setItem(IS_AOC_BANNER_DISMISSED_KEY, true.toString());
  };

  return [isShowAoCBanner, hideAoCBanner] as HookOutput;
};
