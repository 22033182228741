import { useMemo } from 'react';
import { faLaptop, faKey } from '@fortawesome/free-solid-svg-icons';
import { StyledMachineBox, StyledButtonBoxIcon, StyledButtonBoxTitle } from 'src/features/room/components/commons/use-machine-box.styles';
import { ConnectionOptionsProps } from '../access-networks.types';
import { StyledConnectionOptionsTitle, StyledConnectionOptionsHeader, StyledConnectionOptionImage, StyledMachineBoxWrapper } from './connection-options.styles';
export const ConnectionOptions = ({
  setIsAttackBoxScreenOpen,
  setIsNetworksScreenOpen,
  setIsOpenVPNScreenOpen,
  machineIP
}: ConnectionOptionsProps) => {
  const handlerAttackBoxOnClick = () => {
    setIsAttackBoxScreenOpen(true);
    setIsNetworksScreenOpen(false);
  };
  const handlerOpenVPNOnClick = () => {
    setIsOpenVPNScreenOpen(true);
    setIsNetworksScreenOpen(false);
  };
  const isConnected: boolean = useMemo(() => !!machineIP, [machineIP]);
  return <div data-testid="connection-options" data-sentry-component="ConnectionOptions" data-sentry-source-file="connection-options.tsx">
      <StyledConnectionOptionsHeader data-sentry-element="StyledConnectionOptionsHeader" data-sentry-source-file="connection-options.tsx">
        <StyledConnectionOptionImage aria-label="networks-image" data-sentry-element="StyledConnectionOptionImage" data-sentry-source-file="connection-options.tsx" />

        <StyledConnectionOptionsTitle $isConnected={isConnected} data-sentry-element="StyledConnectionOptionsTitle" data-sentry-source-file="connection-options.tsx">
          You are <span>{isConnected ? 'connected' : 'disconnected'}</span> {isConnected ? 'via AttackBox' : ''}
        </StyledConnectionOptionsTitle>
        {machineIP && <p>Your machine IP is {machineIP}</p>}
        <p>To access target machines you need to either:</p>
      </StyledConnectionOptionsHeader>

      <StyledMachineBoxWrapper data-sentry-element="StyledMachineBoxWrapper" data-sentry-source-file="connection-options.tsx">
        <StyledMachineBox data-box="attack-box" onClick={handlerAttackBoxOnClick} data-sentry-element="StyledMachineBox" data-sentry-source-file="connection-options.tsx">
          <StyledButtonBoxIcon icon={faLaptop} data-sentry-element="StyledButtonBoxIcon" data-sentry-source-file="connection-options.tsx" />
          <div>
            <StyledButtonBoxTitle data-sentry-element="StyledButtonBoxTitle" data-sentry-source-file="connection-options.tsx">AttackBox</StyledButtonBoxTitle>
            <p>Use a browser-based attack machine</p>
          </div>
        </StyledMachineBox>

        <StyledMachineBox data-box="open-vpn" onClick={handlerOpenVPNOnClick} data-sentry-element="StyledMachineBox" data-sentry-source-file="connection-options.tsx">
          <StyledButtonBoxIcon icon={faKey} data-sentry-element="StyledButtonBoxIcon" data-sentry-source-file="connection-options.tsx" />
          <div>
            <StyledButtonBoxTitle data-sentry-element="StyledButtonBoxTitle" data-sentry-source-file="connection-options.tsx">OpenVPN (Advanced)</StyledButtonBoxTitle>
            <p>Connect to our network via a VPN</p>
          </div>
        </StyledMachineBox>
      </StyledMachineBoxWrapper>
    </div>;
};