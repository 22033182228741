import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { setHelperTooltipElements } from 'src/app/reducers/room/room.reducer';

import { useGetUserQuery } from '../slices';
import { TaskType } from '../enums/task';
import { NetworkState } from '../enums/networks';

export const useHelperTooltip = (roomCode: string) => {
  const dispatch = useAppDispatch();
  const { isSuccess: isUserAuth, data: userSelf } = useGetUserQuery();
  const { currentTab, tabs } = useAppSelector((state) => state.splitScreen);
  const { activeMachine } = useAppSelector((state) => state.room);
  const { networkDetails } = useAppSelector((state) => state.room);

  const tutorial = userSelf?.data?.user.tutorials;
  useEffect(() => {
    if (roomCode === 'tutorial' && isUserAuth && !tutorial?.rooms) {
      setTimeout(() => {
        dispatch(setHelperTooltipElements('rooms'));
      }, 750); // timeout is to defeat the animation delay for window load
    }
  }, [dispatch, isUserAuth, roomCode, tutorial?.rooms]);

  useEffect(() => {
    if (
      isUserAuth &&
      !tutorial?.staticsite &&
      tabs.some((tab) => tab.type === TaskType.STATIC_SITE && tab?.id === currentTab?.id)
    ) {
      setTimeout(() => {
        dispatch(setHelperTooltipElements('staticsite'));
      }, 1000); // timeout is to defeat the animation delay set for split screen
    }
  }, [currentTab, dispatch, isUserAuth, tabs, tutorial?.staticsite]);

  useEffect(() => {
    if (
      roomCode === 'tutorial' &&
      isUserAuth &&
      !tutorial?.attackbox &&
      tabs.some((tab) => tab.type === TaskType.VM && tab?.id === currentTab?.id) &&
      activeMachine
    ) {
      setTimeout(() => {
        dispatch(setHelperTooltipElements('attackbox'));
      }, 1000); // timeout is to defeat the animation delay set for split screen
    }
  }, [activeMachine, currentTab, dispatch, isUserAuth, roomCode, tabs, tutorial?.attackbox]);

  useEffect(() => {
    if (isUserAuth && !tutorial?.networks && networkDetails?.state === NetworkState.RUNNING) {
      setTimeout(() => {
        dispatch(setHelperTooltipElements('networks'));
      }, 1500); // timeout is to defeat the delay to render the Networks
    }
  }, [dispatch, isUserAuth, networkDetails?.state, tutorial?.networks]);
};
