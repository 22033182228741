import { StyledButton, darkColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledAttackBoxOption = styled.div`
  color: ${({ theme }) => theme.colors.grey[100]};

  p {
    margin-bottom: 2rem;
  }
`;

export const StyledAttackBoxOptionHeader = styled.div`
  text-align: center;
  margin-bottom: 4.6rem;

  svg {
    font-size: 2.4rem;
    margin-right: 1rem;
  }
`;

export const StyledClickable = styled(StyledButton)`
  color: ${darkColors.info.main};
`;
