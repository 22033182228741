/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { StyledLink } from '../../open-vpn-options.styles';
import { StyledCodeTag, StyledDropdown, StyledListWrapper } from './having-problems.styles';
export const HavingProblems = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  return <section data-sentry-component="HavingProblems" data-sentry-source-file="having-problems.tsx">
      <StyledDropdown onClick={() => setIsDropdownOpen(!isDropdownOpen)} $isDropdownOpen={isDropdownOpen} data-sentry-element="StyledDropdown" data-sentry-source-file="having-problems.tsx">
        Having Problems? <FontAwesomeIcon icon={faAngleDown} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="having-problems.tsx" />
      </StyledDropdown>

      {isDropdownOpen && <StyledListWrapper data-testid="dropdown-content">
          <li>
            If you can access{' '}
            <StyledLink to="http://10.10.10.10/" target="_blank">
              10.10.10.10
            </StyledLink>
            , you're connected.{' '}
          </li>
          <li>
            Downloading and getting a 404? Go the <StyledLink to="/access">access</StyledLink> page and switch VPN
            servers.
          </li>
          <li>
            Getting inline cert error? Go the <StyledLink to="/access">access</StyledLink> page and switch VPN servers.{' '}
          </li>
          <li>If you are using a virtual machine, you will need to run the VPN inside that machine.</li>
          <li>
            Is the OpenVPN client running as root? (On Windows, run OpenVPN GUI as administrator. On Linux, run with
            sudo).
          </li>

          <li>Have you restarted your VM?</li>
          <li>Is your OpenVPN up-to-date? </li>
          <li>
            Only 1 OpenVPN connection is allowed. (Run - <StyledCodeTag>ps aux | grep openvpn</StyledCodeTag> are there
            2 VPN sessions running?).
          </li>
          <li>
            Still having issues? Check our{' '}
            <StyledLink to="https://help.tryhackme.com/en/articles/6496029-openvpn-general-troubleshooting">
              docs
            </StyledLink>{' '}
            out.
          </li>
        </StyledListWrapper>}
    </section>;
};