import { StyledLoadingPlaceholder } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';
export const StyledGreeting = styled.h2`
  font-size: ${({
  theme
}) => theme.fonts.sizes.subTitle};
  font-weight: ${({
  theme
}) => theme.fonts.weight.semiBold};
  margin-bottom: 1.6rem;
`;
export const StyledSubGreeting = styled.p`
  font-size: ${({
  theme
}) => theme.fonts.sizes.medium};
  font-weight: ${({
  theme
}) => theme.fonts.weight.regular};
  margin: 0;
`;
export const GreetingContainer = styled.div`
  padding: 1rem 0;
  color: ${({
  theme
}) => theme.colors.text.main};
`;
export const StyledLoadingTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
export const StyledLoadLine = styled.span<{
  width?: string;
  isTitle?: boolean;
}>`
  ${StyledLoadingPlaceholder}
  height: ${({
  isTitle
}) => isTitle ? '3.7rem' : '2.1rem'};
  width: ${({
  width
}) => width ?? '50%'};
  margin-bottom: ${({
  isTitle
}) => isTitle ? '1.6rem' : '0'};
`;