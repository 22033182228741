import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { StyledButton, StyledFlex } from '@tryhackme/thm-ui-components';
import { getLastPartLevelTitle } from 'src/features/dashboard/helpers/get-rank-title';
import { getRankTitle } from 'src/common/helpers';
import { StyledLoading } from 'src/common/components';
import { StyledTitle } from 'src/common/styles';
import { StyledSectionBox } from '../commons/section-box';
import { StatsProps, Record } from './stats.types';
import { StyledRecordsWrapper, StyledUserName, StyledUserWrapper } from './stats.styles';
import { useHoverState } from './hooks/use-hover-state';
import { Level, RecordItem } from './components';
import { UserAvatar } from './components/avatar';
import { useGetUserStatsQuery } from './stats.slice';
export const Stats = ({
  userName,
  fullName,
  isLoading
}: StatsProps) => {
  const {
    data,
    isFetching
  } = useGetUserStatsQuery();
  const [hover, setHover, unsetHover] = useHoverState({
    completedRooms: false,
    points: false,
    streak: false,
    rank: false,
    badges: false
  });
  const records: Record[] = [{
    type: 'streak',
    record: data?.data?.streak ?? 0,
    highlightText: data?.data?.streak ?? 0,
    mainText: 'days streak',
    description: 'Your streak on TryHackMe'
  }, {
    type: 'rank',
    record: data?.data?.rank ?? 0,
    highlightText: data?.data?.rank ?? 0,
    mainText: 'Rank:',
    description: 'Complete more rooms<br />to gain a higher rank'
  }, {
    type: 'points',
    record: data?.data?.totalPoints ?? 0,
    highlightText: data?.data?.totalPoints ?? 0,
    mainText: 'points gained',
    description: 'Complete more rooms to gain more points. <br />Be amongst the first to complete new rooms to gain first blood points'
  }, {
    type: 'completedRooms',
    record: data?.data?.completedRoomsNumber ?? 0,
    highlightText: data?.data?.completedRoomsNumber ?? 0,
    mainText: 'rooms completed',
    description: 'Your total number of completed<br /> rooms on TryHackMe'
  }, {
    type: 'badges',
    record: data?.data?.badgesNumber ?? 0,
    highlightText: data?.data?.badgesNumber ?? 0,
    mainText: 'badges earned',
    description: 'Your total number of badges earned on TryHackMe.<br /> Complete more rooms to earn more badges'
  }];
  const userNameRank = getRankTitle(data?.data?.level ?? 0);
  return <StyledSectionBox data-sentry-element="StyledSectionBox" data-sentry-component="Stats" data-sentry-source-file="stats.tsx">
      <StyledFlex alignItems="start" data-sentry-element="StyledFlex" data-sentry-source-file="stats.tsx">
        <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="stats.tsx">Your Stats</StyledTitle>
        <StyledButton as={Link} to={`/p/${userName ?? ''}`} variant="outlined" color="secondary" data-sentry-element="StyledButton" data-sentry-source-file="stats.tsx">
          Go to profile
        </StyledButton>
      </StyledFlex>

      <Suspense fallback={<StyledLoading height="27.6rem" width="100%" />} data-sentry-element="Suspense" data-sentry-source-file="stats.tsx">
        <StyledUserWrapper data-sentry-element="StyledUserWrapper" data-sentry-source-file="stats.tsx">
          <UserAvatar avatar={data?.data?.avatar ?? ''} userName={userName} fullName={fullName ?? ''} data-sentry-element="UserAvatar" data-sentry-source-file="stats.tsx" />

          {isLoading ? <StyledLoading height="2.4rem" width="20rem" data-testid="user-loading" /> : <StyledUserName>
              <p data-text="user">{userName}</p>
              <p>{getLastPartLevelTitle({
              level: data?.data?.level ?? 0,
              levelTitle: userNameRank
            })}</p>
            </StyledUserName>}
          {isFetching ? <StyledLoading height="2.4rem" width="100%" data-testid="levels-loading" /> : <Level totalPoints={data?.data?.totalPoints ?? 0} />}
        </StyledUserWrapper>
        <StyledRecordsWrapper data-testid="wrapper-records" data-sentry-element="StyledRecordsWrapper" data-sentry-source-file="stats.tsx">
          {records.map(record => <RecordItem key={record.type} type={record.type} hover={hover[record.type]} onEnter={() => setHover(record.type)} onLeave={() => unsetHover(record.type)} record={record.record} isLoading={isFetching} tooltipHighlightText={record.highlightText} tooltipMainText={record.mainText} tooltipDescription={record.description} />)}
        </StyledRecordsWrapper>
      </Suspense>
    </StyledSectionBox>;
};