import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AoCBannerImage from 'src/images/pngs/dashboard/aoc-banner.png';
import AoCBannerTabletImage from 'src/images/pngs/dashboard/aoc-banner-tablet.png';
import AoCBannerMobileImage from 'src/images/pngs/dashboard/aoc-banner-mobile.png';
import GrinchedFont from 'src/features/christmas-page-2024/components/banner/grinched.otf';

export const StyledAoCPromotionalBannerContainer = styled.div`
  width: auto;
  height: fit-content;
  margin-top: 2.4rem;
  border-radius: 0.5rem;
  background: ${({ theme }) =>
    `radial-gradient(68.25% 68.25% at 48.88% 50%, rgba(29, 39, 65, 1) 0%, ${theme.colors.secondary.main} 100%);`};
`;

export const StyledAoCPromotionalBannerWrapper = styled.div`
  @font-face {
    font-family: 'GrinchedFont';
    font-weight: normal;
    src: url(${GrinchedFont}) format('opentype');
  }

  background-image: url(${AoCBannerMobileImage});
  border-radius: 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  grid-template-columns: 1fr 1rem;
  padding: 2.6rem 3.2rem;
  min-height: 23.2rem;
  width: 100%;

  & > div:first-child {
    p,
    h1 {
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
    }

    h2,
    h1 > span {
      color: ${({ theme }) => theme.colors.primary.main};
    }

    h2 {
      font-size: ${({ theme }) => theme.fonts.sizes.medium};
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      font-family: Source Sans Pro, 'sans-serif';
      margin-bottom: 1.6rem;
      text-align: center;
    }

    h1 {
      font-family: 'GrinchedFont', sans-serif;
      font-size: 4.6rem;
      margin-bottom: 1.2rem;
    }

    p {
      margin-bottom: 2.4rem;
      font-size: ${({ theme }) => theme.fonts.sizes.medium};
      font-weight: ${({ theme }) => theme.fonts.weight.regular};
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
    }

    button {
      width: 100%;

      span {
        font-weight: ${({ theme }) => theme.fonts.weight.bold};
      }
    }
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0.2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) and (max-width: ${({ theme }) => theme.sizes.lg}) {
    background-image: url(${AoCBannerTabletImage});
    grid-template-columns: 1fr 0.5rem;
    background-position: center;

    & > div:first-child {
      h2,
      h1,
      p {
        text-align: left;
      }

      button {
        width: auto;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    background-image: url(${AoCBannerImage});
    grid-template-columns: 2fr 1fr;
    background-position: right;

    & > div:first-child {
      h2,
      h1,
      p {
        text-align: left;
      }

      button {
        width: auto;
      }
    }
  }
`;

export const StyledAoCPromotionalCloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  height: 2.23rem;
  width: 2.23rem;
  color: ${({ theme }) => theme.colors.grey[500]};

  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
