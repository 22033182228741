import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const SEEN_ALERT_STORE_KEY = 'last-seen-incident-id';

export interface SocSimState {
  lastSeenIncidentId: Partial<Record<string, number>>;
}

const getInitialLastSeenIncidentId = (): SocSimState['lastSeenIncidentId'] => {
  const item = localStorage.getItem(SEEN_ALERT_STORE_KEY);
  if (!item) return {};
  try {
    return JSON.parse(item) as SocSimState['lastSeenIncidentId'];
  } catch {
    return {};
  }
};

const socSimSlice = createSlice({
  name: 'socSim',
  initialState: (): SocSimState => {
    const lastSeenIncidentId = getInitialLastSeenIncidentId();

    return { lastSeenIncidentId };
  },
  reducers: {
    setLastSeenIncidentId: (state, action: PayloadAction<{ runId: string; incidentId: number }>) => {
      if (action.payload.incidentId > (state.lastSeenIncidentId[action.payload.runId] ?? 0)) {
        const lastSeenIncidentId = { [action.payload.runId]: action.payload.incidentId };
        localStorage.setItem(SEEN_ALERT_STORE_KEY, JSON.stringify(lastSeenIncidentId));

        return {
          ...state,
          lastSeenIncidentId,
        };
      }

      return state;
    },
  },
});

export const { setLastSeenIncidentId } = socSimSlice.actions;

const socSimReducer = socSimSlice.reducer;
export { socSimReducer };
