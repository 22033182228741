import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  gap: 1.6rem;
  text-decoration: none;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.formSelect.optionHover};
    border-radius: ${({ theme }) => theme.radius.small};
    outline: none;
  }

  p {
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.small};
  height: 3.4rem;
  overflow: hidden;
  width: 3.4rem;

  & > img {
    height: inherit;
    object-fit: cover;
    width: inherit;
  }
`;
