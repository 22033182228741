import { Button } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as GoogleLogo } from 'src/images/svgs/brands/google-full-color.svg';

export const StyledForm = styled.form`
  label {
    margin-top: 2.4rem;
  }
`;

export const StyledTitle = styled.h2`
  margin-bottom: 0.7rem;
  line-height: 3.5rem;
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
`;

export const StyledSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.text.lighter};
`;

export const StyledValidationArea = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  ${({ isVisible }) =>
    isVisible
      ? css`
          margin-top: 1.4rem;
          max-height: 50rem;
          opacity: 1;
          visibility: visible;
        `
      : css`
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          visibility: hidden;
        `}
  transition:  margin-top 0.5s ease, max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
`;

export const StyledRecaptchaContainer = styled.div`
  margin-top: 2.4rem;
`;

export const StyledFullWidthButton = styled(Button)`
  margin-top: 2.4rem;
  width: 100%;
`;

export const StyledContinueWithButtons = styled.div`
  margin-top: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2.4rem 1.6rem;
`;

export const StyledContinueButton = styled(Button)`
  flex: 1 1 100%;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    flex: 1 1 0;
  }
`;

export const StyledLinedTextRow = styled.div`
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
`;

export const StyledLine = styled.span`
  width: 100%;
  height: 0.2rem;
  background: ${({ theme }) => theme.colors.border.light};
`;

export const StyledLinedText = styled.p`
  margin: 0 1.5rem;
  color: ${({ theme }) => theme.colors.text.lighter};
`;

export const StyledGoogleLogo = styled(GoogleLogo)`
  && {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const StyledTsAndCs = styled.p`
  margin-top: 2.4rem;
  color: ${({ theme }) => theme.colors.text.lighter};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
