import { StyledButton, darkColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledWrapperBody = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`;

export const StyledModalTitle = styled.h1`
  font-size: 3.2rem;
  margin: 0;
`;

export const StyledButtonVariant = styled(StyledButton)`
  background: ${darkColors.button.close.background};
  border-color: ${({ theme }) => theme.colors.secondary.lighter};
  color: ${({ theme }) => theme.colors.grey[100]};

  &:hover:not(:disabled) {
    color: ${darkColors.button.close.background};
  }
`;
