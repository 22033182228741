/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

import { useGetUserQuery, useGetFeatureFlagsQuery } from 'src/common/slices';
import { FeatureFlagName } from 'src/common/constants';
import { CustomerIOAPI } from 'src/common/interfaces/customer-io';

import { UserData } from '../interfaces';

export const useCustomerIOIdentify = () => {
  const { data: featureFlags, isLoading } = useGetFeatureFlagsQuery();
  const isCustomerIOIdentifyEnabled = !!featureFlags?.data?.find(
    (flag) => flag.name === FeatureFlagName.CUSTOMER_IO_IDENTIFY,
  )?.enabled;
  const {
    data: userData,
    isSuccess,
    isLoading: isLoadingUser,
  } = useGetUserQuery(undefined, { skip: isLoading || !isCustomerIOIdentifyEnabled });

  useEffect(() => {
    if (!isCustomerIOIdentifyEnabled) return;
    if (!window._cio || !userData?.data) return;

    if (isSuccess && !isLoadingUser) {
      const { data } = userData;
      const identifyUser = (user: UserData, cio: CustomerIOAPI) => {
        const id = user?.user?.email ?? '';
        const email = user?.user?.email ?? '';
        const dateSignUp = user?.user?.dateSignUp ?? '';
        const timestamp = Date.parse(dateSignUp);
        const fullName = user?.user?.fullName ?? '';
        cio.identify({ id, created_at: timestamp, email, first_name: fullName });
      };

      if (userData) {
        identifyUser(data, window._cio);
      }
    }
  }, [isCustomerIOIdentifyEnabled, isLoadingUser, isSuccess, userData]);
};
