import styled from 'styled-components';

export const StyledBar = styled.div`
  top: 0;
  background-color: ${({ theme }) => theme.colors.info.main};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 1.6rem;
  padding: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    height: 8rem;
    gap: 2.4rem;
    padding: 0;
  }
`;

export const StyledHeading = styled.span`
  margin: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 2.4rem;
  }
`;

export const StyledCtaTimer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
  }
`;

export const StyledHighlight = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
`;
