import { ChartData } from 'chart.js';

import { ChartJSContext, CanvasGradient } from '../commons';

import { SkillsMatrixScore, GraphColorScheme } from './skill-matrix.types';

let gradientCache: CanvasGradient | undefined;
let width = 0;
let height = 0;

export const createRadialGradient = (
  context: ChartJSContext,
  startColor: string,
  middleColor: string,
  endColor: string,
): CanvasGradient | undefined => {
  const { chartArea } = context.chart;
  // initial load
  if (!chartArea) return undefined;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (width !== chartWidth || height !== chartHeight) gradientCache = undefined;

  let gradient = gradientCache;

  if (!gradientCache) {
    // Create the gradient on first render or when the size of the chart has changed
    width = chartWidth;
    height = chartHeight;

    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const r = Math.min((chartArea.right - chartArea.left) / 2, (chartArea.bottom - chartArea.top) / 2);
    const { ctx } = context.chart;
    gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(0.5, middleColor);
    gradient.addColorStop(1, endColor);
    gradientCache = gradient;
  }
  return gradient as CanvasGradient;
};

const capScoreAt100 = (score: number) => Math.min(score, 100);

const convertDataForChart = (
  inputData: SkillsMatrixScore[],
  startColor: string,
  middleColor: string,
  endColor: string,
) => [
  {
    data: inputData.map((score) => capScoreAt100(score.score)),
    backgroundColor: (context: ChartJSContext) => createRadialGradient(context, startColor, middleColor, endColor),
    color: 'transparent',
    datalabels: { display: false },
  },
];

export const getRadarChartData = (
  data: SkillsMatrixScore[],
  currentColorSettings: GraphColorScheme,
): ChartData<'radar'> => ({
  labels: data.map((scoreItem) => scoreItem.label.split(' ')), // split label by space to wrap text
  datasets: convertDataForChart(
    data,
    currentColorSettings.backgroundGradientStart,
    currentColorSettings.backgroundGradientMiddle,
    currentColorSettings.backgroundEdge,
  ),
});

export const getGraphOptions = (currentColorSettings: GraphColorScheme) => ({
  elements: {
    point: { radius: 0, pointHitRadius: 20 },
    line: {
      borderColor: currentColorSettings.borderColor,
      circular: true,
      borderWidth: 1.7,
      lineTension: 0,
    },
  },
  scales: {
    r: {
      angleLines: {
        color: currentColorSettings.grid,
        borderDash: [4, 4],
      },
      grid: {
        color: currentColorSettings.grid,
      },
      pointLabels: {
        color: currentColorSettings.text,
        font: { size: 14, family: 'Source Sans Pro, sans-serif' },
      },
      ticks: {
        // keep ticks transparent to space out grid lines. To preserve grid lines spacing
        color: 'transparent',
        backdropColor: 'transparent',
        StepSize: 12.5,
      },
      beginAtZero: true,
      min: 0,
      max: 100,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
  },
});
