import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunningInstance } from 'src/common/interfaces/instances';
import { VmTypes } from 'src/common/interfaces/vms';
import { TOOLTIP_ELEMENTS } from 'src/features/room/components/helper-tooltips/helper-tooltip.constants';
import {
  TooltipElementsDataType,
  TutorialsTypes,
} from 'src/features/room/components/helper-tooltips/helper-tooltip.types';
import { NetworkDiagramRoomData } from 'src/common/interfaces';

export interface RoomState {
  taskStates: boolean[][];
  roomCode: string;
  isRoomCompleted: boolean;
  vmStartType: VmTypes;
  activeMachine?: RunningInstance;
  helperTooltipElements?: TooltipElementsDataType;
  remAttackBoxBtn: boolean;
  hasUserJoinedRoom: boolean;
  networkDetails?: NetworkDiagramRoomData | null;
  vmUploadId?: string;
  vmTaskId?: string;
}

const initialState: RoomState = {
  taskStates: [],
  roomCode: '',
  isRoomCompleted: false,
  vmStartType: 'AttackBox',
  helperTooltipElements: undefined,
  remAttackBoxBtn: true,
  hasUserJoinedRoom: false,
  networkDetails: undefined,
  vmUploadId: '',
  vmTaskId: '',
};

const roomStoreSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setTaskStates: (state, action: PayloadAction<boolean[][]>) => ({ ...state, taskStates: action.payload }),
    updateTaskQuestionState: (state, action: PayloadAction<{ taskNo: number; questionNo: number }>) => {
      const newState = [...state.taskStates];
      if (state.taskStates.length >= action.payload.taskNo - 1) {
        newState[action.payload.taskNo - 1] = newState[action.payload.taskNo - 1]?.slice() ?? [false];
        if (newState[action.payload.taskNo - 1]?.[action.payload.questionNo - 1] !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newState[action.payload.taskNo - 1]![action.payload.questionNo - 1] = true;
        }
      }

      return { ...state, taskStates: newState };
    },
    setRoomCode: (state, action: PayloadAction<string>) => ({ ...state, roomCode: action.payload }),
    setIsRoomComplete: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isRoomCompleted: action.payload,
    }),
    setVmStartType: (state, action: PayloadAction<VmTypes>) => ({ ...state, vmStartType: action.payload }),
    setActiveMachine: (state, action: PayloadAction<RunningInstance | undefined>) => ({
      ...state,
      activeMachine: action.payload,
    }),
    setHelperTooltipElements: (state, action: PayloadAction<TutorialsTypes | undefined>) => ({
      ...state,
      helperTooltipElements: action.payload ? TOOLTIP_ELEMENTS[action.payload] : undefined,
    }),
    setHasUserJoinedRoom: (state, action: PayloadAction<boolean>) => ({ ...state, hasUserJoinedRoom: action.payload }),
    setRemAttackBoxBtn: (state, action: PayloadAction<boolean>) => ({ ...state, remAttackBoxBtn: action.payload }),
    setNetworkDetails: (state, action: PayloadAction<NetworkDiagramRoomData | null | undefined>) => ({
      ...state,
      networkDetails: action.payload,
    }),
    setVmUploadId: (state, action: PayloadAction<string>) => ({ ...state, vmUploadId: action.payload }),
    setVmTaskId: (state, action: PayloadAction<string>) => ({ ...state, vmTaskId: action.payload }),
  },
});

export const {
  setTaskStates,
  updateTaskQuestionState,
  setRoomCode,
  setIsRoomComplete,
  setVmStartType,
  setActiveMachine,
  setHelperTooltipElements,
  setHasUserJoinedRoom,
  setRemAttackBoxBtn,
  setNetworkDetails,
  setVmUploadId,
  setVmTaskId,
} = roomStoreSlice.actions;
export const roomReducer = roomStoreSlice.reducer;
