import { ApiResponse, PostErrorResponse, QueryErrorResponse } from 'src/common/interfaces';

export const hasResponseData = <T>(obj: unknown): obj is { data: ApiResponse<T> } =>
  typeof obj === 'object' && !!obj && 'data' in obj && !!(obj as ApiResponse<T>)?.data && !('error' in obj);

export const hasErrorPostData = (obj: unknown): obj is PostErrorResponse =>
  typeof obj === 'object' && !!obj && 'error' in obj && isQueryErrorWithMessage(obj?.error);

export const isQueryErrorWithMessage = (error: unknown): error is QueryErrorResponse =>
  typeof error === 'object' &&
  error != null &&
  'data' in error &&
  typeof (error as QueryErrorResponse).data.message === 'string';
