import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { otherColors } from '@tryhackme/thm-ui-components';

import { ReactComponent as StreakIcon } from 'src/images/svgs/streak.svg';
import { ReactComponent as RoomCompletedIcon } from 'src/images/svgs/door-closed.svg';
import { StyledLoading } from 'src/common/components';

import { StyledAppearAnimation, StyledWrapperTooltipDefault } from '../../../commons';

export const StyledRecordItem = styled.div<{ $isTopRank?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  padding: 1.2rem 1.6rem;
  transition: all 0.3s ease-in-out;
  position: relative;

  p {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: 2rem;
  }

  &:hover {
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.colors.border.main};
    padding: ${({ $isTopRank }) => $isTopRank && '1.2rem 3.5rem'};
    p {
      cursor: default;
    }

    span {
      display: inline-block;
    }
  }

  &[data-type='rank'] {
    &:hover {
      svg path {
        fill: ${({ theme }) => theme.colors.background.invert};
      }
    }
  }

  &[data-type='points'] {
    &:hover {
      svg path {
        fill: ${({ theme }) => theme.colors.warning.main};
      }
    }
  }

  &[data-type='badges'] {
    &:hover {
      svg path {
        fill: ${otherColors.purple3[100]};
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    padding: 1.2rem 2rem;
  }
`;

export const StyledRecordIcon = styled(FontAwesomeIcon)`
  height: 2rem;

  path {
    fill: ${({ theme }) => theme.colors.grey[500]};
    transition: all 0.3s ease-in-out;
  }
`;

export const StyledStreakIcon = styled(StreakIcon)`
  .flame-top {
    fill: rgba(64, 159, 12, 1);
  }

  .flame-back {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const StyledRoomCompletedIcon = styled(RoomCompletedIcon)`
  path {
    fill: ${({ theme }) => theme.colors.info.main};
  }

  .floor {
    opacity: 0.6;
  }
`;

export const StyledRecordItemLoading = styled(StyledLoading)`
  animation-duration: 7s;
`;

export const StyledTopPercentTag = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.info.light};
  color: ${({ theme }) => theme.colors.text.light};
  padding: 0.2rem 0.4rem;
  border-radius: 0 0.4rem 0 0.4rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.info.main};
  display: none;
  z-index: 1;
`;

export const StyledTooltipWrapper = styled.div`
  ${StyledWrapperTooltipDefault}

  p {
    line-height: 1.6rem;
    text-align: left;
  }

  div[role='records-tooltip'] > div {
    padding: 1.6rem;
  }

  div[data-rank='1'] {
    display: flex;
    justify-content: space-between;

    span {
      color: ${({ theme }) => theme.colors.grey[900]};
    }
  }

  p[data-text='main'] {
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    margin-bottom: 0.8rem;

    & > span {
      color: ${({ theme }) => theme.colors.success.main};
    }
  }

  p[data-text='description'] {
    font-size: 1.2rem;
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    left: 0;
    top: 1.5rem;
    transform: translate(0, 100%);
  }
`;

export const StyledWrapperRecordItem = styled.div`
  position: relative;

  &:hover > div[data-section='records-tooltip'] {
    display: inline-block;
    animation: appear 0.3s ease-in-out;
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    & > div[data-type='badges'] + div[data-section='records-tooltip'] {
      transform: translate(-80%, 100%);
    }
  }

  ${StyledAppearAnimation};
`;
