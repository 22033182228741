import { MouseEvent, useState } from 'react';
import { faMagnifyingGlass, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, NotificationDispatch, StyledFlex, TextField } from '@tryhackme/thm-ui-components';
import { isQueryErrorWithMessage } from 'src/common/helpers/slices';
import { StyledLoading } from 'src/common/components';
import { UserItem } from '../user-item';
import { useAddFriendMutation, useSearchUsersMutation } from '../friends.slice';
import { UserType } from '../friends.types';
import { StyledAddFriends, StyledAddFriendsSection, StyledInputWrapper, StyledInviteButton, StyledSearchAddFriendsResults, StyledSearchEmptyResults, StyledSearchIcon } from './add-friends.styles';
export const AddFriends = ({
  setIsAddFriendsView
}: {
  setIsAddFriendsView: (arg: boolean) => void;
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchUsers, searchResponse] = useSearchUsersMutation();
  const {
    data,
    isLoading: isSearchLoading,
    isSuccess
  } = searchResponse;
  const searchResults = data?.data?.users?.docs ?? [];
  const [email, setEmail] = useState('');
  const [addFriend, {
    isLoading: isInviteByEmailLoading,
    isError
  }] = useAddFriendMutation();
  const [hasError, setHasError] = useState(isError);
  const handleInviteClick = (event: React.FormEvent<HTMLFormElement> | MouseEvent) => {
    event.preventDefault();
    const userType = UserType.EMAIL;
    const userProperty = email;
    addFriend({
      userType,
      userProperty
    }).unwrap().then(response => {
      NotificationDispatch('success', response.message);
      setEmail('');
      setHasError(false);
    }).catch(error => {
      NotificationDispatch('error', isQueryErrorWithMessage(error) ? error.data.message : 'Failed to send friend request');
    });
  };
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setHasError(false);
  };
  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleSearch = (event: React.FormEvent<HTMLFormElement> | MouseEvent) => {
    if ('preventDefault' in event) event.preventDefault();
    if (searchText) searchUsers(searchText).catch(error => {
      throw error;
    });
  };
  const isSearchResultsView = isSearchLoading || searchResults.length > 0;
  return <StyledAddFriendsSection data-sentry-element="StyledAddFriendsSection" data-sentry-component="AddFriends" data-sentry-source-file="add-friends.tsx">
      {!isSearchResultsView && <StyledAddFriends data-testid="addFriends">
          <p data-title="title">Invite friends</p>
          <p>
            Enter your friend’s email address and we will send them a personal invite.{' '}
            <FontAwesomeIcon icon={faSparkles} />
          </p>
          <form onSubmit={handleInviteClick}>
            <StyledInputWrapper>
              <TextField variant="input" type="text" label="Email address" placeholder="Enter email address" onChange={handleInput} value={email} data-testid="email-input" />
              <StyledInviteButton variant="outlined" color="add" data-btn="invite"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleInviteClick} disabled={isInviteByEmailLoading || hasError}>
                Invite
              </StyledInviteButton>
            </StyledInputWrapper>
          </form>
        </StyledAddFriends>}

      <form onSubmit={handleSearch}>
        <StyledInputWrapper data-sentry-element="StyledInputWrapper" data-sentry-source-file="add-friends.tsx">
          <TextField variant="input" type="text" onChange={handleSearchValue} leftAdornment={<StyledSearchIcon icon={faMagnifyingGlass} />} data-testid="search-input" placeholder="Search by username or email" data-sentry-element="TextField" data-sentry-source-file="add-friends.tsx" />
          <Button variant="outlined" color="add" onClick={handleSearch} disabled={!searchText.trim()} data-sentry-element="Button" data-sentry-source-file="add-friends.tsx">
            Search
          </Button>
        </StyledInputWrapper>
      </form>

      {isSearchResultsView && <StyledSearchAddFriendsResults data-testid="user-results">
          <ul>
            {searchResults.map((user, index) => <UserItem key={user.username} user={user} index={index} setIsAddFriendsView={setIsAddFriendsView} />)}
          </ul>
          {isSearchLoading && <StyledFlex flexDirection="column" gap="0.8rem" justifyContent="center">
              <StyledLoading height="3.8rem" />
              <StyledLoading height="3.8rem" />
              <StyledLoading height="3.8rem" />
              <StyledLoading height="3.8rem" />
            </StyledFlex>}
        </StyledSearchAddFriendsResults>}

      {isSuccess && !isSearchResultsView && <StyledSearchEmptyResults>
          <p>
            We couldn’t find any users with that name :( <br /> Check for typos or send them an invite!
          </p>
        </StyledSearchEmptyResults>}
    </StyledAddFriendsSection>;
};