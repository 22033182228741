import { LazyExoticComponent, Suspense } from 'react';
import { StyledTitle } from 'src/common/styles';
import { StyledSectionBox } from '../commons';
import { StyledLoadingText } from './lazy-section.styles';
export const LazySection = ({
  title,
  component: Component,
  ...props
}: {
  title: string;
  component: LazyExoticComponent<React.ComponentType<unknown>>;
  [key: string]: any;
}) => {
  const Loading = <StyledSectionBox>
      <StyledTitle>{title}</StyledTitle>
      <StyledLoadingText>Loading...</StyledLoadingText>
    </StyledSectionBox>;
  return <Suspense fallback={Loading} data-sentry-element="Suspense" data-sentry-component="LazySection" data-sentry-source-file="lazy-section.tsx">
      <Component {...props} data-sentry-element="Component" data-sentry-source-file="lazy-section.tsx" />
    </Suspense>;
};