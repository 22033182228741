import { Tooltip, useViewport } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { getLevelByTotalPoints, getProgressToNextLevel } from 'src/features/dashboard/components/stats/helpers/get-level-title-progress';
import { getFirstPartLevelTitle } from 'src/features/dashboard/helpers/get-rank-title';
import { LevelsToolTipContent } from 'src/common/components/levels-tooltip-content';
import { useTooltip } from 'src/features/dashboard/hooks';
import { StyledTooltipWrapper, StyledCircle, StyledLevelInfo, StyledProgressLevel, StyledProgressLevelWrapper, StyledLevelWrapper } from './level.styles';
export const Level = ({
  totalPoints
}: {
  totalPoints: number;
}) => {
  const {
    handleTouchStart,
    tooltipRef
  } = useTooltip();
  const {
    width
  } = useViewport();
  const {
    level,
    rank,
    levelPoints
  } = getLevelByTotalPoints(totalPoints);
  const percentage = getProgressToNextLevel(totalPoints);
  return <StyledLevelInfo onTouchStart={handleTouchStart} data-sentry-element="StyledLevelInfo" data-sentry-component="Level" data-sentry-source-file="level.tsx">
      <p data-testid="user-level" data-text="level">
        Level {level}
      </p>
      <StyledProgressLevelWrapper data-testid="progress-level" data-sentry-element="StyledProgressLevelWrapper" data-sentry-source-file="level.tsx">
        <StyledProgressLevel width={`${percentage}%`} data-sentry-element="StyledProgressLevel" data-sentry-source-file="level.tsx">
          <StyledCircle $percentage={percentage} data-sentry-element="StyledCircle" data-sentry-source-file="level.tsx">
            <FontAwesomeIcon icon={faBolt} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="level.tsx" />
          </StyledCircle>
        </StyledProgressLevel>
      </StyledProgressLevelWrapper>

      <p data-text="type">{getFirstPartLevelTitle(rank)}</p>
      <StyledTooltipWrapper data-section="level-tooltip" ref={tooltipRef} data-sentry-element="StyledTooltipWrapper" data-sentry-source-file="level.tsx">
        <Tooltip arrowPosition={width <= 1024 ? 'top-left' : 'right'} data-sentry-element="Tooltip" data-sentry-source-file="level.tsx">
          <StyledLevelWrapper data-sentry-element="StyledLevelWrapper" data-sentry-source-file="level.tsx">
            <p data-text="level">
              Level {level} <span>{rank}</span>
            </p>

            <p>
              {totalPoints}/{levelPoints} points
            </p>
          </StyledLevelWrapper>
          <LevelsToolTipContent data-sentry-element="LevelsToolTipContent" data-sentry-source-file="level.tsx" />
        </Tooltip>
      </StyledTooltipWrapper>
    </StyledLevelInfo>;
};