import { useState } from 'react';

import {
  IS_JOIN_COMMUNITY_DISMISSED_KEY,
  SHOW_CONTRIBUTE_TO_COMMUNITY_POINTS_THRESHOLD,
  SHOW_JOIN_COMMUNITY_POINTS_THRESHOLD,
} from 'src/features/dashboard/dashboard.constants';
import { HookOutput, UserOrUndefined } from 'src/common/components/community-section';

export const useShowJoinCommunity = (user: UserOrUndefined): HookOutput => {
  const userPoints = user?.local?.totalPoints || 0;
  const isEligibleForCommunity =
    userPoints >= SHOW_JOIN_COMMUNITY_POINTS_THRESHOLD && userPoints < SHOW_CONTRIBUTE_TO_COMMUNITY_POINTS_THRESHOLD;
  const isPreviouslyDisabled = localStorage.getItem(IS_JOIN_COMMUNITY_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState(false);
  const isShowJoinCommunity = !isClosed && isEligibleForCommunity && !isPreviouslyDisabled;

  const hideJoinCommunity = () => {
    setIsClosed(true);
    localStorage.setItem(IS_JOIN_COMMUNITY_DISMISSED_KEY, true.toString());
  };

  return [isShowJoinCommunity, hideJoinCommunity];
};
