import { useState } from 'react';

import {
  IS_ACCESS_NETWORKS_DISMISSED_KEY,
  SHOW_ACCESS_NETWORKS_STREAK_THRESHOLD,
} from 'src/features/dashboard/dashboard.constants';
import { HookOutput, UserOrUndefined } from 'src/common/components/community-section';

export const useShowAccessNetworks = (user: UserOrUndefined): HookOutput => {
  const isPremium = user?.isPremium;
  const streak = user?.streak?.streak ?? 0;
  const isEligibleForAccessNetworks = !isPremium && streak >= SHOW_ACCESS_NETWORKS_STREAK_THRESHOLD;
  const isPreviouslyDisabled = localStorage.getItem(IS_ACCESS_NETWORKS_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState(false);
  const isShowAccessNetworks = !isClosed && isEligibleForAccessNetworks && !isPreviouslyDisabled;

  const hideAccessNetworks = () => {
    setIsClosed(true);
    localStorage.setItem(IS_ACCESS_NETWORKS_DISMISSED_KEY, true.toString());
  };

  return [isShowAccessNetworks, hideAccessNetworks];
};
