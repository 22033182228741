import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.roomProgressBar.first},
    ${({ theme }) => theme.colors.roomProgressBar.second},
    ${({ theme }) => theme.colors.roomProgressBar.third}
  );
`;
