import { useEffect } from 'react';
import { getUnixTime } from 'date-fns';

import { useGetFeatureFlagsQuery, useGetUserQuery } from 'src/common/slices';
import { FeatureFlagName, INTERCOM_API_BASE, INTERCOM_APP_ID } from 'src/common/constants';
import { IntercomSettings } from 'src/common/interfaces/intercom';

const sharedIntercomSettings = {
  api_base: INTERCOM_API_BASE,
  app_id: INTERCOM_APP_ID,
};

const initializeIntercom = (intercomSettings: IntercomSettings) => {
  if (window.Intercom) window.Intercom('boot', intercomSettings);
};

const shutdownIntercom = () => {
  if (window.Intercom) window.Intercom('shutdown');
};

export const useIntercom = () => {
  const { data: featureFlags, isLoading } = useGetFeatureFlagsQuery();
  const isIntercomEnabled = !!featureFlags?.data?.find((flag) => flag.name === FeatureFlagName.INTERCOM_ENABLED)
    ?.enabled;
  const { data: userData, isSuccess, isError } = useGetUserQuery(undefined, { skip: isLoading || !isIntercomEnabled });

  useEffect(() => {
    if (!isIntercomEnabled) return;
    shutdownIntercom();
    const user = userData?.data?.user;
    if (user && isSuccess) {
      // User is logged in
      initializeIntercom({
        ...sharedIntercomSettings,
        user_id: user._id,
        email: user.email,
        name: user.username,
        created_at: getUnixTime(new Date(user.dateSignUp)),
        user_hash: user.intercomUserHmac,
      });
    } else if (isError) {
      // User is logged out
      initializeIntercom(sharedIntercomSettings);
    }
  }, [isIntercomEnabled, isError, isSuccess, userData]);
};
