import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@tryhackme/thm-ui-components';
import { RunningInstanceRemote } from 'src/common/interfaces/instances';
import { StyleActionButton, StyledTooltip } from '../split-screen-navigation/split-screen-navigation.styles';
export interface AccessVmCountDownProps {
  remote: RunningInstanceRemote;
  bootTimeRemaining?: number;
}
export const AccessVmCountDown = ({
  remote,
  bootTimeRemaining = 0
}: AccessVmCountDownProps) => <div data-sentry-component="AccessVmCountDown" data-sentry-source-file="access-vm-count-down.tsx">
    {bootTimeRemaining > 0 ? <p>
        Access desktop in{' '}
        <span>
          <span data-testid="bootTimeRemaining">{Math.round(bootTimeRemaining / 1000)}</span>s
        </span>
      </p> : <StyleActionButton aria-label="full-screen" type="button" onClick={() => window.open(remote.remoteUrl)}>
        <FontAwesomeIcon icon={faExpandAlt} />
        <StyledTooltip data-tooltip="full-screen">
          <Tooltip arrowPosition="bottom-left">
            <p>View in full screen</p>
          </Tooltip>
        </StyledTooltip>
      </StyleActionButton>}
  </div>;