import { TooltipArrow, TooltipContent } from '@radix-ui/react-tooltip';
import styled from 'styled-components';

export const StyledTooltipContent = styled(TooltipContent)<{
  $contentMaxWidth?: `${number}rem`;
}>`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.tertiary};
  border-radius: ${({ theme }) => theme.radius.small};
  box-shadow: ${({ theme }) => theme.boxShadow.light};
  opacity: 1;
  z-index: 1001;
  padding: 1.2rem;
  max-width: ${({ $contentMaxWidth }) => $contentMaxWidth ?? 'inherit'};
`;

export const StyledTooltipArrow = styled(TooltipArrow)`
  fill: ${({ theme }) => theme.colors.background.tertiary};
`;

export const StyledTooltipText = styled.p<{ $fontSize?: 'tiny' | 'default'; $textAlign?: 'center' | 'left' }>`
  font-size: ${({ $fontSize = 'default', theme }) => theme.fonts.sizes[$fontSize]};
  line-height: ${({ $fontSize = 'default' }) => ($fontSize === 'default' ? '1.5rem' : 'normal')};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
  color: ${({ theme }) => theme.colors.text.main};
`;
