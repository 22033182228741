import styled from 'styled-components';
import { darkColors } from '@tryhackme/thm-ui-components';

import { ReactComponent as ConectionOptionImage } from 'src/images/svgs/rooms/target-machines.svg';

export const StyledConnectionOptionsHeader = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  p {
    margin-bottom: 1.6rem;
  }
`;

export const StyledConnectionOptionImage = styled(ConectionOptionImage)`
  height: auto;
  margin-top: 5.6rem;
  margin-bottom: 4.6rem;
  width: 25rem;
`;

export const StyledConnectionOptionsTitle = styled.h1<{ $isConnected: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.4rem;
  text-align: center;

  span {
    color: ${({ theme, $isConnected }) => ($isConnected ? theme.colors.primary.main : darkColors.error.main)};
  }
`;

export const StyledMachineBoxWrapper = styled.div`
  & > div {
    cursor: pointer;
    transition: border 0.3s linear;

    &:hover {
      border-color: ${({ theme }) => theme.colors.grey[400]};
    }
  }
`;
