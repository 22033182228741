import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledFlex } from '@tryhackme/thm-ui-components';
import { StyledCommunityBox, StyledJoinTitle, StyledJoinDiscordLink, StyledCloseJoinCommunityBtn } from './community-section.styled';
import { CommunitySectionParams } from './community-section.types';
export const CommunitySection = ({
  dismiss,
  isWide = false,
  link,
  title,
  icon,
  buttonText
}: CommunitySectionParams) => <StyledCommunityBox isWide={isWide} data-sentry-element="StyledCommunityBox" data-sentry-component="CommunitySection" data-sentry-source-file="community-section.tsx">
    <StyledFlex flexWrap="no-wrap" gap="0" data-sentry-element="StyledFlex" data-sentry-source-file="community-section.tsx">
      <StyledJoinTitle data-sentry-element="StyledJoinTitle" data-sentry-source-file="community-section.tsx">{title}</StyledJoinTitle>
      <StyledCloseJoinCommunityBtn aria-label="close-notification" icon={faXmark} onClick={dismiss} data-sentry-element="StyledCloseJoinCommunityBtn" data-sentry-source-file="community-section.tsx" />
    </StyledFlex>
    <StyledJoinDiscordLink target="_blank" href={link} data-sentry-element="StyledJoinDiscordLink" data-sentry-source-file="community-section.tsx">
      <FontAwesomeIcon icon={icon} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="community-section.tsx" />
      {buttonText}
    </StyledJoinDiscordLink>
  </StyledCommunityBox>;