import { apiSlice } from 'src/features/api/api-slice';

import { NewRoomsResponse } from './new-rooms.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNewRooms: builder.query<NewRoomsResponse, number>({
      query: (limit) => ({
        method: 'GET',
        url: `hacktivities/extended-search`,
        params: {
          kind: 'rooms',
          difficulty: 'all',
          order: 'newest',
          roomType: 'all',
          page: 1,
          limit,
        },
      }),
    }),
  }),
});

export const { useGetNewRoomsQuery } = extendedApiSlice;
