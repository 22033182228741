export const GENERIC_ERROR = 'Something went wrong. Please try again.';
export const EMPTY_EMAIL_ERROR = 'Uh oh! You forgot to add your email.';
export const EMPTY_PASSWORD_ERROR = 'Uh oh! You forgot to choose a password.';
export const EMPTY_USERNAME_ERROR = 'Uh oh! You forgot to choose a username.';
export const INVALID_EMAIL_ERROR = 'This email address isn’t valid! Please try a different one.';
export const INVALID_PASSWORD_ERROR = 'This password isn’t valid! Please try a different one.';
export const INVALID_USERNAME_ERROR = 'This username isn’t valid! Please try a different one.';
export const ALREADY_EXISTS_EMAIL_ERROR = 'Oops! This email is associated with another account.';
export const ALREADY_EXISTS_USERNAME_ERROR = 'Oops! This username is associated with another account.';
export const EMPTY_RECAPTCHA_ERROR = 'Before proceeding, please complete the captcha.';

export const DEFAULT_INPUT_VALUES = {
  username: '',
  email: '',
  password: '',
};
