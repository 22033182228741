import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ManageRoomCategory } from 'src/common/interfaces/manage-room';
import { Tag } from 'src/features/admin/tagging/tagging.types';

export interface ManageRoomStateType {
  openWarnModal: boolean;
  isDirty: boolean;
  shouldBlockerProceed: boolean;
  shouldClearBlocker: boolean;
  generalCategories: {
    categories: ManageRoomCategory[];
    OSTags: ManageRoomCategory[];
    PovTags: ManageRoomCategory[];
  };
  roomCategories: {
    categories: Record<string, ManageRoomCategory>;
    OSTags: Record<string, ManageRoomCategory>;
    PovTags: Record<string, ManageRoomCategory>;
  };
  categoryDefaultValues: {
    categories: Record<string, ManageRoomCategory>;
    OSTags: Record<string, ManageRoomCategory>;
    PovTags: Record<string, ManageRoomCategory>;
  };
  modifiedTags: Tag[];
}

const initialState: ManageRoomStateType = {
  openWarnModal: false,
  isDirty: false,
  shouldBlockerProceed: false,
  shouldClearBlocker: false,
  generalCategories: {
    categories: [],
    OSTags: [],
    PovTags: [],
  },
  roomCategories: {
    categories: {},
    OSTags: {},
    PovTags: {},
  },
  categoryDefaultValues: {
    categories: {},
    OSTags: {},
    PovTags: {},
  },
  modifiedTags: [],
};

const manageRoomSlice = createSlice({
  name: 'manageRoom',
  initialState,
  reducers: {
    setOpenWarnModal: (state, action: PayloadAction<boolean>) => ({ ...state, openWarnModal: action.payload }),
    setIsDirty: (state, action: PayloadAction<boolean>) => ({ ...state, isDirty: action.payload }),
    setRoomCategories: (state, action: PayloadAction<ManageRoomCategory[]>) => {
      const categories: Record<string, ManageRoomCategory> = {};
      const OSTags: Record<string, ManageRoomCategory> = {};
      const PovTags: Record<string, ManageRoomCategory> = {};

      for (const category of action.payload) {
        if (category.type === 'topic') {
          categories[category._id] = category;
        }

        if (category.type === 'os') {
          OSTags[category._id] = category;
        }

        if (category.type === 'team') {
          PovTags[category._id] = category;
        }
      }

      return {
        ...state,
        roomCategories: { ...state.roomCategories, categories, OSTags, PovTags },
        categoryDefaultValues: {
          ...state.categoryDefaultValues,
          categories,
          OSTags,
          PovTags,
        },
      };
    },
    setCategories: (state, action: PayloadAction<ManageRoomCategory[]>) => {
      const categories = [];
      const OSTags = [];
      const PovTags = [];

      for (const category of action.payload) {
        if (category.type === 'topic') {
          categories.push(category);
        }

        if (category.type === 'os') {
          OSTags.push(category);
        }

        if (category.type === 'team') {
          PovTags.push(category);
        }
      }

      return {
        ...state,
        generalCategories: { ...state.generalCategories, categories, OSTags, PovTags },
      };
    },
    addCategory: (state, action: PayloadAction<ManageRoomCategory>) => ({
      ...state,
      roomCategories: {
        ...state.roomCategories,
        categories: { ...state.roomCategories.categories, [action.payload._id]: action.payload },
      },
      generalCategories: {
        ...state.generalCategories,
        categories: state.generalCategories.categories.filter((category) => category._id !== action.payload._id),
      },
    }),
    removeAddedCategory: (state, action: PayloadAction<ManageRoomCategory>) => {
      const { [action.payload._id]: categoryToRemove, ...rest } = state.roomCategories.categories;

      return {
        ...state,
        roomCategories: { ...state.roomCategories, categories: { ...rest } },
        generalCategories: {
          ...state.generalCategories,
          categories: [{ ...action.payload }, ...state.generalCategories.categories],
        },
      };
    },
    updateRoomCategory: (state, action: PayloadAction<ManageRoomCategory>) => ({
      ...state,
      roomCategories: {
        ...state.roomCategories,
        categories: { ...state.roomCategories.categories, [action.payload._id]: action.payload },
      },
    }),
    setTagsList: (state, action: PayloadAction<Tag[]>) => ({
      ...state,
      modifiedTags: action.payload,
    }),
    setTagsListByCategory: (state, action: PayloadAction<{ categoryId?: string; tags: Tag[] }>) => {
      const existingFilteredTags = state.modifiedTags.filter((tag) => tag.category !== action.payload.categoryId);

      const updatedTagList = [...existingFilteredTags, ...action.payload.tags];

      return {
        ...state,
        modifiedTags: updatedTagList,
      };
    },
    addToTagList: (state, action: PayloadAction<Tag>) => {
      const tagList = [...state.modifiedTags];
      tagList.push(action.payload);
      return {
        ...state,
        modifiedTags: tagList,
      };
    },
    addOSTag: (state, action: PayloadAction<ManageRoomCategory>) => ({
      ...state,
      roomCategories: {
        ...state.roomCategories,
        OSTags: { ...state.roomCategories.OSTags, [action.payload._id]: action.payload },
      },
      generalCategories: {
        ...state.generalCategories,
        OSTags: state.generalCategories.OSTags.filter((tag) => tag._id !== action.payload._id),
      },
    }),
    removeOSTag: (state, action: PayloadAction<ManageRoomCategory>) => {
      const { [action.payload._id]: OSTagToRemove, ...rest } = state.roomCategories.OSTags;

      return {
        ...state,
        generalCategories: { ...state.generalCategories, OSTags: [...state.generalCategories.OSTags, action.payload] },
        roomCategories: { ...state.roomCategories, OSTags: { ...rest } },
      };
    },
    addPovTag: (state, action: PayloadAction<ManageRoomCategory>) => ({
      ...state,
      roomCategories: {
        ...state.roomCategories,
        PovTags: { ...state.roomCategories.PovTags, [action.payload._id]: action.payload },
      },
      generalCategories: {
        ...state.generalCategories,
        PovTags: state.generalCategories.PovTags.filter((tag) => tag._id !== action.payload._id),
      },
    }),
    removePovTag: (state, action: PayloadAction<ManageRoomCategory>) => {
      const { [action.payload._id]: PovTagToRemove, ...rest } = state.roomCategories.PovTags;

      return {
        ...state,
        generalCategories: {
          ...state.generalCategories,
          PovTags: [...state.generalCategories.PovTags, action.payload],
        },
        roomCategories: { ...state.roomCategories, PovTags: { ...rest } },
      };
    },

    setShouldBlockerProceed: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shouldBlockerProceed: action.payload,
    }),
    setShouldClearBlocker: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shouldClearBlocker: action.payload,
    }),
  },
});

export const {
  addToTagList,
  setTagsList,
  setTagsListByCategory,
  setOpenWarnModal,
  setIsDirty,
  setRoomCategories,
  setCategories,
  addCategory,
  updateRoomCategory,
  removeAddedCategory,
  addOSTag,
  removeOSTag,
  addPovTag,
  removePovTag,
  setShouldBlockerProceed,
  setShouldClearBlocker,
} = manageRoomSlice.actions;
const manageRoomReducer = manageRoomSlice.reducer;
export { manageRoomReducer };
