import { RouteObject } from 'react-router-dom';
import { routeProtector } from 'src/router/route-protector';
import { FeatureFlagName } from 'src/common/constants';
import { getFeatureFlagsAtLoaderTime } from 'src/router/helpers';
import { ThemeColorMode } from 'src/common/enums';
export const careersRoutes: RouteObject[] = [{
  path: '/careers',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CAREERS_PAGE).requireCompletedOnboardingAndWelcome(),
  children: [{
    path: '',
    loader: getFeatureFlagsAtLoaderTime,
    async lazy() {
      const {
        CareersHub
      } = await import('src/features/career-pages/careers-hub/careers-hub');
      return {
        Component: CareersHub
      };
    }
  }, {
    path: 'penetration-tester',
    loader: getFeatureFlagsAtLoaderTime,
    async lazy() {
      const {
        PenetrationTester
      } = await import('src/features/career-pages/penetration-tester-v2');
      return {
        Component: PenetrationTester
      };
    }
  }, {
    path: 'security-engineer',
    loader: async loadersArgs => {
      const processFlow = routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ENGINEER_CAREER_PAGE);
      const protectorResult = await processFlow(loadersArgs);
      if (protectorResult) return protectorResult;
      return getFeatureFlagsAtLoaderTime();
    },
    async lazy() {
      const {
        SecurityEngineer
      } = await import('src/features/career-pages/security-engineer/security-engineer');
      return {
        Component: SecurityEngineer
      };
    }
  }, {
    path: 'red-teamer',
    loader: getFeatureFlagsAtLoaderTime,
    async lazy() {
      const {
        RedTeamer
      } = await import('src/features/career-pages/red-teamer');
      return {
        Component: RedTeamer
      };
    }
  }, {
    path: 'incident-responder',
    loader: getFeatureFlagsAtLoaderTime,
    async lazy() {
      const {
        IncidentResponder
      } = await import('src/features/career-pages/incident-responder');
      return {
        Component: IncidentResponder
      };
    }
  }, {
    path: 'cyber-security-analyst',
    loader: getFeatureFlagsAtLoaderTime,
    async lazy() {
      const {
        CyberSecurityAnalyst
      } = await import('src/features/career-pages/cyber-security-analyst-v2');
      return {
        Component: CyberSecurityAnalyst
      };
    }
  }, {
    path: 'quiz/:result?',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CAREERS_QUIZ_PAGE),
    async lazy() {
      const {
        CareersQuiz
      } = await import('src/features/career-pages/careers-quiz/careers-quiz');
      return {
        Component: CareersQuiz
      };
    },
    handle: {
      forcedTheme: ThemeColorMode.DARK,
      hideFooter: true,
      hideHeader: true
    }
  }]
}];