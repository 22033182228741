import { Link } from 'react-router-dom';
import { Title, Container, StyledButton } from '@tryhackme/thm-ui-components';
import ImagePath from 'src/images/pngs/404.png';
import ImagePath2x from 'src/images/pngs/404@2x.png';
import { StyledErrorWrapper } from 'src/features/error-pages/error-pages.styles';
import { SEO } from 'src/common/components/seo';
export const NotFound404 = () => <Container data-sentry-element="Container" data-sentry-component="NotFound404" data-sentry-source-file="not-found-404.tsx">
    <SEO title="404 - Page not found" description="Page not found" data-sentry-element="SEO" data-sentry-source-file="not-found-404.tsx" />
    <StyledErrorWrapper data-sentry-element="StyledErrorWrapper" data-sentry-source-file="not-found-404.tsx">
      <div>
        <Title titleText="404" titleTextSecondLine="Nothing to see here" subTitleText="This page doesn't exist..." subTitleTextSecondLine="Did it ever? It's a question of myth and mystery." isLeftAlign data-sentry-element="Title" data-sentry-source-file="not-found-404.tsx" />

        <StyledButton as={Link} to="/" color="primary" variant="solid" data-sentry-element="StyledButton" data-sentry-source-file="not-found-404.tsx">
          Take me home
        </StyledButton>
      </div>
      <figure>
        <img src={ImagePath} alt="Page not found" width="784" height="759" srcSet={`${ImagePath2x} 2x`} />
      </figure>
    </StyledErrorWrapper>
  </Container>;