import { Button, Modal, ModalCloseButton, ModalDescription, ModalFooter, ModalProps, ModalHeader } from '@tryhackme/thm-ui-components';
import { SmallModalProps } from './standard-action-modal.types';
import { StyledModalTitle } from './standard-action-modal.styles';
export const StandardActionModal = ({
  modalTitleText,
  modalBodyText,
  actionButtonTitle,
  dismissButtonTitle,
  handleModalAction,
  triggerElement,
  children,
  iconComponent,
  showIcon = false,
  showDismissButton = true,
  showActionButton = true,
  actionButtonColor = 'error',
  actionButtonVariant = 'solid',
  actionLoading,
  onOpenChange,
  open,
  handleCloseButtonAction,
  stepModal = false,
  ...otherModalProps
}: ModalProps & SmallModalProps) => {
  const handleAction = () => {
    if (onOpenChange) onOpenChange(stepModal);
    handleModalAction?.();
  };
  return <Modal open={open} onOpenChange={onOpenChange} {...otherModalProps} handleCloseButtonAction={handleCloseButtonAction} isSmall data-sentry-element="Modal" data-sentry-component="StandardActionModal" data-sentry-source-file="standard-action-modal.tsx">
      <ModalHeader isSmall data-sentry-element="ModalHeader" data-sentry-source-file="standard-action-modal.tsx">
        {showIcon && iconComponent}
        <StyledModalTitle isSmall data-sentry-element="StyledModalTitle" data-sentry-source-file="standard-action-modal.tsx">{modalTitleText}</StyledModalTitle>
      </ModalHeader>

      <ModalDescription data-sentry-element="ModalDescription" data-sentry-source-file="standard-action-modal.tsx">{modalBodyText}</ModalDescription>

      {children}

      <ModalFooter isSmall data-sentry-element="ModalFooter" data-sentry-source-file="standard-action-modal.tsx">
        {showDismissButton && <ModalCloseButton handleCloseButtonAction={handleCloseButtonAction} text={dismissButtonTitle} />}
        {showActionButton && <Button variant={actionButtonVariant} color={actionButtonColor} onClick={handleAction} isLoading={actionLoading} disabled={actionLoading}>
            {actionButtonTitle}
          </Button>}
      </ModalFooter>
    </Modal>;
};