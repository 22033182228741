import styled from 'styled-components';

export const StyledTableFooter = styled.div<{ $center?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;
  align-items: ${({ $center }) => ($center ? 'center' : 'flex-start')};

  @media (max-width: ${({ theme }) => theme.sizes.md}) {
    flex-direction: column;
    align-items: center;
  }

  > [role='note'] {
    margin-bottom: ${({ $center }) => ($center ? '0' : '2.4rem')};
  }
`;

export const StyledTableFooterText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 2.24rem;
  color: ${({ theme }) => theme.colors.text.lighter};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    margin-bottom: 1.5rem;
    text-align: left;
  }
`;

export const StyledTablePagination = styled.div`
  display: flex;
  flex-direction: column;
`;
