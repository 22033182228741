import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLaptop } from '@fortawesome/pro-regular-svg-icons';
import { setHelperTooltipElements } from 'src/app/reducers/room/room.reducer';
import { StyledButtonBoxIcon, StyledShowConnectionButton } from '../../commons';
import { StyledTitle } from '../access-networks.styles';
import { StyledAttackBoxOption, StyledAttackBoxOptionHeader, StyledClickable } from './attack-box-option.styles';
import { AttackBoxOptionsTypes } from './attack-box-options.types';
export const AttackBoxOption = ({
  setIsSlidingPaneOpen,
  setIsAttackBoxScreenOpen,
  setIsNetworksScreenOpen
}: AttackBoxOptionsTypes) => {
  const dispatch = useDispatch();
  const handleAttackBoxOnClick = useCallback(() => {
    setIsSlidingPaneOpen(false);
    setIsNetworksScreenOpen(true);
    setIsAttackBoxScreenOpen(false);
    dispatch(setHelperTooltipElements('startattackbox'));
  }, [dispatch, setIsAttackBoxScreenOpen, setIsNetworksScreenOpen, setIsSlidingPaneOpen]);
  const handlerConnectionOptionsOnClick = useCallback(() => {
    setIsNetworksScreenOpen(true);
    setIsAttackBoxScreenOpen(false);
  }, [setIsAttackBoxScreenOpen, setIsNetworksScreenOpen]);
  return <div data-testid="attack-box-screen" data-sentry-component="AttackBoxOption" data-sentry-source-file="attack-box-option.tsx">
      <StyledShowConnectionButton variant="text" onClick={handlerConnectionOptionsOnClick} data-sentry-element="StyledShowConnectionButton" data-sentry-source-file="attack-box-option.tsx">
        <FontAwesomeIcon icon={faArrowLeft} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="attack-box-option.tsx" /> Show Connection Options
      </StyledShowConnectionButton>
      <StyledAttackBoxOption data-sentry-element="StyledAttackBoxOption" data-sentry-source-file="attack-box-option.tsx">
        <StyledAttackBoxOptionHeader data-sentry-element="StyledAttackBoxOptionHeader" data-sentry-source-file="attack-box-option.tsx">
          <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="attack-box-option.tsx">
            <StyledButtonBoxIcon icon={faLaptop} data-sentry-element="StyledButtonBoxIcon" data-sentry-source-file="attack-box-option.tsx" /> AttackBox
          </StyledTitle>
          <p>Use your own web-based linux machine to access machines on TryHackMe</p>
        </StyledAttackBoxOptionHeader>

        <p>
          To start your AttackBox in the room, click the{' '}
          <StyledClickable type="button" variant="text" color="info" onClick={handleAttackBoxOnClick} data-sentry-element="StyledClickable" data-sentry-source-file="attack-box-option.tsx">
            Start AttackBox
          </StyledClickable>{' '}
          button. Your private machine will take 2 minutes to start.
        </p>
        <p>
          Free users get 1 free AttackBox hour.{' '}
          <StyledClickable as={Link} to="/why-subscribe" variant="text" color="info" data-sentry-element="StyledClickable" data-sentry-source-file="attack-box-option.tsx">
            Subscribed
          </StyledClickable>{' '}
          users get more powerful machines with unlimited deploys.
        </p>
      </StyledAttackBoxOption>
    </div>;
};