import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, StyledBox, darkColors, otherColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledAddFriendsSection = styled.section`
  padding: 0 1.5rem;
  min-height: 250px;
`;
export const StyledAddFriends = styled.div`
  padding-bottom: 2.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.dark};
  margin-bottom: 2.4rem;

  p {
    color: ${({ theme }) => theme.colors.text.main};
    margin-bottom: 1.6rem;

    &[data-title='title'] {
      font-weight: ${({ theme }) => theme.fonts.weight.bolder};
    }
  }

  svg path {
    fill: ${darkColors.info.main};
  }
`;

export const StyledSearchIcon = styled(FontAwesomeIcon)`
  height: 1.9rem;

  path {
    fill: ${({ theme }) => theme.colors.grey[700]};
  }
`;

export const StyledInputWrapper = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 1fr 10rem;
  gap: 0.8rem;
`;

export const StyledInviteButton = styled(Button)`
  background-color: ${otherColors.blue1[30]};
`;

export const StyledSearchEmptyResults = styled(StyledBox)`
  background-color: ${({ theme }) => theme.colors.background.lighter};
  margin-top: 0.8rem;
  padding: 0.8rem 1.6rem;
  width: 100%;

  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledSearchAddFriendsResults = styled.div`
  padding-top: 0.8rem;
  margin-top: 0.4rem;
  grid-column: 1 / -1;
  border-top: 1px solid ${({ theme }) => theme.colors.border.dark};

  ul {
    max-height: 20rem;
    overflow-y: auto;
  }
`;
