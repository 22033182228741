import { RouteObject } from 'react-router-dom';
import { FeatureFlagName } from 'src/common/constants';
import { ThemeColorMode } from 'src/common/enums';
import { ThmRouteObject } from 'src/common/interfaces';
import { routeProtector } from '../route-protector';
import { socSimRoutes } from './soc-sim';
const fullScreen = {
  hideFooter: true,
  hideHeader: true,
  forcedTheme: ThemeColorMode.DARK
};
const fullScreenWithFooter = {
  hideHeader: true,
  forcedTheme: ThemeColorMode.DARK
};
const makeChildrenFullScreen = (route: ThmRouteObject): ThmRouteObject => {
  let children: ThmRouteObject[] | undefined;
  if (route.children) {
    children = route.children.map(child => makeChildrenFullScreen({
      ...child
    }));
  }
  return ({
    ...route,
    children,
    handle: fullScreen
  } as ThmRouteObject);
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const socSimModifiedRoute = makeChildrenFullScreen(socSimRoutes[0]!);
export const certificationRoutes: RouteObject[] = [{
  path: '/certification',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE).requireCompletedOnboardingAndWelcome(),
  children: [{
    path: '',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE),
    handle: {
      forcedTheme: ThemeColorMode.DARK
    },
    async lazy() {
      const {
        SecurityAnalystLandingPage
      } = await import('src/features/certifications/security-analyst-landing-page');
      return {
        Component: SecurityAnalystLandingPage
      };
    }
  }, {
    path: 'junior-security-analyst',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE),
    async lazy() {
      const {
        JuniorSecurityAnalyst
      } = await import('src/features/certifications/junior-security-analyst');
      return {
        Component: JuniorSecurityAnalyst
      };
    }
  }, {
    path: 'exam/:certificationSlug',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
    async lazy() {
      const {
        CertificationExam
      } = await import('src/features/certifications/exam');
      return {
        Component: CertificationExam
      };
    },
    handle: fullScreen,
    children: [{
      ...socSimModifiedRoute,
      path: 'soc-sim'
    }]
  }, {
    path: 'exam/:certificationSlug/results',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
    async lazy() {
      const {
        CertificationResults
      } = await import('src/features/certifications/exam/certification-results');
      return {
        Component: CertificationResults
      };
    },
    handle: fullScreenWithFooter
  },
  // All below paths are used for testing purposes. Should be removed later on.
  {
    path: 'exam',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
    children: [{
      path: 'landing-page',
      handle: fullScreen,
      async lazy() {
        const {
          CertificationLandingPage
        } = await import('src/features/certifications/exam/certification-intro/certification-landing-page');
        return {
          Component: CertificationLandingPage
        };
      }
    }, {
      path: 'certification-verification',
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
      async lazy() {
        const {
          CertificationVerification
        } = await import('src/features/certifications/exam/certification-intro/certification-verification');
        return {
          Component: CertificationVerification
        };
      },
      handle: fullScreen
    }, {
      path: 'certification-verification-complete',
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
      async lazy() {
        const {
          CertificationVerificationComplete
        } = await import('src/features/certifications/exam/certification-intro/certification-verification-complete');
        return {
          Component: CertificationVerificationComplete
        };
      }
    }, {
      path: 'certification-instructions-video',
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
      async lazy() {
        const {
          CertificationInstructionsVideo
        } = await import('src/features/certifications/exam/certification-intro/certification-instructions-video');
        return {
          Component: CertificationInstructionsVideo
        };
      },
      handle: fullScreen
    }, {
      path: 'certification-identity-verification-state',
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
      async lazy() {
        const {
          CertificationIdentityVerificationState
        } = await import('src/features/certifications/exam/certification-intro/certification-identity-verification-state');
        return {
          Component: CertificationIdentityVerificationState
        };
      },
      handle: fullScreen
    }, {
      path: 'certification-agreement',
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
      async lazy() {
        const {
          CertificationAgreement
        } = await import('src/features/certifications/exam/certification-intro/certification-agreement');
        return {
          Component: CertificationAgreement
        };
      },
      handle: fullScreen
    }]
  }]
}];