import { format, intervalToDuration, parseISO } from 'date-fns';

import { ExpiryTimeLeft } from 'src/features/room/components/active-machine-info/active-machine-info.types';

export const calculateExpiryTimeLeft = (start: Date, end: Date): ExpiryTimeLeft => {
  const duration = intervalToDuration({
    start,
    end,
  });

  return { hours: duration.hours ?? 0, minutes: duration.minutes ?? 0, seconds: duration.seconds ?? 0 };
};

export const formatDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, 'dd MMM');
};

export const formatFullDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, 'd MMMM yyyy');
};
