import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
export const StyledCommunityBox = styled.div<{
  isWide: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 9.4rem;
  margin-top: 0.6rem;
  padding: 1.8rem 1.6rem;
  box-shadow: ${({
  theme
}) => theme.boxShadow.light};
  background-color: ${({
  theme
}) => theme.colors.background.main};
  border-radius: ${({
  theme
}) => theme.radius.small};
  @media screen and (min-width: ${({
  theme
}) => theme.sizes.md}) {
    width: ${({
  isWide
}) => isWide ? '24.9rem' : '22.4rem'};
    margin-top: 0;
  }
`;
export const StyledJoinTitle = styled.p`
  color: ${({
  theme
}) => theme.colors.text.main};
  font-size: ${({
  theme
}) => theme.fonts.sizes.small};
  font-family: ${({
  theme
}) => theme.fonts.familyUbuntu};
  font-weight: ${({
  theme
}) => theme.fonts.weight.semiBold};
  line-height: 1.4rem;
  margin: 0;
`;
export const StyledJoinDiscordLink = styled.a`
  width: 100%;
  height: 2.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  font-size: ${({
  theme
}) => theme.fonts.sizes.small};
  font-family: ${({
  theme
}) => theme.fonts.familyUbuntu};
  background-color: ${({
  theme
}) => theme.colors.background.secondary};
  color: ${({
  theme
}) => theme.colors.text.main};
  border: 0.1rem solid ${({
  theme
}) => theme.colors.border.light};
  border-radius: ${({
  theme
}) => theme.radius.small};
  text-decoration: none;
`;
export const StyledCloseJoinCommunityBtn = styled(FontAwesomeIcon)`
  height: 1.7rem;
  width: 1.7rem;
  position: relative;
  top: -0.7rem;
  right: -0.7rem;
  color: ${({
  theme
}) => theme.colors.text.main};
  cursor: pointer;
  @media screen and (min-width: ${({
  theme
}) => theme.sizes.md}) {
    height: 1.1rem;
    width: 1.1rem;
  }
`;