export enum ChurnZeroEntity {
  CONTACT = 'contact',
  ACCOUNT = 'account',
}
export enum ChurnZeroEventType {
  CONNECTED = 'connected',
}

export type AllowedChurnZeroPushEvents =
  | ['open' | 'close' | 'stop']
  | ['setAppKey' | 'setModule', string]
  | ['setContact', string, string]
  | ['setContact', string, string, string, string]
  | ['silent' | 'urltracking', boolean]
  | ['setAttribute', ChurnZeroEntity, string, string | number | boolean]
  | ['incrementAttribute', ChurnZeroEntity, string, number]
  | ['trackEvent', string, string, number, { [k: string]: string }]
  | ['ChurnZeroEvent:on', ChurnZeroEventType, (arg: any) => void]
  | ['ChurnZeroEvent:off', ChurnZeroEventType];

export interface ChurnZero {
  push: (arg: AllowedChurnZeroPushEvents) => void;
  verify: () => void;
  debug: () => void;
}
