import { Link } from 'react-router-dom';
import { Title, Container, StyledButton } from '@tryhackme/thm-ui-components';
import ImagePath from 'src/images/pngs/500.png';
import ImagePath2x from 'src/images/pngs/500@2x.png';
import { StyledErrorWrapper } from 'src/features/error-pages/error-pages.styles';
import { SEO } from 'src/common/components/seo';
export const Error500 = () => <Container data-sentry-element="Container" data-sentry-component="Error500" data-sentry-source-file="error-500.tsx">
    <SEO title="500" description="Something went wrong!" data-sentry-element="SEO" data-sentry-source-file="error-500.tsx" />
    <StyledErrorWrapper data-sentry-element="StyledErrorWrapper" data-sentry-source-file="error-500.tsx">
      <div>
        <Title titleText="500" titleTextSecondLine="Something went wrong!" subTitleText="We're sorry, we're trying to keep them under control." subTitleTextSecondLine="Maybe one day they'll follow us and form our army..." isLeftAlign data-sentry-element="Title" data-sentry-source-file="error-500.tsx" />

        <StyledButton as={Link} to="/" color="primary" variant="solid" data-sentry-element="StyledButton" data-sentry-source-file="error-500.tsx">
          Take me home
        </StyledButton>
      </div>
      <figure>
        <img src={ImagePath} alt="Page not found" width="784" height="759" srcSet={`${ImagePath2x} 2x`} />
      </figure>
    </StyledErrorWrapper>
  </Container>;