import styled from 'styled-components';

import WelcomeToPremiumWave from 'src/images/svgs/welcome-to-premium-wave.svg';

export const StyledContent = styled.div`
  background: ${({ theme }) => theme.colors.secondary.main};
  background-image: url(${WelcomeToPremiumWave});
  width: 100%;
  height: 64rem;
  path {
    fill: ${({ theme }) => theme.colors.text.main};
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    height: 53rem;
  }
`;

export const StyledWelcomeToPremiumTitle = styled.h1`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 3.2rem;
  margin: 4.1rem 0 2.4rem 0;
  text-align: center;
  span {
    color: ${({ theme }) => theme.colors.text.highlight};
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    margin: 1.2rem 0 0.7rem 0;
  }
`;

export const StyledWelcomeToPremiumSubTitle = styled.p`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 1.8rem;
  text-align: center;
  max-width: 64rem;
  margin: 0 0 4.1rem 0;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    margin: 0 0 1.2rem 0;
  }
`;

export const StyledWelcomeToPremiumImage = styled.img`
  width: 27.4rem;
  height: 31.3rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 20rem;
    height: 22.8rem;
  }
`;
export const StyledRocketImageContainer = styled.div`
  display: flex;
  height: 36rem;
  width: 100%;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    height: 25rem;
  }
`;
