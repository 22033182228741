import { useEffect } from 'react';

import { useTrackViewPageMutation } from '../slices';

import { useCurrentRoute } from './use-current-route';

export const useTrackViewSubPages = () => {
  const { handle } = useCurrentRoute();
  const [trackViewPage] = useTrackViewPageMutation();
  useEffect(() => {
    if (handle?.name) {
      trackViewPage({ name: handle.name }).catch(() => {
        // silently discard errors
      });
    }
  }, [trackViewPage, handle?.name]);
};
