import { GreetingContainer, StyledGreeting, StyledLoadingTitle, StyledLoadLine, StyledSubGreeting } from './greeting.styles';
import { GreetingProps } from './greeting.types';
export const Greeting = ({
  isLoading,
  name
}: GreetingProps) => <GreetingContainer data-sentry-element="GreetingContainer" data-sentry-component="Greeting" data-sentry-source-file="greeting.tsx">
    {isLoading ? <StyledLoadingTitle data-testid="loading">
        <StyledLoadLine width="55%" isTitle />
        <StyledLoadLine width="35%" />
      </StyledLoadingTitle> : <>
        <StyledGreeting>Hey {name}!</StyledGreeting>
        <StyledSubGreeting>Let’s jump in!</StyledSubGreeting>
      </>}
  </GreetingContainer>;