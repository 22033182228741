import { ChangeEvent, useContext, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormErrorMessage, SwitchThemeContext, TextField, ValidationList } from '@tryhackme/thm-ui-components';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';
import { useInputFocus } from 'src/common/hooks/use-input-focus';
import { useForm, Controller, SubmitHandler } from 'src/common/form';
import { GOOGLE_SITE_KEY } from 'src/common/constants';
import { PasswordInput } from 'src/common/components/password-input/password-input';
import { useLazyGetIsEmailUniqueQuery, useLazyGetIsUsernameUniqueQuery } from 'src/features/manage-account/account-details/sections/general-information/general-information.slice';
import { getValidationAreaData, validateUsernameHasNoSymbols } from 'src/features/manage-account/account-details/sections/general-information/general-information.helpers';
import { getPasswordValidationAreaData } from 'src/common/components/password-input/password-input.helpers';
import { displayToast } from 'src/common/helpers/display-toast';
import { ApiResponseStatus } from 'src/common/enums';
import { useSignupMutation } from '../../signup.slice';
import { StyledForm, StyledFullWidthButton, StyledGoogleLogo, StyledLine, StyledLinedText, StyledLinedTextRow, StyledLink, StyledTitle, StyledRecaptchaContainer, StyledTsAndCs, StyledValidationArea, StyledSubtitle, StyledContinueWithButtons, StyledContinueButton } from './signup-form.styles';
import { ALREADY_EXISTS_EMAIL_ERROR, ALREADY_EXISTS_USERNAME_ERROR, DEFAULT_INPUT_VALUES, EMPTY_EMAIL_ERROR, EMPTY_PASSWORD_ERROR, EMPTY_USERNAME_ERROR, GENERIC_ERROR, INVALID_EMAIL_ERROR, INVALID_PASSWORD_ERROR, INVALID_USERNAME_ERROR, EMPTY_RECAPTCHA_ERROR } from './signup-form.constants';
import { SignupFormProps } from './signup-form.types';

// Needed when using SignupForm inside a Modal component e.g. SignupModal.
// Modal adds pointer-event: 'none' to body. This needs removing else Recaptcha popup is not clickable.
const handleRecaptchaLoaded = () => {
  const body = document.querySelectorAll('body');
  if (body && body[0]) {
    body[0].style.pointerEvents = 'auto';
  }
};
export const SignupForm: React.FC<SignupFormProps> = ({
  initialEmail,
  onOpenChange
}) => {
  const navigate = useNavigate();
  const reCaptchaBypass = process.env.REACT_APP_RECAPTCHA_BYPASS === 'true';
  const {
    theme
  } = useContext(SwitchThemeContext);
  const {
    isInputFocused,
    handleInputFocus,
    handleInputBlur
  } = useInputFocus();
  const [signup, {
    isLoading: isSignupLoading
  }] = useSignupMutation();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [usernameUniquenessTrigger, {
    isLoading: isCheckingUsernameUniqueness,
    data: usernameUniquenessData
  }] = useLazyGetIsUsernameUniqueQuery();
  const [emailUniquenessTrigger, {
    isLoading: isCheckingEmailUniqueness
  }] = useLazyGetIsEmailUniqueQuery();
  const signupFormValidationSchema = yup.object({
    username: yup.string().required(EMPTY_USERNAME_ERROR).max(20, INVALID_USERNAME_ERROR).test({
      name: 'invalidUsername',
      message: INVALID_USERNAME_ERROR,
      test(value) {
        if (!value) return false;
        return validateUsernameHasNoSymbols(value);
      }
    }).test({
      name: 'uniqueUsername',
      message: ALREADY_EXISTS_USERNAME_ERROR,
      async test(value) {
        if (!value) return false;
        try {
          const result = await usernameUniquenessTrigger(value).unwrap();
          return result.data?.available || false;
        } catch {
          return false;
        }
      }
    }),
    email: yup.string().email(INVALID_EMAIL_ERROR).required(EMPTY_EMAIL_ERROR).test({
      name: 'uniqueEmail',
      message: ALREADY_EXISTS_EMAIL_ERROR,
      test(value) {
        if (!value) return false;
        return emailUniquenessTrigger(value).unwrap().then(result => result.data?.available || false).catch(() => false);
      }
    }),
    password: yup.string().required(EMPTY_PASSWORD_ERROR).test({
      name: 'invalidPassword',
      message: INVALID_PASSWORD_ERROR,
      test(value) {
        if (value === undefined) {
          return false;
        }
        const passwordValidationData = getPasswordValidationAreaData(value);
        const isValidPassword = passwordValidationData.validationStates.every(state => state.isValid === true);
        return isValidPassword;
      }
    })
  });
  type SignupFromInputs = yup.InferType<typeof signupFormValidationSchema>;
  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: {
      errors
    }
  } = useForm<SignupFromInputs>({
    resolver: yupResolver(signupFormValidationSchema),
    defaultValues: {
      ...DEFAULT_INPUT_VALUES,
      email: initialEmail
    }
  });
  const {
    username: usernameError,
    email: emailError,
    password: passwordError
  } = errors;
  const debouncedUsername = useDebouncedCallback(async (value: string) => {
    if (!value) return;
    try {
      const result = await usernameUniquenessTrigger(value).unwrap();
      if (!result.data?.available) {
        setError('username', {
          type: 'custom',
          message: ALREADY_EXISTS_USERNAME_ERROR
        });
      } else if (!validateUsernameHasNoSymbols(value) || value.length > 20) {
        setError('username', {
          type: 'custom',
          message: INVALID_USERNAME_ERROR
        });
      } else {
        clearErrors('username');
      }
    } catch {
      setError('username', {
        type: 'custom',
        message: ALREADY_EXISTS_USERNAME_ERROR
      });
    }
  }, 300);
  const debouncedEmail = useDebouncedCallback(async (value?: string) => {
    if (!value) return;
    try {
      const result = await emailUniquenessTrigger(value).unwrap();
      if (result.data?.available) {
        clearErrors('email');
      } else {
        setError('email', {
          type: 'custom',
          message: ALREADY_EXISTS_EMAIL_ERROR
        });
      }
    } catch {
      setError('email', {
        type: 'custom',
        message: ALREADY_EXISTS_EMAIL_ERROR
      });
    }
  }, 300);
  const getPasswordValidationStates = (value: string) => {
    const passwordValidationData = getPasswordValidationAreaData(value);
    const isValidPassword = passwordValidationData.validationStates.every(state => state.isValid === true);
    if (isValidPassword) {
      clearErrors('password');
    } else {
      setError('password', {
        type: 'custom',
        message: INVALID_PASSWORD_ERROR
      });
    }
  };
  const handleSignup: SubmitHandler<SignupFromInputs> = async (data: SignupFromInputs) => {
    const reCaptcha = reCaptchaRef.current?.getValue();
    if (reCaptcha || reCaptchaBypass) {
      setIsLoading(true);
      try {
        const signupResponse = await signup({
          reCaptcha,
          ...data
        }).unwrap();
        reset(DEFAULT_INPUT_VALUES);
        reCaptchaRef.current?.reset();
        clearErrors();
        if (signupResponse.status === ApiResponseStatus.SUCCESS) {
          navigate('/onboarding');
        } else {
          displayToast('error', GENERIC_ERROR);
        }
      } catch {
        displayToast('error', GENERIC_ERROR);
      }
      setIsLoading(false);
    } else {
      setRecaptchaError(EMPTY_RECAPTCHA_ERROR);
    }
  };
  const onClickGoogleSignup = () => {
    if (onOpenChange) {
      onOpenChange(false);
    }
    navigate('/login/google');
  };
  const onClickSSO = () => {
    if (onOpenChange) {
      onOpenChange(false);
    }
    navigate('/login/sso');
  };
  return <>
      <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="signup-form.tsx">Sign Up</StyledTitle>
      <StyledSubtitle data-sentry-element="StyledSubtitle" data-sentry-source-file="signup-form.tsx">Join over 3 million users and upskill in cyber security.</StyledSubtitle>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <StyledForm onSubmit={handleSubmit(handleSignup)} data-sentry-element="StyledForm" data-sentry-source-file="signup-form.tsx">
        <Controller name="username" control={control} render={({
        field: {
          ref,
          ...rest
        }
      }) => <div data-testid="username-container">
              <TextField {...register('username', {
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onChange: (e: ChangeEvent<HTMLInputElement>) => debouncedUsername(e.target.value)
        })} id="username" variant="input" label="Username" aria-label="username" placeholder="Username" isError={!!usernameError} errorMessage={usernameError ? usernameError.message : ''} innerRef={ref} {...rest} onFocus={handleInputFocus} onBlur={handleInputBlur} maxLength={20} />
              <StyledValidationArea isVisible={isInputFocused}>
                <ValidationList validationStates={[...getValidationAreaData(getValues('username')), {
            isEmpty: !getValues('username')?.length,
            message: 'Your username needs to be available',
            isValid: !!usernameUniquenessData?.data?.available,
            isLoading: isCheckingUsernameUniqueness || debouncedUsername.isPending()
          }]} isTiny />
              </StyledValidationArea>
            </div>} data-sentry-element="Controller" data-sentry-source-file="signup-form.tsx" />

        <Controller name="email" control={control} render={({
        field: {
          ref,
          ...rest
        }
      }) => <TextField {...register('email', {
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onChange: (e: ChangeEvent<HTMLInputElement>) => debouncedEmail(e.target.value)
      })} id="email" variant="input" label="Email Address" aria-label="email address" placeholder="Example@example.com" isError={!!emailError} errorMessage={emailError ? emailError.message : ''} innerRef={ref} {...rest} />} data-sentry-element="Controller" data-sentry-source-file="signup-form.tsx" />

        <Controller name="password" control={control} render={({
        field: {
          ref,
          ...rest
        }
      }) => <PasswordInput {...register('password', {
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onChange: (e: ChangeEvent<HTMLInputElement>) => getPasswordValidationStates(e.target.value)
      })} id="password" label="Password" placeholder="Password" aria-label="password" showProgressAndValidation isError={!!passwordError} errorMessage={passwordError ? passwordError.message : ''} innerRef={ref} {...rest} />} data-sentry-element="Controller" data-sentry-source-file="signup-form.tsx" />

        <StyledRecaptchaContainer data-sentry-element="StyledRecaptchaContainer" data-sentry-source-file="signup-form.tsx">
          <ReCAPTCHA sitekey={GOOGLE_SITE_KEY} ref={reCaptchaRef} theme={theme === 'dark' ? 'dark' : 'light'} data-testid="recaptcha-comp" asyncScriptOnLoad={onOpenChange ? handleRecaptchaLoaded : undefined} data-sentry-element="ReCAPTCHA" data-sentry-source-file="signup-form.tsx" />
          {recaptchaError && <FormErrorMessage>{EMPTY_RECAPTCHA_ERROR}</FormErrorMessage>}
        </StyledRecaptchaContainer>

        <StyledFullWidthButton color="primary" type="submit" isLoading={isLoading || isSignupLoading || isCheckingEmailUniqueness || isCheckingUsernameUniqueness} data-testid="signup-button" data-sentry-element="StyledFullWidthButton" data-sentry-source-file="signup-form.tsx">
          Sign Up
        </StyledFullWidthButton>
      </StyledForm>
      <StyledLinedTextRow data-sentry-element="StyledLinedTextRow" data-sentry-source-file="signup-form.tsx">
        <StyledLine data-sentry-element="StyledLine" data-sentry-source-file="signup-form.tsx" />
        <StyledLinedText data-sentry-element="StyledLinedText" data-sentry-source-file="signup-form.tsx">Or</StyledLinedText>
        <StyledLine data-sentry-element="StyledLine" data-sentry-source-file="signup-form.tsx" />
      </StyledLinedTextRow>

      <StyledContinueWithButtons data-sentry-element="StyledContinueWithButtons" data-sentry-source-file="signup-form.tsx">
        <StyledContinueButton type="button" variant="outlined" onClick={onClickGoogleSignup} data-sentry-element="StyledContinueButton" data-sentry-source-file="signup-form.tsx">
          <StyledGoogleLogo data-sentry-element="StyledGoogleLogo" data-sentry-source-file="signup-form.tsx" />
          Continue with Google
        </StyledContinueButton>

        <StyledContinueButton type="button" variant="outlined" onClick={onClickSSO} data-sentry-element="StyledContinueButton" data-sentry-source-file="signup-form.tsx">
          <FontAwesomeIcon icon={faKey} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="signup-form.tsx" /> Continue with SSO
        </StyledContinueButton>
      </StyledContinueWithButtons>

      <StyledTsAndCs data-sentry-element="StyledTsAndCs" data-sentry-source-file="signup-form.tsx">
        By signing up, you are agreeing to our{' '}
        <StyledLink onClick={onOpenChange ? () => onOpenChange(false) : undefined} to="/legal/terms-of-use" data-sentry-element="StyledLink" data-sentry-source-file="signup-form.tsx">
          Terms and Conditions
        </StyledLink>
        . Already have an account?{' '}
        <StyledLink onClick={onOpenChange ? () => onOpenChange(false) : undefined} to="/login" data-sentry-element="StyledLink" data-sentry-source-file="signup-form.tsx">
          Log in
        </StyledLink>
        .
      </StyledTsAndCs>
    </>;
};