import styled from 'styled-components';

export const StyledTooltipContentContainer = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fonts.sizes.small};
  max-width: ${({ isModal }) => (isModal ? '100%' : '33.5rem')};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 0.8rem;
  margin-top: 0.8rem;
  max-width: 33.5rem;

  @media screen and (max-width: 492px) {
    grid-template-columns: 1fr;
    gap: 0.2rem;
  }
`;

export const StyledLeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  padding-right: 0.8rem;

  @media screen and (max-width: 490px) {
    border-right: none;
    padding-right: 0;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const StyledRightColumnWrapper = styled.div`
  @media screen and (max-width: 490px) {
    border-right: none;
  }
`;

export const StyledGridItem = styled.div<{ withTitle?: boolean }>`
  display: grid;
  grid-template-columns: ${({ withTitle }) => (withTitle ? '40% 60%' : '20% 80%')};
  color: ${({ theme }) => theme.colors.text.light};
  height: 2.7rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};

  @media screen and (max-width: 490px) {
    grid-template-columns: auto 1fr;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledLevel = styled.div<{ withTitle?: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.success.main};
  justify-content: flex-end;
  text-align: right;
  display: flex;
  align-items: center;
  @media screen and (max-width: 490px) {
    justify-content: flex-start;
  }
`;

export const StyledPoints = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.lighter};
  display: flex;
  font-size: ${({ theme }) => theme.fonts.sizes.small};
`;
