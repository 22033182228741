import { ThemeProvider } from 'styled-components';
import { Button, darkColors, Modal, StyledFlex, theme } from '@tryhackme/thm-ui-components';
import rocketMan from 'src/images/svgs/rocketman.svg';
import { StyledContent, StyledWelcomeToPremiumTitle, StyledWelcomeToPremiumSubTitle, StyledWelcomeToPremiumImage, StyledRocketImageContainer } from './welcome-to-premium.styles';
export const WelcomeToPremiumModal = ({
  dismiss
}: {
  dismiss: () => void;
}) => <ThemeProvider theme={{
  ...theme,
  colors: darkColors
}} data-sentry-element="ThemeProvider" data-sentry-component="WelcomeToPremiumModal" data-sentry-source-file="welcome-to-premium.tsx">
    <Modal defaultOpen padding="0" handleCloseButtonAction={dismiss} data-sentry-element="Modal" data-sentry-source-file="welcome-to-premium.tsx">
      <StyledContent data-sentry-element="StyledContent" data-sentry-source-file="welcome-to-premium.tsx">
        <StyledRocketImageContainer data-sentry-element="StyledRocketImageContainer" data-sentry-source-file="welcome-to-premium.tsx">
          <StyledWelcomeToPremiumImage src={rocketMan} alt="rocket man" data-sentry-element="StyledWelcomeToPremiumImage" data-sentry-source-file="welcome-to-premium.tsx" />
        </StyledRocketImageContainer>
        <StyledFlex gap="0" flexDirection="column" justifyContent="space-between" alignItems="center" data-sentry-element="StyledFlex" data-sentry-source-file="welcome-to-premium.tsx">
          <StyledWelcomeToPremiumTitle data-sentry-element="StyledWelcomeToPremiumTitle" data-sentry-source-file="welcome-to-premium.tsx">
            Welcome to <span>TryHackMe Premium</span>
          </StyledWelcomeToPremiumTitle>
          <StyledWelcomeToPremiumSubTitle data-sentry-element="StyledWelcomeToPremiumSubTitle" data-sentry-source-file="welcome-to-premium.tsx">
            Congratulations! You&apos;ve just unlocked a world of learning possibilities with your premium plan.
            We&apos;re thrilled to have you on board.
          </StyledWelcomeToPremiumSubTitle>
          <Button color="primary" size="large" onClick={dismiss} data-sentry-element="Button" data-sentry-source-file="welcome-to-premium.tsx">
            Start Learning
          </Button>
        </StyledFlex>
      </StyledContent>
    </Modal>
  </ThemeProvider>;