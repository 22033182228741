import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';

import { AddFriendRequestBody, FriendsList, UserSearchResponse, RemoveFriendRequestBody } from './friends.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserFriends: builder.query<ApiResponse<FriendsList>, void>({
      query: () => ({
        method: 'GET',
        url: '/friends',
      }),
      providesTags: [RTKQueryTag.UserFriends],
    }),

    addFriend: builder.mutation<ApiResponse<{ success: boolean; message: string }>, AddFriendRequestBody>({
      query: (body) => ({
        url: '/friends',
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKQueryTag.UserFriends],
    }),

    removeFriend: builder.mutation<ApiResponse, RemoveFriendRequestBody>({
      query: (body) => ({
        url: '/friends',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [RTKQueryTag.UserFriends],
    }),
    searchUsers: builder.mutation<ApiResponse<UserSearchResponse>, string>({
      query: (searchText) => ({
        method: 'GET',
        url: `/users/search?searchText=${searchText}`,
      }),
    }),
  }),
});

export const { useGetUserFriendsQuery, useAddFriendMutation, useSearchUsersMutation, useRemoveFriendMutation } =
  extendedApiSlice;
