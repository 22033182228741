import { useState } from 'react';
import { SlidingPane } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { StyledShowConnectionButton } from '../commons';
import { ConnectionOptions } from './connection-options';
import { AccessNetworksSlidingPane } from './access-networks.types';
import { AttackBoxOption } from './attack-box-option/attack-box-option';
import { OpenVpnOptions } from './open-vpn-options';
export const AccessNetworks = ({
  isSlidingPaneOpen,
  setIsSlidingPaneOpen,
  machineIP
}: AccessNetworksSlidingPane) => {
  const [isNetworksScreenOpen, setIsNetworksScreenOpen] = useState(true);
  const [isAttackBoxScreenOpen, setIsAttackBoxScreenOpen] = useState(false);
  const [isOpenVPNScreenOpen, setIsOpenVPNScreenOpen] = useState(false);
  return <SlidingPane isSlidingPaneOpen={isSlidingPaneOpen} setIsSlidingPaneOpen={setIsSlidingPaneOpen} data-sentry-element="SlidingPane" data-sentry-component="AccessNetworks" data-sentry-source-file="access-networks.tsx">
      {isNetworksScreenOpen && <ConnectionOptions setIsAttackBoxScreenOpen={setIsAttackBoxScreenOpen} setIsNetworksScreenOpen={setIsNetworksScreenOpen} setIsOpenVPNScreenOpen={setIsOpenVPNScreenOpen} machineIP={machineIP} />}

      {isAttackBoxScreenOpen && <AttackBoxOption setIsSlidingPaneOpen={setIsSlidingPaneOpen} setIsAttackBoxScreenOpen={setIsAttackBoxScreenOpen} setIsNetworksScreenOpen={setIsNetworksScreenOpen} />}

      {/* This screen will be built */}
      {isOpenVPNScreenOpen && <div>
          <StyledShowConnectionButton variant="text" onClick={() => {
        setIsNetworksScreenOpen(true);
        setIsAttackBoxScreenOpen(false);
        setIsOpenVPNScreenOpen(false);
      }}>
            <FontAwesomeIcon icon={faArrowLeft} /> Show Connection Options
          </StyledShowConnectionButton>
          <OpenVpnOptions />
        </div>}
    </SlidingPane>;
};