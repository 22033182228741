import { ApiResponse } from 'src/common/interfaces';
import { apiSlice } from 'src/features/api/api-slice';

import { SignupBody } from './signup.types';

export const signupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation<ApiResponse, SignupBody>({
      query: ({ reCaptcha, ...body }: SignupBody) => {
        const headers: Record<string, string> = {};
        if (reCaptcha) {
          headers['x-recaptcha-response'] = reCaptcha;
        }
        return {
          url: `/auth/signup`,
          method: 'POST',
          headers,
          body,
        };
      },
    }),
  }),
});

export const { useSignupMutation } = signupApiSlice;
