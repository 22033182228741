import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import * as Sentry from '@sentry/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RTKQueryTag, BASE_URL_V2, API_REDUCER_PATH } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_V2,
  prepareHeaders: async (headers) => {
    const token = await csrfTokenPromise;
    headers.set('csrf-token', token);
    return headers;
  },
});

let redirectNavigationInProgress = false;
const waitForRedirectNavigation = async (url: string | URL) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- effectively stop execution until redirect
  const infiniteWaitPromise = new Promise(() => {});
  if (redirectNavigationInProgress) await infiniteWaitPromise;
  redirectNavigationInProgress = true;
  window.location.replace(url);
  await infiniteWaitPromise;
};

const baseQueryWithErrorHandlingAndRedirectNavigation: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result?.error) {
    if (typeof args === 'object' && args.url.startsWith('tutor/')) {
      return result;
    }
    if (result.error.status === 500) {
      Sentry.captureException('API 500 Error', { extra: { contexts: { 'API Response Data': result.error.data } } });
      await waitForRedirectNavigation('/r/500');
    } else if (result.meta?.response?.redirected) {
      await waitForRedirectNavigation(result.meta.response.url);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: API_REDUCER_PATH,
  baseQuery: baseQueryWithErrorHandlingAndRedirectNavigation,
  endpoints: () => ({}),
  tagTypes: Object.values(RTKQueryTag),
});

const getCsrfToken = async () => {
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_MOCK) return 'mock-csrf-token';
  const response = await fetch(`${BASE_URL_V2}auth/csrf`);
  if (response.redirected) {
    await waitForRedirectNavigation(response.url);
  }
  const data = (await response.json()) as ApiResponse<{ token: string }>;
  if (!data.data?.token) throw new Error('Failed to fetch CSRF token');
  const { token } = data.data;
  return token;
};
export const csrfTokenPromise = getCsrfToken();
