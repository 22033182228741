import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

import { UserStats } from './stats.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserStats: builder.query<ApiResponse<UserStats>, void>({
      query: () => ({
        method: 'GET',
        url: '/users/statistics',
      }),
    }),
  }),
});

export const { useGetUserStatsQuery } = extendedApiSlice;
