import { darkColors } from '@tryhackme/thm-ui-components';
import styled, { css } from 'styled-components';

export const StyledLists = css`
  padding-left: 1.5rem;
  margin-bottom: 4.6rem;

  li {
    margin-bottom: 1rem;
    line-height: 2.4rem;
  }
`;

export const StyledList = styled.ol`
  ${StyledLists};

  button,
  a[data-link='button'] {
    color: ${darkColors.info.main};
    span {
      border-color: ${darkColors.info.main};
      border-top-color: transparent;
    }
  }

  li {
    & > div {
      text-align: center;
      margin: 1rem 0 2rem;
    }
  }
`;
