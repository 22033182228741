import { useMemo } from 'react';

import { useGetSubscriptionPricingQuery } from 'src/features/pricing/pricing.slice';
import { useFeatureFlag } from 'src/common/hooks/use-feature-flag';

import { FeatureFlagName } from '../constants';

type PricingHookResult = {
  localizedPricingEnabled: boolean;
  price: string;
  symbol: string;
};

export const usePricing = (showYearly?: boolean): PricingHookResult => {
  const { enabled: localizedPricingEnabled } = useFeatureFlag(FeatureFlagName.LOCALIZED_PRICING);
  const { data: response } = useGetSubscriptionPricingQuery(false, {
    skip: !localizedPricingEnabled,
  });

  const { price, symbol } = useMemo(() => {
    if (!response?.data || !localizedPricingEnabled) {
      return { price: '', symbol: '' };
    }

    const { monthlyPlan, yearlyPlan } = response.data[0]!;
    const plan = showYearly ? yearlyPlan : monthlyPlan;

    return {
      price: plan.price.toFixed(Number.isInteger(plan.price) ? 0 : 2),
      symbol: plan.symbol,
    };
  }, [response, showYearly, localizedPricingEnabled]);

  return { localizedPricingEnabled, price, symbol };
};
