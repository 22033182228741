import { FormLabel, MultiSelect, MultiSelectProps, StyledFlex } from '@tryhackme/thm-ui-components';
import { FieldValues, Controller } from 'src/common/form';
import { FormMultiSelectControllerProps } from './form-multi-select-controller.types';
export const FormMultiSelectController = <T extends FieldValues, TValue extends string>({
  name,
  control,
  options,
  onChange,
  label,
  width,
  ...props
}: FormMultiSelectControllerProps<T, TValue> & Omit<MultiSelectProps, keyof FormMultiSelectControllerProps<T, TValue> | 'selected' | 'setSelected'>) => <Controller name={name} control={control} render={({
  field: {
    ref,
    value,
    ...rest
  }
}) => <StyledFlex width={width} flexDirection="column" alignItems="flex-start" gap="1.2rem" justifyContent="flex-start">
        {label && <FormLabel htmlFor={rest.name}>{label}</FormLabel>}
        <MultiSelect isSearchable={false} id={rest.name} {...props} {...rest} ref={ref} selected={value} setSelected={newValue => onChange(rest.name, (newValue as TValue[]))} options={options} />
      </StyledFlex>} data-sentry-element="Controller" data-sentry-component="FormMultiSelectController" data-sentry-source-file="form-multi-select-contoller.tsx" />;