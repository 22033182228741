export enum NodeMachineStatus {
  ICONONLY = 'icononly',
  MACHINE = 'machine',
}

export enum NetworkState {
  DELETED = 'deleted',
  DELETING = 'deleting',
  RESETTING = 'resetting',
  RUNNING = 'running',
  STOPPED = 'stopped',
}

export enum NetworkInstanceType {
  EMPTY = '',
  T2MEDIUM = 't2.medium',
  T2MICRO = 't2.micro',
  T2SMALL = 't2.small',
  T3LARGE = 't3.large',
  T3MICRO = 't3.micro',
  T3SMALL = 't3.small',
  T3NANO = 't3.nano',
  T3ALARGE = 't3a.large',
  T3AMICRO = 't3a.micro',
  T3ASMALL = 't3a.small',
  T3ANANO = 't3a.nano',
}
