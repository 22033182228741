import { Helmet } from 'react-helmet-async';
import { MAIN_DOMAIN_URL } from 'src/common/constants';
import { CREATOR_NAME, DEFAULT_JSON_LD_SCHEMA, DEFAULT_SEO_IMAGE } from './seo.constants';
import { SEOProps } from './seo.types';
export const SEO = ({
  title,
  titleSuffix,
  description,
  twitterCreatorName = CREATOR_NAME,
  ogType = 'website',
  twitterCardType = 'summary_large_image',
  imageURL = DEFAULT_SEO_IMAGE,
  children,
  jsonLd = DEFAULT_JSON_LD_SCHEMA
}: SEOProps) => {
  const canonicalURL = `${MAIN_DOMAIN_URL}${window.location.pathname}`; // This is the full URL of the page, e.g. https://tryhackme.com/r/about

  return <Helmet data-sentry-element="Helmet" data-sentry-component="SEO" data-sentry-source-file="seo.tsx">
      {/* Standard metadata tags */}
      <title>{`TryHackMe | ${title}${titleSuffix ? ` ${titleSuffix}` : ''}`}</title>
      <meta name="description" content={description} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <link rel="canonical" href={canonicalURL} />

      {/* Note keywords is left out, as it is no longer useful for ranking on Google */}

      {/* 
       Opengraph tags (cover more than only Facebook)
       Docs: https://ogp.me/
       */}
      <meta property="og:title" content={title} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta property="og:description" content={description} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta property="og:url" content={canonicalURL} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta property="og:type" content={ogType} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta property="og:image" content={imageURL} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta property="og:site_name" content="TryHackMe" data-sentry-element="meta" data-sentry-source-file="seo.tsx" />

      {/*
       Twitter tags
       Some twitter tags fallback to opengraph tags
       Docs: https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
       */}
      <meta name="twitter:title" content={title} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta name="twitter:description" content={description} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta name="twitter:card" content={twitterCardType} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta name="twitter:creator" content={twitterCreatorName} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta name="twitter:site" content={twitterCreatorName} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <meta name="twitter:image" content={imageURL} data-sentry-element="meta" data-sentry-source-file="seo.tsx" />
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>

      {children}
    </Helmet>;
};