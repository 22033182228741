import { useEffect, useState } from 'react';

export const useLoadedAndVisible = <T>(dependency: T): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasContent = Array.isArray(dependency)
      ? dependency.length > 0
      : !!dependency && Object.keys(dependency).length > 0;

    setIsVisible(hasContent);
  }, [dependency]);

  return isVisible;
};
