import styled from 'styled-components';

import { StyledTitle } from 'src/common/styles';

export const SideSectionImage = styled.img`
  width: 12.2rem;
  position: relative;
  top: -1.4rem;
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    display: block;
  }
`;

export const SideSectionTitle = styled(StyledTitle)`
  margin: 0;
  max-width: 22.7rem;
  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    max-width: none;
  }
`;

export const StyledSideSectionBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 2.4rem;
`;
