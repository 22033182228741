import { useEffect } from 'react';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, StyledFlex } from '@tryhackme/thm-ui-components';
import { ApiResponseStatus } from 'src/common/enums';
import { displayToast } from 'src/common/helpers/display-toast';
import { useTrackEventMutation } from 'src/common/slices';
import { StyledUserName } from '../friend-item/friend-item.styles';
import { useAddFriendMutation } from '../friends.slice';
import { UserType } from '../friends.types';
import { StyledAddBtn, StyledUserItem } from './user-item.styles';
import { UsersProps } from './user-item.types';
export const UserItem = ({
  user,
  index,
  setIsAddFriendsView
}: UsersProps) => {
  const [addFriend, {
    isLoading,
    data,
    isSuccess,
    error
  }] = useAddFriendMutation();
  const [trackEvent] = useTrackEventMutation();
  const handleAddFriend = () => {
    addFriend({
      userProperty: user.username,
      userType: UserType.USERNAME
    }).catch(() => {});
    // eslint-disable-next-line no-void
    void trackEvent({
      event: 'add-friend'
    });
  };
  useEffect(() => {
    if (isSuccess) {
      displayToast(ApiResponseStatus.SUCCESS, data?.message || 'Friend request sent');
      setIsAddFriendsView(false);
    } else if (error && 'data' in error) {
      const errorMessage = (error.data as {
        message: string;
      }).message || 'An error occurred';
      displayToast(ApiResponseStatus.ERROR, errorMessage);
    }
  }, [data, setIsAddFriendsView, isSuccess, error]);
  return <StyledUserItem data-sentry-element="StyledUserItem" data-sentry-component="UserItem" data-sentry-source-file="user-item.tsx">
      <StyledFlex justifyContent="flex-start" gap="0.8rem" data-sentry-element="StyledFlex" data-sentry-source-file="user-item.tsx">
        <p>{index + 1}</p>
        <Avatar avatarSrc={user.avatar} size="38px" mobileSize="38px" borderWidth="0.2rem" data-sentry-element="Avatar" data-sentry-source-file="user-item.tsx" />
        <StyledUserName data-sentry-element="StyledUserName" data-sentry-source-file="user-item.tsx">{user.username}</StyledUserName>
      </StyledFlex>

      <StyledAddBtn disabled={isLoading} variant="outlined" color="secondary" size="small" onClick={handleAddFriend} data-sentry-element="StyledAddBtn" data-sentry-source-file="user-item.tsx">
        <FontAwesomeIcon icon={faUserPlus} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="user-item.tsx" /> Add
      </StyledAddBtn>
    </StyledUserItem>;
};