import { MAX_PASSWORD_SCORE, MIN_PASSWORD_LENGTH, MIN_SYMBOL_COUNT } from 'src/common/validations';

export const getPasswordValidationAreaData = (password: string) => {
  const symbolCount = password.match(/[\W_]/g)?.length ?? 0;
  const hasBothLetterCases = /^(?=.*[a-z])(?=.*[A-Z])/.test(password);
  const progressBarValue =
    Math.min(password.length, MIN_PASSWORD_LENGTH) +
    Math.min(symbolCount, MIN_SYMBOL_COUNT) +
    Number(hasBothLetterCases);

  const data = {
    progressBarValue,
    message: 'Too weak. Please make it stronger!',
    validationStates: [
      {
        message: `Use at least ${MIN_PASSWORD_LENGTH} characters.`,
        isEmpty: !password.length,
        isLoading: false,
        isValid: password.length >= MIN_PASSWORD_LENGTH,
      },
      {
        message: 'Use upper and lower case characters.',
        isEmpty: !password.length,
        isLoading: false,
        isValid: hasBothLetterCases,
      },
      {
        message: 'Use at least 1 symbol (-.,_).',
        isEmpty: !password.length,
        isLoading: false,
        isValid: !!symbolCount,
      },
    ],
  };

  if (progressBarValue === MAX_PASSWORD_SCORE && symbolCount > 1) data.message = 'Strong password. Nice one!';
  else if (progressBarValue === MAX_PASSWORD_SCORE)
    data.message = 'Good password. Add more symbols to make it stronger!';

  return data;
};
