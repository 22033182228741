import { Button } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { StyledAoCPromotionalBannerContainer, StyledAoCPromotionalBannerWrapper, StyledAoCPromotionalCloseButton } from './aoc-promotional-banner.styles';
export const AoCPromotionalBanner = ({
  dismiss
}: {
  dismiss: () => void;
}) => <StyledAoCPromotionalBannerContainer data-sentry-element="StyledAoCPromotionalBannerContainer" data-sentry-component="AoCPromotionalBanner" data-sentry-source-file="aoc-promotional-banner.tsx">
    <StyledAoCPromotionalBannerWrapper data-sentry-element="StyledAoCPromotionalBannerWrapper" data-sentry-source-file="aoc-promotional-banner.tsx">
      <div>
        <h2>The cyber community event of the year!</h2>
        <h1>
          Advent of Cyber <span>2024</span>
        </h1>
        <p>Solve daily beginner-friendly challenges with over $100,000 worth of prizes up for grabs!</p>
        <Link to="/jr/adventofcyber2024" data-sentry-element="Link" data-sentry-source-file="aoc-promotional-banner.tsx">
          <Button color="primary" variant="solid" data-sentry-element="Button" data-sentry-source-file="aoc-promotional-banner.tsx">
            Join for <span>FREE</span>
          </Button>
        </Link>
      </div>
      <div>
        <StyledAoCPromotionalCloseButton aria-label="close-notification" icon={faXmark} onClick={dismiss} data-sentry-element="StyledAoCPromotionalCloseButton" data-sentry-source-file="aoc-promotional-banner.tsx" />
      </div>
    </StyledAoCPromotionalBannerWrapper>
  </StyledAoCPromotionalBannerContainer>;