import { ModalDescription, ModalTitle } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledStreakFreezeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 7.4rem 0;
`;

export const StyledStreakTitle = styled(ModalTitle)`
  font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  margin-top: 4.68rem;
  margin-bottom: 3rem;
`;

export const StyledStreakSubtitle = styled(ModalDescription)`
  line-height: 2.4rem;
  font-size: 1.8rem;
`;
