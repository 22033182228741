import { Link } from 'react-router-dom';
import { StyledBannerText, StyledCTABanner, StyledCTAButtonWrapper, StyledCTAButtonLink } from './cta-banner.styles';
import { CTABannerProps } from './cta-banner.types';
const defaultCTABannerButtons = [{
  btnText: 'TryHackMe for Business',
  href: 'https://business.tryhackme.com'
}];
export const CTABanner = ({
  text = <>
      Join over 640 organisations upskilling their <br /> workforce with TryHackMe
    </>,
  buttons = defaultCTABannerButtons
}: CTABannerProps) => <StyledCTABanner data-sentry-element="StyledCTABanner" data-sentry-component="CTABanner" data-sentry-source-file="cta-banner.tsx">
    <StyledBannerText data-sentry-element="StyledBannerText" data-sentry-source-file="cta-banner.tsx">{text}</StyledBannerText>
    <StyledCTAButtonWrapper data-sentry-element="StyledCTAButtonWrapper" data-sentry-source-file="cta-banner.tsx">
      {buttons.map(({
      btnText,
      href,
      variant = 'solid',
      color = 'primary'
    }) => <StyledCTAButtonLink key={href} as={Link} to={href} color={color} variant={variant}>
          {btnText}
        </StyledCTAButtonLink>)}
    </StyledCTAButtonWrapper>
  </StyledCTABanner>;