import * as yup from 'yup';

export const MIN_PASSWORD_LENGTH = 6;
// 2 = The number of password criteria (Has a symbol + Has both upper & lowercase letters)
export const MAX_PASSWORD_SCORE = MIN_PASSWORD_LENGTH + 2;
export const MIN_SYMBOL_COUNT = 1;

export const password = yup
  .string()
  .required('This field is required.')
  .matches(
    new RegExp(`^(?=.{${MIN_PASSWORD_LENGTH},})(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).*$`),
    "This password isn't valid! Please try a different one.",
  );
