import { UIMatch, useMatches } from 'react-router-dom';

import { RouteHandleObject } from '../interfaces';

export const useCurrentRoute = () => {
  const matches = useMatches();

  // last element of matches is always the route matching the current location
  return matches[matches.length - 1] as UIMatch<unknown, RouteHandleObject | undefined>;
};
