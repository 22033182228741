import { faIdCard, faRetweetAlt, faNetworkWired, faMessageSmile, faUserTie, faCloudUpload, faUsersCog, faTag } from '@fortawesome/pro-regular-svg-icons';
import { UserRoles } from 'src/common/enums';
import { AdminLinkWithId } from '../header.types';
const networksLink = {
  icon: faNetworkWired,
  title: 'Networks',
  href: '/admin/networks',
  visible: true,
  id: 1
};
const userFeedbackLink = {
  icon: faMessageSmile,
  title: 'Feedback',
  href: '/admin/user-feedback',
  visible: true,
  id: 2
};
const occupationDetailsLink = {
  icon: faIdCard,
  title: 'Occupation Details',
  href: '/admin/user-occupation-details',
  visible: true,
  id: 3
};
const manageUsersLink = {
  icon: faUsersCog,
  title: 'Manage Users',
  href: '/admin/manage-users',
  visible: true,
  id: 4
};
const corporateLink = {
  icon: faUserTie,
  title: 'Corporate',
  href: '/admin/corp',
  visible: true,
  id: 5
};
const cloudTrainingLink = {
  icon: faCloudUpload,
  title: 'Cloud Training',
  href: '/admin/cloud',
  visible: true,
  id: 6
};
const submissionsLink = {
  icon: faRetweetAlt,
  title: 'Submissions',
  href: '/admin/submissions',
  visible: true,
  id: 7
};
const taggingLink = {
  icon: faTag,
  title: 'Tagging',
  href: '/admin/tagging',
  visible: true,
  id: 8
};
export const adminLinks = [networksLink, userFeedbackLink, occupationDetailsLink, manageUsersLink, corporateLink, cloudTrainingLink, taggingLink];
export const linksByRole: { [key in UserRoles]?: AdminLinkWithId[] } = {
  analyst: [userFeedbackLink],
  sdr: [userFeedbackLink, occupationDetailsLink],
  bdm: [userFeedbackLink, occupationDetailsLink, corporateLink, cloudTrainingLink],
  mod: [manageUsersLink],
  tester: [submissionsLink],
  'beta-tester': [submissionsLink],
  [UserRoles.TAG_ADMIN]: [taggingLink],
  [UserRoles.CONTENT_DEV]: [cloudTrainingLink],
  [UserRoles.AZURE_ADMIN]: [cloudTrainingLink]
};