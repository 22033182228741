import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { Announcement } from 'src/common/interfaces/announcements';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<ApiResponse<Announcement[]>, void>({
      query: () => ({
        url: 'announcements',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAnnouncementsQuery } = extendedApiSlice;
