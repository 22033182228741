import { useState } from 'react';

import { HoverState } from '../stats.types';

export const useHoverState = (
  initialValues: HoverState,
): [HoverState, (item: keyof HoverState) => void, (item: keyof HoverState) => void] => {
  const [state, setState] = useState<HoverState>(initialValues);
  const setHover = (item: keyof HoverState) => {
    setState((prevState) => ({ ...prevState, [item]: true }));
  };

  const unsetHover = (item: keyof HoverState) => {
    setState((prevState) => ({ ...prevState, [item]: false }));
  };

  return [state, setHover, unsetHover];
};
