import { format } from 'date-fns';
import { DefaultTheme } from 'styled-components';
import Chart from 'chart.js/auto';

import { ChartJSContext } from '../commons';

import { BORDER_COLOR, GRADIENT_FILL_END, GRADIENT_FILL_START } from './questions.constants';
import { QuestionsAnsweredData } from './questions.types';

export const createLinearGradient = (context: ChartJSContext): CanvasGradient | undefined => {
  const { chartArea } = context.chart;
  if (!chartArea) return undefined;
  const { ctx } = context.chart;
  const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
  gradient.addColorStop(0, GRADIENT_FILL_START);
  gradient.addColorStop(1, GRADIENT_FILL_END);
  return gradient;
};

export const convertDataForChart = (inputData: QuestionsAnsweredData) => inputData.map(({ count }) => count + 0.5);

export const convertLabelsForChart = (inputData: QuestionsAnsweredData): string[] =>
  inputData.map(({ date }) => format(new Date(date), 'EEEE, MMM do yyyy'));

export const getGraphOptions = (theme: DefaultTheme) => ({
  scales: {
    y: {
      beginAtZero: true,
      grid: { display: false },
      display: false,
    },
    x: {
      grid: { display: false },
      display: false,
    },
  },
  elements: {
    point: { radius: 0 },
    gird: { display: false },
    legend: { display: false },
    line: {
      borderWidth: 3,
      borderColor: BORDER_COLOR,
      fill: true,
      backgroundColor: (context: ChartJSContext) => createLinearGradient(context),
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      position: 'nearest',
      displayColors: false,
      intersect: false,
      labelColor: theme.colors.text.main,
      backgroundColor: theme.colors.background.tertiary,
      titleColor: theme.colors.text.main,
      titleFont: { size: 12, weight: 'normal', family: theme.fonts.familySansPro },
      bodyColor: theme.colors.text.main,
      bodyFont: { size: 12, weight: 'bold' },
      padding: 12,
      callbacks: {
        label: ({ parsed }: { parsed: { y: number } }): string => {
          if (parsed.y !== 0 && !parsed.y) return '';
          return `${parsed.y - 0.5} ${parsed.y - 0.5 === 1 ? 'question' : 'questions'}`;
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
});
export const getChartConfig = (questionsData: QuestionsAnsweredData, theme: DefaultTheme) => ({
  type: 'line' as const,
  data: {
    labels: convertLabelsForChart(questionsData),
    datasets: [
      {
        data: convertDataForChart(questionsData),
      },
    ],
  },
  options: getGraphOptions(theme),
});

export const initializeChart = (
  chartRef: React.RefObject<HTMLCanvasElement>,
  questionsData: QuestionsAnsweredData,
  theme: DefaultTheme,
) => {
  const ctx = chartRef?.current?.getContext('2d');
  if (!ctx) return () => {};

  const config = getChartConfig(questionsData, theme);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const myChart = new Chart(ctx, config);

  return () => {
    myChart.destroy();
  };
};
