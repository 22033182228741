import { useEffect } from 'react';

import { useAppDispatch } from 'src/app/hooks';
import { setVMTabs, SplitScreenTab } from 'src/app/reducers/split-screen.reducer';
import { setActiveMachine } from 'src/app/reducers/room/room.reducer';
import { useGetRunningQuery } from 'src/features/room/room.slice';

import { ATTACK_BOX_CODES } from '../constants/vms';
import { TaskType } from '../enums/task';

export const useGetVms = (roomCode: string) => {
  const dispatch = useAppDispatch();

  const { data: runningInstancesData } = useGetRunningQuery();

  useEffect(() => {
    if (runningInstancesData) {
      const tabs: SplitScreenTab[] = [];
      let attackBoxPrivateIP;
      const attackBox = runningInstancesData?.find((instance) => ATTACK_BOX_CODES.has(instance.roomCode));

      const activeMachine = runningInstancesData?.find((instance) => instance.roomCode === roomCode);
      dispatch(setActiveMachine(activeMachine));

      if (attackBox) {
        tabs.push({
          id: attackBox.id,
          type: TaskType.VM,
          title: attackBox.title,
          url: attackBox.remote.remoteUrl,
          vm: attackBox,
        });
        attackBoxPrivateIP = attackBox.remote.privateIP;
      }

      if (activeMachine?.remote.remoteUrl) {
        tabs.push({
          id: activeMachine.id,
          type: TaskType.VM,
          title: activeMachine.title,
          url: activeMachine.remote.remoteUrl,
          vm: activeMachine,
        });
      }

      dispatch(setVMTabs({ tabs, attackBoxPrivateIP }));
    }
  }, [dispatch, roomCode, runningInstancesData]);

  return {
    runningInstancesData,
  };
};
