import { FeatureFlagName } from 'src/common/constants';

export const featureFlagToRoomCodesMap: Partial<Record<FeatureFlagName, string[]>> = {
  [FeatureFlagName.ROOMS_REBUILD_WAVE_0]: [
    'burpsuitebasics',
    'owasptop102021',
    'basicpentestingjt',
    'introtosiem',
    'securityawarenessintro',
    'googledorking',
    'wiresharkpacketoperations',
    'linuxforensics',
    'adventofcyber4',
    'exploitingad',
    'vulnerabilities101',
    'activerecon',
    'linprivesc',
    'pythonbasics',
    'hydra',
    'rpnessusredux',
    'threatemulationintro',
    'lazyadmin',
    'cowboyhacker',
    'burpsuiterepeater',
    'wonderland',
    'overpass',
    'kenobi',
    'wreath',
    'bandit',
  ],
  // placeholder
  [FeatureFlagName.ROOMS_REBUILD_WAVE_1]: [
    '0000keygenandautomation',
    '0100commandinjection',
    '0101filteredflagsay',
    '0201shellcodingcustom',
    '02a32c24857111eeb9d10242ac1200',
    '0300nodeppluscoe',
    '0500bypassrandomizing',
    '0600formatstring',
    '0702tcache',
    '0a01harnessing',
    '0b00binaryemulation',
    '0b01systememulation',
    '0ff3nsv3s3curtysw4gg3r',
    '0x01qrscraper',
    '0x02chall',
    '0x41',
    '0x41haz',
    '100',
    '10games',
    '16946',
    '19a8d5e5c496206560f51d325cd256',
    '19a8d5e5c496206560f51d325cd256hd',
    '1itfciana',
    '1itfctftechfusion',
    '1itfgdprkeyrequirements',
    '1itfmodernencryption',
    '1itfolderencryption',
    '1itfreconscriptonlinux',
    '1itfsteganography',
    '1itfwarstories',
    '2022syshacw1',
    '2022uopwk3',
    '2022uopwk4',
    '2022uopwk6',
    '2022uopwk9',
    '2487b4706bc640059799cb839049b8',
    '3108ctfwarkahuntukperwira',
    '32220022024',
    '32crypt',
    '33b462df396e5bc044a6284927aae5',
    '36hours',
    '3xpl0itctfround2',
    '3xploitctfround1',
    '41b7211338d1bcda0da6e629c3d937',
    '420b01huwireshark',
    '420cr',
    '483af95a38f29fddf275e6f6c58c42',
    '49sdnetworkanalysis',
    '4f1ced786e1d26e9a3ec8d14541044',
    '4mi2o',
    '4n6beyondbasics',
    '51d80df7b96f315c7a2d963bbc0f8f',
    '5up3rs3cur3',
    '5w4n6',
    '64I',
    '66b945dee40925ed20d31b8e442dd7',
    '6b66d7d2ea19bd663c0835e1a0afcf',
    '77rangersctf',
    '8kftfuyuqvnagr',
    '8w',
    '9c1f42640909ae55bf419b8e96469d',
    '9skcnzacgsyxhepvufhds4jm',
    '9skcnzacgsyxhepvufhds4jmq8',
    '9skcnzacgsyxhepvufhds4jmq8qk',
    '9skcnzacgsyxhepvufhds4jmzt',
    'a2a50a90b02527072bb3d696eb1f3f',
    'a6bdc09944de1c3c7347516fa90d30',
    'aabuctf',
    'aalyriamalwarechallenge',
    'aalyriaphishingchallenge',
    'abusingwindowsinternals43',
    'academiadeciberseguridadpentes',
    'accefpcheckimage16ab564b9c',
    'accefphfictol2021f03bbea70b2df',
    'accessattacks',
    'achunter',
    'acompanysserverwashacked',
    'acryptographyexplorationcha',
    'actcehv12',
    'activedirectorybasics8tw7',
    'activedirectorybasicsdu',
    'activedirectorybasicsgo',
    'activedirectorybasicsgoua',
    'activedirectorybasicsmp',
    'activedirectorybasicsou',
    'activedirectorybasicsqj',
    'activedirectorybasicsrs',
    'activedirectorybasicsxpb9bbm4',
    'activedirectorybasicsxpb9bbm4yd',
    'activedirectorychallenge',
    'activedirectoryd9',
    'activedirectoryhardeningHb',
    'activedirectoryhardeningtq',
    'activedirectorypowershell',
    'activedirectorypowershellap',
    'activekitty',
    'activerecon0l',
    'activerecon1z',
    'activereconae',
    'activereconbr',
    'activereconcc',
    'activereconccwvWA',
    'activereconct',
    'activerecongo',
    'activereconi16cko',
    'activereconi16ckozJ',
    'activereconob',
    'activereconwi',
    'ad',
    'adana',
    'adeepdiveintocriticalcves',
    'adhardeninggrouppolicies',
    'admin',
    'admin6g',
    'adtiermodel',
    'aductf',
    'adv3nt0fdbopsjcapiyxP',
    'advanceddockersecurity9y',
    'advanceddynamicanalysistw',
    'advancedelkqueriesjn',
    'advancedelkqueriesn1',
    'advancedpentester',
    'adventofcyber2023v9',
    'adventofcyber42u',
    'adventofcyber4ql',
    'adventofcyber5',
    'adventuresinthelinuxcli',
    'adventuretime',
    'afc1d516028a193756fd3697e160a7',
    'afterlife',
    'agctest1',
    'agentsudoctfu0',
    'agiftthatkeepsongiving',
    'aglitxhysystem',
    'ahalloweencarol',
    'ahmad',
    'aiC',
    'aiforcybersecurity',
    'aioctfqualsschools',
    'aioctfqualsschoolslf',
    'aioctfqualsuniversities',
    'aiotesting',
    'airplane',
    'ajwaptlab',
    'alc47raz',
    'alertawareir',
    'alexthehacker',
    'alexthehackervt',
    'alexthehackervtfv',
    'allsignspoint2pwnage',
    'amityctf',
    'analyticwriting',
    'androidmalwareanalysis',
    'androidmalwareanalysisSp',
    'annie',
    'anonymousef',
    'anonymousplayground',
    'anonymousru',
    'anotherapichallenge',
    'anotherctfez',
    'anotherpythonctf',
    'anpactf',
    'anthemTI',
    'antireverseengineeringan',
    'antireverseengineeringkt',
    'aoc2022day22asr',
    'aoc2023clonejhl20231101',
    'aoc2023clonejhl20231102',
    'aoc2023clonejhl20231103',
    'aoc2023clonejhl20231106',
    'aoc2023clonejhl20231108',
    'aoc2023clonejhl20231109',
    'aoc2023clonejhl20231114',
    'aoc2023clonejhl20231114b',
    'aoc2023day23androiddf',
    'aoc2023loganalysis',
    'aoc2023loganalysis3l',
    'aoc2023mlpromptinjectionf4',
    'aoc2023msdos',
    'aoc2023promptinjection',
    'aoc23day15detectingspam',
    'aocdataexfilnetdecompile',
    'aocdiskforensicswithftkimager',
    'aocvolatilityforlinux',
    'apacheaccessloganalysis',
    'apachuu',
    'apichallenge',
    'apihackingpath',
    'apiwizardsbreach',
    'apkreverse',
    'apoorvctf',
    'apple',
    'applicationattack1',
    'apt3elasticsiem',
    'aptloversadmin338',
    'aramcoctf',
    'aramcoctfh8',
    'aramcoctfh8h2',
    'aramcoctfh8rk',
    'aratus',
    'arcane2k23k3jk',
    'archangelel',
    'arcpbhab1238',
    'arcpbnab1218',
    'arcpbnab311',
    'arcpbnab3124',
    'arcpbnab314',
    'arcpbnab315',
    'arcpbnab321',
    'arcpbnab3223',
    'arcpbnab3315',
    'arcpbnab3316',
    'armageddon',
    'arrbpghwwni',
    'artistichacks',
    'arvind',
    'asas4W',
    'asdsadasdasdas',
    'assessmentincidentresponder',
    'assessmentincidentresponder4B',
    'assessmentincidentresponder4BrQ',
    'assignmentsnp',
    'aster',
    'atlas',
    'atmnc',
    'atrangctf2023',
    'attackanddefendworkshop',
    'attackerkb',
    'attackingics1mK',
    'attackingics2',
    'attackingics2eG',
    'attackingkerberos1e',
    'attackingkerberoskege',
    'attackingkerberoskegepb',
    'attackingkerberoslb',
    'attackingkerberosmr',
    'attackingkerberosy8',
    'attackingkerberosye',
    'attacktivedirectoryfg',
    'attacktivedirectoryki',
    'attacktivedirectoryys',
    'attacktivedirectoryz6',
    'attacktivedirectoryZF',
    'auditingandmonitoringsejv',
    'auditingandmonitoringsequ',
    'augmentorosint',
    'aupctf',
    'authenticate',
    'authenticationbypassBd',
    'authenticationbypassmkcq',
    'authenticationbypasso4',
    'authenticationbypasssa',
    'authenticationbypassu1',
    'authorsonly',
    'automationwithpython',
    'autopsy',
    'autopsy2ze01m',
    'autopsy2ze0aI',
    'autopsy2ze0bf',
    'autopsy2ze0Ef',
    'autopsy2ze0mu',
    'autopsy2ze0o2',
    'autopsy2ze0ow',
    'autopsy2ze0wj',
    'autopsyayd521',
    'avengers',
    'avengerseG',
    'avengersnx',
    'avevasionshellcodeya',
    'avsctf',
    'awdawd',
    'awscapstone',
    'awsencryptionservices',
    'awsincidentresponse',
    'axss',
    'azsecm01r02',
    'azsecm01r02a',
    'azsecm01r03',
    'azsecm01r04',
    'azsecm01r05',
    'azsecm02r01',
    'azsecm02r02',
    'azsecm02r03',
    'azurecloudtraining',
    'b3dr0ck',
    'babyad',
    'babysharkoqsf',
    'backdoor',
    'backdoorservices',
    'backtrack',
    'backup',
    'badbyte',
    'badsequel',
    'bandit0',
    'bankctf',
    'barbiegirl',
    'bareeqctfAZ',
    'bashing',
    'bashscripting9s',
    'bashscriptingCZ',
    'bashscriptingih',
    'bashscriptingjl',
    'basicdynamicanalysiskt',
    'basicdynamicanalysisZl',
    'basiclevelctf',
    'basiclinuxusage',
    'basicmacosoperations',
    'basicmemorydumpanalysis',
    'basicnetworkdevices',
    'basicpentesting',
    'basicpentestingjt6zm0',
    'basicpentestingjtbo',
    'basicpentestingjtCB',
    'basicpentestingjtFK',
    'basicpentestingjtIh',
    'basicpentestingjtobcu',
    'basicpentestingjtpI',
    'basicpentestingjtpqni',
    'basicskills',
    'basicskillsyj',
    'basictriage',
    'batchgenius',
    'batmanctf',
    'batmanvssuperman',
    'battery',
    'battleforflags',
    'battleforflagsbo',
    'battleforflagsbopt',
    'battleforflagsboptoq',
    'bauroom',
    'bclhctfeventli',
    'bclhctfeventli4w',
    'beaconsecurity',
    'beansploit',
    'bearitctf',
    'bearitctfdd',
    'bebop',
    'becomeahackerLx',
    'becomeahackerLxDw',
    'becomeahackeroaRm',
    'becomesharpfinder789',
    'becomingafirstresponderOZ',
    'beginnerb2root',
    'beginnerclassbasic2',
    'beginnerpathintro5n',
    'beginnerpathintroe0',
    'beginnerpathintroiq',
    'beginnerpathintronX',
    'beginnerpathintrorp',
    'beginnerpathintrosp',
    'benign4b',
    'benignl3uq',
    'benignnkibz5Lu',
    'beno',
    'bhaskarbharprivate',
    'biblioteca',
    'bigtroubleinlittlechinai',
    'binaryadditionsubtraction',
    'binarygamesun',
    'binaryheaven',
    'binarysubtraction',
    'binex',
    'bios',
    'bitcoinimposible',
    'biteme',
    'blackholerouting',
    'blairathol2024',
    'blamegame',
    'blastertr',
    'blindspot',
    'blockchainvkkgjr',
    'blockchainvkkgjrphsh',
    'blockroom',
    'bloodyhoundv4',
    'blue62nu',
    'blue6p',
    'blueeg',
    'blueeternalwt',
    'bluehensctf',
    'bluehl',
    'blueis',
    'bluek2',
    'bluekeepBL',
    'bluekeepcve',
    'bluekeeprdpvulnerability',
    'bluekill',
    'bluems',
    'bluepf',
    'blueQq',
    'bluerp',
    'bluesv6s',
    'bluesv6soe',
    'blueteami5',
    'blueteamtoolkit',
    'blueuq',
    'bnddecryptorchallenge',
    'bobthebuilderac',
    'bobthebuilderac2w',
    'bobthebuilderac2wrg',
    'bobthebuilderac2wrgeicr',
    'bof13S',
    'bof1aq',
    'bof1y2',
    'boogeyman1',
    'boogeyman2',
    'boogeyman3',
    'bookstoreocjj',
    'bootup202357',
    'bootup2k24',
    'bootup2k24dd',
    'bootup2k24wd',
    'borderlands',
    'botspune1',
    'box',
    'box1',
    'box101basicpentesting1',
    'box102basicpentesting2',
    'box16forbidden',
    'box17hackaish',
    'box2',
    'box20mybox',
    'box3',
    'box4',
    'box5',
    'box501brainpan1withflag',
    'box6',
    'box7',
    'box8',
    'boxo1troll1',
    'boxo1troll2',
    'bpnetworking',
    'bpsplunk',
    'bpsplunk5s',
    'bpvolatility',
    'bpvolatilityia',
    'bpvolatilityncos',
    'brainhurt',
    'breakingthe1c3d4ky',
    'breakit',
    'breakmenu',
    'brim9x',
    'brimlz',
    'brokenlinux',
    'broker',
    'bruteforce',
    'bruteitlZ',
    'bsidescos',
    'bsidesgtdav',
    'bsidesgtdevelpy',
    'bstbashscriptingpractical',
    'btautopsye0AE',
    'btautopsye0cE',
    'btautopsye0i0',
    'btautopsye0myiNMR',
    'btautopsye0rt',
    'btautopsye0ut',
    'btautopsye0y3',
    'btautopsye0yd',
    'btautopsye0za',
    'btcirt23',
    'btlopretest',
    'btredlinejoxr3d2ml4',
    'btredlinejoxr3d6E',
    'btredlinejoxr3ddm',
    'btredlinejoxr3dt4',
    'btredlinejoxr3dwn',
    'btreversingnetapps',
    'btsysinternalssgok',
    'btsysinternalssgqX',
    'btsysinternalssgsi',
    'btsysinternalssgwnsbSQ',
    'btsysinternalssgzm',
    'btwindowsinternals55',
    'btwindowsinternals96',
    'btwindowsinternalsb6',
    'btwindowsinternalsgu',
    'btwindowsinternalskv',
    'btwindowsinternalsml',
    'btwindowsinternalstM',
    'btwindowsinternalszk',
    'bubirdenemectf',
    'bugbountyforbeginners',
    'bugbountyhuntingintro',
    'bugbountymethadology',
    'bugged',
    'bugged',
    'buildingblocksinesitf',
    'buildingsoftwareapplicationsww',
    'buildingspamdetector',
    'burpsuite',
    'burpsuitebasics0cvthl',
    'burpsuitebasics1f',
    'burpsuitebasics8u',
    'burpsuitebasicsac',
    'burpsuitebasicsae',
    'burpsuitebasicsAr',
    'burpsuitebasicsbp',
    'burpsuitebasicsbp8q',
    'burpsuitebasicsbp8qCl',
    'burpsuitebasicseq',
    'burpsuitebasicsfk',
    'burpsuitebasicsh9',
    'burpsuitebasicsu6',
    'burpsuitebasicsyb',
    'burpsuiteextenderiq6s',
    'burpsuiteextenderiq6s4G',
    'burpsuiteextenderkj',
    'burpsuiteextenderold',
    'burpsuiteintruder0l',
    'burpsuiteintruder48',
    'burpsuiteintruderbd',
    'burpsuiteintruderbdnk',
    'burpsuiteintrudermiox',
    'burpsuiteintruderold',
    'burpsuiteintruderqu',
    'burpsuiteintruderqujy',
    'burpsuiteintrudert8',
    'burpsuiteomDj',
    'burpsuiteomold',
    'burpsuiteompm',
    'burpsuiteomtwlY',
    'burpsuiterepeatera2',
    'burpsuiterepeatera21O',
    'burpsuiterepeaterBW',
    'burpsuiterepeatercm',
    'burpsuiterepeaterold',
    'burpsuiterepeaterqQ',
    'burpsuiterepeaterz1',
    'businessprocesses',
    'busyvimfrosteaun8',
    'bwapp',
    'byb1rps8ite',
    'bypass',
    'bypassinguacly',
    'bypassmypassword',
    'c1pentesttrainingdw',
    'c3malwareanalysis',
    'c4networksecurity',
    'c4networksecurityed',
    'c4ptur3th3fl4gbd',
    'campuspartycyberpcdf2023',
    'can',
    'canyoumakeit',
    'canyouseemekr',
    'capturereturns',
    'capturethebox',
    'capturetheflagcrescent',
    'capturethegoal',
    'capturingyourfirstflag',
    'careersincyber08',
    'careersincyber2k',
    'careersincyber5G',
    'careersincyberD2',
    'careersincyberdw',
    'careersincyberix',
    'careersincyberJR',
    'careersincyberl8',
    'careersincybern5',
    'careersincybern5zo',
    'careersincybern5zoHk',
    'careersincyberOf',
    'careersincyberzpQp50',
    'carhacking101',
    'carnival24',
    'carpediem1',
    'carpediem1fc',
    'caseb4dm755nd',
    'caseb4dm755rs',
    'cat',
    'catnipkingpinusb',
    'catpictures',
    'catpictures2',
    'catregexc7',
    'catregexGk',
    'cauldronrj',
    'cbcmacforgerywiprohs23ap',
    'cbrosintctf',
    'cc24osint',
    'ccghidra',
    'cchacktestowjaqnjlq5kevb',
    'ccpentesting',
    'ccpentestinghi',
    'ccpentestingowjaqnjl',
    'ccpentestingqt6zdmktqlxT',
    'ccradare2',
    'ccstego',
    'cct2019',
    'cct2019pbua',
    'cdpintial',
    'cebootcampsearchingwithchronic',
    'cehpracticequestions',
    'cehv12',
    'cerez',
    'certaindoom',
    'ceskoct23',
    'cewl',
    'cfadvencodingendiannessyr',
    'cfadvencodingendiannessyro0',
    'cfadvex23nmap',
    'cfadvex25harvesting',
    'cfadvex26hydra',
    'cfadvex26hydrauA',
    'cfadvex27bufferoverflowa',
    'cfadvex28exploitashare',
    'cfadvex29sqlinjection',
    'cfadvex30andex31sqlpwd',
    'cfadvex33enumeratingws',
    'cfadvex34vulnerablecms',
    'cfadvex35exploitwebappsc',
    'cfadvex36doxing4K',
    'cfex24examineopenvas',
    'cforbeginnerstg',
    'challenge',
    'challenge01',
    'challenge01er',
    'challenge01g8',
    'challenge01g8ui',
    'challengetry',
    'chandanroom',
    'chanttoopen',
    'chaos3z',
    'charlienewyear7w',
    'charlienewyear7wx2',
    'cheesectfv8',
    'cheesectfv9',
    'cherryblossom',
    'cherryblossomut',
    'chocolate',
    'chocolatefactorybo',
    'chocolatefactorybont',
    'christest',
    'christest2',
    'christestchallenge',
    'christestwalkthrough',
    'chronicle',
    'chroniclealertingchallenge3a',
    'chroniclemalwarechallengevj26x5rk',
    'chroniclemalwarechallengevjvu1f',
    'chroniclemalwarechallengevjvu5s',
    'chroniclephishingchallengegwg',
    'chroniclephishingchallengejovwovzt',
    'chroniclephishingchallengejovwovztbs',
    'chroniclephishingchallengejovwovzts5',
    'chroniclephishingchallengejovwovzts50c4l',
    'chronicleudmsearchchallenge',
    'ciatriangle',
    'ciberneticos',
    'cicada3301vol1',
    'cicada3301vol2',
    'cicdtakeover',
    'cipherdose',
    'ciphers',
    'cipherverse',
    'citc2356networkreview',
    'cjpt24',
    'class1',
    'classicpasswd',
    'clocky',
    'closedoor',
    'cloud101',
    'cloudbasediac8Y',
    'cloudhuntctf',
    'cloudhuntctf2',
    'cloudsecuritychallenge2ygmr1',
    'cloudsecuritychallenge2ygmr1tk',
    'cloudsecuritychallenge2ygmr1u9',
    'cloudsecuritytop8',
    'clusterhardening',
    'cmnaticsnippets',
    'cmsozexud',
    'cmspit',
    'cnbasiclinux',
    'cobaltstriked',
    'cochroniclemalware',
    'cochroniclephishing',
    'code',
    'codewave',
    'codgovorni',
    'codgovornidy',
    'codingclub1',
    'colddboxeasyl9',
    'coldvvars',
    'collectjohnsinventory',
    'combiningencryptionhashing',
    'commandandcrush',
    'commandandcrushxw',
    'commandlinefun',
    'committed',
    'commonattacks83',
    'commonattacksam',
    'commonattacksamp7',
    'commonattacksamp7kb',
    'commonattacksamyi',
    'commonattacksdl',
    'commonattacksf2',
    'commonattacksJz',
    'commonattacksni',
    'commonattacksor',
    'commonattacksrj',
    'commonattackssm',
    'commonattacksttwuu00s',
    'commonattacksvu',
    'commonattackswhes',
    'commonattackswhesHS',
    'commonattacksxs',
    'commonattacksyh',
    'commonattacksyv',
    'commonlinuxprivesc6v',
    'commonlinuxprivesc83',
    'commonlinuxpriveschy',
    'commonlinuxprivescsj',
    'communitybasedtools',
    'comparinghashvalues',
    'completebeginner',
    'compromisedcomms',
    'computerliteracy',
    'confidential',
    'confidentiality',
    'confluence2023225151l',
    'confluence202322515ZL',
    'console',
    'containerhardeningov',
    'containernetworking',
    'containervulnerabilitiesDG3v',
    'containervulnerabilitiesDG82',
    'containervulnerabilitiesdq',
    'containme1',
    'contentdiscovery0V',
    'contentdiscovery4r',
    'contentdiscovery9o',
    'contentdiscoveryl8',
    'contentdiscoveryxx',
    'contentdiscoveryzs',
    'contiransomwarehgh',
    'contiransomwarehgh1s',
    'contiransomwarehghat',
    'contiransomwarehghxd',
    'contrabando',
    'cooctusadventures',
    'cookiestf',
    'corgi',
    'corporate',
    'corsmisconfiguration',
    'corvitctfceqj',
    'cosmo',
    'couch',
    'courier',
    'courriel101',
    'courriel102',
    'cpqdteste1',
    'cr',
    'cr310',
    'crackthehash7x',
    'crackthehashat',
    'crackthehashb3',
    'crackthehashk3',
    'crackthehashlevel2nj',
    'crackthehashlevel2sy',
    'crackthehashn8',
    'crackthepass',
    'creative',
    'credharvesting5k',
    'crocccrew',
    'crocodile',
    'cron',
    'crowdstrikechallenges',
    'crowdstrikechallengesub',
    'crowdstrikechallengesubQa',
    'crowdstrikechallengesubQapD',
    'crylo4a',
    'crypticalien',
    'cryptochallenges',
    'cryptocsc101',
    'cryptocsc101t1',
    'cryptograhp',
    'cryptographie101',
    'cryptographie101db',
    'cryptographybasic',
    'cryptographyfordummiesa4',
    'cryptographyfordummiesia',
    'cryptographyfordummiesjj',
    'cryptographyfordummiesmgikr',
    'cryptographyfordummiesng',
    'cryptographyfordummiesni',
    'cryptographyfordummiesqv',
    'cryptographyfordummiestx',
    'cryptographyfordummiesXX',
    'cryptographyfromrpca2023',
    'cryptographyfunclub',
    'cryptographyintro0j',
    'cryptographyintro1n',
    'cryptographyintromu',
    'cryptographyintroNw',
    'cryptographyintropy',
    'cryptographyintropyvm',
    'cryptographyintropyvmkg',
    'cryptographyintroS5',
    'cryptographyintroud',
    'cryptographyintroug',
    'cryptographyissues',
    'cryptographyroomrhys',
    'cryptographyroomthebest1',
    'cryptographyw0',
    'crypton',
    'cryptovault',
    'cryptoverse',
    'cryptoversemagnum2024',
    'cs1660burpra',
    'cs1660nmapqtqv',
    'cs188019vtr',
    'cs188019vtr8c',
    'cs1880pwdcracking4a',
    'csaendexamx7',
    'csbasics',
    'csc101',
    'cschardening',
    'csclubctf',
    'cscmcs',
    'cscpentestg6',
    'cscpentestg62j',
    'cscpentestg6of',
    'cseccrypto',
    'csft01',
    'csfto1labs',
    'csmaprotocol',
    'csp',
    'cspdd',
    'cspes',
    'csrf',
    'csrfV2',
    'csshirechallengelevel1',
    'cstuffbits',
    'ctf0012242',
    'ctf100',
    'ctf100w1',
    'ctf101wj',
    'ctf101xu',
    'ctf1Hi',
    'ctf2023Btgp',
    'ctf2023Btgpk6',
    'ctf2023Btgpy0',
    'ctf2023kaxa',
    'ctf33BB',
    'ctfasaservicev1uavx',
    'ctfatrang23',
    'ctfcapstone',
    'ctfchallengecodefest2023C0',
    'ctfcollectionvol18o',
    'ctfcollectionvol1OX',
    'ctfcollectionvol1yqjg',
    'ctfcollectionvol2',
    'ctfcollectionvol3',
    'ctfdemotechathon2k23',
    'ctfdiamundialciberseguridadtec',
    'ctfforpractice',
    'ctfforthebeginners',
    'ctffromctt2023',
    'ctflevelone',
    'ctfonencoding',
    'ctfonepiece65',
    'ctfpcapfile',
    'ctfsecomembers',
    'ctfSl',
    'ctfsoc',
    'ctfsunewgeneration',
    'ctftechathon2k23boo2root',
    'ctftechathon2k23yu',
    'ctftechnokrat2024yd',
    'ctftest2024',
    'ctftestTT',
    'ctfufaz2024hardeningbasicspart',
    'ctfwQ',
    'ctfyr',
    'ctiabasicmodule41',
    'ctiabasicmodule42and43',
    'ctiaipb',
    'ctiapublications',
    'cugcagnxziu2psoo32hrw1ptyp9tndwoqh',
    'cugcagnxziu2psoo32hrw1ptyp9tndwoqhmi',
    'cugcagnxziu2psoo32hrw1ptyp9tndwoqhvd',
    'cupp',
    'cursoehtelconetccfa',
    'customalertrulesinwazuhdo',
    'customctfevent',
    'customctfevent0M',
    'customctfevent0S',
    'customctfevent0X',
    'customctfevent16',
    'customctfevent1c',
    'customctfevent1T',
    'customctfevent1Y',
    'customctfevent28',
    'customctfevent2H',
    'customctfevent2J',
    'customctfevent2P',
    'customctfevent2T',
    'customctfevent2TnE',
    'customctfevent2u',
    'customctfevent2z',
    'customctfevent35',
    'customctfevent3K',
    'customctfevent3y',
    'customctfevent44',
    'customctfevent4b',
    'customctfevent4J',
    'customctfevent4W',
    'customctfevent52',
    'customctfevent5C',
    'customctfevent5K',
    'customctfevent5n',
    'customctfevent5o',
    'customctfevent5S',
    'customctfevent6e',
    'customctfevent6F',
    'customctfevent6G',
    'customctfevent6h',
    'customctfevent6j',
    'customctfevent6O',
    'customctfevent6P',
    'customctfevent6Q',
    'customctfevent6s',
    'customctfevent6t',
    'customctfevent7E',
    'customctfevent7EjI',
    'customctfevent7F',
    'customctfevent7L',
    'customctfevent7Q',
    'customctfevent7s',
    'customctfevent7z',
    'customctfevent83',
    'customctfevent83Oq',
    'customctfevent8X',
    'customctfevent8XZy',
    'customctfevent8y',
    'customctfevent8z',
    'customctfevent93',
    'customctfevent9C',
    'customctfevent9N',
    'customctfevent9t',
    'customctfeventa5',
    'customctfeventA7',
    'customctfeventa7up',
    'customctfeventaa',
    'customctfeventab',
    'customctfeventag',
    'customctfeventaH',
    'customctfeventai',
    'customctfeventAo',
    'customctfeventAR',
    'customctfeventB0Wq',
    'customctfeventba',
    'customctfeventbAil',
    'customctfeventBH',
    'customctfeventBOiJ',
    'customctfeventBr',
    'customctfeventBrln',
    'customctfeventbs',
    'customctfeventby',
    'customctfeventc5',
    'customctfeventCG',
    'customctfeventcgKm',
    'customctfeventcj',
    'customctfeventCM',
    'customctfeventcT',
    'customctfeventcW',
    'customctfeventCWDp',
    'customctfeventD5',
    'customctfeventd6',
    'customctfeventDa',
    'customctfeventdB',
    'customctfeventdBUd',
    'customctfeventDd',
    'customctfeventDE',
    'customctfeventdixB',
    'customctfeventDm',
    'customctfeventdP',
    'customctfeventDS',
    'customctfeventDt',
    'customctfeventdu',
    'customctfeventduCk',
    'customctfeventdv',
    'customctfeventdvGC',
    'customctfeventdWVR',
    'customctfeventE0',
    'customctfeventE3',
    'customctfeventEg',
    'customctfeventeKck',
    'customctfeventel',
    'customctfeventeV',
    'customctfeventf2',
    'customctfeventfB',
    'customctfeventff',
    'customctfeventfh',
    'customctfeventFi',
    'customctfeventfN',
    'customctfeventfo',
    'customctfeventFQ',
    'customctfeventFt',
    'customctfeventfX',
    'customctfeventfz',
    'customctfeventg9',
    'customctfeventGc',
    'customctfeventgK',
    'customctfeventGl',
    'customctfeventGW',
    'customctfeventh9',
    'customctfeventHa',
    'customctfeventhD',
    'customctfeventhE',
    'customctfeventhGoo',
    'customctfeventhGoodA',
    'customctfeventhGooHp',
    'customctfeventhGooNG',
    'customctfeventHK',
    'customctfeventhw',
    'customctfeventhw4o',
    'customctfeventI2',
    'customctfeventIdqD',
    'customctfeventie',
    'customctfeventiF',
    'customctfeventIh',
    'customctfeventiL',
    'customctfeventiL5i',
    'customctfeventIU',
    'customctfeventiZ',
    'customctfeventJ1',
    'customctfeventja',
    'customctfeventjc',
    'customctfeventJf',
    'customctfeventJI',
    'customctfeventJp',
    'customctfeventJT',
    'customctfeventjW',
    'customctfeventJz',
    'customctfeventk3',
    'customctfeventka',
    'customctfeventkC',
    'customctfeventkD',
    'customctfeventKF',
    'customctfeventKFcB',
    'customctfeventKg',
    'customctfeventKK',
    'customctfeventkl',
    'customctfeventKQ',
    'customctfeventKr',
    'customctfeventkt',
    'customctfeventku',
    'customctfeventkuMo',
    'customctfeventKZ',
    'customctfeventl60w',
    'customctfeventlA',
    'customctfeventlB',
    'customctfeventLE',
    'customctfeventLi',
    'customctfeventlK',
    'customctfeventlK2x',
    'customctfeventlR',
    'customctfeventLS',
    'customctfeventlu',
    'customctfeventlujT',
    'customctfeventlX',
    'customctfeventLz',
    'customctfeventM6',
    'customctfeventM7',
    'customctfeventM8',
    'customctfeventME5D',
    'customctfeventmf',
    'customctfeventmI',
    'customctfeventML',
    'customctfeventMm',
    'customctfeventMP',
    'customctfeventMS',
    'customctfeventMS7r',
    'customctfeventMU',
    'customctfeventMUrv',
    'customctfeventMwvQ',
    'customctfeventn8GS',
    'customctfeventNa',
    'customctfeventNFRn',
    'customctfeventNH',
    'customctfeventNJ',
    'customctfeventNM',
    'customctfeventnp',
    'customctfeventnR',
    'customctfeventnU',
    'customctfeventnUQe',
    'customctfeventNv',
    'customctfeventNw',
    'customctfeventnZ',
    'customctfeventO2',
    'customctfeventO6',
    'customctfeventOE',
    'customctfeventog',
    'customctfeventOK',
    'customctfeventoQ',
    'customctfeventou',
    'customctfeventOx2o',
    'customctfeventP4',
    'customctfeventPc',
    'customctfeventpE',
    'customctfeventPH',
    'customctfeventPk',
    'customctfeventPS',
    'customctfeventpu',
    'customctfeventPV',
    'customctfeventQ7',
    'customctfeventQ7YM',
    'customctfeventq8',
    'customctfeventqb',
    'customctfeventqbrJ',
    'customctfeventQd',
    'customctfeventqG',
    'customctfeventQI',
    'customctfeventQL',
    'customctfeventQLPn',
    'customctfeventqm',
    'customctfeventqT',
    'customctfeventqV76',
    'customctfeventQY',
    'customctfeventR0',
    'customctfeventrh',
    'customctfeventRk',
    'customctfeventRL',
    'customctfeventrM',
    'customctfeventro',
    'customctfeventRU',
    'customctfeventrV',
    'customctfeventRy',
    'customctfeventRyZT',
    'customctfevents3',
    'customctfevents3g6',
    'customctfeventS4',
    'customctfeventsC',
    'customctfeventSl',
    'customctfeventSs',
    'customctfeventst',
    'customctfeventSx',
    'customctfeventsYvf',
    'customctfeventt1',
    'customctfeventTB',
    'customctfeventte',
    'customctfeventTf',
    'customctfeventTj',
    'customctfeventtL',
    'customctfeventTP',
    'customctfeventtv',
    'customctfeventTY',
    'customctfeventTz',
    'customctfeventu6',
    'customctfeventUB',
    'customctfeventuC',
    'customctfeventUE',
    'customctfeventUM',
    'customctfeventUU',
    'customctfeventuy',
    'customctfeventUz',
    'customctfeventV3pl',
    'customctfeventV3Wx',
    'customctfeventv5ZE',
    'customctfeventv7',
    'customctfeventvA',
    'customctfeventVe',
    'customctfeventVh',
    'customctfeventVhON',
    'customctfeventVV',
    'customctfeventw1',
    'customctfeventw2',
    'customctfeventW7',
    'customctfeventWC',
    'customctfeventWp',
    'customctfeventWs',
    'customctfeventWV',
    'customctfeventwx',
    'customctfeventWz',
    'customctfeventX4',
    'customctfeventX4C4',
    'customctfeventxLWp',
    'customctfeventxr',
    'customctfeventxt',
    'customctfeventxtiE',
    'customctfeventxV',
    'customctfeventXW',
    'customctfeventXZ',
    'customctfeventYB',
    'customctfeventyC',
    'customctfeventYd',
    'customctfeventyE',
    'customctfeventyEt3',
    'customctfeventyg',
    'customctfeventyL',
    'customctfeventyn',
    'customctfeventyR',
    'customctfeventYu',
    'customctfeventYz',
    'customctfeventZ5',
    'customctfeventzc',
    'customctfeventzD',
    'customctfeventzE',
    'customctfeventzO',
    'customctfeventzQ',
    'customctfeventZR',
    'customctfeventZt',
    'cv2',
    'cve20084177searchboxsqli',
    'cve20084250kf',
    'cve20090075',
    'cve20091026',
    'cve20101240',
    'cve20102075',
    'cve201020750q',
    'cve20102075dl',
    'cve20104243',
    'cve20121823',
    'cve20124915',
    'cve20131828',
    'cve20136875',
    'cve20140038',
    'cve20140160',
    'cve20150802',
    'cve20151701bg',
    'cve20157547',
    'cve20168655wh',
    'cve201701442c',
    'cve20170144aj',
    'cve20170144eternalblue',
    'cve20170213lx',
    'cve201715125',
    'cve201715958sqlinjection',
    'cve201715974',
    'cve20175753spectre',
    'cve20176074',
    'cve201810097xssinjection',
    'cve201819421',
    'cve201899581h',
    'cve201913272',
    'cve201913975',
    'cve201914287',
    'cve201914287kt',
    'cve201914287QH',
    'cve20198',
    'cve20200601',
    'cve202012800',
    'cve20201472',
    'cve20207246',
    'cve2020724626',
    'cve202072469L',
    'cve20207246AR',
    'cve20207246ARgv',
    'cve20207246C3',
    'cve20207246walkthrough',
    'cve202124917dU',
    'cve202129447',
    'cve202129447c0',
    'cve20213156Yf',
    'cve20213560',
    'cve20213560kz',
    'cve202136934',
    'cve20214034',
    'cve20214034room',
    'cve20214034zU',
    'cve20214034zUs4',
    'cve202141773',
    'cve20220708',
    'cve20220847',
    'cve20220847tk',
    'cve202226134',
    'cve202226923',
    'cve202228637',
    'cve202228672',
    'cve202229072',
    'cve202229072iF',
    'cve202229072iK',
    'cve20224510',
    'cve20231835',
    'cve202323488',
    'cve202327350',
    'cve202327997',
    'cve202328434',
    'cve202332315GK',
    'cve20233460',
    'cve202335078',
    'cve202335165',
    'cve202337979',
    'cve202338831',
    'cve2023388315R',
    'cve202338831dL',
    'cve202338831ha',
    'cve202338831QA',
    'cve202338831ZA',
    'cve202338831zZ',
    'cve20233971htmlinjection',
    'cve20234278',
    'cve202346604activemqrce',
    'cve202421413',
    'cvebluekeep',
    'cvechallenge',
    'cveexploitanalysis',
    'cveexploitation',
    'cveexploitationsit22576552',
    'cvelY',
    'cveP1',
    'cveresearchproject',
    'cwctf2023',
    'cy3py',
    'cy3py1s',
    'cy3pyW9',
    'cyb6020dostaljy',
    'cyber',
    'cyber101',
    'cyberacademy',
    'cyberbanglastegctf2021',
    'cyberbank',
    'cyberbugbounty',
    'cyberchef',
    'cyberchefchallengessw',
    'cyberchefem',
    'cybercheftestroom',
    'cybercrafted',
    'cyberdarkk',
    'cyberfundamentle',
    'cybergovernanceregulationbk',
    'cybergovernanceregulationDI',
    'cyberiumfirstctfvl',
    'cyberkillchainzmt2y',
    'cyberkillchainzmtdg',
    'cyberkillchainzmtfN',
    'cyberkillchainzmtGo',
    'cyberkillchainzmtlk',
    'cyberkillchainzmtpe',
    'cyberkillchainzmtrc',
    'cyberkillchainzmtrn',
    'cyberkillchainzmtvs',
    'cyberknights',
    'cyberlegionbsc2023',
    'cyberlensp6',
    'cybermaniactfxyzxyzxyz1',
    'cybermaryland',
    'cybermaryland4year',
    'cybermoon',
    'cybernaut',
    'cyberoperationshackathon',
    'cyberopscyberxploit',
    'cyberopsexploits',
    'cyberopsinfosec',
    'cyberopsphotos',
    'cyberopsroom2',
    'cyberopsroom3',
    'cyberpiratetreasuremap',
    'cyberpsychosis',
    'cyberpunk',
    'cyberquest',
    'cyberreadywg2nvjdbo',
    'cyberresiliencecentre',
    'cyberrisk',
    'cyberrisktest',
    'cyberriskthreat',
    'cybersecstudentsctfnl',
    'cybersecurity',
    'cybersecurityanalystmidkv0l',
    'cybersecurityframeworks1u',
    'cybersecurityfundamentals',
    'cybersecurityroomxq',
    'cybersecuritywithnewgen',
    'cybershield',
    'cybershujaamakeupsa42023',
    'cyberslay2320',
    'cyberslay24',
    'cybersleuthquest2023',
    'cybersloit',
    'cybersphere',
    'cyberspherereborn',
    'cybertechajju',
    'cyberthreatintelbv',
    'cyberthreatintelbv3x',
    'cyberthreatinteldm',
    'cyberthreatintelg2',
    'cyberthreatinteloc',
    'cyberthreatintelwc',
    'cyberthreatintelxx',
    'cyberthreatintelz2',
    'cybertron',
    'cyberunbound',
    'cybervisionf6qi',
    'cybervisionf6qipk',
    'cybervolunteer',
    'cyberweek2021',
    'cyberweek2021jmra',
    'cyberweek2021qM',
    'cyberyx',
    'cyberzeroday8z',
    'cybr1022technicalproject',
    'cybr1082shellshock',
    'd3l3x',
    'd75c0ckp17c7fi9o82o22pq90803gl7ye',
    'd75c0ckp17c7fi9o82o22pq90ie7s3r',
    'dailybuglesF',
    'damnvulnerablewebfrench',
    'danceanalystdancest',
    'dangerzone',
    'dark',
    'darkctflo',
    'darkleaksdb',
    'darkraictf',
    'darkwebskillstraining',
    'dastzapo2',
    'databaseel',
    'datacarvingfrompcap',
    'dataxexfiltd9',
    'davesblog',
    'day13diamonddefencemodel',
    'day4reverseengineering',
    'daybreak3r',
    'days17and23aoc2022',
    'dbk01criptografa',
    'ddd',
    'deadinside',
    'dealwiththedevil',
    'dearqa',
    'deathflag',
    'debianprivesc',
    'debug',
    'decodememB',
    'dedseclinuxctf102021',
    'deepce',
    'defendmonplacton',
    'defenseindepthpartii',
    'defensespace',
    'defensivesecurity3p',
    'defensivesecuritybf',
    'defensivesecuritydn',
    'defensivesecurityeN',
    'defensivesecurityeypgqD',
    'defensivesecurityeypgqDt8',
    'defensivesecurityF2',
    'defensivesecuritygu',
    'defensivesecurityHc',
    'defensivesecurityhqjw',
    'defensivesecurityhqlzym',
    'defensivesecurityqm3u',
    'defensivesecurityqx',
    'defensivesecuritys9',
    'defensivesecuritytb',
    'defensivesecuritytk',
    'defensivesecurityuz',
    'defensivesecuritywA',
    'defensivesecuritywi',
    'dejavu',
    'delta9security',
    'democtfbX',
    'democtfj2',
    'demov2',
    'demoxa',
    'denem21',
    'dependencymanagementfs',
    'detective',
    'developer',
    'devie',
    'devilshackertest',
    'df',
    'dfgh',
    'dfir',
    'dfirprocesslegalconsiderations',
    'dfirreport',
    'dfirtimelineanalysis',
    'dghack',
    'dhbwwebappsec2023',
    'dhbwwebappsec2024',
    'dhsesir101pc',
    'diabolicalbox',
    'diamondmodelrmuwwg424d',
    'diamondmodelrmuwwg424di7',
    'diamondmodelrmuwwg42fy',
    'diamondmodelrmuwwg42zr',
    'digdug',
    'digdug6J',
    'digidocaboh',
    'digidocabohzc',
    'digitalcertificate',
    'digitalcertificateeS',
    'digitalcertificates',
    'digitalcertificates8H',
    'digitalcertificatese4',
    'digitalcertificatesie',
    'digitaldefendersctf20kc',
    'digitalforensicfromrpcactf',
    'digitalforensicsfishies',
    'digitalforensicswithtsk',
    'digitalforensicswithtskbd',
    'digitalpursuit',
    'dimension',
    'directorydfirroom',
    'dirtypipeexploit',
    'discofever',
    'discovery2k23ctf',
    'discuzctfbysitechsecr9',
    'disgruntledeG',
    'disgruntledG0',
    'dissectingpeheaderszb',
    'distrofever',
    'divedeep',
    'django',
    'djangoj0',
    'djclairepracticeroom',
    'djinn',
    'dllhijacking',
    'dmuhackerssteg',
    'dnsindetail3v',
    'dnsindetail61',
    'dnsindetail8R',
    'dnsindetaila7',
    'dnsindetailbp',
    'dnsindetailcmv9',
    'dnsindetailg0',
    'dnsindetaillh',
    'dnsindetailoaju',
    'dnsindetailoarqwf',
    'dnsindetailoarqwfhxqr',
    'dnsindetailoarqwfMj',
    'dnsindetailpm',
    'dnsindetailq6',
    'dnsindetailqmuu',
    'dnsindetailRZ',
    'dnsindetailRZTx',
    'dnsindetailut',
    'dnsindetailvr',
    'dnsindetailytyf',
    'dnsmanipulation',
    'dnsmanipulation',
    'dnsmanipulationxe',
    'dockerplayground',
    'dockerrodeo',
    'dockerrodeoqa',
    'dockmagic',
    'dodge',
    'dogcatxm',
    'domainistration',
    'doomers',
    'dpheoc',
    'drillcourse1segfault',
    'drillcourse2segfault',
    'drillcourse3segfault',
    'drillcourse4',
    'drone',
    'dsolactf',
    'dttechnicalexercise',
    'dune',
    'dungeon',
    'dunklematerieptxc9',
    'dupa',
    'dvwafrench',
    'dvwaod',
    'dx1libertyislandplde',
    'dx2hellskitchen',
    'e',
    'e0d00b9f337d357c6faa2f8ceae4a6',
    'e42470ee3b6c56c232cc30173e8295',
    'e7274bd06ff93210298e7117d11ea6',
    'eastereggtest',
    'easyctfok',
    'easyctft',
    'easyinjection',
    'easyIZnf',
    'easypeasyctfixXv',
    'easypeasyctfoc',
    'easypeasyctfQz',
    'eavesdropper',
    'ec2ssrfcloudgoatwalkthrough',
    'ececrackmes',
    'ecessi',
    'ecetp2firensics',
    'ecolecybersecuriteweb1337',
    'ecolecybertraining',
    'ecorp',
    'editactf2023',
    'edrmanagementctf',
    'edrmanagementctf67',
    'edrmanagementctf67KTmA',
    'eez0',
    'eflzm',
    'ehab',
    'ehdecevale',
    'ehfcourseexercises',
    'ehfcourseexercisesrf',
    'ehpctf01qs',
    'ehpedition50ctf',
    'ehpedition50exercises',
    'ehpv2',
    'einstein',
    'elbandito',
    'electron',
    'elevate2',
    'elevate3',
    'eliteghostctfb2reasy',
    'emailanalysis',
    'emailtriage',
    'emaster',
    'empline',
    'encoding',
    'encodingcrypto',
    'encryptenvoyssn',
    'encryptenvoyssnbq',
    'encryption97',
    'encryptioncrypto1010c',
    'encryptioncrypto1015g',
    'encryptioncrypto101dE',
    'encryptioncrypto101fa',
    'encryptioncrypto101fk',
    'encryptioncrypto101FW',
    'encryptioncrypto101gdrk',
    'encryptioncrypto101gy',
    'encryptioncrypto101kx',
    'encryptioncrypto101lq',
    'encryptioncrypto101Lt',
    'encryptioncrypto101mn7a',
    'encryptioncrypto101pu',
    'encryptioncrypto101pv',
    'encryptioncrypto101qm',
    'encryptioncrypto101td',
    'encryptioncrypto101tp',
    'encryptioncrypto101ux',
    'encryptioncrypto101Y3',
    'eng0x00',
    'enigmatictravelblogdbh',
    'enpass',
    'enterprise',
    'enterprise',
    'enterprised3',
    'enterpriseuE',
    'enterprize',
    'enum',
    'enum2',
    'enumerationpe9ba1',
    'enumerationpenv',
    'envizon',
    'epoch',
    'epoch8b',
    'eqwqewqda',
    'ergasia1',
    'ergasia2ayd521',
    'eritsecurusi',
    'erlikmachine',
    'error404',
    'escaladodeprivilegioslinux',
    'escalationquestw6',
    'escalationquestw60i',
    'escalationquestw60iwt',
    'escaperoom',
    'escaperoomd2',
    'esfileexplorevulnerability',
    'essentialeight',
    'eternalblue1yf',
    'eternalblue70',
    'eternalbluecve3a',
    'eternalblueexploit',
    'eternalblueuop',
    'ethicalhackingbasic',
    'ethicalhackingdemo',
    'ethicalhackingfinal',
    'etreassurehuntfu',
    'ettubrute',
    'etude',
    'eurotechexamfl',
    'eventfib',
    'eventually',
    'everheardofcicada',
    'evogenfinals',
    'ewuthmlab3',
    'ewuthmlab3sl',
    'ex10blockciphers',
    'ex11xor',
    'ex14steganography',
    'ex15blockchain',
    'ex15blockchainD0',
    'ex16transactionsigning',
    'ex17googleoperatorsdorks',
    'ex3forensicimaging',
    'ex4viewingdeleteddata',
    'ex7filecarving',
    'exam2023extra',
    'examelk',
    'examenuvt290124',
    'examrnj21',
    'exfatexamination',
    'exfilibur',
    'exfiliburOa',
    'expenseexpedition',
    'exploitationnmapnessuskc',
    'exploitcve20101240',
    'exploitcve20101240aa',
    'exploitcve20101240yt',
    'exploitingavulnerabilityv2AQ',
    'exploitingavulnerabilityv2ey7v',
    'exploitingavulnerabilityv2gy',
    'exploitingavulnerabilityv2jjxh5stc',
    'exploitingavulnerabilityv2jjxh5stc9v',
    'exploitingavulnerabilityv2nz',
    'exploitingavulnerabilityv2qk',
    'exploitingavulnerabilityv2sq',
    'exploitingcve20220847',
    'exploitingcveids',
    'exploitingkeepass2xmaster',
    'exploradoresderedconnmap',
    'exploradoresderedconnmap2',
    'exploringwinincidentsurface',
    'exposenU',
    'extendingyournetwork2j',
    'extendingyournetwork5p',
    'extendingyournetwork6c',
    'extendingyournetwork6r',
    'extendingyournetworkAs',
    'extendingyournetworkAsaJ',
    'extendingyournetworkcn4e',
    'extendingyournetworkdu',
    'extendingyournetworkex',
    'extendingyournetworkff',
    'extendingyournetworkgx',
    'extendingyournetworkhr',
    'extendingyournetworkiu',
    'extendingyournetworkjp',
    'extendingyournetworkjpTv',
    'extendingyournetworkkw',
    'extendingyournetworklq',
    'extendingyournetworkn1',
    'extendingyournetworksu',
    'extendingyournetworkv2',
    'extendingyournetworkzi',
    'extractedroom',
    'f4b',
    'familyguyctf',
    'faxcorpdev',
    'fearsecond',
    'feeicctf101',
    'feelingeternalblue',
    'feroxbuster',
    'feroxbustere4',
    'fetllactfjan202445',
    'fetllavulnpal',
    'ff2023',
    'ffufme',
    'fiabasiclinux',
    'fialearnlinux',
    'fialinuxentrancetest',
    'fiatechnicalbranchtesta6',
    'fiatechnicalbranchtestdh',
    'fiatechnicalbranchtestdhn3',
    'fightclub',
    'fileanalysis',
    'filecarvingexercise2',
    'filefindy',
    'fileincaw',
    'fileinco1io',
    'fileincRH',
    'fileinctw',
    'fileincvj',
    'filesystemanalysisfordfir',
    'filesystemanalysisTO',
    'filesystemforensics',
    'filesystemforensicsmg',
    'filgravennc3ctf2023',
    'final',
    'finalassessment',
    'finalassessmentfc',
    'find',
    'findthedifference',
    'findthehiddenroom',
    'findtherobots',
    'finraandrewchallenge',
    'finracoreychallenge',
    'finradonaldchallenge',
    'finrafeb2024',
    'finrafelixchallenge',
    'finranatechallenge',
    'finraomarchallenge',
    'finrarobertchallenge',
    'finraseanchallenge',
    'firstEJ',
    'firstmachine',
    'firstroomtohack',
    'fishylogs',
    'fitness101',
    'fixitC9',
    'fixitoh',
    'fixitTp',
    'flagfrenzy20239w',
    'flagfrenzy20239wzl',
    'flask',
    'flaskra',
    'flatline',
    'flightno305',
    'flip',
    'flipp5',
    'fluenytoons',
    'follinaexploitation',
    'follinamsdt',
    'forbusinessreasons',
    'force',
    'forensic',
    'forensicanalysiswsofelk',
    'forensicfridaychallenge1',
    'forensicswh',
    'forgottenimplant',
    'fortress',
    'fortuneseeker5c1337pjehuvra',
    'fortyyb',
    'fotofbebfjkwbefwnke',
    'foursquarecipherzh',
    'foxfostercryptographyctf',
    'fptufia1sttest',
    'fragilefortress',
    'francescagonemissing',
    'frankandherby',
    'frankandherbytryagain',
    'freeattackbox',
    'freeattackboxxo',
    'freshly',
    'fridayovertime',
    'fruit',
    'fsa2023assessment3',
    'fsocietya4',
    'ftreasures',
    'functf',
    'fundamentalsofcryptography',
    'fundametalsma',
    'funhousejt',
    'funky',
    'funwithfunctional',
    'furthernmap4g',
    'furthernmap6x',
    'furthernmapatq5',
    'furthernmapbbgd',
    'furthernmapci',
    'furthernmapcjhh',
    'furthernmapdy',
    'furthernmapfhicgruq',
    'furthernmapg4CX',
    'furthernmapJl',
    'furthernmapkvwb4vrarK',
    'furthernmaplu',
    'furthernmapmR',
    'furthernmapoh',
    'furthernmappxih',
    'furthernmaprh',
    'furthernmaprr7f',
    'furthernmaprx',
    'furthernmapsd',
    'furthernmaptmsp',
    'furthernmapvk',
    'furthernmapwk',
    'furthernmapx4',
    'furthernmapxd',
    'furthernmapxn',
    'furthernmapyr',
    'furthernmapz9',
    'fusion',
    'fusioncorp',
    'fyp1',
    'fypdonline',
    'g1identifyweaknessllecqct2024',
    'g1introtoresearch2024',
    'g1nessusvulnscan2024',
    'g1ufaz2024activedirectorybasic',
    'g1ufaz2024attackingkerberos',
    'g1ufaz2024attacktivedirectory',
    'g1ufaz2024bpsplunk',
    'g1ufaz2024btsysinternals',
    'g1ufaz2024btwindowsinternals',
    'g1ufaz2024encryptioncrypto',
    'g1ufaz2024hashingcrypto',
    'g1ufaz2024intro2windows',
    'g1ufaz2024introcloudsecurity',
    'g1ufaz2024introtoisa',
    'g1ufaz2024networkservices',
    'g1ufaz2024networkservices2',
    'g1ufaz2024owasptop10',
    'g1ufaz2024protocolsandservers2',
    'g1ufaz2024selfintronetdatasecu',
    'g1ufaz2024windowseventlogs',
    'g1ufaz2024windowseventlogsdS',
    'g1ufaz2024yara',
    'g1ufazcsp2024',
    'g1ufazsudovulnsbypass2024',
    'g1ufazxss2024',
    'g2identifyweaknessllecqct2024',
    'g2introtoresearch2024',
    'g2nessusvulnscan2024',
    'g2ufaz2024activedirectorybasic',
    'g2ufaz2024attackingkerberos',
    'g2ufaz2024attacktivedirectory',
    'g2ufaz2024bpsplunk',
    'g2ufaz2024btsysinternals',
    'g2ufaz2024btwindowsinternals',
    'g2ufaz2024encryptioncrypto',
    'g2ufaz2024hashingcrypto',
    'g2ufaz2024intro2windows',
    'g2ufaz2024introcloudsecurity',
    'g2ufaz2024introtoisac',
    'g2ufaz2024networkservices',
    'g2ufaz2024networkservices2',
    'g2ufaz2024owasptop10',
    'g2ufaz2024protocolsandservers2',
    'g2ufaz2024selfintronetdatasecu',
    'g2ufaz2024windowseventlogs',
    'g2ufaz2024yara',
    'g2ufazcsp2024',
    'g2ufazsudovulnsbypass2024',
    'g2ufazxss2024',
    'gamebuzz',
    'gandalf',
    'gdms',
    'gdmsmalwarechallenge',
    'gdmsphishingchallenge',
    'gdprusw2023',
    'genericuniversity',
    'geolocatingimages6c',
    'geolocatingimagesmp',
    'geolocatingimagesyl',
    'geolocatingimagesypctxpIW',
    'getsuricata',
    'gettingcomfortablewithkali',
    'gettingstartedwithsemgrep',
    'ghizerctf',
    'ghostintheshell',
    'ghoulxroot1',
    'ghxctf01',
    'ghxctf01oc',
    'giantspaceball',
    'giantspaceball02',
    'giforjif',
    'giftwrappingpoisoning',
    'gilgamesh',
    'gitandcrumpets',
    'githappensch',
    'gitlabcve20237028mH',
    'gitlabcve20237028rp',
    'gittedwinjactfnullcon2023c5',
    'gkasrmastermind',
    'gns3vmvirtualbox22441',
    'goldeneyess',
    'googleadvanced',
    'googlechromecve20206418',
    'googlechroniclectf',
    'googlechroniclephishingctf',
    'googleclouddfir',
    'googledorking1s1c3dj5',
    'googledorkingdj',
    'googledorkingec',
    'googledorkingot',
    'googledorkingy9',
    'googledorkingyt',
    'googlevirustotalctf',
    'googlevtthreatintelctf',
    'govcsirtdrilltest',
    'gowiththenetflow',
    'gpguardian',
    'grandline',
    'graphql',
    'gravityfallsk2',
    'gravlight',
    'grcpi',
    'greenboneappliance',
    'greenbonecloud',
    'greencatctf0x1',
    'greenhorn',
    'grepnewtotriage',
    'greprtp5q',
    'greprtpyL',
    'grootosint',
    'grootosinten',
    'grootredteamlab',
    'grootsecurity',
    'grootweb',
    'gsocforensicsctf1',
    'guidemehvapt30',
    'gum1kspg',
    'gutipotaka1',
    'gyenyamebankWd',
    'h4ckedas',
    'h4ckedqf',
    'h4cks0ci4l30',
    'haactivedirectorybasicsyb',
    'habiticagamifyyourlife',
    'hack404sz',
    'hackacademyprivesc',
    'hackcraft20ctf',
    'hackcraftctf',
    'hackcraftctfvb',
    'hacked',
    'hacker',
    'hackergrityctf',
    'hackerhaltrecon',
    'hackerofthehill',
    'hackersandhacking',
    'hackersnhops',
    'hackerspacejustpractice',
    'hackertrivia',
    'hackervshacker',
    'hacketyhack',
    'hacking',
    'hackinghadoop',
    'hackinghadoop7kk',
    'hackinghadoop7kkim',
    'hackingheatpumps',
    'hackingmetasploitable',
    'hackingwithmetasplot',
    'hacklabchallengetussentijds',
    'hacklabintroductie2023',
    'hacklabintromachine',
    'hacklabintroosint',
    'hacklabs',
    'hacklabtoets12345',
    'hacklabtoets2osint',
    'hacklabtoets3er',
    'hacklabtussentijdsetoets',
    'hackme2023',
    'hackmeig',
    'hacknightctfpv',
    'hacksmartersecurity',
    'hacksnackctf',
    'hacksocab',
    'hacksocweek7',
    'hacktactixctf',
    'hackthebox',
    'hacktrixonlineqc',
    'hackweek23',
    'hadoophunt',
    'halloweenctf',
    'hameconnage101',
    'hamitreyp',
    'hamlet',
    'hanumansquest6a8j',
    'hanumansquestub',
    'hapracticalnetworklab',
    'hapracticalnetworklaboechlsdomwcfm',
    'hapracticalnetworklaboechlsdomwcfmef',
    'hapracticalnetworklaboechlsdomwcfmefyl',
    'hapracticalnetworklaboechlsdomwcfmefylMv',
    'hapracticalnetworklaboechlsdomwcfmrv',
    'hapracticalnetworklaboechlsdomwcfmrv6s',
    'hardeningbasicspart1',
    'hardeningbasicspart143',
    'hardeningbasicspart187',
    'hardeningbasicspart1yk',
    'hardeningbasicspart2',
    'hardeningbasicspart26y',
    'hardeningbasicspart2a31i',
    'hardeningbasicspart2a6',
    'hardeningbasicspart2s7',
    'harder',
    'hardwarecomputercooling',
    'hardwarehackingl5',
    'hardwarepowersupply',
    'hardwareshop',
    'haribahadur',
    'harken',
    'hashcatLn',
    'hashcatplayground',
    'hashesgalore',
    'hashfunctionsandtheirlogic',
    'hashfunctionsattacks',
    'hashing',
    'hashingcrypto1011l',
    'hashingcrypto1014t',
    'hashingcrypto1016k5v',
    'hashingcrypto1018c',
    'hashingcrypto1019d',
    'hashingcrypto101aa',
    'hashingcrypto101Aq',
    'hashingcrypto101bb',
    'hashingcrypto101dr',
    'hashingcrypto101dw',
    'hashingcrypto101eh',
    'hashingcrypto101fr',
    'hashingcrypto101fv',
    'hashingcrypto101jd',
    'hashingcrypto101mf',
    'hashingcrypto101p8',
    'hashingcrypto101pm',
    'hashingcrypto101py',
    'hashingcrypto101txx6',
    'hashinglm',
    'hashinglmts',
    'haskhell',
    'hasubnettingjf',
    'hasubnettingjfssgx',
    'hasubnettingjfssgxncvu',
    'havok',
    'havokctf',
    'havoksecctf',
    'hawindowsserverxpi94g',
    'hawindowsserverxpi94gle',
    'hawindowsserverxpi94gleus',
    'hawindowsserverxpi94gleusyt',
    'hawindowsserverxpi94gleusytVz',
    'hawindowsserverxppw',
    'hc0nchristmasctf',
    'hd24',
    'headless',
    'heartbleed',
    'heartbleedtC',
    'heartbleedvk',
    'helk',
    'hell',
    'hello0u',
    'helloiamscriptkiddie',
    'hellopt',
    'helloqy',
    'hellosuricata',
    'helloufaz018lrmx',
    'helloufaz2024Rv',
    'helloworldasdaaa',
    'hellsender1y7',
    'helptobob',
    'hgjkhkgvkghv',
    'hidden',
    'hiddenmessage',
    'hiddenw3b2',
    'hiddenweb2',
    'hideandseekvg',
    'hideinzipay',
    'hijack4Z',
    'hillcipherfundamentalsnu',
    'hintinitial',
    'hintpelow',
    'hintsinitialshellhigh',
    'hipflask',
    'historyofmalware5G',
    'historyofmalware5G2u',
    'historyofmalware8t',
    'historyofmalwareaG',
    'historyofmalwarecl',
    'historyofmalwarerm',
    'hitrun',
    'hnhpcap',
    'hocevalnetworkdiagramiw',
    'hocwrtsosimodel',
    'holacq',
    'holo',
    'hongqiaocap2023mingbacker',
    'howdowebsiteswork',
    'howtousetryhackme3p',
    'howtousetryhackme7D',
    'howtousetryhackmef8hL',
    'howtousetryhackmeiQ',
    'howtousetryhackmeRS',
    'howwebsiteswork1i',
    'howwebsiteswork7x',
    'howwebsiteswork9c',
    'howwebsitesworkan',
    'howwebsitesworkch',
    'howwebsitesworkFl',
    'howwebsitesworkFlvA',
    'howwebsitesworkfn',
    'howwebsitesworkFz',
    'howwebsitesworkkz',
    'howwebsitesworkL2',
    'howwebsitesworkle',
    'howwebsitesworknh',
    'howwebsitesworkoT',
    'howwebsitesworksg',
    'howwebsitesworku4',
    'howwebsitesworkug',
    'howwebsitesworkws',
    'howwebsitesworkxb',
    'howwebsitesworkyajc',
    'howwebsitesworkyajc4i',
    'howwebsitesworkyajc4iK6',
    'hsc0',
    'hth2022thehunt',
    'httpindetail9b',
    'httpindetail9Q',
    'httpindetailAT',
    'httpindetailc4',
    'httpindetailckyw',
    'httpindetailDj',
    'httpindetailfb',
    'httpindetailfb4I',
    'httpindetailff',
    'httpindetailfs',
    'httpindetailgu',
    'httpindetaili9',
    'httpindetailjz',
    'httpindetailL0',
    'httpindetailphgb',
    'httpindetailsc',
    'httpindetailwtfr',
    'httpindetailwtfrhG',
    'httpindetailwtfrhG7m',
    'httpindetailwtfrhG7mbH',
    'httpindetailyc',
    'httprequestsmugglingh6',
    'httprequestsmugglingVM',
    'hungergames',
    'huntforthecrownjewels',
    'huntinginsplunkwithchatgpt',
    'huntorbehunted',
    'hvawalkthrough',
    'hvaxcuccibu',
    'hvaxcuccibula',
    'hvayear1otj',
    'hydra1t',
    'hydrafc',
    'hydrali',
    'hydrawi',
    'hydraXn',
    'i5spacecyber010',
    'iaaaidm3G',
    'iaaaidmJl',
    'iaaaidmso',
    'iacssecurity',
    'iahaa1insecureandroidapp',
    'ica1',
    'ice1x',
    'iceppwd',
    'iceqt',
    'icexmb3',
    'icey79t',
    'icumedtestroom1',
    'icy1',
    'icy2',
    'identifyandcrack',
    'identifyvlanfrompcap',
    'identityandaccountmanagement3k',
    'idorgc',
    'idorji',
    'idormaze',
    'idorpu',
    'idsevasion',
    'idsevasion7p',
    'idsevasionn3',
    'ihatenegrosthatwalkinpar',
    'iicctfv001',
    'iiiwe4r3h4ckers2k22ko',
    'iiiwe4r3h4ckers2k22lk',
    'iituctf',
    'iiyainthemetaverse',
    'iktlab',
    'imagemagickcve202244268',
    'imlovinit',
    'immersion',
    'imperiumbank',
    'inacave',
    'incidentresponse7C',
    'incidentresponsechallenge',
    'incidentresponsell',
    'include',
    'inclusion',
    'index',
    'infected',
    'inferno',
    'infinityx24',
    'influensec',
    'informationgathering',
    'informationsecurityqd',
    'infosec4tc',
    'injectdaemon',
    'injection',
    'injectthejuice',
    'inphysecsocctf2023',
    'insekube',
    'insekube9z',
    'insekubefU',
    'insightinspectormaltego',
    'intelcreationandcontainment6c',
    'interactivedemos',
    'interactivedemosu8',
    'intermediatenmap7t',
    'intermediatenmapcu',
    'internalctfnI',
    'internetofthings',
    'internshiptest1',
    'intro',
    'intro2cyber',
    'intro2internet',
    'intro2windows',
    'intro2windowsma',
    'intro2windowsmaflmd',
    'intro2windowsmaflmdvhnlIX',
    'intro2windowso5',
    'introandhistoryoflinux3u',
    'introdigitalforensics1u',
    'introdigitalforensics56',
    'introdigitalforensicsbd',
    'introdigitalforensicsed',
    'introdigitalforensicsga',
    'introdigitalforensicshY',
    'introdigitalforensicsNi',
    'introdigitalforensicspy',
    'introdigitalforensicsq3',
    'introdigitalforensicssi',
    'introdigitalforensicszO',
    'introductionbashbunny',
    'introductiondocker',
    'introductionintoosint',
    'introductionintoterminal',
    'introductionlapolitique',
    'introductiontoawsiamfm',
    'introductiontocloudgoat',
    'introductiontocloudsecurityc60c',
    'introductiontocloudsecurityc6gI',
    'introductiontocloudsecurityc6kk',
    'introductiontocloudsecurityc6kz',
    'introductiontocloudsecurityc6kznu',
    'introductiontocloudsecurityc6tz',
    'introductiontocryptography',
    'introductiontoctith',
    'introductiontodevsecopsha',
    'introductiontodevsecopslg',
    'introductiontodevsecopspk',
    'introductiontodevsecopsq4',
    'introductiontoentraid',
    'introductiontofrida',
    'introductiontohoneypots',
    'introductiontohoneypots6x',
    'introductiontokalilinuxnw',
    'introductiontolinux',
    'introductiontolinuxsnp',
    'introductiontothreathunting8C',
    'introductiontothreathuntingfs',
    'introductiontothreathuntingGj',
    'introductiontothreathuntingl1',
    'introductiontothreathuntingW9',
    'introductoryroomdfirmodule17',
    'introductoryroomdfirmodule9h',
    'introductoryroomdfirmodulefm',
    'introductoryroomdfirmoduletw',
    'introductoryroomdfirmoduleyf',
    'introlinuxrtkch',
    'intromalwareanalysisa7',
    'intromalwareanalysisAZ',
    'intromalwareanalysisaZCy',
    'intromalwareanalysiscv',
    'intromalwareanalysisDX',
    'intromalwareanalysisjj',
    'intromalwareanalysisjX',
    'intromalwareanalysisrq',
    'intromalwareanalysisrr',
    'intronetworksecurity4r',
    'intronetworksecuritybk',
    'intronetworksecuritybn',
    'intronetworksecuritygojjKY',
    'intronetworksecurityn8',
    'intronetworksecurityrt',
    'intronetworksecurityu3',
    'intronetworksecurityyd',
    'intronetworksecurityz4',
    'intropocscripting',
    'intropocscriptingm8',
    'introtoavbg',
    'introtoavbgjb',
    'introtoavif',
    'introtoavph',
    'introtoavQo',
    'introtoavZ3',
    'introtoc2e4',
    'introtoc2xh',
    'introtoc2yi',
    'introtocryptography',
    'introtocryptography4j',
    'introtocsecosintchallenge',
    'introtocybersecurityxm',
    'introtocyberss',
    'introtodigitalforensics',
    'introtodockerk8pd',
    'introtoehenumeration',
    'introtoehscanningfootprinting',
    'introtoendpointsecurity0R',
    'introtoendpointsecurity6s',
    'introtoendpointsecuritymi',
    'introtoendpointsecuritynv',
    'introtoendpointsecurityQa',
    'introtoendpointsecurityun',
    'introtographql',
    'introtoirandimir',
    'introtoirandimKQ',
    'introtoirandimzi',
    'introtoisac',
    'introtoisac1e',
    'introtoisacec',
    'introtokubernetes',
    'introtolan5c',
    'introtolanau',
    'introtolanc0',
    'introtolancq',
    'introtolaneh',
    'introtolanei',
    'introtolaneodm',
    'introtolanfc',
    'introtolangn',
    'introtolani5',
    'introtolanIU',
    'introtolanJ9',
    'introtolanlzhk',
    'introtolann6',
    'introtolanNR',
    'introtolanoa1iif37',
    'introtolanoa1iif3714',
    'introtolanoaqN',
    'introtolanpt',
    'introtolanqx',
    'introtolansd',
    'introtolanus',
    'introtolany8',
    'introtolanzv',
    'introtolinux',
    'introtolinuxwithdocker94',
    'introtologanalysis08',
    'introtologanalysis0F',
    'introtologanalysisBb',
    'introtologanalysisWY',
    'introtologanalysisxn',
    'introtologanalysiszh',
    'introtologs1r',
    'introtologs4w',
    'introtologs5v',
    'introtologshi',
    'introtologsr3',
    'introtologsTm',
    'introtonetworking7e',
    'introtonetworking8c',
    'introtonetworking9l',
    'introtonetworkingcgn6',
    'introtonetworkinghh',
    'introtonetworkingijbf',
    'introtonetworkingko',
    'introtonetworkingkq',
    'introtonetworkingktcx',
    'introtonetworkinglc',
    'introtonetworkingoakqre',
    'introtonetworkingoakqregK',
    'introtonetworkingoakqrelZ',
    'introtonetworkingonG7',
    'introtonetworkingpg',
    'introtonetworkingrC',
    'introtonetworkingsq',
    'introtonetworkingyf',
    'introtonetworkingyt',
    'introtonetworkingyy',
    'introtonetworkingzs',
    'introtonetworksecurity',
    'introtooffensivesecurity3s',
    'introtooffensivesecurity5u',
    'introtooffensivesecurity8v',
    'introtooffensivesecurityaq',
    'introtooffensivesecuritycj',
    'introtooffensivesecurityf6',
    'introtooffensivesecurityf6rv',
    'introtooffensivesecurityf6rvyJ',
    'introtooffensivesecurityFB',
    'introtooffensivesecurityfe',
    'introtooffensivesecuritygD',
    'introtooffensivesecuritygi',
    'introtooffensivesecuritygi5F',
    'introtooffensivesecurityjC',
    'introtooffensivesecurityKJ',
    'introtooffensivesecuritymU',
    'introtooffensivesecurityova8',
    'introtooffensivesecurityPe',
    'introtooffensivesecuritySE',
    'introtooffensivesecuritysr',
    'introtooffensivesecuritytz',
    'introtooffensivesecurityuwvep9',
    'introtooffensivesecurityv5',
    'introtooffensivesecurityw6cg',
    'introtooffensivesecurityyj',
    'introtooffensivesecurityyx',
    'introtooperatingsystems',
    'introtopcidss',
    'introtopipelineautomation4h',
    'introtopipelineautomationju',
    'introtopwntools',
    'introtopwntools',
    'introtopython',
    'introtopython8d',
    'introtopythonun',
    'introtoresearch0s',
    'introtoresearch3q',
    'introtoresearch4d',
    'introtoresearchcuj8m8wx',
    'introtoresearchi7',
    'introtoresearchi7Hu',
    'introtoresearchrc',
    'introtoresearchs0',
    'introtoresearchsf',
    'introtosecurityarchitecturek2',
    'introtosecurityarchitecturev3',
    'introtosecurityarchitecturey9',
    'introtoshells8v',
    'introtoshellsap',
    'introtoshellsD3',
    'introtoshellsdr',
    'introtoshellshl',
    'introtoshellswe',
    'introtoshellswz',
    'introtoshellsXv',
    'introtosiem1m',
    'introtosiemng',
    'introtosiemng3rdq',
    'introtosiemngdb',
    'introtosiemSD',
    'introtosiemtq',
    'introtosiemWW',
    'introtox8664',
    'introtoxss',
    'introwebapplicationsecurityas',
    'introwebapplicationsecurityd1',
    'introwebapplicationsecuritydh',
    'introwebapplicationsecurityem',
    'introwebapplicationsecurityfepcah',
    'introwebapplicationsecurityfepcahmR',
    'introwebapplicationsecuritypr',
    'introwebapplicationsecurityprX9',
    'introwebapplicationsecurityprX9BP',
    'introwebapplicationsecurityqa',
    'introwebapplicationsecurityqk',
    'introwebapplicationsecuritysc64',
    'introwebapplicationsecurityuk',
    'introzct',
    'intrusiondetectionsystemsw4',
    'intrusionunraveled',
    'investigateadatabreach',
    'investigatingwindows0b',
    'investigatingwindows2vo',
    'investigatingwindows3',
    'investigatingwindows3DmC4',
    'investigatingwindowsHP',
    'investigatingwindowsNS',
    'investigatingwindowspx',
    'investigatingwindowsSbtK',
    'investigatingwindowswy',
    'investigatingwithelk101bo',
    'investigatingwithelk101g5',
    'investigatingwithelk101rf',
    'investigatingwithsplunkqru9',
    'investigatingwithsplunku5',
    'investigatingwithsplunkuirfWZ',
    'investigatingwithsplunkzu',
    'invictus',
    'invoke',
    'invoke2',
    'invoke3',
    'iosforensics',
    'iosforensicsqo',
    'iot',
    'iotforensics',
    'ipcamerahacking',
    'ipv6remotecodeexecution',
    'irdifficultiesandchallenges',
    'iro1',
    'ironcorp',
    'ironhackable',
    'ironhackctf',
    'ironhackctf2',
    'ironhacklevel3',
    'ironhole',
    'ironpatchable',
    'irphilosophyethics',
    'irvinesecuritywargame1',
    'irvinesecuritywargame2',
    'isgctfm5',
    'islandorchestration',
    'ismanagement2023',
    'iso27001cU',
    'it22317926',
    'itsamystery',
    'itsecfoundationtascsq',
    'itsybitsyjv',
    'itsybitsyld',
    'itt422testosintoptzjlj',
    'itucyberctf2023',
    'itucyberctf2023f0',
    'itucyberctf2023f0c9gx',
    'ivwe4r3h4ckers2k23vs',
    'j7ksr3l9zwta9dvz',
    'j7ksr3l9zwtalngnrp',
    'j7ksr3l9zwtalngnrpzric',
    'j7ksr3l9zwtamjpl3l',
    'jack',
    'jack3ck6',
    'jackofalltrades',
    'jackofalltrades',
    'jacobtheboss',
    'jan2024ctf',
    'jason',
    'javascriptbasicsgr',
    'javascriptbasicsmE',
    'jcachackerchallengebasic',
    'jobfierctf',
    'joelogs',
    'johntheripper',
    'johntheripper091tz0ykyqa',
    'johntheripper091tz0ykyqa7G',
    'johntheripper0a3',
    'johntheripper0c8',
    'johntheripper0cw',
    'johntheripper0da',
    'johntheripper0gh',
    'johntheripper0h9',
    'johntheripper0MD',
    'johntheripper0od26',
    'johntheripper0qhmd',
    'johntheripper0R95A',
    'johntheripper0sa',
    'johntheripper0sy',
    'johntheripper0y4',
    'johntheripper0yk',
    'jomskiller',
    'jormungandr',
    'joystick',
    'jpgchat',
    'jrsecanalystintrouxo4j',
    'jrsecanalystintrouxoa4',
    'jrsecanalystintrouxoCU',
    'jrsecanalystintrouxofH',
    'jrsecanalystintrouxokk',
    'jrsecanalystintrouxost',
    'jrsecanalystintrouxou8LR',
    'jrsecanalystintrouxoz9',
    'juiceshop',
    'juiceshopvc',
    'juicydetailsbi',
    'jumpervf',
    'jumpervfd0',
    'jungl3',
    'juniordeveloper',
    'jupyter101',
    'jurassicpark',
    'jvmreverseengineering',
    'jwtjsonwebtoken',
    'jwtsec',
    'jxozqnqeprjdjsaefamybpeuuphxsj4v',
    'k23ctf',
    'k2room',
    'kali7i',
    'kali7inj',
    'kalihacksfirst',
    'kalilinuxinandroid',
    'kalipkafbxv2',
    'kapeju',
    'kapenj',
    'kawaycoffeef7',
    'kazcha1',
    'keepasscve202332784',
    'keepitsecret1cj',
    'keepitsimple',
    'keldagrim',
    'kennedykriegermalware',
    'kennedykriegerphishing',
    'kennysquest0',
    'kenobirq',
    'kenobivr',
    'kenobiw3',
    'keshavcryptography',
    'killchain',
    'killer',
    'kirbytoast',
    'kisamesgul',
    'klk',
    'knowwheretogo',
    'koala',
    'koopatroopa',
    'kothfoodctf',
    'kothhackers',
    'kritictf',
    'ku24lfpse',
    'kubasiccryptographyhw',
    'kuberneteschalltdi2020',
    'kubernetesforyouly',
    'kubernetesforyoulyd6',
    'kuctf2024',
    'kum1cfsectiononequiz',
    'kum2lfl12e',
    'kum2lfl13e',
    'kum2lfl15e',
    'kum2lfl16',
    'kum2lfl1e',
    'kum2lfl2e',
    'kum2lfl3e',
    'kum2lfl4e',
    'kum2lfl5e',
    'kum2lfl6e',
    'kum3nfl1',
    'kum3nfl5',
    'kum3nfl6',
    'kuroctf',
    'kyberneticktoky',
    'l33t',
    'lab1vwau',
    'lab2linuxtechtips',
    'lab3',
    'lab4',
    'lab5gq',
    'lab65u',
    'lab69w',
    'lab8',
    'lab9',
    'lab9oc',
    'lab9oct9',
    'lab9oct95s',
    'lab9oct95s9f',
    'labtest2xz',
    'labtest2xzm9',
    'labtest2xzm986',
    'labyrinth8llv',
    'laffairesophiemartinet',
    'lantestausr29a',
    'layer1networkingdevices',
    'layer2kg',
    'layer2kgvu',
    'layer2t5',
    'layer2ZT',
    'layer2zw',
    'lazydeveloper',
    'leakpass',
    'learnburp',
    'learnhtml',
    'learningkustoquerylanguage',
    'learnowaspzap4t',
    'learnowaspzap9e',
    'learnowaspzapqi',
    'learnssti',
    'learnwifihacking4578',
    'legionofghostsx07',
    'lessonlearnedjl',
    'letsplaymemorycorruption',
    'letsplaythegame',
    'level8vG',
    'level8Z2',
    'lewisctfevent',
    'lfibasics',
    'lightsw',
    'lininvestigateds',
    'lininvestigatedsOr',
    'linprivescaA',
    'linprivescfv',
    'linprivescvu',
    'linprivescvy',
    'linux',
    'linux1',
    'linux1ee',
    'linux2',
    'linux2d1',
    'linux3',
    'linux3ew',
    'linux4',
    'linuxbackdoorsw8',
    'linuxbasicsforhackers1ol',
    'linuxcommandmastery',
    'linuxdirectorychallenge',
    'linuxfilesystemanalysis',
    'linuxforcyber',
    'linuxforensics5P',
    'linuxforensicsinvestigating',
    'linuxforensicsit',
    'linuxforensicsjl',
    'linuxforensicsrz',
    'linuxforensicsxl',
    'linuxfunctionhooking',
    'linuxfundamentals1',
    'linuxfundamentalspart10b',
    'linuxfundamentalspart10h',
    'linuxfundamentalspart10v',
    'linuxfundamentalspart12i',
    'linuxfundamentalspart12iNA',
    'linuxfundamentalspart12j',
    'linuxfundamentalspart13d',
    'linuxfundamentalspart15b',
    'linuxfundamentalspart186waeo',
    'linuxfundamentalspart186waeoQ4',
    'linuxfundamentalspart1bd',
    'linuxfundamentalspart1bn',
    'linuxfundamentalspart1bo',
    'linuxfundamentalspart1ci',
    'linuxfundamentalspart1ciIh',
    'linuxfundamentalspart1cw',
    'linuxfundamentalspart1ebwt',
    'linuxfundamentalspart1gj',
    'linuxfundamentalspart1h1',
    'linuxfundamentalspart1Iq',
    'linuxfundamentalspart1jq',
    'linuxfundamentalspart1jx',
    'linuxfundamentalspart1l6',
    'linuxfundamentalspart1le',
    'linuxfundamentalspart1ms',
    'linuxfundamentalspart1NE',
    'linuxfundamentalspart1p435',
    'linuxfundamentalspart1p46hkkt6',
    'linuxfundamentalspart1Pj',
    'linuxfundamentalspart1PRQi',
    'linuxfundamentalspart1pv',
    'linuxfundamentalspart1pv4q',
    'linuxfundamentalspart1ql',
    'linuxfundamentalspart1ra',
    'linuxfundamentalspart1rl',
    'linuxfundamentalspart1SK',
    'linuxfundamentalspart1sx',
    'linuxfundamentalspart1u0',
    'linuxfundamentalspart1ub',
    'linuxfundamentalspart1uj',
    'linuxfundamentalspart1valmblse',
    'linuxfundamentalspart1wi',
    'linuxfundamentalspart1y5',
    'linuxfundamentalspart1y5mv',
    'linuxfundamentalspart20t',
    'linuxfundamentalspart20U',
    'linuxfundamentalspart22b',
    'linuxfundamentalspart253',
    'linuxfundamentalspart27o',
    'linuxfundamentalspart28h',
    'linuxfundamentalspart2c0',
    'linuxfundamentalspart2ckgmOJ',
    'linuxfundamentalspart2ckgmOJSC',
    'linuxfundamentalspart2f4',
    'linuxfundamentalspart2gx',
    'linuxfundamentalspart2i6ozwq',
    'linuxfundamentalspart2i6ozwq86',
    'linuxfundamentalspart2iy',
    'linuxfundamentalspart2iynx',
    'linuxfundamentalspart2jf',
    'linuxfundamentalspart2jj',
    'linuxfundamentalspart2jo77',
    'linuxfundamentalspart2me',
    'linuxfundamentalspart2pl',
    'linuxfundamentalspart2qs',
    'linuxfundamentalspart2qsMI',
    'linuxfundamentalspart2ra',
    'linuxfundamentalspart2rd',
    'linuxfundamentalspart2v1',
    'linuxfundamentalspart2Vv',
    'linuxfundamentalspart2yZAa',
    'linuxfundamentalspart33M',
    'linuxfundamentalspart33M8W',
    'linuxfundamentalspart34s',
    'linuxfundamentalspart3cyrO',
    'linuxfundamentalspart3dt',
    'linuxfundamentalspart3fW',
    'linuxfundamentalspart3ie',
    'linuxfundamentalspart3ja',
    'linuxfundamentalspart3jo',
    'linuxfundamentalspart3kl',
    'linuxfundamentalspart3oc',
    'linuxfundamentalspart3qp',
    'linuxfundamentalspart3qx',
    'linuxfundamentalspart3ra',
    'linuxfundamentalspart3sh4la6',
    'linuxfundamentalspart3tp',
    'linuxfundamentalspart3ud',
    'linuxfundamentalspart3xc',
    'linuxfundamentalspart3ya',
    'linuxfundamentalspart3ye',
    'linuxfundamentalspf',
    'linuxfundamentalsQ1',
    'linuxfundamentalsQ15z',
    'linuxfundamentalsQ15ze8',
    'linuxintermediate',
    'linuxirnewtotriage',
    'linuxloganalysis',
    'linuxmodules5fzmgqms',
    'linuxmodulesqb',
    'linuxmodulesqbVv',
    'linuxmodulesto',
    'linuxmodulesyt',
    'linuxonvirtualmachine',
    'linuxosfundctfeq',
    'linuxosfundfbuy3r61',
    'linuxosfundfbuy3reo',
    'linuxosfundfbuy3reovc',
    'linuxosfundfbuy3reovcV9',
    'linuxpart2',
    'linuxprivesc0s',
    'linuxprivescarenair18',
    'linuxpriveschg',
    'linuxpriveschl',
    'linuxprivesciy',
    'linuxprivesclw',
    'linuxprivescr6',
    'linuxprivescsd',
    'linuxprivescsv',
    'linuxprivescut',
    'linuxprocessanalysis',
    'linuxsecforembeddedsystemsut',
    'linuxserverforensicsxf',
    'linuxspamitflaggen1',
    'linuxstrengthtrainingnp',
    'linuxstrengthtrainingzi',
    'linuxsystemhardeninglv',
    'linuxtraining',
    'linuxtrainingpwc',
    'livingoffthelandex',
    'lle',
    'llee2',
    'lnbtisessionymjjzjkgbmkyiw',
    'localpotato',
    'localpotatoxbc8',
    'locals',
    'locator',
    'lockdown',
    'loganalysisdW',
    'loganalysisoncompromisedw',
    'loggingforaccountabilityn0',
    'logicminimizationyrno',
    'logoperations8d',
    'logoperationsnE',
    'logsculptorpro',
    'loguniversear',
    'loguniversenK',
    'loguniverseoc',
    'loguniverseoe',
    'loguniversero',
    'loguniverseul',
    'loguniversevi',
    'lonestarknv1',
    'lookatthiscryptograph',
    'lookback',
    'lookingforevidencesrr',
    'lookup',
    'looneytunablesexploitation',
    'lordoftheroot',
    'lowesctf2023',
    'lowesstayawareapril2024',
    'lowsint',
    'lumberjackturtle',
    'lumplump',
    'lunizzctfnd',
    'm10l11riskmanagement',
    'm10l12nistrmf',
    'm10l1f1riskmanagement',
    'm10l21riskgovernance',
    'm10l22nistir8286',
    'm10l2f1riskgovernance',
    'm10l31introtocompliance',
    'm10l32compliancestandards',
    'm10l3f1compliance',
    'm11l12revsexploitdev',
    'm11l1f1repractice',
    'm11l21malwareanalysistool',
    'm11l22advancedmalware',
    'm11l2f1malwareanalysis',
    'm11l31analyzingweaknesses',
    'm11l32stigsandhardening',
    'm11l3f1cistop18',
    'm1cflab1',
    'm1cflab10',
    'm1cflab11',
    'm1cflab12',
    'm1cflab13',
    'm1cflab14',
    'm1cflab15',
    'm1cflab16',
    'm1cflab17',
    'm1cflab18',
    'm1cflab19',
    'm1cflab2',
    'm1cflab20',
    'm1cflab21',
    'm1cflab22',
    'm1cflab23',
    'm1cflab24',
    'm1cflab3',
    'm1cflab4',
    'm1cflab5',
    'm1cflab6',
    'm1cflab7',
    'm1cflab8',
    'm1cflab9',
    'm2cllf11mcl',
    'm2cllf2mcl',
    'm2cllf3mcl',
    'm4l12introtothreatintel',
    'm4tr1xexitdenied',
    'm5l112introtocryptii',
    'm5l22hostnetworkattacks',
    'm5l31identityandaccessma',
    'm5l32activedirreviewdrsm',
    'm5l51adreviewgoldenticke',
    'm5siclpe',
    'm5sicml1',
    'm5sicml2',
    'm5sicml5',
    'm5sicwpe',
    'm6l11networkvulnerability',
    'm6l21patchhostnwksec',
    'm6l22introsiemsoarfund',
    'm6l22introsiemsoarfundhl',
    'm6l41secdesignarchcloud',
    'm6l4f1cloudcommonattacks',
    'm6l51accesscontrolreq',
    'm7dirtypipevuln',
    'm7l2networktrafficloganal',
    'm7l2networktrafficloganal0l',
    'm8l11introincidresponse',
    'm8l12incidentresponsecase',
    'm8l1f1incidentresponselab',
    'm9l11introtosdlc',
    'm9l12intrototheagile',
    'm9l3f1softwaretestingqa',
    'm9l52appsecbestpractices',
    'm9l52practicalwebdefense',
    'maatrix',
    'madeyescastle',
    'magicdisaster',
    'magician',
    'magnumKM',
    'malbusterCP',
    'malmalintroductoryba',
    'malmalintroductoryhQ',
    'malmalintroductoryn7',
    'malmalintroductorysr5k',
    'malmalintroductoryxq',
    'malmalintroductoryxqD7',
    'malremnux',
    'malremnuxv2r1',
    'malremnuxv2tp',
    'malstringsrr',
    'malwareanalysis',
    'malwareanalysisWt',
    'malwaretrafficanalysisusing',
    'manage',
    'manyfacedgod',
    'martasbannerstorage',
    'mashin',
    'mastermindsxlq',
    'mastermindsxlq4a',
    'mastermindsxlq8uZm',
    'mastermindsxlqql',
    'masterschooladroom',
    'masterschoolctf5y',
    'masterschoolctf5yfr',
    'masterschoolnetplayground',
    'masterschoolplayground',
    'masterschoolsshlab',
    'masterschoolvulnerability',
    'matrix',
    'matrix1',
    'matryoshkaunleasheddbh',
    'mayhemroom',
    'mbbootcamp1',
    'mbbootcamp1de',
    'mbrmptexamination',
    'mccsandwich',
    'md2pdf',
    'meltdownexplained',
    'memlabs',
    'memory',
    'memoryanalysisexercise6',
    'memoryforensicsayd521',
    'memoryforensicsun',
    'memoryvulnexploit',
    'mengkounglim',
    'mengkuonglim',
    'meowx',
    'mercedes04',
    'meruuniversityctf2023ub',
    'metafire',
    'metamorphosis',
    'metasploit',
    'metasploitable3tg',
    'metasploitexploitation1v',
    'metasploitexploitation6o',
    'metasploitexploitation8k',
    'metasploitexploitation94',
    'metasploitexploitation9o',
    'metasploitexploitationev',
    'metasploitexploitationlj',
    'metasploitexploitationvh',
    'metasploitexploitationvi',
    'metasploitexploitationvj',
    'metasploitexploitationvn',
    'metasploitexploitationxl',
    'metasploitexploitationxskdvm',
    'metasploitintro3m',
    'metasploitintro5y',
    'metasploitintro8v',
    'metasploitintroa0',
    'metasploitintrofs',
    'metasploitintroh6',
    'metasploitintron8',
    'metasploitintroqj',
    'metasploitintrota',
    'metasploitintrouh',
    'metasploitintrox9',
    'metasploitjfq1',
    'metaspoitable2franch',
    'meterpreterb3',
    'meterpretercp',
    'meterpreterda',
    'meterpretereu',
    'meterpreterfp',
    'meterpreterkg',
    'meterpreteroa',
    'meterpreterrzrn',
    'meterpretersk',
    'meterpretervb',
    'microsoftsmbv1rce',
    'microsoftwindowshardeningbifi',
    'microsoftwindowshardeningrr',
    'midnightlinux9v',
    'midsummer',
    'miicybersectest',
    'miicybersectestan',
    'mindgames',
    'mindmaze',
    'mindrefined',
    'miscellaneousmiscbycsc',
    'misconfig',
    'misguidedghosts',
    'misp59',
    'mispd3',
    'mispm4',
    'mispm5',
    'mispQQ',
    'mispsc',
    'misptp',
    'mitmcool',
    'mitre0f',
    'mitre5m',
    'mitre7f',
    'mitrefK',
    'mitrefKmJ',
    'mitreJW',
    'mitreJWYV',
    'mitrend',
    'mitreo9',
    'mitreRM',
    'mitrextrx',
    'mitrezi',
    'mitrezlaz',
    'mitsmapachelogs',
    'mitsmcainundabel',
    'mitsmgamezonefoundation',
    'mitsmitsecfoundation',
    'mitsmitsecfoundation1',
    'mitsmitsecfoundation11',
    'mitsmitsecfoundation3',
    'mitsmitsecfoundation6',
    'mitsmitsecpentester10',
    'mitsmitsecpentester2',
    'mitsmitsecpentester3',
    'mitsmitsecpentester7',
    'mitsmitsecpentester9',
    'mitsmkenobi',
    'mitsmmetasploit',
    'mitsmnessus',
    'mitsmnmap',
    'mitsmonetimepad',
    'mitsmpentester',
    'mitsmsambacry',
    'mitsmvulnversity',
    'mitsmwebscanning',
    'mitsmxss',
    'mitsmxssfoundation',
    'mixupM6',
    'mixupng',
    'mkingdom',
    'mlscctf2021',
    'mmalb',
    'mmaur',
    'mnemonic',
    'mobileforensicsandroidcc',
    'mobileforensicsayd521',
    'modbusserver',
    'moinmoin',
    'mondaymonitor',
    'monikerlinkr4',
    'monitoringevasionf7',
    'monitoringevasionwv',
    'monplactonoticsintern',
    'monplactonprofiler',
    'monplancton',
    'moon',
    'morbitcipherrecap',
    'morpheus',
    'motunui',
    'mountaineer',
    'mountaineerlinux',
    'mrmcyber',
    'mrphisher',
    'mrphisher',
    'mrphishers8',
    'mrreverser',
    'mrrobot5hku',
    'mrroboteize',
    'mrrobotqu',
    'mrrobotrs',
    'mrrobotyhha',
    'mtadevelopmentrules',
    'mtaexploitingavulnerabilityv2',
    'mtaintrotoresearch',
    'mtametodyaformyvuky',
    'mtanmap03',
    'mtanmap04',
    'mtapassiverecon',
    'mtaremoteadminrt95',
    'mtaremoteadminrtxh',
    'mtaremotedesktopmta',
    'mtator',
    'mtavm',
    'mtavulnerabilities101',
    'mtavulnerabilitycapstone',
    'mtawiresharkpacketoperations',
    'mtawiresharkthebasics',
    'mtawiresharktrafficanalysis',
    'mtazachranababicky',
    'mtts',
    'multiplecpusspectre',
    'musatroglodytarum',
    'musicalstego',
    'myprivateroom',
    'mysqlbasics',
    'mysqlchallenge',
    'mysqlmayhem',
    'mysterybox',
    'n3xus1stchal',
    'nacsss',
    'nahamsecsudemylabs',
    'nanocherryctf',
    'nappingis1337',
    'naxezho',
    'nc3ctf2021sommer1337',
    'ncsapokemonctf',
    'nearestplanet',
    'neptunepZ',
    'nerdherd',
    'nessus',
    'nessuseurotechv10',
    'nessusexp0811j9p8osdkjdqs',
    'nessuswalkthrough',
    'netmonsoc',
    'netsecchallengeau',
    'netsecchallengeio',
    'netsecchallengemm',
    'netsecchallengeop',
    'netsecchallengeq7',
    'netsecchallengeszgc',
    'netsecchallengev9',
    'networkanalyst342',
    'networkanalystbasic1313',
    'networkanalystbasic149',
    'networkanalystbasic341',
    'networkcasttypes',
    'networkcommunicationmodes',
    'networkdevicehardeningnB',
    'networkdevicehardeningp1',
    'networkdevicehardeningYJ',
    'networkforensicsayd521',
    'networking101',
    'networkingfundamentaljourney',
    'networkingfundamentalsdm',
    'networkinghardwaretheosi',
    'networkingms',
    'networkingtest',
    'networkingtools',
    'networkminer0z',
    'networkminer4p',
    'networkminerkl',
    'networkminerq5',
    'networkminerud',
    'networkninjas',
    'networkprotocols',
    'networksecurity',
    'networksecuritychallenge2b6ze',
    'networksecuritychallenge2b6ze68',
    'networksecuritychallenge2b6ze68HY',
    'networksecuritychallengef5ykf4ee',
    'networksecuritychallengef5ykf4eej5',
    'networksecuritychallengef5ykf4eeJTfL',
    'networksecuritypentestPI',
    'networksecuritypentestPIUm',
    'networksecuritypentestPIWB',
    'networksecuritypentestPIWBC0',
    'networksecurityprotocolsLb',
    'networksecurityprotocolsPJ',
    'networksecurityprotocolsyb',
    'networkservices2basic',
    'networkservices2basicodzl',
    'networkservices2jd',
    'networkservices2lx',
    'networkservices2n3',
    'networkservices2ov',
    'networkservices2ph',
    'networkservices2rw',
    'networkservices2t0',
    'networkservices2td',
    'networkservices2upqydrhocxcp',
    'networkservices2yw',
    'networkservices7d',
    'networkservices8f',
    'networkservicesanzxkydwmh',
    'networkservicesbo',
    'networkservicesbu',
    'networkservicesdjkm18',
    'networkservicesfbyq',
    'networkserviceshhzyffro',
    'networkserviceshhzyffroU6',
    'networkservicesjy',
    'networkservicesll',
    'networkservicesmd',
    'networkservicesnv',
    'networkservicesqa',
    'networkservicesqrsd',
    'networkservicesqz',
    'networkservicesqzpj',
    'networkservicesrx',
    'networkservicesxe',
    'networkservicesy6',
    'newbielinux',
    'newcode',
    'newcodenz',
    'newhireoldartifacts',
    'newroomfs',
    'newyeartestroom',
    'niceroom',
    'nightwalkes',
    'niiitpbatch5networkexam',
    'ninan7it',
    'nislinuxone',
    'nislinuxoneiji6',
    'nislinuxoneiji6k3',
    'nislinuxoneiji6ly',
    'nislinuxoneiji6lyjl',
    'nislinuxonepg6f',
    'nislinuxonepg6fGs',
    'nistcsf',
    'nistcsf11basics',
    'niststandards',
    'nlp',
    'nmap',
    'nmap012m',
    'nmap015s',
    'nmap017y',
    'nmap01bhg6',
    'nmap01ea',
    'nmap01ebxt19',
    'nmap01lf',
    'nmap01mp',
    'nmap01mv',
    'nmap01mvp3',
    'nmap01mz',
    'nmap01ns',
    'nmap01P3',
    'nmap01pi',
    'nmap01s9',
    'nmap01tm',
    'nmap01vjqh',
    'nmap01vu',
    'nmap01wvikr',
    'nmap01x6',
    'nmap01Y4',
    'nmap01yjKu',
    'nmap01yp',
    'nmap023djsofo8sY',
    'nmap026x',
    'nmap02am',
    'nmap02cf',
    'nmap02cn',
    'nmap02f2',
    'nmap02hg',
    'nmap02jguq',
    'nmap02k9',
    'nmap02l5',
    'nmap02lb',
    'nmap02ly',
    'nmap02mc',
    'nmap02mp',
    'nmap02nbfz',
    'nmap02px',
    'nmap02qe',
    'nmap02qi',
    'nmap02tz',
    'nmap02uv',
    'nmap02zdpz6q',
    'nmap0357',
    'nmap037l',
    'nmap03ag',
    'nmap03as',
    'nmap03bz',
    'nmap03dg',
    'nmap03ft',
    'nmap03gi',
    'nmap03klm6s6vu0E',
    'nmap03nh',
    'nmap03ok',
    'nmap03ovayJO',
    'nmap03pis7',
    'nmap03sg',
    'nmap03t7',
    'nmap03zo',
    'nmap040h',
    'nmap04b0vs',
    'nmap04df',
    'nmap04dz',
    'nmap04lm5uvK',
    'nmap04mn',
    'nmap04on',
    'nmap04qy',
    'nmap04rq',
    'nmap04So',
    'nmap04uD',
    'nmapfrench',
    'nmaplearning',
    'nmapm1',
    'nmapt5',
    'nonamectf',
    'nooncakeoct23',
    'normalticskillcourse2',
    'normalticskillcourse3',
    'normas',
    'nosqlinjectiontutorial',
    'notbydefault',
    'notetaking',
    'notredamemalware',
    'notredamephishing',
    'notsus',
    'npacyberl6dfassignment',
    'npwinethicalhacking',
    'nsttestroom',
    'ntfstexamination',
    'ntlmhashingexercise',
    'ntofflineintroductioncourse',
    'null',
    'numeralsystems2k',
    'numericconversions',
    'nustctf2023h3',
    'nutechacademylab2',
    'oakacademyredlinnh',
    'oauthexercises',
    'oawebsecuritychallenge6osbgd9C',
    'oawebsecuritychallenge6osbgd9ChE',
    'oawebsecuritychallenge6osbgd9ChEl3',
    'obfuscationbasics',
    'obfuscationprinciplesbl',
    'obfuscationprinciplesblik',
    'obfuscationprinciplesDX',
    'obfuscationprinciplesle',
    'obfuscationprinciplesxh',
    'obscured',
    'ocamlessentials',
    'october2023ctf',
    'octoencryptanddecrypt',
    'odnajdzaginioneosint',
    'offensivesecuritygakirish',
    'offenso',
    'offensogv',
    'offensoio',
    'ohsintjm',
    'ohsintsk',
    'ohsintun',
    'ohsintwp',
    'ollie',
    'olympusroom',
    'onboarding2023',
    'onyx',
    'oobxxe',
    'openctigf',
    'openctigy',
    'openctim2',
    'opensesame',
    'opensourceintelligence166',
    'openvas9u',
    'openvaskU',
    'openvaspi',
    'openvasyj',
    'openvpn3b',
    'openvpn9l',
    'openvpnqY',
    'openvpntnvw',
    'operatingsystemsecuritybf',
    'operatingsystemsecurityeo',
    'operatingsystemsecurityez',
    'operatingsystemsecuritygk',
    'operatingsystemsecurityio',
    'operatingsystemsecurityq4',
    'operatingsystemsecurityq4cB',
    'opsec5I',
    'opsec84',
    'opsecu6',
    'oracleweblogicbypass',
    'orangectf',
    'oscommandinjection1r',
    'oscommandinjection8rzg',
    'oscommandinjectionlc',
    'oscommandinjectionot',
    'oscommandinjectionpl',
    'oscommandinjectionVG',
    'osflab01u5',
    'osflab02vv',
    'osflab0313j',
    'osflab0313jaj',
    'osflab032zk96',
    'osflab04d5ny',
    'osflab0510fe',
    'osflab0510fear',
    'osflab066psg',
    'osflab07noii',
    'osflab08icrv',
    'osflab11',
    'osflab15x8',
    'osforhackersctf',
    'osimodelzi1dotly',
    'osimodelzi1dotlyk1',
    'osimodelzi1dotlyk1pj',
    'osimodelzi3r',
    'osimodelzi4m',
    'osimodelzi9f',
    'osimodelziaq',
    'osimodelzibt',
    'osimodelzicn',
    'osimodelzidt',
    'osimodelziga',
    'osimodelzii2',
    'osimodelziLz',
    'osimodelziq4',
    'osimodelzird',
    'osimodelzit7ko',
    'osimodelzixc',
    'osincybersecuritynp',
    'osint101',
    'osint4t',
    'osintchallenges',
    'osintctfdK',
    'osintevent',
    'osintformn00bz2023',
    'osintgastles',
    'osintlodrimed',
    'osintlodrimedgraduaat',
    'osintmagnumround1',
    'osintmyrunawaydaughter',
    'osintoroshit',
    'osintqk',
    'osintquestlg',
    'osintx4',
    'osiris',
    'osirisctf',
    'osqueryf89j',
    'osqueryf8zq',
    'oss123',
    'osscryptoworkshop4ctf',
    'otp',
    'outdateddfir',
    'outlast',
    'outlookntlmleakkjv1',
    'overpass2hackedkp',
    'overpassc4',
    'overpasseb',
    'overpassto',
    'overpassye',
    'overpassyew1',
    'oversightscc',
    'owaspapisecuritytop105wrf',
    'owaspbrokenaccesscontrolci',
    'owaspbrokenaccesscontrolgG',
    'owaspcrescentctf101',
    'owaspjuiceshop0h',
    'owaspjuiceshop56',
    'owaspjuiceshop5f',
    'owaspjuiceshopf5',
    'owaspjuiceshopw6',
    'owaspjuiceshopwr',
    'owaspjuiceshopwv',
    'owaspjuiceshopyq',
    'owaspjuiceshopyy',
    'owaspjuiceshopZ6',
    'owasptop1020215j',
    'owasptop1020216R',
    'owasptop102021kl',
    'owasptop102021lvzm',
    'owasptop102021oc',
    'owasptop102021ua',
    'owasptop102m',
    'owasptop103qho',
    'owasptop108K',
    'owasptop10h7',
    'owasptop10m7',
    'owasptop10o6',
    'owasptop10uu',
    'owasptop10wq',
    'owasptop10yh',
    'owasptop10ym9W',
    'owasptopten',
    'owlsecctf',
    'ownmachine',
    'oxetechctf2023',
    'pa',
    'packageinstallerforpythonr5',
    'packetanalysisjd',
    'packetanalysisjdjism',
    'packetanalysisjdjisms6',
    'packets',
    'packetsframesab',
    'packetsframesab1n',
    'packetsframesci',
    'packetsframesg1',
    'packetsframesg1vn',
    'packetsframesNZ',
    'packetsframesoaffvxivonul',
    'packetsframesrz',
    'packetsframesst',
    'packetsframesxn',
    'packetsframesxt',
    'packetsframeszo',
    'paidmembershipsprosql',
    'palletcity',
    'palletcity2022',
    'palpation',
    'palsforlife',
    'pandavas2024',
    'pandora',
    'papercut',
    'papercutHg',
    'papercutngcve202338831',
    'papermask',
    'parrotpost',
    'parrotpostam',
    'passiverecon4u',
    'passiverecon6e',
    'passiverecon8f',
    'passivereconcc5y9zy0',
    'passivereconi5',
    'passivereconk7',
    'passivereconnaissance',
    'passivereconoh',
    'passivereconox',
    'passivereconrr',
    'passivereconsl',
    'passivereconwc',
    'passivereconwwpqhh',
    'passivereconwwpqhhjc',
    'passivereconxa',
    'passivereconxj',
    'passwordattacksa576g6oq9g',
    'passwordattacksa576g6oq9ge5',
    'passwordattackse3',
    'passwordattacksIZ',
    'passwordattacksjC',
    'passwordattackslb',
    'passwordattacksmr',
    'passwordattacksMV',
    'passwordattacksNN',
    'passwordattacksrk',
    'passwordattackss0',
    'passwordattacksuz',
    'passwordattacksx2',
    'passwordcracking101',
    'passwordcrackinglab',
    'passwordcrackingtechniqueslj',
    'passwordscracking',
    'passwordscrackingri',
    'passwordscrackingrinx',
    'patched',
    'patience',
    'paymentcollectorsaGRc',
    'paymentcollectorsYx',
    'pcapanalysis',
    'peakhill',
    'peanutbutter',
    'peanutbutterisgood',
    'penetrationtestingzh',
    'pentest',
    'pentestchallengev1',
    'pentesting',
    'pentestingfundamentals2n',
    'pentestingfundamentals2nDH',
    'pentestingfundamentals3d',
    'pentestingfundamentals98',
    'pentestingfundamentalsbv',
    'pentestingfundamentalsdj',
    'pentestingfundamentalsdt',
    'pentestingfundamentalse5',
    'pentestingfundamentalsf9',
    'pentestingfundamentalsl2',
    'pentestingfundamentalsm2',
    'pentestingfundamentalsMY',
    'pentestingfundamentalsrp',
    'pentestingfundamentalsuq',
    'pentestinginterno',
    'pentestmetasploit',
    'petir2024',
    'pettalkctf',
    'phishinganalysis',
    'phishingemaildemo',
    'phishingemails1tryoebo',
    'phishingemails1tryoebs',
    'phishingemails1tryoefegt',
    'phishingemails1tryoefegt1a',
    'phishingemails1tryoekD',
    'phishingemails1tryoepV',
    'phishingemails1tryoers',
    'phishingemails1tryoeRX',
    'phishingemails1tryoerz',
    'phishingemails1tryoeTg',
    'phishingemails1tryoeuV',
    'phishingemails1tryoez2',
    'phishingemails1tryoezm',
    'phishingemails2rytmuv1t',
    'phishingemails2rytmuvfe',
    'phishingemails2rytmuvpf',
    'phishingemails2rytmuvtp',
    'phishingemails2rytmuvui',
    'phishingemails2rytmuvv3',
    'phishingemails2rytmuvv4',
    'phishingemails2rytmuvWk',
    'phishingemails3tryoeDfux',
    'phishingemails3tryoeDfuxzb',
    'phishingemails3tryoeep',
    'phishingemails3tryoeM2',
    'phishingemails3tryoeou',
    'phishingemails3tryoesf',
    'phishingemails3tryoeXb',
    'phishingemails3tryoeyv',
    'phishingemails4gkxhih',
    'phishingemails4gkxhNM',
    'phishingemails5fgjlzxca5',
    'phishingemails5fgjlzxcct',
    'phishingemails5fgjlzxcls',
    'phishinghiddeneye',
    'phishinghiddeneye',
    'phishinginthedarkaa',
    'phishingyl7x',
    'phishingyl8siR',
    'phishingylbA',
    'phishingyld1',
    'phishingyliE',
    'phishingyljj',
    'phishingyllz4q',
    'phishingylmz',
    'phishingylPa',
    'phishingylQE',
    'phishingylso',
    'phishingyluk',
    'phishingyly3',
    'phishmlwreanlysthreatintel',
    'phishmlwreanlysthreatintel9c',
    'phishtraceexchangelogs',
    'phpbasics',
    'phpcgiargumentinjection',
    'phpcgiargumentinjectionbO',
    'phpmailar5220',
    'phunetseckk',
    'physicalsecurity101',
    'physicalsecurityintrovo',
    'picklerick2y',
    'picklerickBb',
    'picklerickbj',
    'picklerickbj8j',
    'picklerickbj8jrSH7k5M2ez',
    'picklerickdn',
    'picklerickj5',
    'picklerickni',
    'picklerickOt',
    'picklerickxa',
    'picklerickxr',
    'picklerickyk',
    'pigeons',
    'pineappleonpizza',
    'pinger',
    'pingsignal',
    'piratedgames',
    'planetart',
    'planetexpress',
    'planetsctf',
    'play',
    'playbookintroduction',
    'playbooksusingcustomlistsf7',
    'playbooktroubleshooting',
    'playground',
    'plottedemr',
    'plottedlms',
    'plottedtms',
    'plottedtms',
    'pngortext',
    'pointblanc',
    'polkit',
    'polkitfiascounleashthechao',
    'poly4challservicesreseau',
    'poly4challvulnerabilites',
    'poly4kerberos',
    'poly4lactivedirectory',
    'poly4metasploit',
    'poly4servicesreseau',
    'poly4servicesreseau2',
    'poly4shell',
    'poly4sniffingetmitm',
    'poly4vulnrabilitsv3',
    'poly5apirest',
    'poly5authbypass',
    'poly5burpsuitebasics',
    'poly5burpsuiterepeater',
    'poly5cms',
    'poly5commandinjection',
    'poly5csp',
    'poly5fileinclusion',
    'poly5log4j',
    'poly5owasp',
    'poly5sqlinjection',
    'poly5ssti',
    'poly5upload',
    'poly5xss',
    'poly7credharvestingn',
    'poly7jtr',
    'poly7windowspersistence',
    'poly7windowsprivesc',
    'polybasicctf',
    'polybox101',
    'polydirtypipe',
    'polynmap1',
    'polynmap2',
    'polynmap3',
    'polyredrecon',
    'polysudovulnssamedits',
    'polywebdisc1',
    'polywebenum1',
    'portfolioHw',
    'portsandprotocolsnc3ctf20',
    'postexploit2n',
    'postexploit6k',
    'postexploiti4',
    'postexploitlg',
    'postgresql',
    'postgresqlvulnerability',
    'potatochips',
    'potatochipsclone',
    'potatochipsp0',
    'powerofthepenguin',
    'powershellc3',
    'powershellforpentestersxajv',
    'powershellforpentestersy5',
    'powershelllp',
    'powershellscripting8w',
    'powershellZE',
    'practicaltools',
    'practiseclibasics',
    'preparationit',
    'prepufazopenvpn2023',
    'prepufazopenvpn2024',
    'prepufazopenvpn2024yd',
    'principeslinux',
    'principlesofsecurity1e',
    'principlesofsecurity1l',
    'principlesofsecurity3c',
    'principlesofsecuritymj',
    'principlesofsecuritymq',
    'principlesofsecurityor',
    'principlesofsecurityt8qpd2gp',
    'principlesofsecurityt8qpd2gp4p',
    'principlesofsecurityt8qpd2gp4plt',
    'principlesofsecurityt8qpd2gp4pOW',
    'principlesofsecurityu2',
    'principlesofsecurityvl',
    'principlesofsecurityxe',
    'printnightmarec2bn7l',
    'printnightmarec3kj',
    'printnightmarehpzqlp8',
    'prioritise',
    'privateinvestigator1',
    'privatemac',
    'privileged',
    'privilegeescalationdF',
    'privilegeescalationvulnerabi',
    'probe8E',
    'probe8E08',
    'probe8I',
    'processinjectwikiquiz',
    'prodigy2k23testing',
    'profilesroom',
    'projectq',
    'promptengineering',
    'prompting',
    'protocolsandservers2e1',
    'protocolsandservers2f5',
    'protocolsandservers2fa',
    'protocolsandservers2qa',
    'protocolsandserversms',
    'protocolsandserversqx',
    'protocolsandserverssl',
    'protocolsandserverstv',
    'protocolsandserversxs',
    'prototypepollutionOY',
    'proxybrokersctf',
    'pruebactfhm',
    'pruebadeaptitudoffsec',
    'psychobreak',
    'publickeyinfrastructuretf',
    'publisher',
    'purestaticre',
    'purpleteamings6',
    'purpleteamingwithcyync',
    'purpleteamworkshop1vw',
    'pushupbrovd',
    'puttingitalltogether0blz',
    'puttingitalltogethere1',
    'puttingitalltogetherha',
    'puttingitalltogetherNV',
    'puttingitalltogetherp7',
    'puttingitalltogetherph',
    'puttingitalltogetherqo',
    'puttingitalltogetheru4',
    'puttingitalltogethervd',
    'puttingitalltogethervdfe',
    'puttingitalltogetherwm',
    'puttingitalltogetherx3',
    'pwn101',
    'pwn101fz',
    'pwn2own',
    'pwnintrotorop',
    'pwnkit',
    'pwnkit',
    'pwnmemachine',
    'pwntheflags',
    'pxlenumerationlabaz',
    'pxlgettingtoknowyourgroup',
    'pxlhashing',
    'pxlhashingth',
    'pxlintrotocryptography',
    'pxlshodan',
    'pxlvolatility',
    'pxlvolatility187v',
    'pxlvolatility187voc',
    'pxlvulnerabilitysearch',
    'pxlwireshark',
    'pxlyellowteamsecurecoding',
    'pyforctf101',
    'pylonzf',
    'pyramidofpainaxab',
    'pyramidofpainaxgc',
    'pyramidofpainaxgcxl',
    'pyramidofpainaxgw',
    'pyramidofpainaxtd04',
    'pyramidofpainaxzr',
    'pyrat',
    'python101pP',
    'pythonbasics6g',
    'pythonbasics7m',
    'pythonbasics8s',
    'pythonbasics92',
    'pythonbasicsei',
    'pythonbasicsf8eish',
    'pythonbasicsjs',
    'pythonbasicsoz',
    'pythonbasicsuf',
    'pythonbasicsxd',
    'pythonddoslogic',
    'pythonforbeginners81',
    'pythonforcybersecurityaq',
    'pythonforcybersecurityiy',
    'pythonforcybersecuritylxepc4',
    'pythonforcybersecuritymn',
    'pythonforcybersecuritysnp',
    'pythonforcybersecuritysq',
    'pythonforcybersecurityss',
    'pythonforcybersecuritysseaka',
    'pythonforcybersecurityub',
    'pythonforhackers',
    'pythonlogginganalysisimfp',
    'pythonplayground',
    'pywinapi',
    'pywinapi8f',
    'qbypass',
    'qdpm91cve20207246',
    'qmaccesscontrol',
    'qmaccesscontrolNL',
    'qmbufferoverflow2024wj',
    'qmulwiresharkvh',
    'quizad',
    'r00tm3',
    'r00tmeifyoucan',
    'ra2',
    'rabbitholeqq',
    'race',
    'raceconditions',
    'racetrackbank',
    'racist23',
    'radiohackers',
    'ramanalysis',
    'rangeroverFT',
    'ransomwareinvestigation',
    'rapidascentorientation',
    'rapplexim',
    'rarlabwinrarvulnerabilityb6',
    'raven1he',
    'raven2uy',
    'rawsearchwithchronicle',
    'rawsearchwithchronicleam',
    'raz0rblack',
    'rceexploit',
    'rcevulnerability',
    'recon',
    'reconnaissancehx',
    'reconnaissancemaltego',
    'reconocimientoactivoyacceso',
    'reconrendezvous',
    'reconroguex',
    'recoveringactivedirectory',
    'recovery',
    'red',
    'redflagcr',
    'redisl33t',
    'redisl33tgo',
    'redisme',
    'redisme1nc3ctf2023',
    'redisme2nc3ctf2023',
    'redstoneonecarat',
    'redteam',
    'redteamattack',
    'redteamengagementsBP',
    'redteamengagementsbt',
    'redteamengagementsf0',
    'redteamengagementshd',
    'redteamengagementslf',
    'redteamengagementsX5',
    'redteamfirewalls15',
    'redteamfirewalls1i',
    'redteamfirewallsic',
    'redteamfirewallski',
    'redteamfirewallsOY',
    'redteamfirewallssn',
    'redteamfirewallsvi',
    'redteamfirewallswf',
    'redteamfirewallsye',
    'redteamfirewallsYj',
    'redteamfirewallszM',
    'redteamfundamentalsFx',
    'redteamfundamentalsnt',
    'redteaming101',
    'redteamjy',
    'redteamlab1',
    'redteamlabgroot',
    'redteamnetsec5l',
    'redteamnetsecdrq5',
    'redteamnetsecHs',
    'redteamnetsecwm',
    'redteamreconI0',
    'redteamreconjv',
    'redteamthreatintel1h',
    'redteamthreatintelNh',
    'redteamthreatintelsy',
    'redteamtoolkit',
    'redtest',
    'redvsblue',
    'refineyourexpertise',
    'registry4n6',
    'registryforensics09',
    'registryforensicsays521',
    'registrypersistencedetection4s',
    'relevantjn',
    'reloaded',
    'remotecode',
    'remotecodeexecution',
    'requestsmugglingbrowserdesync',
    'resistmalwareanalysis',
    'restaurant',
    'ret2libc',
    'retracted',
    'rev',
    'revenge',
    'reverseengineering',
    'reversingacrackme',
    'reviewctf1',
    'revilcorp',
    'revilcorp5H',
    'revilcorpg8',
    'revilcorpx4',
    'rfirmware',
    'rhsl03',
    'riceumalware',
    'riceuphishing',
    'rickastleyctf',
    'ripa',
    'ripper',
    'ripperdoc',
    'riskint',
    'robertraidsrubertusriches',
    'robocoptwist',
    'robocoptwistfx',
    'rocket',
    'rogueserver',
    'rolesincybersecuritygt9n',
    'rolesincybersecurityraboeing',
    'rome',
    'room1pl1337',
    'roomofmirrors',
    'roomsubpe',
    'roomtest999',
    'roomtest999o7',
    'rope',
    'ropmeifyoucan',
    'roppersthm',
    'rotcrypto',
    'rpburpsuite',
    'rpburpsuitecq',
    'rpburpsuiteht',
    'rpburpsuitezz',
    'rpmetasploit',
    'rpmetasploit6afb',
    'rpmetasploit6g',
    'rpmetasploitul',
    'rpmetasploituw',
    'rpmetasploituz',
    'rpmetasploitxp',
    'rpnessus',
    'rpnessusdq',
    'rpnessusredux1i',
    'rpnessusredux4N',
    'rpnessusredux9u',
    'rpnessusreduxco',
    'rpnessusreduxii',
    'rpnessusreduxjk',
    'rpnessusreduxkl',
    'rpnessusreduxrf',
    'rpnessusreduxtt',
    'rpnessusreduxty',
    'rpnessusreduxud',
    'rpnmap',
    'rpnmaplm',
    'rpnmapxr8e',
    'rppsempire',
    'rppsempire',
    'rpsublist3r',
    'rpsublist3r17s5kr',
    'rpsublist3r17s5kr9i',
    'rptmuxpqpp',
    'rpwebscanning',
    'rpwebscanning3k',
    'rrootmeci',
    'rrootmedv',
    'rrutcactfan',
    'rsa',
    'rtarf2023',
    'rthabasicpentest101',
    'rthafunbox',
    'rthakchlabsforcommands',
    'rthapentest102',
    'rtkchlab',
    'rtpen101',
    'rust',
    'rustscang0',
    'ruwan',
    's',
    's0ci4l3ngin33ring',
    's0uln3tc7f',
    'safeaeon101',
    'safezone',
    'sakuraix',
    'salesforcectf',
    'salle1',
    'salustestmar2024',
    'saml',
    'samolectf',
    'samplectf48',
    'sandboxevasionP8',
    'sandiegosecuritywargame1',
    'sandiegosecuritywargame2',
    'sastahackuur',
    'sastgh',
    'sastsecuresourcecode',
    'sastsm',
    'sasyq',
    'sasyq0r',
    'savezootopia',
    'savezootopia9dr5',
    'scanningforvulnarablilities',
    'scavengerhack',
    'sccctf',
    'sceintroductiontocsoc',
    'scotlandctf1',
    'scriptalertscriptjp',
    'scripting',
    'scriptingforlinuxintrokz',
    'scriptingforsecurity',
    'sdnsecuritytv',
    'searchlightosintdn',
    'searchlightosintok',
    'seasurfer',
    'sec',
    'secju',
    'seco',
    'secodirbnikto',
    'secondaryschoolweek1',
    'secopsdemo',
    'secopsdrill',
    'secoukv1tmoy',
    'secoukv1tmoy14',
    'secuneusctf',
    'securathon',
    'securathonya',
    'securecode1withfoxy',
    'securecoding',
    'secureforfuture',
    'securegitops',
    'securesdlchd',
    'securesdlcvi',
    'securesolacodersintra',
    'securesolacodersnofilesrv',
    'securesolacodersnowebsrv',
    'securingdockerxavy',
    'securingopensourcedependenc',
    'securitayxctf',
    'securitayxiictf',
    'security',
    'securityawarenessintroeu',
    'securityawarenessintrohD',
    'securityawarenessintroi4',
    'securityawarenessintroMx',
    'securityawarenessintrond',
    'securityawarenessintrosf',
    'securityawarenessintrosfxm',
    'securityawarenessintrosfxmwc',
    'securityawarenessintrov7',
    'securityawarenessintrovj',
    'securityawarenessintrowj',
    'securityconceptsx',
    'securityengineerintrokh',
    'securityfootage',
    'securityforqaintroduction',
    'securitygarage',
    'securityoperationscc',
    'securityoperationsdb',
    'securityoperationsia',
    'securityoperationskv',
    'securityoperationskw',
    'securityoperationsPF',
    'securityoperationsqe',
    'securityoperationsWo',
    'securityoperationsxt',
    'securityprinciplesHt',
    'securityprinciplesW2',
    'securityprinciplesWB',
    'securityprincipleswS',
    'seetworoom',
    'segaplicacoesweb',
    'segma',
    'seo',
    'sep2023ctf',
    'seriskmanagement7z',
    'seriskmanagementhr',
    'seriskmanagementrh',
    'seriskmanagementu6',
    'server',
    'serverservicevulnerability',
    'services',
    'servidaeax',
    'seshchristmasctf2023',
    'set',
    'sfs24',
    'shadow',
    'shadowdp',
    'shadyreloading',
    'shaker',
    'sharko',
    'sharktest',
    'shell',
    'shellctf',
    'shellitems',
    'shellmemberselection2023',
    'shellsecrecy',
    'shellshock',
    'shellshockpxdv',
    'shellshockvb',
    'shellweeklychallenge01',
    'shiftciphers',
    'shivajivailajellbi',
    'shodanqs',
    'shodanry',
    'sibergveliegiri',
    'sibersaldrlar',
    'sibersavunma',
    'sidechannelattackspectre',
    'siemsplunkor',
    'sifat009',
    'sigmaa5',
    'sigmarr',
    'sigmaxz',
    'sigmaxzjT',
    'siguriaetedhenave24',
    'silence',
    'silverplatter',
    'simp',
    'simplectf',
    'simplectf1',
    'simplelogintoadmindbh',
    'simplesample',
    'sivajivailajalebi',
    'skchva',
    'skctf2024',
    'sku',
    'sky0U',
    'skyctf2023bd',
    'skyfall7L',
    'skylaps',
    'skymanctf',
    'skynetworkn3',
    'smbghost',
    'smbrahil',
    'smol',
    'snappedphishinglineHZ',
    'snappedphishinglineIp',
    'snappedphishinglinezN',
    'snortchallenges1hj',
    'snortchallenges2h2',
    'snortchallenges2te',
    'snortchallenges2zx',
    'snortEo',
    'snortfl',
    'snortm3',
    'snortrs',
    'snortzn',
    'snphj',
    'snykadvisor',
    'snykcode',
    'snykcoderoom',
    'snykopensourceroom',
    'socialcybersecurity',
    'socialengineeringky',
    'socialengineeringtechniques',
    'socmintrc',
    'soctoolacceptableusepolicyai',
    'soen321',
    'softwarebillofmaterials',
    'solarkk',
    'solarkkHy6i',
    'solarozsg',
    'somesintd0',
    'sp0tthefalse',
    'spctfvol1',
    'spectresidechannelattack',
    'spectreunleashed',
    'spfedition50',
    'splunk',
    'splunk1015j',
    'splunk1015jb3',
    'splunk1016z',
    'splunk1017A',
    'splunk101c7',
    'splunk101dotnkpYy',
    'splunk101EL',
    'splunk101fd',
    'splunk101gD',
    'splunk101jC',
    'splunk101jfvm',
    'splunk101k9',
    'splunk101lant',
    'splunk101na',
    'splunk101old',
    'splunk101pg',
    'splunk101pgs3',
    'splunk101qi',
    'splunk101wo',
    'splunk2018B',
    'splunk201hb',
    'splunk201hk',
    'splunk201k9',
    'splunk201uo',
    'splunk2gcd53e',
    'splunk2gcd54y',
    'splunk2gcd56s',
    'splunk2gcd5c8uw',
    'splunk2gcd5cD',
    'splunk2gcd5js',
    'splunk2gcd5ky',
    'splunk2gcd5l8',
    'splunk3',
    'splunk3zs',
    'splunk3zs1g',
    'splunk3zsac',
    'splunk3zsld',
    'splunkadvancedskills',
    'splunkchallenge',
    'splunkctf',
    'splunkdashboardsandreports6c',
    'splunkdashboardsandreports7s',
    'splunkdashboardsandreportsBs',
    'splunkdashboardsandreportsHE',
    'splunkdatamanipulation8k',
    'splunkdatamanipulationaB',
    'splunkdatamanipulationfa',
    'splunkdatamanipulationIQ',
    'splunkdataparsing',
    'splunkexploringsplHY',
    'splunkexploringsplMNC5',
    'splunkexploringsplp5',
    'splunkfundamental',
    'splunklabbs',
    'splunklabbs6t',
    'splunklabHt',
    'splunkvx',
    'spotifyvulnerabilities',
    'spraythis',
    'spring',
    'spring4shell',
    'spring4shellexploitation',
    'spring4shellz0',
    'sputnik8o',
    'sqathebug',
    'sqhellg0',
    'sqli',
    'sqli2rcehacknotes',
    'sqlibasics',
    'sqlilab1s',
    'sqlilab1sao',
    'sqlilabav',
    'sqlilabax',
    'sqlilabhr',
    'sqlilabm3yx',
    'sqlilabmc',
    'sqlilabme',
    'sqlilabPG',
    'sqlilabpw',
    'sqlilabRU',
    'sqlinjection',
    'sqlinjectionlm2N',
    'sqlinjectionlm8g',
    'sqlinjectionlm9d',
    'sqlinjectionlmAU',
    'sqlinjectionlmcg',
    'sqlinjectionlmDJ',
    'sqlinjectionlmdV',
    'sqlinjectionlmea',
    'sqlinjectionlmehcpvy',
    'sqlinjectionlmli',
    'sqlinjectionlmlwgc',
    'sqlinjectionlmuc',
    'sqlinjectionlmw1',
    'sqlinjectionlmwp',
    'sqlinjectionlmyj',
    'sqlinjectionlmzh',
    'sqlinjectionlmzh7J',
    'sqlinjectionrm',
    'sqliwm',
    'sqlmapcT',
    'sqlmapja',
    'sqlmapn6',
    'sqlmapn6YC',
    'sqlmapsq',
    'squidgameroom',
    'srcsummer2024challenge',
    'ssctf1',
    'sshbestpractices',
    'sshcrack',
    'sshgQ',
    'ssltslhandshake',
    'ssrf',
    'ssrfaoc',
    'ssrfhreT',
    'ssrfqi62',
    'ssrfqi7d',
    'ssrfqi87',
    'ssrfqiak',
    'ssrfqinj',
    'ssrfqiV2',
    'sssecssdeep',
    'ssti',
    'st3gan0',
    'stagefrightcve20153864',
    'stalkingprofessionally',
    'standardcompliance',
    'starterpack',
    'startingoutincyberseck4',
    'startingoutincybersecs7',
    'statesofdatadataprivacy',
    'staticanalysis1yW',
    'stayhighh',
    'stc',
    'stealthcopterctfprimer1',
    'stealthoa',
    'steelmountainlt',
    'steganography',
    'steganographyctf',
    'stegnography',
    'stegoint',
    'stickerz',
    'storyofwonderland',
    'streakhealer',
    'strike',
    'strongholdctf',
    'student12ctf',
    'stuxctf',
    'subcbsmdflabfinal',
    'subcbsmdflabfinalb',
    'subdomainenumerationjl',
    'subdomainenumerationox',
    'subnettingnu',
    'subsitcyberseclabfinala',
    'subsitcyberseclabfinalb',
    'subsitcyberseclabfinalc',
    'suctfnewway',
    'sucybersecmidterma',
    'sucybersecmidtermb',
    'sucybersecmidtermc',
    'sudfmidterma',
    'sudfmidtermb',
    'sudfmidtermc',
    'sudovulnssamedit',
    'suhail',
    'summit',
    'sunset',
    'superspamr',
    'surferfl',
    'surferh0',
    'surferrG',
    'sustah',
    'svccs326gettingstarted',
    'sweettoothinc',
    'swordartctf',
    'symmetricasymetricencryption',
    'synclature2023up',
    'sysinternals',
    'sysinternalsplayground',
    'sysmonCR',
    'sysmonhf',
    'sysmonyK',
    'szsoc',
    'szutesttse',
    't',
    't111advwirednetarch',
    't113cloudfundamentals',
    't114cloudarchitecture',
    't11f1advancedsecurecloud',
    't11f1wirelessexercises',
    't122setupimplementation',
    't123accesscontrolsiam',
    't123setupimplementation',
    't131threatsvulns',
    't133grcfundamentals',
    't13f1threatvulnmngmnt',
    't143operationsmonitoring',
    't1mth3h4ck3rb0y',
    't221principlesofcloudsec',
    't222cloudsecbestpractice',
    't223azureseccompliance',
    't224azuresecandcomp',
    't225awsseccompliance',
    't227awsprivacydatasec',
    't229cloudsecurityauditing',
    't22f3awsseccompliance',
    't22f4awsprivacyautomate',
    'taextrachallenge',
    'tailsos',
    'takedown',
    'tallerinterno',
    'target1',
    'target2',
    'target3',
    'target4',
    'target5',
    'target6',
    'target7',
    'target8',
    'tartaraus',
    'tasawtctf2024qq',
    'tastafekaligeneralCX',
    'taswin',
    'tcphysicalsecurity',
    'tcssecurity',
    'teagansfunhouse',
    'teamcityY9',
    'teamonboarding',
    'techcareerctfrs',
    'techjamroom',
    'technikictf',
    'technikictfvk',
    'technocrathf2019',
    'technocratosintctf',
    'technocratpen101',
    'technocratsunset',
    'technologyintctf2023',
    'technologyintctf20231l',
    'technologyintctf20231lxa',
    'technovorehackathon2023ctf',
    'technovorehackathon2024ctf',
    'techsupport28',
    'teestoV',
    'tekpedago',
    'teleporthu',
    'tempestincident',
    'templates',
    'temple',
    'templel2VM',
    'templezf',
    'temproomforreview',
    'tempusfugitdurius',
    'terminalexample',
    'termuxbasics',
    'termuxforandroid',
    'tes',
    'test001qp',
    'test123019009sf90s9d0f9s0df',
    'test1235p',
    'test123x4',
    'test12fb',
    'test16j',
    'test1l',
    'test28o9',
    'test29jv0',
    'test3qq',
    'test4k',
    'test6hewewe',
    'testattach',
    'testav',
    'testax',
    'testaxYU',
    'testaxYUwF',
    'testb6',
    'testbug',
    'testc7b3',
    'testcheesectf82',
    'testcmqa',
    'testcp2a',
    'testcryptography',
    'testctf12023',
    'testctf21D',
    'testctfBX',
    'testctfmachine',
    'testctfR5',
    'testdojo',
    'teste123Z0',
    'tested',
    'testeN',
    'testeo',
    'testerdette',
    'testetstets',
    'testhhnroundcube',
    'testing',
    'testing1i',
    'testingdv',
    'testingforpotentialgame',
    'testingvt',
    'testingxyz123',
    'testiu',
    'testJo',
    'testkf',
    'testml',
    'testn6',
    'testnM',
    'testnQ',
    'testosintctf',
    'testp2',
    'testQt',
    'testqwertypl',
    'testr2',
    'testRf',
    'testroom123xw',
    'testroom1A7',
    'testroom2q7',
    'testroom89',
    'testroomen',
    'testroomgj73',
    'testroomgy',
    'testroomhe',
    'testroomjfbi',
    'testroomJi',
    'testroomydtpf8',
    'testrt9l',
    'testrt9leq',
    'testrt9lkc',
    'testsplunk',
    'testtesttesttesttesttest',
    'testtyxa',
    'testU3',
    'testUY',
    'testVk',
    'testw2',
    'testwhiterabbit',
    'testwhiterabbitlx',
    'testwithvm',
    'testwn',
    'testWO',
    'testwX',
    'testXs',
    'testyiaq',
    'testznoo',
    'testZZ',
    'tewst',
    'tewste7',
    'text4shellcve',
    'textbasedinjection',
    'texttobinary',
    'thatstheticket',
    'thealertflow',
    'thearchive',
    'thebankheistdbh',
    'thebasiclowdown',
    'thebindingofcyber',
    'theblobblog',
    'theblurredvisiondbh',
    'thebrickathonpreseasonvi',
    'theclassifiedfiledbh',
    'thecyberctf0x01',
    'thecyberctf0x06',
    'thecyberctf0x09',
    'thecyberctf0x1',
    'thecyberctf0x13',
    'thecyberctf0x18',
    'thecyberctf0x19',
    'thedwarfplanet',
    'theend',
    'theeternalblue',
    'thefinalassessment',
    'thefindcommand',
    'thefindcommandkf',
    'thefirstctfkeys',
    'thegatekeeperdbh',
    'thegreatcyberdetective',
    'thegreatescape',
    'thegreatlibrary',
    'thehiddeninjectiondbh',
    'thehive',
    'thehiveprojecta4',
    'thehiveprojectfi',
    'thehorcruxhunt',
    'theimpossiblechallenge',
    'theinfectedclientdbh',
    'theintruderstracedbh',
    'thelaststep',
    'thelayoftheland8g',
    'thelayofthelandpg0h',
    'thelayofthelandpm',
    'thelightningquizdbh',
    'thelondonbrigde',
    'themidsummercorphack',
    'thenewbies',
    'theoverfriendlycomputerdbh',
    'thepointbetweenia',
    'thepostgresql81cve',
    'theprinceandthepauper',
    'theprojectcerberusdbh',
    'thepyramidofpain',
    'theransomwareheistdbh',
    'theseowebappdbh',
    'theserverfromhell',
    'theseus',
    'thestickershop',
    'thestickynotee',
    'theterminator',
    'theunder',
    'theunrulylibcdbh',
    'theworldishidden',
    'theworldofctfwarmup',
    'thickclientpentestingrtkch',
    'thief',
    'thisisatestroomformyfyp4x',
    'thm',
    'thmnfsuctf2024',
    'thorlite',
    'threathunting101',
    'threathuntingfootholdfd',
    'threathuntingforqbot',
    'threathuntingpivoting8O',
    'threatintelligenceforsoc6n',
    'threatintelligenceforsoc8a',
    'threatintelligenceforsociz',
    'threatintelligenceforsocjk',
    'threatintelligenceforsocXA',
    'threatinteltools2w',
    'threatinteltools4t',
    'threatinteltools4y',
    'threatinteltools6a',
    'threatinteltoolsec',
    'threatinteltoolsez',
    'threatinteltoolsg1',
    'threatinteltoolsss',
    'threatinteltoolsvx',
    'threatinteltoolsWR',
    'threatmodellingl5',
    'threatmodellingp1',
    'threatmodellingyr',
    'threatreport',
    'throwback',
    'tickets3',
    'tickets4old',
    'tigris',
    'timber',
    'timetrigger',
    'titanshield',
    'tmuxremux',
    'tnctest',
    'toastyctf',
    'toc2',
    'tokyoghoul666',
    'tokyoghoul666u6',
    'tokyoghoul667',
    'tokyorevengers',
    'tomcatpentest',
    'tomcattangosY',
    'tonythetiger',
    'toolboxvimzh',
    'toolsrus1h',
    'torforbeginners9r',
    'tothemoon',
    'tr0ll2',
    'tr0ll3tuur',
    'tr1x',
    'traceback',
    'tracklinu8',
    'trafficanalysisessentialsqY',
    'trafficanalysisessentialszy',
    'trainingfall2023',
    'trainingfornewanalyst6o',
    'traversalvulnerability',
    'triageanalysttuningtraining',
    'tributeqf',
    'trickster',
    'trooper',
    'trycaldera',
    'tryhackme',
    'tryhackmeroom',
    'tryhackyoulinked',
    'tryhardchallenge',
    'tryroom5S',
    'tsarkactfvm1bfzq',
    'tsharkchallengesone',
    'tsharkchallengestwo',
    'tsharkci',
    'tsharkcliwiresharkfeatures',
    'tsharkczebxj',
    'tsharkhi',
    'tsharkjohn4ll1n0n3presplit',
    'tsharkpcapanalysis',
    'tsharkthebasics',
    'tsharky3',
    'tsirt2024',
    'tunnelcrack',
    'turtles',
    'tutorial8j',
    'tutorialfe',
    'tutorialj9',
    'tutorialjkil',
    'tutorialnoPE',
    'tutorialot',
    'tutorialpn',
    'tutorialra',
    'tutorialrj',
    'tutorialSs',
    'tutorialza',
    'tutorialzacr',
    'tuwaiqmachine',
    'twchnocratlabpwnpentest',
    'typhoonctf',
    'uavdronesecurityoperator',
    'ubuntuserver',
    'uc2fsa10simengsland',
    'uccctf',
    'ucchitbyoniichan',
    'udomctf6memory',
    'ufaz2024basicmalware',
    'ufaz2024bpvolatility',
    'ufaz2024brainstorm',
    'ufaz2024ccghidra',
    'ufaz2024cchacktest',
    'ufaz2024ctfcollectionvol1',
    'ufaz2024ctfevent',
    'ufaz2024forensics',
    'ufaz2024furthernmap',
    'ufaz2024gitandcrumpetsic',
    'ufaz2024githappensavgbit',
    'ufaz2024hackmethod',
    'ufaz2024handsonmalware',
    'ufaz2024hardeningbasicspart1',
    'ufaz2024investigatingwindows',
    'ufaz2024investigatingwindows2',
    'ufaz2024investigatingwindows3',
    'ufaz2024iotintro',
    'ufaz2024malwareintroductory',
    'ufaz2024owasptop10',
    'ufaz2024phishinghiddeneye',
    'ufaz2024pwexam',
    'ufaz2024reverselfiles',
    'ufaz2024shakermbony',
    'ufaz2024shodan',
    'ufaz2024socialengcyberweek',
    'ufaz2024solargek',
    'ufaz2024threatintelligence',
    'ufaz2024wireshark',
    'ufazbypasscontrols',
    'ufazchallengehackers',
    'ufazdetectsconfidentialfile',
    'ufazkali2024',
    'ufazkali2024mf',
    'ufazopenvpn2024',
    'ufazprinciplesofsecurity2024',
    'ufsitlinux',
    'ufvctf3xpl01td3v3l0pm3nt',
    'ufvctfmiscellaneous6fiv',
    'ufvctfmiscellaneoussm',
    'ufvctfpwnao',
    'ufvctfpwnbqvf',
    'ufvctfpwncu',
    'ufvctfpwnzucu',
    'ufvctfwebbt',
    'ufvctfwebff',
    'ufvctfwebxb',
    'uitsctfBr',
    'uitsctfcz',
    'uitsctfczmc',
    'uitswctf',
    'uitswinterctf2024',
    'ultr0nctf',
    'ultratech1ltxo4pvf',
    'ultratech1ltxo4pvfg3',
    'ultratech1mv',
    'umgctryoutctf',
    'unattended',
    'unbakedpie',
    'unbyteme',
    'uncommonprocessdllloading',
    'understandingbackend',
    'undiscoveredup',
    'unectf',
    'unictfv10',
    'unifiedkillchaincw',
    'unifiedkillchainfg',
    'unifiedkillchainuhcq',
    'unifiedkillchainxv',
    'unistraufazpwexam2023',
    'unpepene',
    'unrealircRz',
    'unrestrictedfileuploadaoc',
    'unseen',
    'unstabletwin',
    'untitledgooseroom',
    'untrustedzonetransfers',
    'uopad',
    'uopeasy',
    'uopehcw',
    'uopwk1',
    'uploadvulns1z',
    'uploadvulnsnc',
    'uploadvulnsr2',
    'uploadvulnswd',
    'uranium',
    'urducyber',
    'ureckoncybercipher',
    'usescasesofhashing',
    'ussfctf001',
    'ussfctfc32402',
    'ussfctfc32404',
    'ussfctfc32405',
    'ussfctfc32406',
    'ussfctfc32407',
    'ussfctfc324v1',
    'ustoun',
    'uswmetasploitable',
    'usxwxknghrangc58ymcq4hycwz6crd',
    'usxwxknghrangc58ymcq4hycwz6crdws',
    'v0l5t4t3winkex',
    'v8205vlqm37e',
    'valentinespecialchallenge',
    'vbaroom',
    'vctf',
    'vegetapentesting',
    'velociraptorhp72',
    'velociraptorhpho',
    'virtualizationandcontainers5n',
    'virtualizationandcontainersCg',
    'virtualizationandcontainersdt',
    'virustotaldefencemaster',
    'visualizationinsplunk',
    'volatility2h',
    'volatility3memoryforensics',
    'volatility8H',
    'volatility90',
    'volatilitynq',
    'volatilityoI',
    'vp',
    'vsftpd234vjr',
    'vtfredteam',
    'vu23215at2p2a3',
    'vucic',
    'vulnapi',
    'vulnerabilities1015Q',
    'vulnerabilities1015Qyn',
    'vulnerabilities1016r',
    'vulnerabilities101ij',
    'vulnerabilities101ikr',
    'vulnerabilities101ki',
    'vulnerabilities101lr',
    'vulnerabilities101Nq',
    'vulnerabilities101oo',
    'vulnerabilities101pq',
    'vulnerabilities101pybvdogkk8',
    'vulnerabilities101pybvdogklc',
    'vulnerabilities101pybvdogklcM5',
    'vulnerabilities101qz',
    'vulnerabilities101qzeu',
    'vulnerabilities101rebj',
    'vulnerabilities101rebjmS',
    'vulnerabilities101wnlh',
    'vulnerabilitiesinthenba',
    'vulnerabilitycapstone3V',
    'vulnerabilitycapstone9y',
    'vulnerabilitycapstonee3jj',
    'vulnerabilitycapstonehu',
    'vulnerabilitycapstonemt',
    'vulnerabilitycapstonepH',
    'vulnerabilitycapstonex9zuagCc',
    'vulnerabilitycapstoneye',
    'vulnerabilitymanagement',
    'vulnerabilitymanagementkjvI',
    'vulnerabilitymanagementkjvj',
    'vulnerabilitymanagementkjW7',
    'vulnerabilitymanagementkjZQ',
    'vulnerabilitymanagementkjZQ4T',
    'vulnerabledebiantest',
    'vulnerablemachine',
    'vulnerablemachineBC',
    'vulnerablewinrar622',
    'vulnnet1',
    'vulnnet1rg',
    'vulnnetactive',
    'vulnnetdotjar',
    'vulnnetdotpy',
    'vulnnetendgame',
    'vulnnetnode',
    'vulnnetroastedG2ek',
    'vulnversity26',
    'vulnversityey',
    'vulnversitygv',
    'vulnversityhszt',
    'vulnversityjx',
    'vulnversitypg',
    'vulnversityq9',
    'vulnversitysg',
    'vulnversityvg',
    'w1seguy',
    'w2l22bashscriptforlinux',
    'w2l23pythonbashautoproj',
    'w2l24utiloutsidescripts',
    'w2l41introtobloodhound',
    'w2l43introtostigsbasel',
    'w2l51azureintroduction',
    'w2l52manageazuread',
    'w2l53kubernetesfundamental',
    'w3b101',
    'w3l2f1introductiontoipv6',
    'wakanda',
    'walker',
    'walkinganapplication1k',
    'walkinganapplicationc1',
    'walkinganapplicationim',
    'walkinganapplicationoY',
    'walkinganapplicationrc',
    'walkinganapplicationYg',
    'walkinganapplicationzy',
    'walkinganapplicationzyto',
    'wannacry',
    'warsztatyforensicsosint',
    'warzone',
    'warzone234209',
    'warzone982739872',
    'warzoneone',
    'warzonetwo',
    'wasoc1',
    'wasoc2',
    'waybackmachine',
    'wazuhctkp',
    'wazuhctqg',
    'wazuhctxn',
    'wbactf001',
    'wbaeehexam',
    'wctf2k24',
    'wctf2k24ob',
    'weak',
    'weakcipher3n',
    'weakcipherUN',
    'weaponizationgq',
    'weaponizationo9',
    'weaponizationRV',
    'weasel',
    'web',
    'webapplicationfirewall',
    'webapplicationsecurity2',
    'webappsec101',
    'webbash',
    'webcs1880dr',
    'webdeveloper',
    'webdisassembly',
    'webenumerationv27m',
    'webfundamentals',
    'webfundamentalsdd1kof',
    'webfundamentalsdd1kofiH',
    'webfundamentalsdd1kofiHlf',
    'webfundamentalsh8',
    'webfundamentalsqj',
    'webgoat6a',
    'webofbugs',
    'webopgaver2022',
    'webosint8ckb',
    'webosint8ckbsm8usrbin2',
    'webosint8ckbsmrdj0ai',
    'webosintds',
    'webosintmq',
    'webpentest101',
    'websec101',
    'websecurityvulnerability',
    'websecuritywithvictimemachine',
    'website101',
    'webwander',
    'wecctf2024Oc',
    'weinnovatecyberdefensectf',
    'weinnovatecyberdefensectf2',
    'weinnovatecyberdefensectfday1',
    'weinnovatecyberdefensectfkz',
    'wekorra',
    'welcometohackabit',
    'welcometowifiland',
    'wellwisher',
    'wgm2022medium',
    'wgmy2022hard',
    'wgmysanitycheck',
    'wgncjacobsladdercohort',
    'whatisacomputer',
    'whatisnetworking0e',
    'whatisnetworking3c',
    'whatisnetworking4s',
    'whatisnetworking6f',
    'whatisnetworking7p',
    'whatisnetworking9j',
    'whatisnetworkingAi',
    'whatisnetworkingaj',
    'whatisnetworkingau',
    'whatisnetworkingav',
    'whatisnetworkingaw',
    'whatisnetworkingcc',
    'whatisnetworkingcphct0',
    'whatisnetworkingcphct0jr',
    'whatisnetworkingcphct0jr0G',
    'whatisnetworkingej',
    'whatisnetworkingfx',
    'whatisnetworkingJE',
    'whatisnetworkinglm',
    'whatisnetworkingmy',
    'whatisnetworkingnm',
    'whatisnetworkingof',
    'whatisnetworkingok',
    'whatisnetworkingql',
    'whatisnetworkingti',
    'whatisnetworkingtz',
    'whatisnetworkingvk',
    'whatisnetworkingWK',
    'whatisnetworkingYA',
    'whatisnuclei',
    'whatsprogramming',
    'whereishoppyat',
    'wheresmysupersuit',
    'whitebandassociates',
    'whitehatsamyak23',
    'whitehatsamyak230n',
    'whiterose',
    'whoamithecomputerghosthun',
    'wicys',
    'wifihacking101np',
    'wifihacking101zy',
    'willow',
    'win10',
    'win64assemblyqn',
    'win7',
    'winadbasics53no',
    'winadbasicsat',
    'winadbasicseg',
    'winadbasicsS2',
    'winadbasicssg',
    'winadbasicsss',
    'winadbasicsth',
    'winadbasicstp',
    'winadbasicsup',
    'winadbasicsv8',
    'winadbasicswq',
    'winadbasicsYk',
    'winadbasicsz8',
    'winadbasicszA',
    'winadbasicszi',
    'windbgornotwindbg',
    'windowctf1',
    'windowctf101',
    'windows10privesc7a',
    'windows10privesc7l',
    'windows10privesc94',
    'windows10privescej',
    'windows10privescni',
    'windowsapplications',
    'windowsartifactsayd521',
    'windowsbase',
    'windowsbase7zjd',
    'windowsbase7zjdyu',
    'windowsbasekz',
    'windowseventlogs4h',
    'windowseventlogscp',
    'windowseventlogseu',
    'windowseventlogsMr',
    'windowseventlogstt',
    'windowseventlogsu1',
    'windowseventlogsufpmq1',
    'windowseventlogsxe',
    'windowsforensics15x',
    'windowsforensics1ed',
    'windowsforensics1gk',
    'windowsforensics1mc',
    'windowsforensics1pp',
    'windowsforensics1r2',
    'windowsforensics1we',
    'windowsforensics1wen4',
    'windowsforensics2e6',
    'windowsforensics2jk',
    'windowsforensics2Sw',
    'windowsforensics2ti',
    'windowsfundamentals',
    'windowsfundamentals1xbx7n',
    'windowsfundamentals1xbxaxh5zcb9',
    'windowsfundamentals1xbxaxh5zcb9twFH',
    'windowsfundamentals1xbxaxh5zcb9twFHWh',
    'windowsfundamentals1xbxbh',
    'windowsfundamentals1xbxcc',
    'windowsfundamentals1xbxf1',
    'windowsfundamentals1xbxfd',
    'windowsfundamentals1xbxil',
    'windowsfundamentals1xbxil6q',
    'windowsfundamentals1xbxO4',
    'windowsfundamentals1xbxp6',
    'windowsfundamentals1xbxqp',
    'windowsfundamentals1xbxrr',
    'windowsfundamentals1xbxw0',
    'windowsfundamentals2x0xbl',
    'windowsfundamentals2x0xjm',
    'windowsfundamentals2x0xpa',
    'windowsfundamentals2x0xr5',
    'windowsfundamentals2x0xxq',
    'windowsfundamentals2x0xzw4rv4xyWo',
    'windowsfundamentals3xzxgw',
    'windowsfundamentals3xzxik',
    'windowsfundamentals3xzxtv',
    'windowsfundamentals3xzxui',
    'windowsfundamentals3xzxwk',
    'windowsfundamentals3xzxxv',
    'windowsfundamentals3xzxzn',
    'windowsfundamentalscastlegarde',
    'windowsnetworkanalysis',
    'windowspersistence',
    'windowspracticeroom',
    'windowspracticeroombj',
    'windowsprivesc20h6',
    'windowsprivesc20k5',
    'windowsprivesc20qy',
    'windowsprivesc20yc',
    'windowsprivescarenavlkl',
    'windowsprivescarenavlklkh',
    'windowsrce',
    'windowsreversingintrox4',
    'windowsscanning1',
    'windowsscanning13S',
    'windowsscanning1ct',
    'windowsscanning1de',
    'windowsscanning268',
    'windowsscanning268cu',
    'windowsscanning2m6',
    'windowsserver',
    'windowsserver2019cve',
    'windowsserver2019nodomain',
    'windowsserver2019withoutad',
    'windowsserver2019withoutadiU',
    'windowsserver2019withoutadiUGG',
    'windowsserverdhcpiisdns',
    'windowsserverdhcpiisdnsvb',
    'windowsserverdhcpiisdnsvbCK',
    'windowsserverdhcpiisdnsvbCKhw',
    'windowsservermgmti',
    'windowsservermgmtibp',
    'windowsservermgmtikz',
    'windowsservermgmtikzXx',
    'windowssqlserv',
    'windowsuseraccountforensics',
    'winincidentsurface',
    'winlabexe',
    'winprivesc',
    'winrarexploitthemachine',
    'wirectf',
    'wireshark0x01',
    'wireshark2k',
    'wireshark4w',
    'wireshark9fgy',
    'wiresharkaq',
    'wiresharkas',
    'wiresharkasnvqs',
    'wiresharkctfpractice',
    'wiresharkd2',
    'wiresharkda',
    'wiresharkejtl',
    'wiresharkex',
    'wiresharkfilter',
    'wiresharkfilters',
    'wiresharkg9',
    'wiresharkinspectiondhcp1l',
    'wiresharkinspectiondhcp1lyu',
    'wiresharkinspectiondhcpz9hzb1',
    'wiresharkiw',
    'wiresharkiy',
    'wiresharkiysR',
    'wiresharkJQ',
    'wiresharklm',
    'wiresharknz',
    'wiresharkoweu',
    'wiresharkoweuMI',
    'wiresharkpacketoperations9q',
    'wiresharkpacketoperationslM',
    'wiresharkpacketoperationsmq',
    'wiresharkpacketoperationsp2',
    'wiresharkpacketoperationsqkoq7Y',
    'wiresharkpacketoperationsrv',
    'wiresharkpacketoperationsUU',
    'wiresharkpacketoperationsvt',
    'wiresharkqe',
    'wiresharkqO',
    'wiresharkthebasics7u',
    'wiresharkthebasics9s',
    'wiresharkthebasicseC',
    'wiresharkthebasicseq',
    'wiresharkthebasicsgh',
    'wiresharkthebasicskh',
    'wiresharkthebasicsLY',
    'wiresharkthebasicsoakmmlqr53yk',
    'wiresharkthebasicsoakmmlqrmj',
    'wiresharkthebasicsrK',
    'wiresharkthebasicsSM',
    'wiresharkthebasicsuf',
    'wiresharkthebasicszo',
    'wiresharktrafficanalysis3C',
    'wiresharktrafficanalysis8z',
    'wiresharktrafficanalysisao',
    'wiresharktrafficanalysishp',
    'wiresharktrafficanalysisMQ',
    'wiresharktrafficanalysisoQ',
    'wiresharktrafficanalysisqk',
    'wiresharktrafficanalysisT1',
    'wiresharktrafficanalysiszn',
    'wiresharkwj',
    'wiresharkxb',
    'wiresharkzw',
    'witchorwidow',
    'wolf',
    'wonderlandeM',
    'wonderlandse',
    'wordpresscve202425600',
    'workfromhome',
    'workshopiyrfmp',
    'workstationanalysis',
    'worldcup2020ctf',
    'wpdatabaseresetplugin56',
    'wsrequestsmuggling',
    'wustwritingguidecheck',
    'wwbuddy',
    'wywmhackathonaug2021gy',
    'wywmlpe',
    'x86assemblycrashcourse02',
    'xa5drjoeth2z',
    'xa5drjoeth2zicbkjs9h',
    'xa5drjoeth2zicbkjs9hsa',
    'xa5drjoeth2zicbkjsgq',
    'xa5drjoeth2zr0qh1tqsjutv',
    'xaenomorph63dbh',
    'xfxv',
    'xmlrpc',
    'xss6c',
    'xssaF',
    'xssattack',
    'xssattack1u',
    'xssgijp',
    'xssgiqy',
    'xssgitv',
    'xssgiwm',
    'xssia',
    'xssIH',
    'xssmasterclassfromscriptk',
    'xssold',
    'xssqa',
    'xssvp',
    'xsswl',
    'xssXn',
    'xsszx',
    'xxe',
    'xyzf2',
    'yara7e',
    'yaralf',
    'yaraof',
    'yaraTP',
    'yaravl',
    'yctfweek2Mv',
    'yctfweekly',
    'yearofthedog',
    'yearofthejellyfish',
    'yearoftheowl',
    'yearofthepig',
    'yearoftherabbit5Q',
    'yougotmail',
    'yourfirstassignment',
    'yourfirstiotappliancehack',
    'yrdy',
    'yueiua',
    'zeekbroab',
    'zeekbroexercises1z',
    'zeekbroexercisesat',
    'zeekbroexercisesrj',
    'zeekbromk',
    'zeekbroo4',
    'zeekbrouu',
    'zeno',
    'zer0logonFU',
    'zerobug2k23ubjoa1o8',
    'zerobug2k240',
    'zerodayzenith',
    'zerologon',
    'zerotrustarchitecture',
    'zoroctf',
    'zphisher',
    'zsecurityangusctf',
    'zsecurityclientctf',
    'zsecuritycommentctf',
    'zsecurityctf1',
    'zsecuritycutectfij',
    'zsecurityfuelctfps',
    'zsecuritygalleryctf',
    'zsecurityvipchallenge1',
    'zthlinux',
    'zthlinux11',
    'zzen9201knock',
  ],
  [FeatureFlagName.ROOMS_REBUILD_WAVE_2]: [
    '0day',
    '25daysofchristmas',
    '4th3n4',
    'activedirectorybasics',
    'adcertificatetemplates',
    'adv3nt0fdbopsjcap',
    'adventofcyber2',
    'adventofcyber2023',
    'adventofcyber23sidequest',
    'adventofcyber3',
    'adventofcyber4',
    'agentsudoctf',
    'agentt',
    'allinonemj',
    'androidhacking101',
    'anonymous',
    'anthem',
    'archangel',
    'armageddon2r',
    'attackingics1',
    'avenger',
    'bandit',
    'bashscripting',
    'becomeahackeroa',
    'beginnerpathintro',
    'biohazard',
    'blaster',
    'blog',
    'blueprint',
    'bof1',
    'boilerctf2',
    'bolt',
    'bookstoreoc',
    'bppenguin',
    'breakoutthecage1',
    'breakrsa',
    'brooklynninenine',
    'bruteforceheroes',
    'bruteit',
    'bsidesgtanonforce',
    'bsidesgtlibrary',
    'bsidesgtthompson',
    'busyvimfrosteau',
    'bypassdisablefunctions',
    'c2carnage',
    'c4ptur3th3fl4g',
    'cactus',
    'capture',
    'caseb4dm755',
    'catregex',
    'cauldron',
    'chillhack',
    'chocolatefactory',
    'chrome',
    'cmess',
    'colddboxeasy',
    'commonattacks',
    'compiled',
    'confluence202322515',
    'convertmyvideo',
    'corridor',
    'cowboyhacker',
    'crackthehash',
    'crackthehashlevel2',
    'cryptographyfordummies',
    'ctf',
    'ctfcollectionvol1',
    'cve202338408',
    'cyberheroes',
    'cyborgt8',
    'dirtypipe',
    'disgruntled',
    'dogcat',
    'dreaming',
    'dvwa',
    'easyctf',
    'easypeasyctf',
    'eviction',
    'expose',
    'ffuf',
    'filepathtraversal',
    'forensics',
    'gallery666',
    'gamingserver',
    'geolocatingimages',
    'gettingstarted',
    'githappens',
    'gitlabcve20237028',
    'glitch',
    'goldeneye',
    'googledorking',
    'greprtp',
    'h4cked',
    'hackermethodology',
    'hackernote',
    'hello',
    'hijack',
    'hololive',
    'howtousetryhackme',
    'http2requestsmuggling',
    'httprequestsmuggling',
    'ice',
    'ide',
    'ignite',
    'intermediatenmap',
    'investigatingwindows2',
    'iotintro',
    'iso27001',
    'javascriptbasics',
    'jokerctf',
    'juicydetails',
    'kali',
    'kiba',
    'kitty',
    'layer2',
    'lazyadmin',
    'learncyberin25days',
    'learnowaspzap',
    'lessonlearned',
    'lianyu',
    'linuxagency',
    'linuxbackdoors',
    'linuxmodules',
    'linuxprivescarena',
    'linuxserverforensics',
    'linuxstrengthtraining',
    'loguniverse',
    'lookingglass',
    'looneytunes',
    'madness',
    'malresearching',
    'marketplace',
    'memoryforensics',
    'mma',
    'monikerlink',
    'mustacchio',
    'nahamstore',
    'nax',
    'neighbour',
    'ninjaskills',
    'ohmyweb',
    'ohsint',
    'opacity',
    'openvpn',
    'outlookntlmleak',
    'overlayfs',
    'overpass',
    'overpass3hosting',
    'owaspbrokenaccesscontrol',
    'owaspmutillidae',
    'owasptop10',
    'physicalsecurityintro',
    'pokemon',
    'posheclipse',
    'poster',
    'postexploit',
    'powershellforpentesters',
    'printerhacking101',
    'probe',
    'ra',
    'redteamcapstonechallenge',
    'registrypersistencedetection',
    'res',
    'resetui',
    'reverselfiles',
    'road',
    'rptmux',
    'rrootme',
    'rustscan',
    'sakura',
    'searchlightosint',
    'securityawarenessintro',
    'servidae',
    'shodan',
    'smaggrotto',
    'snappedphishingline',
    'solar',
    'somesint',
    'source',
    'sqhell',
    'sqlilab',
    'sqlmap',
    'ssrfhr',
    'startup',
    'stealth',
    'sudovulnsbof',
    'sudovulnsbypass',
    'supersecrettip',
    'surfer',
    'surfingyetiiscomingtotown',
    'takeover',
    'teamcw',
    'techsupp0rt1',
    'thecodcaper',
    'tickets1',
    'tickets2',
    'tickets4',
    'tomghost',
    'toolboxvim',
    'toolsrus',
    'torforbeginners',
    'tshark',
    'ultratech1',
    'umbrella',
    'valleype',
    'vulnnetinternal',
    'vulnnetroasted',
    'watcher',
    'webenumerationv2',
    'webgoat',
    'webosint',
    'welcome',
    'wgelctf',
    'whyhackme',
    'wifihacking101',
    'win64assembly',
    'windows10privesc',
    'windowsprivescarena',
    'windowsreversingintro',
    'wonderland',
    'wordpresscve202129447',
    'wreath',
    'yearoftherabbit',
    'yotf',
  ],
  [FeatureFlagName.ROOMS_REBUILD_WAVE_3]: [
    'activedirectoryhardening',
    'adenumeration',
    'advanceddynamicanalysis',
    'advancedelkqueries',
    'advancedstaticanalysis',
    'alfred',
    'amazonec2attackdefense',
    'amazonec2dataexfiltration',
    'antireverseengineering',
    'atomicbirdone',
    'atomicbirdtwo',
    'atomicredteam',
    'attackingdefendingvpcs',
    'attackingkerberos',
    'attacktivedirectory',
    'auditingandmonitoringse',
    'auroraedr',
    'autopsy2ze0',
    'awsapigateway',
    'awsbasicconcepts',
    'awsiamenumeration',
    'awsiaminitialaccess',
    'awslambda',
    'awss3service',
    'awsvpcdataexfiltration',
    'basicdynamicanalysis',
    'basicmalwarere',
    'becomingafirstresponder',
    'blue',
    'brainpan',
    'brainstorm',
    'breachingad',
    'btautopsye0',
    'btredlinejoxr3d',
    'btsysinternalssg',
    'btwindowsinternals',
    'bufferoverflowprep',
    'caldera',
    'cloud101aws',
    'codeanalysis',
    'corp',
    'credharvesting',
    'cryptographyintro',
    'customalertrulesinwazuh',
    'cybercrisismanagement',
    'cybergovernanceregulation',
    'dailybugle',
    'dastzap',
    'dissectingpeheaders',
    'eradicationandremediation',
    'exploitingad',
    'fixit',
    'gamezone',
    'gatekeeper',
    'hackpark',
    'historyofmalware',
    'iaaaidm',
    'iamcredentials',
    'iampermissions',
    'iamprincipals',
    'identificationandscoping',
    'intelcreationandcontainment',
    'internal',
    'introductiontoawsiam',
    'introductiontocloudsecurityc6',
    'introductiontodevsecops',
    'introductiontothreathunting',
    'introtodetectionengineering',
    'introtoirandim',
    'introtologanalysis',
    'introtologs',
    'introtonetworking',
    'introtosecurityarchitecture',
    'investigatingwindows',
    'kenobi',
    'lambdadataexfiltration',
    'lateralmovementandpivoting',
    'lessonslearned',
    'linuxsystemhardening',
    'loggingforaccountability',
    'logoperations',
    'logstash',
    'malbuster',
    'maldoc',
    'malmalintroductory',
    'malremnuxv2',
    'malstrings',
    'microsoftwindowshardening',
    'misp',
    'mitre',
    'mrrobot',
    'networkdevicehardening',
    'networksecurityprotocols',
    'networkservices',
    'networkservices2',
    'openvas',
    'osqueryf8',
    'overpass2hacked',
    'owaspapisecuritytop105w',
    'owaspapisecuritytop10d0',
    'owasptop102021',
    'paymentcollectors',
    'persistingad',
    'powershell',
    'preparation',
    'relevant',
    'resourcepoliciesscps',
    'retro',
    'rpnessusredux',
    'sast',
    'securesdlc',
    'securityengineerintro',
    'securityprinciples',
    'seriskmanagement',
    'sighunt',
    'sigma',
    'skynet',
    'slingshot',
    'soar',
    'splunk101',
    'splunk2gcd5',
    'splunkdashboardsandreports',
    'splunkdatamanipulation',
    'splunkexploringspl',
    'splunklab',
    'staticanalysis1',
    'steelmountain',
    'stscredentialslab',
    'sysmon',
    'tacticaldetection',
    'tardigrade',
    'thequestforleastprivilege',
    'threatemulationintro',
    'threathuntingendgame',
    'threathuntingfoothold',
    'threathuntingpivoting',
    'threatintelligenceforsoc',
    'threatmodelling',
    'threatmodelling',
    'traverse',
    'tutorial',
    'tutorial',
    'typosquatters',
    'virtualizationandcontainers',
    'volatility',
    'vulnerabilitymanagementkj',
    'vulnversity',
    'weaponizingvulnerabilities',
    'winadbasics',
    'winadbasics',
    'windowseventlogs',
    'windowsforensics1',
    'windowsforensics2',
    'windowsfundamentals1xbx',
    'windowsinternals',
    'wireshark',
    'x8664arch',
    'x86assemblycrashcourse',
    'yara',
    'zer0logon',
  ],
  [FeatureFlagName.ROOMS_REBUILD_WAVE_4]: [
    'activerecon',
    'authenticationbypass',
    'basicpentestingjt',
    'benign',
    'blue',
    'breachingad',
    'brim',
    'btautopsye0',
    'btredlinejoxr3d',
    'btsysinternalssg',
    'btwindowsinternals',
    'burpsuitebasics',
    'burpsuitebasics',
    'burpsuitebasicsold',
    'burpsuiteextensions',
    'burpsuiteintruder',
    'burpsuiteom',
    'burpsuiterepeater',
    'commonlinuxprivesc',
    'contentdiscovery',
    'credharvesting',
    'cybergovernanceregulation',
    'cyberkillchainzmt',
    'cyberthreatintel',
    'diamondmodelrmuwwg42',
    'dnsindetail',
    'encryptioncrypto101',
    'fileinc',
    'furthernmap',
    'hashingcrypto101',
    'howwebsiteswork',
    'howwebsiteswork',
    'httpindetail',
    'httpindetail',
    'httpindetail',
    'hydra',
    'idor',
    'introductoryroomdfirmodule',
    'intromalwareanalysis',
    'introtoendpointsecurity',
    'introtonetworking',
    'introtoresearch',
    'introtoshells',
    'introtosiem',
    'investigatingwithelk101',
    'investigatingwithsplunk',
    'itsybitsy',
    'johntheripper0',
    'jrsecanalystintrouxo',
    'kape',
    'kenobi',
    'lateralmovementandpivoting',
    'linprivesc',
    'linuxforensics',
    'linuxfundamentalspart1',
    'linuxfundamentalspart2',
    'linuxfundamentalspart3',
    'linuxprivesc',
    'metasploitexploitation',
    'metasploitintro',
    'metasploitintro',
    'meterpreter',
    'misp',
    'mitre',
    'networkminer',
    'networkservices',
    'networkservices2',
    'nmap01',
    'nmap02',
    'nmap03',
    'opencti',
    'oscommandinjection',
    'osqueryf8',
    'owaspjuiceshop',
    'owaspjuiceshop',
    'owaspjuiceshop',
    'owasptop102021',
    'owasptop102021',
    'owasptop102021',
    'passiverecon',
    'pentestingfundamentals',
    'persistingad',
    'phishingemails1tryoe',
    'phishingemails2rytmuv',
    'phishingemails3tryoe',
    'phishingemails4gkxh',
    'phishingemails5fgjlzxc',
    'phishingyl',
    'picklerick',
    'picklerick',
    'puttingitalltogether',
    'pyramidofpainax',
    'pythonbasics',
    'pythonforcybersecurity',
    'redteamengagements',
    'snort',
    'snortchallenges1',
    'snortchallenges2',
    'splunk101',
    'splunk201',
    'sqlinjectionlm',
    'ssrfqi',
    'startingoutincybersec',
    'steelmountain',
    'subdomainenumeration',
    'sysmon',
    'thehiveproject',
    'threatinteltools',
    'trafficanalysisessentials',
    'tutorial',
    'unifiedkillchain',
    'uploadvulns',
    'uploadvulns',
    'velociraptorhp',
    'volatility',
    'vulnversity',
    'walkinganapplication',
    'wazuhct',
    'winadbasics',
    'winadbasics',
    'windowseventlogs',
    'windowsforensics1',
    'windowsforensics2',
    'windowsfundamentals1xbx',
    'windowsfundamentals2x0x',
    'windowslocalpersistence',
    'windowsprivesc20',
    'wiresharkpacketoperations',
    'wiresharkthebasics',
    'wiresharkthebasics',
    'wiresharktrafficanalysis',
    'xss',
    'yara',
    'zeekbro',
    'zeekbroexercises',
  ],
  [FeatureFlagName.ROOMS_REBUILD_WAVE_5]: [
    'abusingwindowsinternals',
    'activerecon',
    'adenumeration',
    'authenticationbypass',
    'avevasionshellcode',
    'breachingad',
    'burpsuitebasics',
    'burpsuiteextensions',
    'burpsuiteintruder',
    'burpsuiteom',
    'burpsuiterepeater',
    'bypassinguac',
    'careersincyber',
    'careersincyber',
    'careersincyber',
    'contentdiscovery',
    'credharvesting',
    'dataxexfilt',
    'defensivesecurity',
    'defensivesecurity',
    'defensivesecurity',
    'dnsindetail',
    'enumerationpe',
    'exploitingad',
    'exploitingavulnerabilityv2',
    'extendingyournetwork',
    'fileinc',
    'howwebsiteswork',
    'httpindetail',
    'idor',
    'introdigitalforensics',
    'intronetworksecurity',
    'introtoav',
    'introtoc2',
    'introtolan',
    'introtooffensivesecurity',
    'introtooffensivesecurity',
    'introtooffensivesecurity',
    'introtoshells',
    'introwebapplicationsecurity',
    'lateralmovementandpivoting',
    'linprivesc',
    'linuxfundamentalspart1',
    'linuxfundamentalspart2',
    'linuxfundamentalspart3',
    'livingofftheland',
    'metasploitexploitation',
    'metasploitintro',
    'meterpreter',
    'monitoringevasion',
    'netsecchallenge',
    'nmap01',
    'nmap02',
    'nmap03',
    'nmap04',
    'obfuscationprinciples',
    'operatingsystemsecurity',
    'opsec',
    'oscommandinjection',
    'osimodelzi',
    'packetsframes',
    'passiverecon',
    'passwordattacks',
    'pentestingfundamentals',
    'persistingad',
    'phishingyl',
    'principlesofsecurity',
    'protocolsandservers',
    'protocolsandservers2',
    'puttingitalltogether',
    'redteamengagements',
    'redteamfirewalls',
    'redteamfundamentals',
    'redteamnetsec',
    'redteamrecon',
    'redteamthreatintel',
    'runtimedetectionevasion',
    'sandboxevasion',
    'securityoperations',
    'signatureevasion',
    'sqlinjectionlm',
    'ssrfqi',
    'subdomainenumeration',
    'thelayoftheland',
    'vulnerabilities101',
    'vulnerabilitycapstone',
    'walkinganapplication',
    'weaponization',
    'whatisnetworking',
    'winadbasics',
    'windowsapi',
    'windowsfundamentals1xbx',
    'windowsfundamentals2x0x',
    'windowsfundamentals3xzx',
    'windowsinternals',
    'windowslocalpersistence',
    'windowsprivesc20',
    'windowsprivesc20',
    'xss',
  ],
};
