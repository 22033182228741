import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledMachineBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  border: 0.1rem solid ${({ theme }) => theme.colors.secondary.lighter};
  border-radius: ${({ theme }) => theme.radius.small};
  color: ${({ theme }) => theme.colors.grey[100]};
  display: grid;
  justify-content: space-between;
  gap: 3rem;
  grid-template-columns: 3.4rem 1fr auto;
  padding: 1.8rem;
  text-decoration: none;

  &:first-of-type {
    margin-bottom: 1.6rem;
  }

  p:last-of-type {
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
  }

  &[data-box='attack-box'] {
    button:hover:not(:disabled) {
      color: white;
    }
  }
`;

export const StyledButtonBoxIcon = styled(FontAwesomeIcon)`
  font-size: 3.4rem;

  path {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const StyledButtonBoxTitle = styled.p`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;
