import { faLocationDotSlash } from '@fortawesome/pro-solid-svg-icons';
import { Button, Modal, ModalDescription, ModalFooter, ModalHeader, ModalProps, ModalTitle } from '@tryhackme/thm-ui-components';
import { useAppDispatch } from 'src/app/hooks';
import { setIsRestrictedServiceModalOpen } from 'src/app/reducers/modal-states.reducer';
import { StyledFontAwesomeIcon } from './restricted-service-modal.styles';
export const RestrictedServiceModal = ({
  onOpenChange,
  ...props
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const setRSMOpen = (state: boolean) => dispatch(setIsRestrictedServiceModalOpen(state));
  const handleModalOpenStateChange = (state: boolean) => {
    setRSMOpen(state);
    onOpenChange?.(state);
  };
  return <Modal isSmall onOpenChange={handleModalOpenStateChange} {...props} data-sentry-element="Modal" data-sentry-component="RestrictedServiceModal" data-sentry-source-file="restricted-service-modal.tsx">
      <ModalHeader isSmall data-sentry-element="ModalHeader" data-sentry-source-file="restricted-service-modal.tsx">
        <StyledFontAwesomeIcon icon={faLocationDotSlash} data-sentry-element="StyledFontAwesomeIcon" data-sentry-source-file="restricted-service-modal.tsx" />
        <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="restricted-service-modal.tsx">Can&apos;t access this service</ModalTitle>
      </ModalHeader>
      <ModalDescription isSmall data-sentry-element="ModalDescription" data-sentry-source-file="restricted-service-modal.tsx">
        Whoops! Looks like the service you&apos;re trying to access isn&apos;t available in your region. Don&apos;t
        worry though, you can still enjoy many amazing features on TryHackMe for free. Keep exploring and have fun!
      </ModalDescription>
      <ModalFooter isSmall data-sentry-element="ModalFooter" data-sentry-source-file="restricted-service-modal.tsx">
        <Button color="secondary" variant="outlined" onClick={() => handleModalOpenStateChange(false)} data-sentry-element="Button" data-sentry-source-file="restricted-service-modal.tsx">
          OK
        </Button>
      </ModalFooter>
    </Modal>;
};