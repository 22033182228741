import React from 'react';
import { faAward, faDoorClosed, faFire, faTrophyStar, faCrosshairs } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip, useViewport } from '@tryhackme/thm-ui-components';
import { faCrosshairs as faCrosshairsDuo, faTrophyStar as faTrophyStarDuo, faAward as faAwardDuo } from '@fortawesome/pro-duotone-svg-icons';
import { ArrowPosition } from '@tryhackme/thm-ui-components/dist/components/atoms/tooltip/tooltip.types';
import { useTooltip } from 'src/features/dashboard/hooks';
import { RecordItemProps } from '../../stats.types';
import { StyledRecordItemLoading, StyledStreakIcon, StyledRecordIcon, StyledRecordItem, StyledRoomCompletedIcon, StyledTopPercentTag, StyledTooltipWrapper, StyledWrapperRecordItem } from './record-item.styles';
export const RecordItem = ({
  type,
  hover,
  onEnter,
  onLeave,
  record,
  isLoading,
  tooltipHighlightText,
  tooltipMainText,
  tooltipDescription
}: RecordItemProps) => {
  const {
    handleTouchStart,
    tooltipRef
  } = useTooltip();
  const {
    width
  } = useViewport();
  const getIcon = () => {
    switch (type) {
      case 'streak':
        {
          return hover ? <StyledStreakIcon /> : <StyledRecordIcon icon={faFire} />;
        }
      case 'rank':
        {
          return hover ? <StyledRecordIcon icon={faTrophyStarDuo} /> : <StyledRecordIcon icon={faTrophyStar} />;
        }
      case 'points':
        {
          return hover ? <StyledRecordIcon icon={faCrosshairsDuo} /> : <StyledRecordIcon icon={faCrosshairs} />;
        }
      case 'completedRooms':
        {
          return hover ? <StyledRoomCompletedIcon /> : <StyledRecordIcon icon={faDoorClosed} />;
        }
      case 'badges':
        {
          return hover ? <StyledRecordIcon icon={faAwardDuo} /> : <StyledRecordIcon icon={faAward} />;
        }
      default:
        {
          return null;
        }
    }
  };
  const determineRankDisplay = (recordType: string, recordNumber: number) => {
    if (recordType === 'rank') {
      return recordNumber < 1 ? <p>Unranked</p> : <p>{recordNumber}</p>;
    }
    return <p data-sentry-component="determineRankDisplay" data-sentry-source-file="record-item.tsx">{recordNumber}</p>;
  };
  const getRankText = (rank: number) => {
    switch (true) {
      case rank < 1:
        {
          return <>
            <p data-text="main">
              <span>0/100 </span>points gained
            </p>
            <p data-text="description">Earn 100 points first</p>
          </>;
        }
      case rank === 1:
        {
          return <>
            <div data-rank="1">
              <p data-text="main">
                Rank: <span>{rank}</span>
              </p>
              <span>top 1%</span>
            </div>
            <p data-text="description">
              Complete more rooms
              <br />
              to gain a higher rank
            </p>
          </>;
        }
      default:
        {
          return <>
            <p data-text="main">
              Rank: <span>{rank}</span>
            </p>
            <p data-text="description">
              Complete more rooms
              <br />
              to gain a higher rank
            </p>
          </>;
        }
    }
  };
  const isTopRank = type === 'rank' && record === 1;
  let arrowPosition;
  if (type === 'badges' && width <= 1024) {
    arrowPosition = 'top-right';
  } else if (width <= 1024) {
    arrowPosition = 'top-left';
  } else {
    arrowPosition = 'right';
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? <StyledRecordItemLoading height="8rem" width="inherit" /> : <StyledWrapperRecordItem onTouchStart={handleTouchStart}>
          <StyledRecordItem data-type={type} onMouseEnter={onEnter} onMouseLeave={onLeave} data-testid={`record-item-${type}`} $isTopRank={isTopRank} data-hover={hover}>
            {getIcon()}
            {determineRankDisplay(type, record)}
            {isTopRank && <StyledTopPercentTag>top 1%</StyledTopPercentTag>}
          </StyledRecordItem>
          {width >= 768 && <StyledTooltipWrapper data-section="records-tooltip" ref={tooltipRef}>
              <Tooltip arrowPosition={(arrowPosition as ArrowPosition)}>
                {type === 'rank' ? getRankText(record) : <>
                    <p data-text="main">
                      <span>{tooltipHighlightText}</span> {tooltipMainText}
                    </p>
                    <p data-text="description">
                      {tooltipDescription.split('<br />').map((line, index) =>
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>)}
                    </p>
                  </>}
              </Tooltip>
            </StyledTooltipWrapper>}
        </StyledWrapperRecordItem>}
    </>
  );
};