import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalTitle } from '@tryhackme/thm-ui-components';

export const StyledModalIcon = styled(FontAwesomeIcon)`
  height: 2.4rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.sm}) {
    height: 2.1rem;
  }

  &[data-icon='error'] {
    color: ${({ theme }) => theme.colors.error.main};
  }

  &[data-icon='warning'] {
    color: ${({ theme }) => theme.colors.warning.main};
  }
`;

export const StyledModalTitle = styled(ModalTitle)`
  line-height: 3.5rem;
`;
