import { useState, KeyboardEvent } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpAZ } from '@fortawesome/pro-regular-svg-icons';
import { TextField, ValidationList, ProgressBar } from '@tryhackme/thm-ui-components';
import { useInputFocus } from 'src/common/hooks/use-input-focus';
import { MAX_PASSWORD_SCORE } from 'src/common/validations';
import { getPasswordValidationAreaData } from './password-input.helpers';
import { StyledShowHidePasswordButton, StyledFontAwesomeIcon, StyledProgressBarMessage, StyledValidationArea } from './password-input.styles';
import { PasswordInputProps } from './password-input.types';
export const PasswordInput = ({
  showProgressAndValidation = false,
  errorMessage,
  value,
  ...rest
}: PasswordInputProps) => {
  const {
    isInputFocused,
    handleInputFocus,
    handleInputBlur
  } = useInputFocus();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEnteringCaps, setIsEnteringCaps] = useState(false);
  const validationAreaData = getPasswordValidationAreaData(value || '');
  const checkForCaps = (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.getModifierState('CapsLock') || event.shiftKey) && !isEnteringCaps) {
      setIsEnteringCaps(true);
    } else if (isEnteringCaps) {
      setIsEnteringCaps(false);
    }
  };
  return <>
      <TextField {...rest} variant="input" type={isPasswordVisible ? 'text' : 'password'} isError={!!errorMessage} errorMessage={errorMessage} onFocus={handleInputFocus} onBlur={handleInputBlur} onKeyDown={checkForCaps} onKeyUp={checkForCaps} rightAdornment={<>
            {isEnteringCaps && <StyledFontAwesomeIcon icon={faArrowUpAZ} title="Caps Lock is On" />}
            <StyledShowHidePasswordButton type="button" onClick={() => {
        setIsPasswordVisible(prevIsPasswordVisible => !prevIsPasswordVisible);
      }} aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}>
              <StyledFontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
            </StyledShowHidePasswordButton>
          </>} data-sentry-element="TextField" data-sentry-source-file="password-input.tsx" />
      <StyledValidationArea isVisible={showProgressAndValidation && isInputFocused} data-sentry-element="StyledValidationArea" data-sentry-source-file="password-input.tsx">
        <ProgressBar title="Password strength" max={MAX_PASSWORD_SCORE} value={validationAreaData.progressBarValue} data-sentry-element="ProgressBar" data-sentry-source-file="password-input.tsx" />
        <StyledProgressBarMessage data-sentry-element="StyledProgressBarMessage" data-sentry-source-file="password-input.tsx">{validationAreaData.message}</StyledProgressBarMessage>
        <ValidationList validationStates={validationAreaData.validationStates} isTiny data-sentry-element="ValidationList" data-sentry-source-file="password-input.tsx" />
      </StyledValidationArea>
    </>;
};