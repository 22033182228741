import { useContext } from 'react';
import { SwitchThemeContext } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { ThemeColorMode } from 'src/common/enums';
import { ReactComponent as EmptyStateImage } from 'src/images/svgs/dashboard/empty-state.svg';
import { ReactComponent as EmptyStateImageDM } from 'src/images/svgs/dashboard/empty-state-dm.svg';
import { StyledEmptyState, StyledPathsButton } from './empty-state.styles';
export const EmptyState = ({
  valueTab
}: {
  valueTab: string;
}) => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkMode = theme === ThemeColorMode.DARK;
  return <StyledEmptyState data-sentry-element="StyledEmptyState" data-sentry-component="EmptyState" data-sentry-source-file="empty-state.tsx">
      {valueTab === 'current' ? <>
          <p data-text="title">You have not joined any learning paths</p>
          <p data-text="description">You can join learning paths on the “Learn” page</p>
          <StyledPathsButton variant="solid" color="primary" as={Link} to="/r/hacktivities">
            Go to learning paths
          </StyledPathsButton>
        </> : <>
          {isDarkMode ? <EmptyStateImageDM /> : <EmptyStateImage />}
          <p data-text="title">{`You have no ${valueTab} rooms`}</p>
          <p data-text="description">
            {valueTab === 'recent' ? 'When you join rooms your recently joined rooms will show here' : 'You can save rooms from the option panel shown in the header of each room.'}
          </p>
        </>}
    </StyledEmptyState>;
};