import { useState } from 'react';
import { useViewport } from '@tryhackme/thm-ui-components';

import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { useGetExperimentGroupsQuery } from 'src/common/slices/experiments';
import { ExperimentNames } from 'src/common/constants';
import { useGetUserQuery } from 'src/common/slices';

const HOST_ROADMAP_DEDICATED_PAGE_START_DATE = new Date('2024-10-28');
const HOST_ROADMAP_DEDICATED_PAGE_END_DATE = new Date('2024-11-14');

export const useRoadmapHook = () => {
  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>();

  const { width } = useViewport();
  const isMobile = width < BREAKPOINTS.MOBILE;

  const arrowStyle = {
    style: {
      endShape: {
        arrow: { arrowLength: 6.5, arrowThickness: 6.5 },
      },
    },
  };

  return { isMobile, arrowStyle, selectedOption, setSelectedOption };
};

export const useHostRoadmapsAsSeparatePageExperiment = () => {
  const { data: user } = useGetUserQuery();
  const dateSignUp = user?.data?.user?.dateSignUp;

  const skipExperimentEndpointCall =
    !dateSignUp ||
    new Date(dateSignUp) < HOST_ROADMAP_DEDICATED_PAGE_START_DATE ||
    new Date(dateSignUp) > HOST_ROADMAP_DEDICATED_PAGE_END_DATE;

  const { data: experimentData } = useGetExperimentGroupsQuery([ExperimentNames.HOST_ROADMAP_DEDICATED_PAGE], {
    skip: skipExperimentEndpointCall,
  });

  const isExperimentEnabled = experimentData?.data?.hostRoadmapDedicatedPage === 'treatment';

  return {
    isHostRoadmapsAsSeparatePageExperimentEnabled: isExperimentEnabled,
  };
};
