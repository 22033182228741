export const AUTHENTICATION_CODE_ERROR_MESSAGE = 'Uh-oh! That authentication code is not valid.';

export const AUTHENTICATION_CODE_REQUIRED_MESSAGE = 'Please enter your authentication code.';

export const PASSWORD_ERROR_MESSAGE = 'Uh-oh! That password is incorrect.';

export const GENERIC_SERVER_ERROR_MESSAGE = 'Uh-oh! Something went wrong.';

export const COPY_BUTTON_LABEL = 'Copy';

export const COPIED_BUTTON_LABEL = 'Copied!';
