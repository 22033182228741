import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CertificationSectionType } from 'src/common/enums/certification';
import { CertificationState, CertificateSectionState } from 'src/common/interfaces/certifications';

interface IsLoading {
  isLoading: true;
}

export interface CertificationStore {
  status: CertificationState | IsLoading;
  sectionStatus: CertificateSectionState | IsLoading;
}

const initialState: CertificationStore = {
  status: { isLoading: true },
  sectionStatus: { isLoading: true },
};

const certificationStatusSlice = createSlice({
  name: 'certificationExamStatus',
  initialState,
  reducers: {
    setCertificationStatus: (state, { payload }: PayloadAction<CertificationState>) => ({ ...state, status: payload }),
    setCertificationSectionStatus: (state, { payload }: PayloadAction<CertificateSectionState>) => ({
      ...state,
      sectionStatus: payload,
    }),
    setCertificationMultipleChoiceAnswers: (state, { payload }: PayloadAction<number>) => {
      if (state.sectionStatus.isLoading) {
        return state;
      }

      if (state.sectionStatus.sectionType !== CertificationSectionType.MULTIPLE_CHOICE) {
        return state;
      }

      return { ...state, sectionStatus: { ...state.sectionStatus, selectedAnswerOrderNumber: payload } };
    },
  },
});

export const { setCertificationStatus, setCertificationSectionStatus, setCertificationMultipleChoiceAnswers } =
  certificationStatusSlice.actions;

const certificationReducer = certificationStatusSlice.reducer;

export { certificationReducer };
