import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledResultsSection = styled.div`
  margin: 2.4rem 0 0;
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 1.8rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledItemList = styled.div`
  margin-top: 1.6rem;
  padding: 0;
`;

export const StyledNotFound = styled.p`
  color: ${({ theme }) => theme.colors.paragraph};
  font-size: 1.8rem;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.info.main};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fonts.sizes.default};

  &:focus {
    border-radius: ${({ theme }) => theme.radius.small};
    border: 1px solid ${({ theme }) => theme.colors.info.main};
    outline: none;
  }
`;
