import { css } from 'styled-components';

export const StyledWrapperTooltipDefault = css`
  position: absolute;
  left: -1rem;
  transform: translate(-100%, -50%);
  width: max-content;
  z-index: 1;
  text-align: center;
  display: none;
  top: 50%;
`;
