const levelToRankTitleMap = {
  1: '[0x1]',
  2: '[0x2]',
  3: '[0x3]',
  4: '[0x4]',
  5: '[0x5]',
  6: '[0x6]',
  7: '[0x7]',
  8: '[0x8][HACKER]',
  9: '[0x9][OMNI]',
  10: '[0xA][WIZARD]',
  11: '[0xB][MASTER]',
  12: '[0xC][GURU]',
  13: '[0xD][GOD]',
  997: '[STAFF]',
  998: '[CONTRIBUTOR]',
  999: '[BUG HUNTER]',
  1337: '[CYBER CRUSADER]',
} as const;

export const isValidRank = (level: number): level is keyof typeof levelToRankTitleMap => level in levelToRankTitleMap;

export const getRankTitle = (level: number) => (isValidRank(level) ? levelToRankTitleMap[level] : '');

export const levelsAndPoints = {
  '0x1': '0',
  '0x2': '200',
  '0x3': '500',
  '0x4': '1,000',
  '0x5': '1,500',
  '0x6': '2,000',
  '0x7': '3,000',
};

export const levelsAndPointsWithTitle = {
  '0x8 [Hacker]': '4,000',
  '0x9 [Omni]': '8,000',
  '0xA [Wizard]': '12,000',
  '0xB [Master]': '15,000',
  '0xC [Guru]': '17,000',
  '0xD [God]': '20,000',
};

export const pointWithTitles: { [key: number]: { rank: string; level: number } } = {
  0: { rank: '[0x1]', level: 1 },
  200: { rank: '[0x2]', level: 2 },
  500: { rank: '[0x3]', level: 3 },
  1000: { rank: '[0x4]', level: 4 },
  1500: { rank: '[0x5]', level: 5 },
  2000: { rank: '[0x6]', level: 6 },
  3000: { rank: '[0x7]', level: 7 },
  4000: { rank: '[0x8][HACKER]', level: 8 },
  8000: { rank: '[0x9][OMNI]', level: 9 },
  12_000: { rank: '[0xA][WIZARD]', level: 10 },
  15_000: { rank: '[0xB][MASTER]', level: 11 },
  17_000: { rank: '[0xC][GURU]', level: 12 },
  20_000: { rank: '[0xD][GOD]', level: 13 },
};
