import { NotificationDispatch, StyledNotificationContent, useViewport } from '@tryhackme/thm-ui-components';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { setSplitScreenOpen } from 'src/app/reducers/split-screen.reducer';
import { ExperimentNames } from 'src/common/constants';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { hasErrorPostData } from 'src/common/helpers/slices';
import { RunningInstance } from 'src/common/interfaces/instances';
import { useGetUserQuery } from 'src/common/slices';
import { useGetExperimentGroupsQuery } from 'src/common/slices/experiments';
import { useGetSearchProgressQuery } from 'src/features/hacktivities/search/content/content.slice';
export const useShouldSeeStartVmExpand = (roomCode: string, activeAccordion: number | null) => {
  const {
    width
  } = useViewport();
  const {
    data: searchProgress
  } = useGetSearchProgressQuery({
    roomCodes: roomCode
  });
  if (!activeAccordion || width < BREAKPOINTS.MOBILE) return false;
  if (activeAccordion !== 2 || roomCode !== 'offensivesecurityintro') {
    return false;
  }
  const progressPercentage = searchProgress?.data?.roomProgress?.[0]?.progressPercentage;
  // 20% percent completion is the first question
  if (typeof progressPercentage !== 'number' || progressPercentage > 20) return false;
  return true;
};
const messageStarting = 'Starting your machine… please wait!';
const messageStarted = 'Hooray! Your machine has started. It may need a few minutes to become accessible.';
const LOAD_VM_BACKGROUND_IOS_V2_START_DATE = new Date('2024-11-4');
export const useShouldSeeLoadVmBackgroundIOSV2Experiment = (roomCode: string) => {
  const {
    width
  } = useViewport();
  const isTreatmentGroupForLoadVMInBackground = useIsUserTreatmentGroupForLoadVmBackgroundIOSV2Experiment();
  const isVmTaskComplete = useAppSelector(state => state.room.taskStates[1]?.every(task => task === true));
  if (!isTreatmentGroupForLoadVMInBackground) return false;
  if (isVmTaskComplete) return false;
  if (width < BREAKPOINTS.MOBILE) return false;
  if (roomCode !== 'offensivesecurityintro') return false;
  return true;
};
export const useIsUserTreatmentGroupForLoadVmBackgroundIOSV2Experiment = () => {
  const {
    data: userData
  } = useGetUserQuery();
  const userSignupDate = userData?.data?.user?.dateSignUp;
  const isUserEligableForExperiment = userSignupDate ? new Date(userSignupDate) >= new Date(LOAD_VM_BACKGROUND_IOS_V2_START_DATE) || process.env.REACT_APP_ENVIRONMENT !== 'production' : false;
  const {
    data: experimentData
  } = useGetExperimentGroupsQuery([ExperimentNames.LOAD_VM_BACKGROUND_IOS_V2], {
    skip: !isUserEligableForExperiment
  });
  return experimentData?.data?.loadVmBackgroundIOSV2 === 'treatment';
};
export const useHandleMachineNotificationsAndSplitScreen = () => {
  const dispatch = useAppDispatch();
  const handleNotificationsAndSplitScreen = (activeMachine: RunningInstance) => {
    NotificationDispatch('warning', messageStarting);
    if (activeMachine?.remote?.remoteUrl) {
      NotificationDispatch('success', messageStarted);
      dispatch(setSplitScreenOpen(true));
      return;
    }
    let answerMessage;
    if (hasErrorPostData(activeMachine)) {
      answerMessage = activeMachine.error?.data?.message;
      if (answerMessage.toLowerCase().includes('subscribe')) {
        NotificationDispatch('error', <StyledNotificationContent>
            <p dangerouslySetInnerHTML={{
            __html: answerMessage
          }} />
          </StyledNotificationContent>);
      }
    } else {
      NotificationDispatch('error', `Oh no, an error occurred while starting VM: ${answerMessage ?? 'unknown'}`);
    }
  };
  return {
    handleNotificationsAndSplitScreen
  };
};