import styled from 'styled-components';

export const StyledDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 1.6rem;
  line-height: 2.1rem;
`;
