import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darkColors, StyledFlex } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledWelcomeSectionTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${darkColors.text.main};
  margin: 0;
`;
export const StyledWelcomeSectionSubTitle = styled.p`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-weight: 400;
  color: ${darkColors.text.main};
  margin-bottom: 1.7rem;
`;
export const StyledFontAwesomeInfoIcon = styled(FontAwesomeIcon)`
  color: ${darkColors.text.highlight};
`;
export const StyledCompletionMessage = styled(StyledWelcomeSectionSubTitle)`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-weight: 400;
  color: ${darkColors.text.main};
  width: 25rem;
  text-align: center;
  margin: 0;
`;
export const StyledWelcomeTasksFooter = styled(StyledFlex)`
  margin-top: 1.8rem;
`;
export const StyledCloseWelcomeSection = styled.button`
  background-color: ${darkColors.background.secondary};
  font-size: 1.6rem;
  color: ${darkColors.text.main};
  padding: 1rem 1.6rem;
  border-radius: ${({ theme }) => theme.radius.small}};
  border: 1px solid ${darkColors.border.light};
`;
