import type { AoCInternalPromotionalBannerProps } from './aoc-promotional-banner.types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { StyledAoCInternalPromotionalBannerBackground, StyledAoCInternalPromotionalBannerCloseButton, StyledAoCInternalPromotionalBannerContainer, StyledAoCInternalPromotionalBannerWrapper } from './aoc-promotional-banner.styles';
export const AoCInternalPromotionalBanner = ({
  dismiss,
  isUserLoggedIn
}: AoCInternalPromotionalBannerProps) => <StyledAoCInternalPromotionalBannerContainer data-sentry-element="StyledAoCInternalPromotionalBannerContainer" data-sentry-component="AoCInternalPromotionalBanner" data-sentry-source-file="aoc-promotional-banner.tsx">
    <StyledAoCInternalPromotionalBannerBackground data-sentry-element="StyledAoCInternalPromotionalBannerBackground" data-sentry-source-file="aoc-promotional-banner.tsx">
      <StyledAoCInternalPromotionalBannerWrapper data-sentry-element="StyledAoCInternalPromotionalBannerWrapper" data-sentry-source-file="aoc-promotional-banner.tsx">
        <div>
          <h1>
            Advent of Cyber <span>2024</span>
          </h1>
          <p>Solve daily beginner-friendly challenges with over $100,000 worth of prizes up for grabs!</p>
          <Link to="/jr/adventofcyber2024" data-sentry-element="Link" data-sentry-source-file="aoc-promotional-banner.tsx">
            <Button color="primary" variant="solid" onClick={() => ''} data-sentry-element="Button" data-sentry-source-file="aoc-promotional-banner.tsx">
              Join for <span>FREE</span>
            </Button>
          </Link>
        </div>
        <div>
          {isUserLoggedIn && <StyledAoCInternalPromotionalBannerCloseButton aria-label="close-notification" icon={faXmark} onClick={dismiss} />}
        </div>
      </StyledAoCInternalPromotionalBannerWrapper>
    </StyledAoCInternalPromotionalBannerBackground>
  </StyledAoCInternalPromotionalBannerContainer>;