import React, { useEffect, useMemo } from 'react';
import { StyledFlex } from '@tryhackme/thm-ui-components';
import { useTheme } from 'styled-components';
import { useGetQuestionsAnsweredThisWeekQuery } from './questions.slice';
import { StyledGraphWrapper, StyledQuestionsSection, StyledQuestionsSubtitle, StyledQuestionsTitle } from './questions.styles';
import { initializeChart } from './questions.helpers';
export const Questions = () => {
  const questionsDataQuery = useGetQuestionsAnsweredThisWeekQuery();
  const questionsData = useMemo(() => questionsDataQuery?.data?.data ?? [], [questionsDataQuery]);
  const totalQuestions = questionsData.reduce((acc: number, {
    count
  }) => acc + count, 0);
  const theme = useTheme();
  const chartRef = React.useRef<HTMLCanvasElement>(null);
  useEffect(() => initializeChart(chartRef, questionsData, theme), [chartRef, questionsData, theme]);
  return <StyledQuestionsSection data-sentry-element="StyledQuestionsSection" data-sentry-component="Questions" data-sentry-source-file="questions.tsx">
      <StyledFlex flexDirection="column" gap="0.4rem" alignItems="flex-start" alignContent="flex-start" data-sentry-element="StyledFlex" data-sentry-source-file="questions.tsx">
        <StyledQuestionsTitle data-sentry-element="StyledQuestionsTitle" data-sentry-source-file="questions.tsx">
          {totalQuestions} {totalQuestions === 1 ? 'Question' : 'Questions'}
        </StyledQuestionsTitle>
        <StyledQuestionsSubtitle data-sentry-element="StyledQuestionsSubtitle" data-sentry-source-file="questions.tsx">Answered this week</StyledQuestionsSubtitle>
      </StyledFlex>
      <StyledGraphWrapper data-sentry-element="StyledGraphWrapper" data-sentry-source-file="questions.tsx">
        <canvas data-testid="questions-graph" aria-label="Questions answered graph" ref={chartRef} />
      </StyledGraphWrapper>
    </StyledQuestionsSection>;
};