import { apiSlice } from 'src/features/api/api-slice';

import {
  GetHacktivitiesSearchQueryParams,
  GetSearchProgressSearchQueryParams,
  HacktivitiesApiResponse,
  SearchProgressResponse,
} from './content.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHacktivitiesSearch: builder.query<HacktivitiesApiResponse, GetHacktivitiesSearchQueryParams>({
      query: ({
        kind,
        difficulty,
        order,
        roomType,
        contentSubType,
        page,
        searchText,
        userProgress,
        newLimit,
      }: GetHacktivitiesSearchQueryParams) => ({
        url: 'hacktivities/extended-search',
        params: {
          kind,
          difficulty,
          order,
          roomType,
          contentSubType,
          page,
          searchText,
          userProgress,
          limit: newLimit ?? 12,
        },
      }),
    }),
    getSearchProgress: builder.query<SearchProgressResponse, GetSearchProgressSearchQueryParams>({
      query: ({ roomCodes, pathIds, moduleIds }) => ({
        url: 'hacktivities/search-progress',
        params: {
          roomCodes,
          pathIds,
          moduleIds,
        },
      }),
    }),
  }),
});

export const { useGetHacktivitiesSearchQuery, useGetSearchProgressQuery, useLazyGetSearchProgressQuery } =
  extendedApiSlice;
