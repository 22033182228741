import { darkColors, StyledBox } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

interface StyledSectionBoxProps {
  isDarkVersion?: boolean;
}

export const StyledSectionBox = styled(StyledBox)<StyledSectionBoxProps>`
  width: 100%;
  padding: 2.4rem;
  background-color: ${({ theme, isDarkVersion }) =>
    isDarkVersion ? darkColors.background.light : theme.colors.background.light};
`;
