import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';
import { store } from 'src/app/store';

import { CurrentPathResponse } from './continue-learning.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentPath: builder.query<ApiResponse<CurrentPathResponse>, void>({
      query: () => ({
        method: 'GET',
        url: 'paths/current',
      }),
      providesTags: [RTKQueryTag.PathsCurrent],
    }),
  }),
});

export const invalidateGetCurrentPathQuery = () => {
  store.dispatch(extendedApiSlice.util.invalidateTags([RTKQueryTag.PathsCurrent]));
};

export const { useGetCurrentPathQuery } = extendedApiSlice;
