import styled from 'styled-components';

export const StyledRecordsWrapper = styled.div`
  display: flex;
  margin-top: 1.6rem;
  justify-content: space-between;
  gap: 0.8rem;
  width: 100%;
  p {
    font-weight: 600;
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  }
`;

export const StyledUserWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 8.1rem 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.8rem;
  justify-content: start;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-template-rows: 8.1rem 1fr;
    justify-content: center;
  }
`;

export const StyledUserName = styled.div`
  grid-column: 2;
  grid-row: 1;
  align-self: end;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  p {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colors.text.main};
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    align-self: center;
  }
`;
