export enum VPNTypeSearch {
  MACHINES = 'machines',
  NETWORKS = 'networks',
}

export interface GetVPNQuery {
  type?: string;
}

export interface UsersNetworkVPNs {
  title: string;
  networkId: string;
  group: string;
}

export interface AvailableVPNServers {
  availableServers: string[] | UsersNetworkVPNs[];
  unlockVIPServersPossibility?: boolean;
  currentServer?: string;
}

export interface GetNetworkQuery {
  networkId?: string;
}

export interface VpnConfigResponse {
  url: string;
}

export interface ChangeServerBody {
  server: string;
}

export interface ConnectionResponse {
  serverStatus?: boolean;
  virtualIP?: string;
  publicIP?: string;
}

export enum ServerStatusEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum ConnectionEnum {
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not connected',
}
