import { PaginatedResponse } from 'src/common/interfaces';

import { FriendDataProps } from './friend-item/friend-item.types';

export type FriendsList = FriendDataProps[];
type UserSearchResult = Pick<FriendDataProps, 'username' | 'avatar'>;

export enum UserType {
  USERNAME = 'username',
  EMAIL = 'email',
}
export interface AddFriendRequestBody {
  userType: UserType;
  userProperty: string;
}

export interface RemoveFriendRequestBody {
  username: string;
}

export interface UserSearchResponse {
  users: PaginatedResponse<UserSearchResult>;
}
