export const validateUsernameHasNoSymbols = (value: string) => !/[^\d.A-Za-z]+/g.test(value);
export const validateNoNumbers = (value: string) => !/\d/.test(value);
export const validateFullNameNoSymbols = (value: string) => !/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~€]/gi.test(value);

export const getValidationAreaData = (value = '') => [
  {
    message: 'Your username cannot contain any symbols, this includes spaces and underscores.',
    isEmpty: !value.length,
    isLoading: false,
    isValid: validateUsernameHasNoSymbols(value),
  },
  {
    message: 'Your username cannot be any longer than 20 characters long.',
    isEmpty: !value.length,
    isLoading: false,
    isValid: value.length <= 20,
  },
];
