import { useGetFeatureFlagsQuery } from 'src/common/slices';
import { FeatureFlagName } from 'src/common/constants';

export const useFeatureFlag = (name: FeatureFlagName) => {
  const queryResponse = useGetFeatureFlagsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      enabled: result.data?.data?.find((flag) => flag.name === name)?.enabled || false,
    }),
  });

  return queryResponse;
};
