import { useFeatureFlag } from 'src/common/hooks';
import { FeatureFlagName } from 'src/common/constants';
import { AvatarWithRoomProps } from './avatar-with-room.types';
import { StyledAvatar, StyledAvatarWrapper, StyledRoomTitleWrapper, StyledLink, StyledRoomTitle } from './avatar-with-room.styles';
export const AvatarWithRoom = ({
  roomCode,
  avatar,
  roomTitle
}: AvatarWithRoomProps) => {
  const {
    enabled: isRoomsRebuildEnabled
  } = useFeatureFlag(FeatureFlagName.ROOMS_REBUILD);
  return <StyledLink to={isRoomsRebuildEnabled ? `/room/${roomCode}?auto-join=true` : `/jr/${roomCode}`} data-sentry-element="StyledLink" data-sentry-component="AvatarWithRoom" data-sentry-source-file="avatar-with-room.tsx">
      <StyledAvatarWrapper data-sentry-element="StyledAvatarWrapper" data-sentry-source-file="avatar-with-room.tsx">
        <StyledAvatar src={avatar} alt="" data-sentry-element="StyledAvatar" data-sentry-source-file="avatar-with-room.tsx" />
        <StyledRoomTitleWrapper data-sentry-element="StyledRoomTitleWrapper" data-sentry-source-file="avatar-with-room.tsx">
          <StyledRoomTitle data-sentry-element="StyledRoomTitle" data-sentry-source-file="avatar-with-room.tsx">{roomTitle}</StyledRoomTitle>
        </StyledRoomTitleWrapper>
      </StyledAvatarWrapper>
    </StyledLink>;
};