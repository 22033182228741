import { useViewport } from '@tryhackme/thm-ui-components';
import { useLocation } from 'react-router-dom';

import { DISBALED_TUTOR_ROOMS_FOR_USERS, ExperimentNames, FeatureFlagName } from 'src/common/constants';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { FeatureFlagData, UserData } from 'src/common/interfaces';
import { useGetExperimentGroupsQuery } from 'src/common/slices/experiments';
import { useGetUserQuery } from 'src/common/slices';

import { RoomDetailsData } from './room.types';
import { useGetRoomDetailQuery } from './room.slice';

const TUTOR_SOFT_LAUNCH_START_DATE = new Date('2024-09-17');

const ADD_CTA_PATH_OUTLINE_END_DATE = new Date('2024-10-01');

export const useShouldSeePathOulineCTAExperiment = (
  roomDetails: RoomDetailsData | undefined,
  userData: UserData | undefined,
) => {
  const { width } = useViewport();
  const { data: experimentData } = useGetExperimentGroupsQuery([ExperimentNames.ADD_CTA_PATH_OUTLINE_ROOMS]);
  const userSignupDate = userData?.user?.dateSignUp;
  const isAddCtaToPathOutlineTreatmentGroup =
    experimentData?.data?.addCtaPathOutlineRooms === 'treatment' &&
    userSignupDate &&
    new Date(userSignupDate) <= ADD_CTA_PATH_OUTLINE_END_DATE;
  if (!isAddCtaToPathOutlineTreatmentGroup) return false;
  if (width < BREAKPOINTS.MOBILE) return false;
  if (!roomDetails?.breadcrumbs?.path) return false;
  if (userData?.user?.companies && userData.user.companies.length > 0) return false;

  return true;
};

export const useShouldSeeTutorExperiment = (roomCode: string, isJoined: boolean, featureFlags?: FeatureFlagData[]) => {
  const { width, height } = useViewport();
  const { data: user } = useGetUserQuery();
  const { data: roomData } = useGetRoomDetailQuery(roomCode);

  const isMobile = width < BREAKPOINTS.MOBILE || height < 600;
  const isFeatureFlagEnabled =
    featureFlags?.some((flag) => flag.name === FeatureFlagName.TUTOR_AI && flag.enabled) ?? false;

  const isBadrEnabled = roomData?.data?.tutor?.isBadrEnabled;

  const isTryHackMeUser = user?.data?.user.email?.endsWith('@tryhackme.com') && user?.data?.user.verified;
  const isTutorEnabledForUser = user?.data?.user.tutorEnabled;
  const signUpDate = user?.data?.user?.dateSignUp && new Date(user.data.user.dateSignUp);
  const signedUpAfterLaunch = signUpDate ? signUpDate >= TUTOR_SOFT_LAUNCH_START_DATE : false;

  const isTutorDisabled = !isJoined || !isBadrEnabled;

  const skipExperimentEndpointCall =
    isTutorDisabled || !isFeatureFlagEnabled || isTryHackMeUser || isTutorEnabledForUser;

  const { data: experimentV1 } = useGetExperimentGroupsQuery([ExperimentNames.TUTOR_SOFT_LAUNCH_V1], {
    skip: skipExperimentEndpointCall || !signedUpAfterLaunch,
  });
  const { data: experimentV2 } = useGetExperimentGroupsQuery([ExperimentNames.TUTOR_SOFT_LAUNCH_V2], {
    skip: skipExperimentEndpointCall || signedUpAfterLaunch,
  });
  const hasExperiment =
    experimentV1?.data?.tutorSoftLaunchV1 === 'treatment' || experimentV2?.data?.tutorSoftLaunchV2 === 'treatment';

  if (isTutorDisabled || isMobile) {
    return false;
  }

  if (isTryHackMeUser) {
    return true;
  }

  if (DISBALED_TUTOR_ROOMS_FOR_USERS.includes(roomCode)) {
    return false;
  }

  if (isTutorEnabledForUser) {
    return true;
  }

  return isFeatureFlagEnabled && hasExperiment;
};

const REMOVE_NABVAR_ROOMS_START_DATE = new Date('2024-11-13');

export const useShouldNotSeeNavigationExperiment = () => {
  const { width } = useViewport();
  const { data: user } = useGetUserQuery();
  const location = useLocation();

  const isRoomPage = location.pathname.includes('/room/');

  const dateSignUp = user?.data?.user?.dateSignUp;

  const userEligableForExperiment = dateSignUp
    ? new Date(dateSignUp) >= new Date(REMOVE_NABVAR_ROOMS_START_DATE)
    : false;

  const { data: experimentData } = useGetExperimentGroupsQuery([ExperimentNames.REMOVE_NAVBAR_ROOMS], {
    skip: !userEligableForExperiment || width < BREAKPOINTS.MOBILE || !isRoomPage,
  });

  const isExperimentEnabled = experimentData?.data?.removeNavbarRooms === 'treatment';

  return isExperimentEnabled;
};
