import { apiSlice } from 'src/features/api/api-slice';
import {
  RoomCode,
  ApiResponse,
  SetRoomVotesBody,
  AddWriteupMutation,
  JoinRoomMutation,
  ResetNetworkResponse,
  NetworkDiagramRoomData,
  JoinRoomRequest,
} from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';
import { RoomTask } from 'src/common/interfaces/tasks';
import { GetScoreboardQueryParams, ScoreboardUser } from 'src/common/interfaces/scoreboard';
import { Feedback } from 'src/common/interfaces/feedback';
import { RunningInstance } from 'src/common/interfaces/instances';
import { store } from 'src/app/store';
import { decode } from 'src/common/helpers';

import { DownloadVpnConfig, RevealTicketValueData, RoomDetailsData, RoomVotesData } from './room.types';
import {
  GetRoomTaskFilesRequestBody,
  GetRoomTaskFilesResponse,
} from './components/tasks/components/accordion/accordion.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoomDetail: builder.query<ApiResponse<RoomDetailsData>, string>({
      query: (roomCode) => `rooms/details?roomCode=${roomCode}`,
      providesTags: (result, error, roomCode) => [{ type: RTKQueryTag.Room, id: roomCode }],
    }),

    getRoomVotes: builder.query<ApiResponse<RoomVotesData>, string>({
      query: (roomCode) => `rooms/votes?roomCode=${roomCode}`,
    }),

    getRevealTicketValue: builder.query<ApiResponse<RevealTicketValueData>, string>({
      query: (roomCode) => `games/tickets/reveal?roomCode=${roomCode}`,
      providesTags: (result, error, roomCode) => [{ type: RTKQueryTag.Room, id: roomCode }],
    }),

    getRoomTasks: builder.query<ApiResponse<RoomTask[]>, string>({
      query: (roomCode) => ({ url: 'rooms/tasks', params: { roomCode } }),
      transformResponse: (response: ApiResponse<RoomTask[]>) => ({
        ...response,
        data:
          response?.data?.map((t: RoomTask) => ({
            ...t,
            questions: t.questions.map((q) => ({
              ...q,
              hint: decode(q.hint),
              progress: q.progress && {
                ...q.progress,
                submission: decode(q.progress.submission),
              },
            })),
          })) ?? [],
      }),
      providesTags: (result, error, roomCode) => [{ type: RTKQueryTag.Task, id: roomCode }],
    }),

    getRoomTaskFilesDownloadUrl: builder.query<ApiResponse<GetRoomTaskFilesResponse>, GetRoomTaskFilesRequestBody>({
      query: ({ roomId, downloadId, taskId }) => ({
        url: `rooms/tasks/download`,
        method: 'GET',
        params: { roomId, downloadId, taskId },
      }),
    }),

    getScoreboardData: builder.query<ApiResponse<ScoreboardUser[]>, GetScoreboardQueryParams>({
      query: ({ roomCode, limit }) => ({ url: `rooms/scoreboard`, params: { roomCode, limit } }),
      providesTags: (result, error, { roomCode }) => [{ type: RTKQueryTag.Scoreboard, id: roomCode }],
    }),

    setRoomVotes: builder.mutation<ApiResponse, SetRoomVotesBody>({
      query: ({ roomCode, vote }) => ({
        url: 'rooms/votes',
        method: 'POST',
        body: { roomCode, vote },
      }),
    }),

    leaveRoom: builder.mutation<ApiResponse, RoomCode>({
      query: ({ roomCode }) => ({
        url: 'rooms/leave',
        method: 'POST',
        body: { roomCode },
      }),
      invalidatesTags: (result, error, args) =>
        !error && result
          ? [
              { type: RTKQueryTag.Room, id: args.roomCode },
              { type: RTKQueryTag.Task, id: args.roomCode },
              { type: RTKQueryTag.Scoreboard, id: args.roomCode },
              { type: RTKQueryTag.UserRooms },
            ]
          : [],
    }),

    provideRoomCompletionFeedback: builder.mutation<ApiResponse, Omit<Feedback, 'userId' | 'date'>>({
      query: (body) => ({
        url: 'feedback',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (!error && result ? [RTKQueryTag.UserFeedback] : []),
    }),

    resetUserProgress: builder.mutation<ApiResponse, RoomCode>({
      query: ({ roomCode }) => ({
        url: 'rooms/reset-progress',
        method: 'POST',
        body: { roomCode },
      }),
      invalidatesTags: (result, error, args) =>
        !error && result
          ? [
              { type: RTKQueryTag.Room, id: args.roomCode },
              { type: RTKQueryTag.Task, id: args.roomCode },
              { type: RTKQueryTag.Scoreboard, id: args.roomCode },
            ]
          : [],
    }),

    addWriteup: builder.mutation<ApiResponse, AddWriteupMutation>({
      query: ({ roomCode, title, link }) => ({
        url: 'rooms/writeups',
        method: 'POST',
        body: { roomCode, title, link },
      }),
      invalidatesTags: (result, error, args) =>
        !error && result ? [{ type: RTKQueryTag.UserRooms, id: args.roomCode }] : [],
    }),

    joinRoom: builder.mutation<ApiResponse<true | JoinRoomMutation>, JoinRoomRequest>({
      query: ({ roomCode, pageReferrer }) => ({
        url: 'rooms/join',
        method: 'POST',
        body: { roomCode, pageReferrer },
      }),
      invalidatesTags: (result, error, args) =>
        !error && result
          ? [
              { type: RTKQueryTag.Room, id: args.roomCode },
              { type: RTKQueryTag.Task, id: args.roomCode },
              { type: RTKQueryTag.Scoreboard, id: args.roomCode },
              { type: RTKQueryTag.Networks },
            ]
          : [],
    }),
    getRunning: builder.query<RunningInstance[], void>({
      query: () => `vms/running`,
      providesTags: () => [{ type: RTKQueryTag.RunningInstances }],
      transformResponse: (response: ApiResponse<RunningInstance[]>) => response?.data ?? [],
    }),
    terminateVm: builder.mutation<ApiResponse, string>({
      query: (instanceId) => ({
        url: 'vms/terminate',
        method: 'POST',
        body: { id: instanceId },
      }),
      invalidatesTags: [{ type: RTKQueryTag.RunningInstances }],
    }),
    downloadVpnConfig: builder.query<ApiResponse<DownloadVpnConfig>, string | undefined | null>({
      query: (networkId) => (networkId ? `vpns/config?networkId=${networkId}` : `vpns/config`),
    }),
    startNetwork: builder.mutation<ApiResponse<boolean>, string>({
      query: (networkId) => ({
        url: 'networks/start',
        method: 'POST',
        body: { id: networkId },
      }),
      invalidatesTags: (result, error) => (!error && result?.data ? [{ type: RTKQueryTag.Networks }] : []),
    }),
    resetNetwork: builder.mutation<ApiResponse<ResetNetworkResponse>, string>({
      query: (networkId) => ({
        url: 'networks/reset',
        method: 'POST',
        body: { id: networkId },
      }),
      invalidatesTags: (result, error) => (!error && result ? [{ type: RTKQueryTag.Networks }] : []),
    }),
    getNetwork: builder.query<NetworkDiagramRoomData | null, string>({
      query: (roomId) => ({ url: `networks/details`, params: { roomId } }),
      providesTags: (result, error, roomId) => [{ type: RTKQueryTag.Networks, id: roomId }],
      transformResponse: (response: ApiResponse<NetworkDiagramRoomData>) => response?.data ?? null,
    }),
    extendNetwork: builder.mutation<ApiResponse, string>({
      query: (networkId) => ({
        url: 'networks/extend',
        method: 'POST',
        body: { id: networkId },
      }),
      invalidatesTags: (result, error) => (!error && result ? [{ type: RTKQueryTag.Networks }] : []),
    }),
  }),
});

export const invalidateCachedRunningVms = () => {
  store.dispatch(extendedApiSlice.util.invalidateTags([RTKQueryTag.RunningInstances]));
};

export const invalidateNetworkDetailsCache = () => {
  store.dispatch(extendedApiSlice.util.invalidateTags([RTKQueryTag.Networks]));
};

export const invalidateResetProgress = () => {
  store.dispatch(
    extendedApiSlice.util.invalidateTags([
      { type: RTKQueryTag.Room },
      { type: RTKQueryTag.Task },
      { type: RTKQueryTag.Scoreboard },
    ]),
  );
};

export const {
  useResetUserProgressMutation,
  useProvideRoomCompletionFeedbackMutation,
  useLeaveRoomMutation,
  useGetRoomDetailQuery,
  useGetRoomVotesQuery,
  useSetRoomVotesMutation,
  useGetRoomTasksQuery,
  useLazyGetRoomTaskFilesDownloadUrlQuery,
  useGetRoomTaskFilesDownloadUrlQuery,
  useAddWriteupMutation,
  useJoinRoomMutation,
  useGetScoreboardDataQuery,
  useGetRunningQuery,
  useLazyGetRevealTicketValueQuery,
  useTerminateVmMutation,
  useLazyDownloadVpnConfigQuery,
  useResetNetworkMutation,
  useStartNetworkMutation,
  useGetNetworkQuery,
  useExtendNetworkMutation,
} = extendedApiSlice;
