import styled, { css } from 'styled-components';
import { StyledButton } from '@tryhackme/thm-ui-components';
import { ButtonVariants } from '@tryhackme/thm-ui-components/dist/components/atoms/button/button.types';

import ResourcesPageBannerXl from 'src/images/svgs/resources-page-banner-w1920px.svg';
import ResourcesPageBannerLg from 'src/images/svgs/resources-page-banner-w1440px.svg';
import ResourcesPageBannerMd from 'src/images/svgs/resources-page-banner-w768px.svg';
import ResourcesPageBannerSm from 'src/images/svgs/resources-page-banner-w360px.svg';

export const StyledCTABanner = styled.section`
  background-image: url(${ResourcesPageBannerXl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.2rem 0;

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    background-image: url(${ResourcesPageBannerLg});
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    background-image: url(${ResourcesPageBannerMd});
  }
  @media screen and (min-width: ${({ theme }) => theme.sizes.xs}) and (max-width: ${({ theme }) => theme.sizes.md}) {
    background-image: url(${ResourcesPageBannerSm});
  }
`;

export const StyledBannerText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 3.677rem;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.sizes.xs}) and (max-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 2.4rem;
    line-height: 2.758rem;
  }
`;

export const StyledCTAButtonWrapper = styled.div`
  margin-top: 3rem;
  &&:hover {
    color: ${({ theme }) => theme.colors.grey[200]};
  }
  display: flex;
  gap: 1.6rem;
`;

export const StyledCTAButtonLink = styled(StyledButton)<{ variant: ButtonVariants }>`
  color: ${({ theme, variant }) => variant === 'outlined' && theme.colors.white};
  ${({ variant }) =>
    variant === 'solid' &&
    css`
      &&:hover {
        color: ${({ theme }) => theme.colors.grey[200]};
      }
    `}
`;
