import { routeProtector } from 'src/router/route-protector';
import { FeatureFlagName } from 'src/common/constants';
import { ThemeColorMode } from 'src/common/enums';
import { RouteHandleObject, ThmRouteObject } from 'src/common/interfaces';
const rootHandle: Partial<RouteHandleObject> = {
  forcedTheme: ThemeColorMode.DARK
};
const withoutHeader: Partial<RouteHandleObject> = {
  ...rootHandle,
  hideHeader: true
};
const withoutFooter: Partial<RouteHandleObject> = {
  ...rootHandle,
  hideFooter: true
};
const fullScreen: Partial<RouteHandleObject> = {
  ...withoutHeader,
  ...withoutFooter
};
export const socSimRoutes: ThmRouteObject[] = [{
  path: '/soc-sim',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.SOC_SIM)
  // .requireBusinessUserOrCorporatePartner()
  .requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      SocSim
    } = await import('src/features/soc-sim');
    return {
      Component: SocSim
    };
  },
  children: [{
    // guard wrapper for routes that should not be accessible if there is an active run
    path: '',
    async lazy() {
      const {
        SocSimActiveRunGuard
      } = await import('src/features/soc-sim');
      return {
        Component: SocSimActiveRunGuard
      };
    },
    children: [{
      path: '',
      async lazy() {
        const {
          SocSimLandingSections
        } = await import('src/features/soc-sim');
        return {
          Component: SocSimLandingSections
        };
      },
      handle: rootHandle,
      children: [{
        path: '',
        async lazy() {
          const {
            SocSimHome
          } = await import('src/features/soc-sim');
          return {
            Component: SocSimHome
          };
        },
        handle: {
          ...rootHandle,
          name: 'soc_sim_home'
        }
      }, {
        path: 'scenarios',
        async lazy() {
          const {
            Scenarios
          } = await import('src/features/soc-sim');
          return {
            Component: Scenarios
          };
        },
        handle: {
          ...rootHandle,
          name: 'soc_sim_scenarios'
        }
      }, {
        path: 'stats',
        async lazy() {
          const {
            ProgressAndStats
          } = await import('src/features/soc-sim');
          return {
            Component: ProgressAndStats
          };
        },
        handle: {
          ...rootHandle,
          name: 'soc_sim_stats'
        }
      }, {
        path: 'leaderboard',
        async lazy() {
          const {
            Leaderboard
          } = await import('src/features/soc-sim');
          return {
            Component: Leaderboard
          };
        },
        handle: {
          ...rootHandle,
          name: 'soc_sim_leaderboard'
        }
      }]
    }, {
      path: 'scenario-overview',
      async lazy() {
        const {
          ScenarioOverview
        } = await import('src/features/soc-sim');
        return {
          Component: ScenarioOverview
        };
      },
      handle: {
        ...withoutFooter,
        name: 'soc_sim_overview'
      }
    }, {
      path: 'scenario-documentation',
      async lazy() {
        const {
          ScenarioDocumentation
        } = await import('src/features/soc-sim');
        return {
          Component: ScenarioDocumentation
        };
      },
      handle: {
        ...withoutFooter,
        name: 'soc_sim_onboarding'
      }
    }, {
      path: 'summary/:runId',
      async lazy() {
        const {
          RunSummaryPage
        } = await import('src/features/soc-sim');
        return {
          Component: RunSummaryPage
        };
      },
      handle: {
        ...withoutHeader,
        name: 'soc_sim_summary'
      }
    }]
  }, {
    // guard wrapper for routes that should only be accessible if there is an active run
    path: '',
    async lazy() {
      const {
        SocSimSimulator
      } = await import('src/features/soc-sim');
      return {
        Component: SocSimSimulator
      };
    },
    children: [{
      path: 'dashboard',
      async lazy() {
        const {
          SocSimDashboard
        } = await import('src/features/soc-sim');
        return {
          Component: SocSimDashboard
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_dashboard'
      }
    }, {
      path: 'alert-queue',
      async lazy() {
        const {
          AlertQueue
        } = await import('src/features/soc-sim');
        return {
          Component: AlertQueue
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_alerts'
      }
    }, {
      path: 'siem',
      async lazy() {
        const {
          Siem
        } = await import('src/features/soc-sim');
        return {
          Component: Siem
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_siem'
      }
    }, {
      path: 'my-computer',
      async lazy() {
        const {
          MyComputer
        } = await import('src/features/soc-sim');
        return {
          Component: MyComputer
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_my_computer'
      }
    }, {
      path: 'documentation',
      async lazy() {
        const {
          SocSimDocumentation
        } = await import('src/features/soc-sim');
        return {
          Component: SocSimDocumentation
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_documentation'
      }
    }, {
      path: 'playbooks',
      async lazy() {
        const {
          Playbooks
        } = await import('src/features/soc-sim');
        return {
          Component: Playbooks
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_playbooks'
      }
    }, {
      path: 'playbooks/:playbookId',
      async lazy() {
        const {
          PlaybooksDetails
        } = await import('src/features/soc-sim');
        return {
          Component: PlaybooksDetails
        };
      },
      handle: fullScreen
    }, {
      path: 'case-reports',
      async lazy() {
        const {
          CaseReports
        } = await import('src/features/soc-sim');
        return {
          Component: CaseReports
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_reports'
      }
    }, {
      path: 'case-reports/:alertId/:reportId?',
      async lazy() {
        const {
          CaseReportDetails
        } = await import('src/features/soc-sim/case-reports-details');
        return {
          Component: CaseReportDetails
        };
      },
      handle: fullScreen
    }, {
      path: 'guide',
      async lazy() {
        const {
          SocSimGuide
        } = await import('src/features/soc-sim');
        return {
          Component: SocSimGuide
        };
      },
      handle: {
        ...fullScreen,
        name: 'soc_sim_guide'
      }
    }]
  }]
}];