import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';

import {
  AvailabilityResponse,
  GeneralInformationFormData,
  UpdateAvatarData,
  AvatarData,
} from './general-information.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateGeneralInformation: builder.mutation<ApiResponse, GeneralInformationFormData>({
      query: (body) => ({
        url: 'users/general-info',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [RTKQueryTag.User],
    }),
    getIsEmailUnique: builder.query<ApiResponse<AvailabilityResponse>, string>({
      query: (email) => ({
        url: `/users/availability?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getIsUsernameUnique: builder.query<ApiResponse<AvailabilityResponse>, string>({
      query: (username) => ({
        url: `/users/availability?username=${username}`,
        method: 'GET',
      }),
    }),
    updateAvatar: builder.mutation<ApiResponse, UpdateAvatarData>({
      query: (body) => ({
        url: 'users/avatar',
        method: 'POST',
        body,
      }),
    }),
    getAvatarUrl: builder.query<ApiResponse<AvatarData>, string>({
      query: (filename) => ({
        url: `users/avatar?filename=${filename}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useUpdateGeneralInformationMutation,
  useLazyGetIsEmailUniqueQuery,
  useLazyGetIsUsernameUniqueQuery,
  useGetIsUsernameUniqueQuery,
  useUpdateAvatarMutation,
  useLazyGetAvatarUrlQuery,
} = extendedApiSlice;
