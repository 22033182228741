import { lazy, Suspense, useState } from 'react';
import { InfoBox } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { StyledLoading } from 'src/common/components';
import { useTrackEventMutation } from 'src/common/slices';
import { StyledTitle } from 'src/common/styles';
import { StyledFriends, StyledFriendsWrapper, StyledHeader, StyledLoadingWrapper, StyledMainButton } from './friends.styles';
import { AddFriends } from './add-friends';
import { useGetUserFriendsQuery } from './friends.slice';
export const Friends = ({
  userName
}: {
  userName: string;
}) => {
  const [isAddFriendsView, setIsAddFriendsView] = useState(false);
  const [isSearchResultsView, setSearchResultsView] = useState(false);
  const {
    data,
    isLoading
  } = useGetUserFriendsQuery();
  const [trackEvent] = useTrackEventMutation();
  const LazyFriendItem = lazy(() => import('./friend-item').then(module => ({
    default: module.FriendItem
  })));
  const handleAddFriendsBtn = () => {
    if (isSearchResultsView) {
      setSearchResultsView(false);
      setIsAddFriendsView(true);
    } else {
      setIsAddFriendsView(!isAddFriendsView);
      setSearchResultsView(false);
      // eslint-disable-next-line no-void
      void trackEvent({
        event: 'add-friend'
      });
    }
  };
  return <StyledFriends data-sentry-element="StyledFriends" data-sentry-component="Friends" data-sentry-source-file="friends.tsx">
      <StyledHeader data-sentry-element="StyledHeader" data-sentry-source-file="friends.tsx">
        <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="friends.tsx">Friends</StyledTitle>
        <StyledMainButton variant="outlined" color="secondary" onClick={handleAddFriendsBtn} data-sentry-element="StyledMainButton" data-sentry-source-file="friends.tsx">
          {isAddFriendsView || isSearchResultsView ? <span>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </span> : <span>Add friends</span>}
        </StyledMainButton>
      </StyledHeader>

      {data?.data && data.data.length === 0 && !isAddFriendsView && <InfoBox>
          <p>
            You don’t have any friends on TryHackMe :( <br />
            Click the <strong>Add friends</strong> button to start hacking with your friends. It’s way more fun this
            way!
          </p>
        </InfoBox>}
      {isAddFriendsView ? <AddFriends setIsAddFriendsView={setIsAddFriendsView} /> : <StyledFriendsWrapper data-testid="friends-list">
          {isLoading ? <StyledLoadingWrapper>
              <StyledLoading height="5rem" />
              <StyledLoading height="5rem" />
              <StyledLoading height="5rem" />
            </StyledLoadingWrapper> : data?.data?.map((friend, index) => <Suspense key={friend.username} fallback={<StyledLoading height="5rem" />}>
                <LazyFriendItem friend={friend} index={index} isMyUser={userName === friend.username} />
              </Suspense>)}
        </StyledFriendsWrapper>}
    </StyledFriends>;
};