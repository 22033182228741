import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';

export const experimentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExperimentGroups: builder.query<ApiResponse<{ [key: string]: string }>, string[]>({
      query: (experimentKeys) => ({
        url: `users/experiments?experimentKeys[]=${experimentKeys.join('&experimentKeys[]=')}`,
      }),
      providesTags: [RTKQueryTag.Experiments],
    }),
  }),
});

export const { useGetExperimentGroupsQuery, endpoints: experimentEndpoints } = experimentsApiSlice;
