import { FC, useMemo } from 'react';
import { CountdownProps } from './countdown.types';
import { StyledClockSeparator, StyledCountdown, StyledMessage, StyledTimeblock, StyledTimeblockValue, StyledTimer, StyledUnit } from './countdown.styles';
export const Countdown: FC<CountdownProps> = ({
  timeLeft
}) => {
  const {
    hours,
    mins,
    secs
  } = useMemo(() => {
    const h = Math.floor(timeLeft / 3600);
    const m = Math.floor((timeLeft - h * 3600) / 60);
    const s = Math.floor(timeLeft - h * 3600 - m * 60);
    return {
      hours: h,
      mins: m,
      secs: s
    };
  }, [timeLeft]);
  return <StyledTimer data-sentry-element="StyledTimer" data-sentry-component="Countdown" data-sentry-source-file="countdown.tsx">
      <StyledMessage data-sentry-element="StyledMessage" data-sentry-source-file="countdown.tsx">Sale ends in:</StyledMessage>
      <StyledCountdown data-sentry-element="StyledCountdown" data-sentry-source-file="countdown.tsx">
        <StyledTimeblock data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{hours}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">hr</StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>
        <StyledTimeblock data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{mins}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">min</StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>
        <StyledTimeblock data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx">{secs}</StyledTimeblockValue>
          <StyledUnit data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">sec</StyledUnit>
        </StyledTimeblock>
      </StyledCountdown>
    </StyledTimer>;
};