import styled from 'styled-components';

import { StyledAppearAnimation, StyledWrapperTooltipDefault } from '../../../commons';

export const StyledTooltipWrapper = styled.div`
  ${StyledWrapperTooltipDefault}
  left: -2rem;
  top: 55%;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    left: 1.2rem;
    top: 1.5rem;
    transform: translate(0, 30%);
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.sm}) {
    left: 0;
  }

  & > div {
    padding: 1.6rem 4rem;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
      padding: 1.6rem;
    }
  }

  & > p {
    font-size: 1.4rem;
  }
`;

export const StyledAvatar = styled.div`
  grid-column: 1;
  grid-row: 1 / -1;
  position: relative;

  &:hover {
    div[data-section='tooltip'] {
      display: inline-block;
      animation: appear 0.4s ease-in-out;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-row: 1;
  }

  ${StyledAppearAnimation};
`;

export const StyledUserName = styled.h3`
  font-size: 2.2rem;
  margin: 0.8rem 0 0.6rem;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.6rem;
  gap: 0.8rem;
  align-items: center;
  flex-wrap: wrap;
`;
