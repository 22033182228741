import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskType } from 'src/common/enums/task';
import { RunningInstance } from 'src/common/interfaces/instances';
import { UploadStaticSite } from 'src/common/interfaces/tasks';
import { SHOW_SPLIT_SCREEN } from 'src/common/constants';

export interface SplitScreenTab {
  id: string;
  type: TaskType;
  title: string;
  url?: string;
  vm?: RunningInstance;
  staticSite?: UploadStaticSite;
}

export interface ClearSplitScreenVmPayloadType {
  type: TaskType.VM;
  vm?: RunningInstance;
}

export interface SplitScreenPayloadType {
  tabs: SplitScreenTab[];
  attackBoxPrivateIP?: string;
}

export interface SplitScreenStateType {
  open: boolean;
  tabs: SplitScreenTab[];
  currentTab: SplitScreenTab | null;
  message: string;
  attackBoxPrivateIP?: string;
}

const initialState: SplitScreenStateType = {
  open: false,
  tabs: [],
  currentTab: null,
  message: 'Use the AttackBox to attack machines you start on tasks',
};

const splitScreenSlice = createSlice({
  name: 'splitScreen',
  initialState,
  reducers: {
    setStaticSiteTabs: (state, action: PayloadAction<SplitScreenPayloadType>) => {
      const tabIds = new Set([...action.payload.tabs.map((t) => t.id), ...state.tabs.map((t) => t.id)]);

      const tabsToKeep = state.tabs.filter((tab) => tabIds.has(tab.id));

      appendOrReplaceSplitScreenTabs(tabsToKeep, action.payload.tabs);

      return {
        ...state,
        tabs: tabsToKeep,
      };
    },
    setVMTabs: (state, action: PayloadAction<SplitScreenPayloadType>) => {
      const tabIds = new Set([
        ...action.payload.tabs.map((t) => t.id),
        ...state.tabs.filter((t) => t.type === TaskType.STATIC_SITE).map((t) => t.id),
      ]);

      const tabsToKeep = state.tabs.filter((tab) => tabIds.has(tab.id));

      appendOrReplaceSplitScreenTabs(tabsToKeep, action.payload.tabs);

      let activeTab = state.currentTab;

      if (tabsToKeep.length > 0) {
        const i = tabsToKeep.findIndex((t) => t.id === state.currentTab?.id);
        if (!state.currentTab || !tabIds.has(state.currentTab.id)) {
          activeTab = tabsToKeep[0] ?? null;
        } else if (i !== -1) {
          activeTab = tabsToKeep[i] ?? null;
        }
      } else {
        activeTab = null;
      }

      let open;
      if (tabsToKeep.length === 0) {
        open = false;
      } else if ([null, 'true'].includes(window.sessionStorage.getItem(SHOW_SPLIT_SCREEN))) {
        open = true;
      } else {
        open = state.open;
      }

      return {
        ...state,
        open,
        currentTab: activeTab,
        attackBoxPrivateIP: action.payload.attackBoxPrivateIP,
        tabs: tabsToKeep,
      };
    },
    setCurrentTab: (state, action: PayloadAction<SplitScreenTab>) => ({ ...state, currentTab: action.payload }),
    clearSplitScreenVmOrAllVms: (state, action: PayloadAction<ClearSplitScreenVmPayloadType>) => {
      const tabs = state.tabs.filter((tab) =>
        tab.type === TaskType.VM && action.payload.type === TaskType.VM && action.payload.vm?.id
          ? tab.vm?.id !== action?.payload?.vm?.id
          : tab.type !== TaskType.VM,
      );

      const currentTabIndex = tabs.findIndex((t) => t.id === state.currentTab?.id);

      let currentTab = null;
      let open = false;

      if (tabs.length > 0) {
        currentTab = currentTabIndex === -1 ? tabs[0] ?? null : tabs[currentTabIndex] ?? null;
        open = state.open;
      }

      return {
        ...state,
        tabs,
        currentTab,
        open,
      };
    },
    clearSplitScreen: (state) => ({
      ...state,
      ...initialState,
      open: false,
    }),
    setSplitScreenOpen: (state, action: PayloadAction<boolean>) => {
      window.sessionStorage.setItem(SHOW_SPLIT_SCREEN, action.payload.toString());
      return { ...state, open: action.payload };
    },
    setMessage: (state, action: PayloadAction<string>) => ({ ...state, message: action.payload }),
  },
});

export const appendOrReplaceSplitScreenTabs = (existingTabs: SplitScreenTab[], newTabs: SplitScreenTab[]) => {
  for (const tab of newTabs) {
    const existingIndex = existingTabs.findIndex((existingTab) => existingTab.id === tab.id);
    if (existingIndex === -1) {
      existingTabs.push(tab);
    } else {
      existingTabs.splice(existingIndex, 1, tab);
    }
  }
};

export const {
  setStaticSiteTabs,
  setVMTabs,
  setSplitScreenOpen,
  clearSplitScreen,
  setCurrentTab,
  clearSplitScreenVmOrAllVms,
  setMessage,
} = splitScreenSlice.actions;

const splitScreenReducer = splitScreenSlice.reducer;
export { splitScreenReducer };
