import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { CurrentModuleResponse } from './dashboard.types';
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCurrentModule: builder.query<ApiResponse<CurrentModuleResponse>, void>({
      query: () => ({
        method: 'GET',
        url: 'paths/current-module'
      })
    })
  })
});
export const {
  useGetCurrentModuleQuery
} = extendedApiSlice;