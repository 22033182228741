import styled from 'styled-components';
import { Button } from '@tryhackme/thm-ui-components';

import { StyledSectionBox } from '../commons/section-box';

export const StyledFriends = styled(StyledSectionBox)`
  padding: 2.4rem 1.35rem;
`;

export const StyledHeader = styled.header`
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.1rem;

  h2 {
    margin-bottom: 0;
  }
`;

export const StyledMainButton = styled(Button)`
  & > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      height: 1.9rem;
    }
  }
`;

export const StyledFriendsWrapper = styled.div`
  max-height: 27rem;
  overflow-y: auto;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    max-height: 50rem;
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
