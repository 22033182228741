import styled from 'styled-components';
import { Button } from '@tryhackme/thm-ui-components';

export const StyledCopyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  width: 100%;
  margin: 3rem 0;
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 3.6rem;
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 0.1rem solid ${({ theme }) => theme.colors.border.light};
  border-radius: 0.5rem 0 0 0.5rem;
`;

export const StyledCopyText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.text.lighter};
  padding: 1.3rem 0.9rem;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCopyButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
