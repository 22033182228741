import styled from 'styled-components';

export const StyledSideSectionCloseBtn = styled.button`
  svg {
    height: 2.1rem;
    width: 2.1rem;
    path {
      fill: ${({ theme }) => theme.colors.text.main};
    }
  }
`;
