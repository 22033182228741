import { AvatarWithUsernameProps } from './avatar-with-username.types';
import { StyledLink, StyledAvatar, StyledAvatarWrapper, StyledCompanyNameWrapper, StyledUsername } from './avatar-with-username.styles';
export const AvatarWithUsername = ({
  username,
  avatar,
  isManageUsers = false
}: AvatarWithUsernameProps) => <StyledAvatarWrapper data-sentry-element="StyledAvatarWrapper" data-sentry-component="AvatarWithUsername" data-sentry-source-file="avatar-with-username.tsx">
    <StyledLink to={isManageUsers ? '/admin/manage-users' : `/p/${username}`} data-sentry-element="StyledLink" data-sentry-source-file="avatar-with-username.tsx">
      {!!username && <StyledAvatar src={avatar} alt="" />}
      <StyledCompanyNameWrapper data-sentry-element="StyledCompanyNameWrapper" data-sentry-source-file="avatar-with-username.tsx">
        <StyledUsername data-sentry-element="StyledUsername" data-sentry-source-file="avatar-with-username.tsx">{username}</StyledUsername>
      </StyledCompanyNameWrapper>
    </StyledLink>
  </StyledAvatarWrapper>;