import styled from 'styled-components';
import { otherColors } from '@tryhackme/thm-ui-components';

import { StyledAppearAnimation, StyledWrapperTooltipDefault } from '../../../commons';

export const StyledTooltipWrapper = styled.div`
  ${StyledWrapperTooltipDefault}
  top: 60%;
  text-align: left;

  @media screen and (max-width: ${({ theme }) => theme.sizes.lg}) {
    left: -2.5rem;
    top: 0;
    transform: translate(0, 10%);
  }

  @media screen and (max-width: 492px) {
    width: auto;
  }
`;

export const StyledLevelInfo = styled.div`
  align-items: center;
  display: grid;
  gap: 1.2rem;
  grid-template-columns: auto 1fr auto;
  grid-column: 2;
  grid-row: 2;
  align-self: start;
  position: relative;

  p {
    color: ${({ theme }) => theme.colors.text.main};
  }

  p[data-text='type'] {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &:hover {
    div[data-section='level-tooltip'] {
      display: inline-block;
      animation: appear 0.4s ease-in-out;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-column: 1 / -1;
    grid-row: 2;
    align-self: center;
  }
  ${StyledAppearAnimation};
`;

export const StyledProgressLevelWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.greyLight};
  border-radius: 10rem;
  height: 0.9rem;
  width: 100%;
`;

export const StyledProgressLevel = styled.div<{ width: string }>`
  background: ${otherColors.gradientLevels};
  border-radius: 10rem;
  height: inherit;
  position: relative;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.15) inset;
  width: ${({ width }) => width || '100%'};
`;

export const StyledCircle = styled.div<{ $percentage: number }>`
  background: ${({ theme }) => theme.colors.success.accent};
  border-radius: 10rem;
  display: grid;
  height: 1.4rem;
  place-items: center;
  position: absolute;
  width: 1.4rem;
  top: -2px;
  right: ${({ $percentage }) => ($percentage <= 10 ? '-10px' : '0')};

  svg {
    height: 0.8rem;
    width: 0.6rem;
  }
`;

export const StyledLevelRankTitleWrapper = styled.div`
  position: relative;
`;

export const StyledLevelWrapper = styled.div`
  p {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }

  span {
    color: ${({ theme }) => theme.colors.success.main};
  }
`;
