import { Button } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledUserItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  justify-content: space-between;

  p {
    color: ${({ theme }) => theme.colors.paragraph};
  }

  &:hover {
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.colors.border.main};
  }
`;

export const StyledAddBtn = styled(Button)`
  svg {
    height: auto;
    width: 1.8rem;

    path {
      fill: ${({ theme }) => theme.colors.text.main};
    }
  }
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.secondary.main};
    }
  }
`;
