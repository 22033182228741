import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { RTKQueryTag } from 'src/common/constants';

import { QuestionsAnsweredData } from './questions.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionsAnsweredThisWeek: builder.query<ApiResponse<QuestionsAnsweredData>, void>({
      query: () => ({
        method: 'GET',
        url: 'questions/questions-answered',
      }),
      providesTags: [RTKQueryTag.DashboardQuestionsChart],
    }),
  }),
});

export const { useGetQuestionsAnsweredThisWeekQuery } = extendedApiSlice;
