import { NotificationDispatch, StyledNotificationContent } from '@tryhackme/thm-ui-components';
import { TypeOptions } from 'react-toastify';
import { ExtendVMTimeStatusCodes } from 'src/common/enums/instances';
import { ApiResponseStatus } from 'src/common/enums';
import { useExtendVmMutation } from 'src/common/slices';
export const displayToast = (type: TypeOptions, children: React.ReactNode) => {
  NotificationDispatch(type, <StyledNotificationContent>{children}</StyledNotificationContent>);
};
export const useExtendVmTime = () => {
  const [extendVmTime, {
    isLoading
  }] = useExtendVmMutation();

  // eslint-disable-next-line consistent-return
  const handleExtendVmTime = async (id: string) => {
    try {
      const response = await extendVmTime({
        id
      });
      if ('error' in response || !('data' in response)) throw new Error('Something went wrong.');
      if (response.data.status === ApiResponseStatus.SUCCESS) {
        return displayToast('success', 'Successfully updated your machines expiry time.');
      }
      switch (response.data.data?.status) {
        case ExtendVMTimeStatusCodes.SUBSCRIBER_ONLY:
          {
            displayToast('error', <p>
              Non subscribers can only deploy the free AttackBox for 1 hour a day.&nbsp;
              <a href="/why-subscribe">Subscribe</a>&nbsp;for unlimited deploys!
            </p>);
            break;
          }
        case ExtendVMTimeStatusCodes.VM_RUNNING_FOR_MORE_THAN_6HOURS:
          {
            displayToast('error', 'You have had your machine deployed for too long');
            break;
          }
        case ExtendVMTimeStatusCodes.VM_EXPIRY_NOT_LESS_THAN_1HOUR:
          {
            displayToast('warning', "Uh-oh! Slow down... You can extend your machine 1 hour before it's due to expire.");
            break;
          }
        default:
          {
            displayToast('error', 'Something went wrong');
            break;
          }
      }
    } catch {
      displayToast('error', 'Something went wrong');
    }
  };
  return {
    isLoading,
    handleExtendVmTime
  };
};