import { ProgressBar } from '@tryhackme/thm-ui-components';
import { SplitScreenContentProps } from 'src/common/components/split-screen/components/split-screen-content/split-screen-content.types';
import { TaskType } from 'src/common/enums/task';
import { useAppSelector } from 'src/app/hooks';
import { RootState } from 'src/app/store';
import { StyledContent, StyledDescription, StyledProgressBarTextVM, StyledTitle } from './split-screen-content.styles';
const focusIFrame = () => document.querySelector<HTMLIFrameElement>('#remote-window')?.contentWindow?.focus();
export const SplitScreenContent = ({
  bootProgress,
  ...props
}: SplitScreenContentProps) => {
  const {
    title,
    url,
    type,
    vm
  } = props.currentTabData;
  const {
    message
  } = useAppSelector((state: RootState) => state.splitScreen);
  return bootProgress && bootProgress < 100 ? <StyledContent>
      <StyledTitle>Your machine is initializing...</StyledTitle>
      <br />
      <StyledDescription>{message}</StyledDescription>
      <ProgressBar height={8} title={`${bootProgress}`} value={bootProgress} />
      <StyledProgressBarTextVM>Loading ( {bootProgress}% )</StyledProgressBarTextVM>
    </StyledContent> : <iframe id="remote-window" width="100%" height="100%" style={type === TaskType.STATIC_SITE || vm?.remote?.protocol?.startsWith('http') ? {
    backgroundColor: 'rgb(255, 255, 255)'
  } : undefined} allow="fullscreen; clipboard-read; clipboard-write" title={title} src={url} onMouseOver={focusIFrame} onFocus={focusIFrame} />;
};