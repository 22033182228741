import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

const downloadOVPNSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    trackDownloadOVPN: builder.mutation<ApiResponse, string>({
      query: (location) => ({
        method: 'POST',
        url: '/analytics/download-ovpn',
        body: {
          location,
        },
      }),
    }),
  }),
});

export const { useTrackDownloadOVPNMutation } = downloadOVPNSlice;
