import styled from 'styled-components';

export const StyledErrorWrapper = styled.section`
  height: auto;
  margin: 7.2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    margin: 10rem 0;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
  }

  a {
    margin: 3.4rem 0 4.6rem;
  }
`;
