import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';

import { useGetUserQuery } from 'src/common/slices';

export const useHotjarIdentify = () => {
  const { data: userData } = useGetUserQuery();
  const isHotjarReady = Hotjar.isReady();

  useEffect(() => {
    if (!Hotjar.isReady()) return;

    const user = userData?.data?.user;
    if (user) {
      Hotjar.identify(user._id, {
        ...(user.demographics.experience && { experience: user.demographics.experience }),
        subscribed: user.isPremium,
        dateSignUp: user.dateSignUp,
        displayName: user.username,
        countryCode: user?.local?.country,
      });
    }
  }, [userData?.data?.user, isHotjarReady]);
};
