import styled from 'styled-components';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 53%;
  margin: auto;
`;

export const StyledTitle = styled.h4`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.grey[100]};
`;

export const StyledDescription = styled.p`
  margin: 0 auto;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.colors.grey[200]};
`;

export const StyledProgressBarTextVM = styled.div`
  margin-top: 1rem;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.grey[200]};
  line-height: 0.94rem;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fonts.sizes.small};
`;
