import { Avatar, Tag, Tooltip, useViewport } from '@tryhackme/thm-ui-components';
import { useTooltip } from 'src/features/dashboard/hooks';
import { useGetCurrentPathQuery } from 'src/features/dashboard/components/continue-learning/continue-learning.slice';
import { StyledAvatar, StyledTooltipWrapper, StyledUserName, StyledInfoWrapper } from './avatar.styles';
export const UserAvatar = ({
  avatar,
  userName,
  fullName
}: {
  avatar: string;
  userName: string;
  fullName: string;
}) => {
  const {
    handleTouchStart,
    tooltipRef
  } = useTooltip();
  const {
    width
  } = useViewport();
  const {
    data: currentPathData
  } = useGetCurrentPathQuery();
  return <StyledAvatar onTouchStart={handleTouchStart} data-sentry-element="StyledAvatar" data-sentry-component="UserAvatar" data-sentry-source-file="avatar.tsx">
      <Avatar avatarSrc={avatar} size="8.1rem" mobileSize="8.1rem" data-sentry-element="Avatar" data-sentry-source-file="avatar.tsx" />
      <StyledTooltipWrapper data-section="tooltip" ref={tooltipRef} data-sentry-element="StyledTooltipWrapper" data-sentry-source-file="avatar.tsx">
        <Tooltip arrowPosition={width <= 1024 ? 'top-left' : 'right'} data-sentry-element="Tooltip" data-sentry-source-file="avatar.tsx">
          <Avatar avatarSrc={avatar} size="9.3rem" mobileSize="9.3rem" data-sentry-element="Avatar" data-sentry-source-file="avatar.tsx" />
          <StyledUserName data-sentry-element="StyledUserName" data-sentry-source-file="avatar.tsx">{userName}</StyledUserName>
          {fullName && <p>{fullName}</p>}
          <StyledInfoWrapper data-sentry-element="StyledInfoWrapper" data-sentry-source-file="avatar.tsx">
            <p>Learning </p>
            {currentPathData?.data && <Tag size="default" color="secondary" variation="main" textColor="main">
                {currentPathData?.data?.path?.title}
              </Tag>}
          </StyledInfoWrapper>
        </Tooltip>
      </StyledTooltipWrapper>
    </StyledAvatar>;
};