import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const StyledAdditionalModalContent = styled.div`
  background: ${({ theme }) => theme.colors.secondary.lighter};
  padding: 3.6rem;
  gap: 2rem;
  width: 100%;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button,
  a {
    color: ${({ theme }) => theme.colors.grey[100]};
  }

  &[data-style='white'] {
    background: ${({ theme }) => theme.colors.background.main};
    border-top: 1px solid ${({ theme }) => theme.colors.border.main};
    justify-content: center;
    padding: 2.6rem;

    button {
      color: ${({ theme }) => theme.colors.info.main};
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-1.2rem);
      &:hover {
        svg {
          transform: translateX(0.2rem);
          &[data-icon='left'] {
            transform: translateX(1.7rem);
          }
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    padding: 2rem;
  }
`;

export const StyledModalBodyText = styled.span`
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledArrow = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.info.main};
  transition: all 0.3s ease-in-out;

  &[data-icon='left'] {
    opacity: 0.6;
    transform: translateX(1.4rem);
  }
`;

export const StyledContinueButtonWrapper = styled.div`
  padding: 0.4rem 0 2.4rem;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    padding: 1rem 0 2.4rem;
  }
`;
