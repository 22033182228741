import { keyframes } from 'styled-components';

export const rotateIn = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
`;

export const rotateOut = keyframes`
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0);
  }
`;

export const scaleInVertical = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const scaleOutVertical = keyframes`
    from {
      opacity: 1;
      transform: scaleY(1);
    }
  to {
    opacity: 0;
    transform: scaleY(0);
  }
`;

export const scaleInHorizontal = keyframes`
  from {
    opacity: 0;
    transform: scaleX(0);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
`;

export const scaleOutHorizontal = keyframes`
    from {
      opacity: 1;
      transform: scaleX(1);
    }
  to {
    opacity: 0;
    transform: scaleX(0);
  }
`;

export const slideDownRadixAccordion = keyframes`
 from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

export const slideUpRadixAccordion = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;
