import { levelsAndPoints, levelsAndPointsWithTitle } from 'src/common/helpers';
import { LevelsToolTipContentProps } from './levels-tooltip-content.types';
import { StyledTableWrapper, StyledTooltipContentContainer, StyledGridItem, StyledLevel, StyledPoints, StyledLeftColumnWrapper, StyledRightColumnWrapper } from './levels-tooltip-content.styles';
export const LevelsToolTipContent = ({
  isModal
}: LevelsToolTipContentProps) => <StyledTooltipContentContainer isModal={isModal} data-sentry-element="StyledTooltipContentContainer" data-sentry-component="LevelsToolTipContent" data-sentry-source-file="levels-tooltip-content.tsx">
    Levels are obtained by playing rooms. You get a certain number of points for each question that you complete. <br />{' '}
    <br />
    There are 13 obtainable levels:
    <StyledTableWrapper data-sentry-element="StyledTableWrapper" data-sentry-source-file="levels-tooltip-content.tsx">
      <StyledLeftColumnWrapper data-sentry-element="StyledLeftColumnWrapper" data-sentry-source-file="levels-tooltip-content.tsx">
        {Object.entries(levelsAndPoints).map(([level, points]) => <StyledGridItem key={level}>
            <StyledLevel>{level}&nbsp;</StyledLevel>
            <StyledPoints>&ge; {points} points</StyledPoints>
          </StyledGridItem>)}
      </StyledLeftColumnWrapper>
      <StyledRightColumnWrapper data-sentry-element="StyledRightColumnWrapper" data-sentry-source-file="levels-tooltip-content.tsx">
        {Object.entries(levelsAndPointsWithTitle).map(([level, points]) => <StyledGridItem key={level} withTitle>
            <StyledLevel>{level}&nbsp;</StyledLevel>
            <StyledPoints>&ge; {points} points</StyledPoints>
          </StyledGridItem>)}
      </StyledRightColumnWrapper>
    </StyledTableWrapper>
  </StyledTooltipContentContainer>;