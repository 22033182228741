import { Button } from '@tryhackme/thm-ui-components';
import { StyledList } from '../common.styles';
import { StyledCodeTag } from '../having-problems/having-problems.styles';
import { VpnContentProps } from '../../../access-networks.types';
export const LinuxContent: React.FC<VpnContentProps> = ({
  isLoading,
  downloadHandler
}) => <StyledList data-testid="linux-content" data-sentry-element="StyledList" data-sentry-component="LinuxContent" data-sentry-source-file="linux-content.tsx">
    <li>
      <Button variant="text" color="info" isLoading={isLoading} loadingMsg="Downloading..." onClick={downloadHandler} data-testid="download-button" data-sentry-element="Button" data-sentry-source-file="linux-content.tsx">
        Download
      </Button>{' '}
      your OpenVPN configuration pack.
    </li>

    <li>
      Run the following command in your terminal: <StyledCodeTag data-sentry-element="StyledCodeTag" data-sentry-source-file="linux-content.tsx">sudo apt install openvpn</StyledCodeTag>
    </li>
    <li>Locate the full path to your VPN configuration file (normally in your ~/Downloads folder).</li>
    <li>
      {' '}
      Use your OpenVPN file with the following command: <StyledCodeTag data-sentry-element="StyledCodeTag" data-sentry-source-file="linux-content.tsx">sudo openvpn /path/to/file.ovpn</StyledCodeTag>
    </li>
  </StyledList>;