import styled from 'styled-components';
import { darkColors, StyledButton, StyledLoadingPlaceholder } from '@tryhackme/thm-ui-components';

import { StyledSectionBox } from '../commons';

export const StyledContinueLearningSectionBox = styled(StyledSectionBox)`
  background: linear-gradient(
    105.62deg,
    #0c2549 -8.15%,
    #1c2538 13.18%,
    #053129 30.25%,
    #1c2538 72.2%,
    #0c2760 131.47%
  );
  display: grid;
  grid-template-columns: 1fr 14rem;
  grid-template-rows: auto auto auto;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 14rem auto;
  }
`;

export const StyledPathName = styled.p`
  font-size: 2.8rem;
  font-weight: 600;
  color: ${darkColors.text.main};
  grid-column: 1;
  grid-row: 2;
`;

export const StyledContinueLearningMessage = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: ${darkColors.text.main};
  grid-column: 1;
  grid-row: 1;
  align-self: center;
`;

export const StyledContinueLearningLoading = styled.div`
  ${StyledLoadingPlaceholder}
  width: 100%;
  height: 32.1rem;
  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    height: 18.5rem;
  }
`;

export const StyledProgressContainerLarge = styled.div`
  align-self: center;
  grid-column: 2;
  grid-row: 1 / -1;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-column: 1;
    grid-row: 3;
  }
`;

export const StyledStartLearningButton = styled(StyledButton)`
  width: 100%;
  grid-column: 1;
  grid-row: 3;
  width: 20.9rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-row: 4;
    width: 100%;
  }
`;

export const StyledCertificateButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 100%;
  }
`;
