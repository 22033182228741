import { StyledLoadingPlaceholder } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

import { StyledTitle } from 'src/common/styles';

export const StyledRadarWrapper = styled.div`
  width: 30rem;
  height: 25.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    width: 36.8rem;
    height: 31.5rem;
  }
`;

export const StyledRadarChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const StyledBetaTag = styled.span`
  background-color: ${({ theme }) => theme.colors.alertBanner.info};
  color: ${({ theme }) => theme.colors.info.main};
  padding: 0.2rem 1rem;
  border-radius: 100px;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
`;
export const StyledQuestionTitle = styled(StyledTitle)`
  margin: 0;
`;
export const StyledChartLoading = styled.div`
  ${StyledLoadingPlaceholder}

  width: 30rem;
  height: 25.6rem;
  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    width: 36.8rem;
    height: 31.5rem;
  }
`;
