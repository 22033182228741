import { useLayoutEffect, useState } from 'react';
import { addSeconds, millisecondsInSecond } from 'date-fns';

import { useAppDispatch } from 'src/app/hooks';
import { invalidateCachedRunningVms } from 'src/features/room/room.slice';

import { RunningInstance } from '../interfaces/instances';
import { calculateExpiryTimeLeft } from '../helpers/date/date';

export const useStartTimeVm = (activeMachine: RunningInstance) => {
  const [startTimeLeft, setStartTimeLeft] = useState<number>(activeMachine.waitTime ?? 0);
  const [hideIpAddress, setHideIpAddress] = useState(true); // Target Ip Address
  const [formattedStartTimeLeft, setFormattedStartTimeLeft] = useState<string>('');
  const dispatch = useAppDispatch();

  // Calculate the time left for Ip Address to be shown
  useLayoutEffect(() => {
    if (!startTimeLeft) {
      // The public IP is exposed only after the waitTime is over, so we need to invalidate the cache and there is almost around 1 difference between the back-end and front-end time calculation
      setTimeout(() => {
        invalidateCachedRunningVms();
      }, 1000);
      setHideIpAddress(false);
      return;
    }
    const timer = setInterval(() => {
      const createdTimeLeft = calculateExpiryTimeLeft(new Date(), addSeconds(new Date(), startTimeLeft));
      if (createdTimeLeft.hours === 0 && createdTimeLeft.minutes === 0 && createdTimeLeft.seconds === 0) {
        setHideIpAddress(false);
        clearInterval(timer);
        return;
      }
      setFormattedStartTimeLeft(`${createdTimeLeft.minutes}min ${createdTimeLeft.seconds}s`);
      setStartTimeLeft((prev) => prev - 1);
    }, millisecondsInSecond);

    // eslint-disable-next-line consistent-return
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [activeMachine.created, activeMachine.waitTime, dispatch, startTimeLeft]);

  return { hideIpAddress, formattedStartTimeLeft };
};
