import { useEffect, useRef } from 'react';
import { StyledLoadingCard } from './hubspot-form.styles';
import { HubspotFormProps } from './hubspot-form.types';
declare const window: {
  hbspt: undefined | {
    forms: {
      create: (options: object) => void;
    };
  };
} & Window;

/**
 * This component can only be used a context where the Hubspot script has been loaded.
 * Ideally, use the useExternalScript hook
 */
export const HubspotForm = (hubspotFormOptions: HubspotFormProps) => {
  const formRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (window.hbspt && formRef.current) {
      window.hbspt.forms.create({
        ...hubspotFormOptions,
        target: `#${formRef.current.id}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={formRef} id="reactHubspotForm" data-sentry-component="HubspotForm" data-sentry-source-file="hubspot-form.tsx">
      <StyledLoadingCard data-sentry-element="StyledLoadingCard" data-sentry-source-file="hubspot-form.tsx" />
    </div>;
};