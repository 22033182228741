import styled from 'styled-components';

export const StyledCenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContent = styled(StyledCenteredContent)`
  gap: 2.4rem;
`;

export const StyledHeading = styled.h2`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 2.4rem;
  margin-bottom: 0.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 5.4rem;
  }
`;

export const StyledSubTitle = styled.h3`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 0.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  }
`;

export const StyledMessage = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 2.4rem;
  margin-bottom: 1rem;

  span {
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    text-decoration: line-through;
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
