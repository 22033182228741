import { secondaryColors } from '@tryhackme/thm-ui-components';

import { GraphColorScheme } from './skill-matrix.types';

const BACKGROUND_GRADIENT_END = 'rgba(115, 234, 42, 0.6)';
const BORDER_COLOR = 'rgba(92, 182, 50, 1)';

export const darkColorScheme: GraphColorScheme = {
  grid: secondaryColors.secondary.lighter,
  text: secondaryColors.grey[200],
  backgroundGradientStart: 'rgba(12, 123, 203, 0.4)',
  backgroundGradientMiddle: 'rgba(115, 234, 42, 0.4)',
  backgroundEdge: BACKGROUND_GRADIENT_END,
  borderColor: BORDER_COLOR,
};
export const lightColorScheme: GraphColorScheme = {
  grid: secondaryColors.grey[300],
  text: secondaryColors.grey[900],
  backgroundGradientStart: 'rgba(12, 203, 191,0.3)',
  backgroundGradientMiddle: 'rgba(84, 225, 87, 0.4)',
  backgroundEdge: BACKGROUND_GRADIENT_END,
  borderColor: BORDER_COLOR,
};
