import { Button } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledViewMore = styled(Button)`
  margin-top: 2.4rem;
  border-radius: 0;
`;

export const StyledNewRoomsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
