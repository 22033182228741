import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

import { Room } from './my-learning.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecentRooms: builder.query<ApiResponse<Room[]>, void>({
      query: () => ({
        method: 'GET',
        url: '/rooms/recent-rooms',
      }),
    }),
  }),
});

export const { useGetRecentRoomsQuery } = extendedApiSlice;
