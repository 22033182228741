import { TooltipElementType, TooltipElementsType } from './helper-tooltip.types';

export const STATIC_SITE_TOOLTIP_ELEMENTS: TooltipElementType[] = [
  {
    id: '#split-screen-right-side',
    position: {
      top: { offset: 100 },
      left: { elementDimensions: 'offsetWidth', offset: -321 },
    },
    arrowPosition: 'right',
    text: 'This half of the screen is for your lesson exercises, used within tasks.',
  },
];
export const ACTIVE_MACHINE_TOOLTIP_ELEMENTS: TooltipElementType[] = [
  {
    id: '#split-screen-right-side',
    position: {
      top: { offset: 100 },
      left: { elementDimensions: 'offsetWidth', offset: -321 },
    },
    arrowPosition: 'right',
    text: 'This is your AttackBox, used to attack the machine you just started.',
  },
  {
    id: '#active-machine-info',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', elementDimensions: 'halfOfWidth', offset: -157 },
    },
    arrowPosition: 'top-center',
    text: 'This is the machine you just started, that you can practice hacking on (using the AttackBox).',
  },
];

export const TUTORIAL_ROOM_ELEMENTS: TooltipElementType[] = [
  {
    id: 'header button[aria-label="Toggle dropdown for Learn"]',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', elementDimensions: 'offsetWidth', offset: -55 },
    },
    arrowPosition: 'top-left',
    text: 'Use the learn page to find cyber security labs, challenges and learning paths.',
  },
  {
    id: '#start-machine-button-1',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: -263 },
    },
    arrowPosition: 'top-right',
    text: 'Some tasks contain machines that you can start, which are used to put your knowledge into practice.',
  },
];

export const ROOM_ATTACK_BOX_ELEMENTS: TooltipElementType[] = [
  {
    id: '#start-attackbox',
    parentId: '#room-banner',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: -60 },
    },
    arrowPosition: 'top-center',
    text: 'Deploy your AttackBox to access other machines on TryHackMe.',
  },
];

export const NETWORK_ROOM_ELEMENTS: TooltipElementType[] = [
  {
    id: '#network-diagram',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: 55 },
    },
    arrowPosition: 'top-left',
    text: "As you answer questions, this network map updates to show when you've compromised or discovered machines.",
  },
  {
    id: '#network-diagram-extend',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: 1 },
    },
    arrowPosition: 'top-left',
    text: 'Running networks have an expiry time (where machines shutdown). Extend the network timer to keep it running.',
  },
  {
    id: '#network-diagram-reset',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: 1 },
    },
    arrowPosition: 'top-left',
    text: 'Networks are shared with other users. You can vote (once an hour) to reset the network to its initial state.',
  },
  {
    id: '#network-access-left',
    position: {
      top: { documentPosition: 'top', elementDimensions: 'offsetHeight', offset: 10 },
      left: { documentPosition: 'left', offset: 1 },
    },
    arrowPosition: 'top-left',
    text: 'How long you have left in this room.',
  },
];

export const TOOLTIP_ELEMENTS: TooltipElementsType = {
  staticsite: {
    elements: STATIC_SITE_TOOLTIP_ELEMENTS,
    userTutorialProperty: 'staticsite',
  },
  attackbox: {
    elements: ACTIVE_MACHINE_TOOLTIP_ELEMENTS,
    userTutorialProperty: 'attackbox',
  },
  rooms: {
    elements: TUTORIAL_ROOM_ELEMENTS,
    userTutorialProperty: 'rooms',
  },
  // This is to display the tooltip under the Attackbox button in the room banner
  startattackbox: {
    elements: ROOM_ATTACK_BOX_ELEMENTS,
  },
  networks: {
    elements: NETWORK_ROOM_ELEMENTS,
    userTutorialProperty: 'networks',
  },
};
