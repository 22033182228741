import styled from 'styled-components';

import { StyledAppearAnimation, StyledWrapperTooltipDefault } from '../../commons';

export const StyledUserName = styled.p`
  max-width: 10.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: ${({ theme }) => theme.colors.text.main};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const StyledFriendItem = styled.div`
  display: grid;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  transition: all 0.3s ease-in-out;
  grid-template-columns: auto 3.8rem auto 1fr;

  p {
    color: ${({ theme }) => theme.colors.paragraph};
  }

  &:hover {
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.colors.border.main};

    ${StyledUserName} {
      max-width: 6rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-template-columns: 1rem 3.8rem 1fr;
    grid-template-rows: 3.8rem 1fr;
    :not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
`;

export const StyledTooltipWrapper = styled.div<{ $isFirstItem?: boolean }>`
  ${StyledWrapperTooltipDefault};
  top: 50%;
  transform: ${({ $isFirstItem }) => ($isFirstItem ? 'translate(-50%, 100%)' : 'translate(-50%, -100%)')};
  left: 50%;
  top: ${({ $isFirstItem }) => ($isFirstItem ? '2px' : '-50%')};

  &[data-type='remove-friend'] {
    transform: ${({ $isFirstItem }) => ($isFirstItem ? 'translate(-67%, 100%)' : 'translate(-67%, -100%)')};
    left: 0;

    div[role='tooltip'] {
      &:before {
        right: 1rem;
      }
    }
  }

  & > div[role='tooltip'] {
    max-width: 12rem;
    & > div {
      padding: 0.8rem;
    }
  }
`;

export const StyledWrapperRecords = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.2rem;
  justify-content: flex-start;
  position: relative;
  justify-self: end;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    grid-row: 2;
    justify-self: start;
    gap: 0.65rem;
  }
`;

export const StyledWrapperRecord = styled.div`
  position: relative;

  &:hover {
    div[data-section='records-tooltip'] {
      display: inline-block;
      animation: appear 0.3s ease-in-out;
    }
  }
  ${StyledAppearAnimation};
`;

export const StyledRecordItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  border-radius: 10rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    gap: 0.65rem;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${({ theme }) => theme.colors.paragraph};
  }

  &[data-type='points'] {
    svg path {
      fill: ${({ theme }) => theme.colors.warning.main};
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
      background-color: ${({ theme }) => theme.colors.statusTag.orange.background};
    }
  }

  &[data-type='streak'] {
    svg path {
      fill: ${({ theme }) => theme.colors.primary.main};
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
      background-color: ${({ theme }) => theme.colors.success.light};
    }
  }

  &[data-type='last-room'] {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 3.5rem;
    }

    path {
      fill: ${({ theme }) => theme.colors.info.main};
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
      background-color: ${({ theme }) => theme.colors.statusTag.blue.background};
      p {
        max-width: 11rem;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
      background-color: ${({ theme }) => theme.colors.statusTag.blue.background};
      p {
        max-width: 11rem;
      }
    }
  }

  &:hover {
    &[data-type='points'] {
      background-color: ${({ theme }) => theme.colors.statusTag.orange.background};
    }

    &[data-type='streak'] {
      background-color: ${({ theme }) => theme.colors.success.light};
    }

    &[data-type='last-room'] {
      background-color: ${({ theme }) => theme.colors.statusTag.blue.background};
    }
  }

  a {
    color: ${({ theme }) => theme.colors.paragraph};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const StyledRemoveBtn = styled.button`
  padding: 0.56rem 0.45rem;
  border-radius: ${({ theme }) => theme.radius.small};
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;

  svg path {
    fill: ${({ theme }) => theme.colors.grey[600]};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};

    svg path {
      fill: ${({ theme }) => theme.colors.error.main};
    }
  }
`;
