import { css } from 'styled-components';

export const RoomHeaderStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.black};
  }

  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.fonts.familyUbuntu};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }

  @media (min-width: ${({ theme }) => theme.sizes.md}) {
    h1 {
      font-size: 3.2rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 2.2rem;
    }

    h4 {
      font-size: 2rem;
    }

    h5 {
      font-size: 1.8rem;
      font-family: ${({ theme }) => theme.fonts.familyUbuntu};
      font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
    }
  }
`;
