import { StyledButton } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledEmptyState = styled.div`
  align-content: center;
  background-color: ${({ theme }) => theme.colors.background.tableOdd};
  border-radius: ${({ theme }) => theme.radius.small};
  display: grid;
  gap: 1rem;
  height: 46.4rem;
  padding: 0.8rem 1.6rem 0.8rem 1.2rem;
  place-items: center;

  p {
    color: ${({ theme }) => theme.colors.text.main};
    text-align: center;
  }

  p[data-text='title'] {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 2rem;
  }

  svg {
    height: 16.7rem;
    width: 21.9rem;
  }
`;

export const StyledPathsButton = styled(StyledButton)`
  margin-top: 0.6rem;
`;
