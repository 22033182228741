import styled from 'styled-components';
import { CodeTag, darkColors } from '@tryhackme/thm-ui-components';

import { StyledLists } from '../common.styles';

export const StyledDropdown = styled.div<{ $isDropdownOpen: boolean }>`
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.secondary.lighter};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.7rem;

  svg {
    transform: ${({ $isDropdownOpen }) => ($isDropdownOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.3s ease-in-out;
  }
`;

export const StyledListWrapper = styled.ul`
  ${StyledLists};
  list-style: disc;
  margin-top: 1.8rem;
`;

export const StyledCodeTag = styled(CodeTag)`
  color: ${darkColors.codeTag.text};
  background: ${darkColors.codeTag.background};
  border-color: ${({ theme }) => theme.colors.secondary.lighter};
  white-space: nowrap;
`;
