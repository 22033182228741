import { TextField, TextFieldSelectProps } from '@tryhackme/thm-ui-components';
import { FieldValues, Controller } from 'src/common/form';
import { FormSelectControllerProps } from './form-select-controller.types';
export const FormSelectController = <T extends FieldValues, TValue extends string>({
  name,
  control,
  options,
  onChange,
  ...props
}: FormSelectControllerProps<T, TValue> & Omit<TextFieldSelectProps, keyof FormSelectControllerProps<T, TValue> | 'value' | 'variant' | 'isMulti' | 'selected' | 'handleSelection'>) => <Controller name={name} control={control} render={({
  field: {
    ref,
    value,
    ...rest
  }
}) => {
  const selected = options.find(option => option.value === value);
  return <TextField isSearchable={false} id={rest.name} {...props} {...rest} innerRef={ref} value={selected} variant="select" isMulti={false} selected={selected} handleSelection={newValue => onChange(rest.name, (newValue.value as TValue))} options={options} />;
}} data-sentry-element="Controller" data-sentry-component="FormSelectController" data-sentry-source-file="form-select-contoller.tsx" />;