import { InfoBox } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledSearchResultsPopUp = styled.div`
  width: 49.2rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) and (max-width: ${({ theme }) => theme.sizes.lg}) {
    max-width: 49.2rem;
    min-width: 40rem;
    width: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: auto;
  }
`;

export const StyledSearchInput = styled.div`
  & svg {
    height: 1.9rem;
    color: ${({ theme }) => theme.colors.grey[700]};
  }
  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: calc(100% - 3.2rem);
  }
`;

export const StyledSearchInfoBox = styled(InfoBox)`
  text-align: center;
  margin-top: 2.4rem;

  p[data-content='title'] {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: 1.4rem;
  }

  p[data-content='subtitle'] {
    font-size: 1.4rem;
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.6rem;
`;
