import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { StyledModalIcon } from 'src/common/components/standard-action-modal/standard-action-modal.styles';
import { OptionsModalProps } from 'src/features/room/room.types';
import { StyledModalBodyText } from '../commons';
const iconComponent = <StyledModalIcon data-icon="warning" icon={faWarning} />;
const modalBodyText = <StyledModalBodyText>
    Your machine is going to expire soon. Close this and add an hour to stop it from terminating!
  </StyledModalBodyText>;
export const MachineExpiringPopup = ({
  open,
  onOpenChange
}: Omit<OptionsModalProps, 'roomCode'>) => <StandardActionModal modalTitleText="Machine expiring soon" modalBodyText={modalBodyText} dismissButtonTitle="Close" showIcon iconComponent={iconComponent} open={open} onOpenChange={onOpenChange} showActionButton={false} data-sentry-element="StandardActionModal" data-sentry-component="MachineExpiringPopup" data-sentry-source-file="machine-expiring-popup.tsx" />;