import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse, PlanPricingData } from 'src/common/interfaces';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPricing: builder.query<ApiResponse<PlanPricingData[]>, boolean>({
      query: (includeAll) => ({
        url: `payments/cost?includeAll=${includeAll.toString()}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSubscriptionPricingQuery } = extendedApiSlice;
