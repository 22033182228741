import { useContext } from 'react';
import { StyledButton, StyledFlex, SwitchThemeContext } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import workspacePNG from 'src/images/pngs/dashboard/workspace.png';
import darkModeWorkspacePNG from 'src/images/pngs/dashboard/workspace-dark.png';
import { StyledSectionBox } from 'src/features/dashboard/components/commons';
import { ThemeColorMode } from 'src/common/enums';
import { useUpdateDashboardNotificationMutation } from 'src/common/slices';
import { DismissUserDashboardNotificationItems } from 'src/common/interfaces';
import { StyledDescription } from '../commons/description';
import { StyledSideSectionCloseBtn } from '../commons/close-btn';
import { SideSectionImage, StyledSideSectionBody, SideSectionTitle } from './workspaces.styles';
export const Workspaces = () => {
  const {
    theme: themeName
  } = useContext(SwitchThemeContext);
  const isDarkMode = themeName === ThemeColorMode.DARK;
  const currentImage = isDarkMode ? darkModeWorkspacePNG : workspacePNG;
  const [dismissNotification] = useUpdateDashboardNotificationMutation();
  const dismiss = () => {
    dismissNotification({
      notification: DismissUserDashboardNotificationItems.WORKSPACES_AD
    }).catch(() => {});
  };
  return <StyledSectionBox data-sentry-element="StyledSectionBox" data-sentry-component="Workspaces" data-sentry-source-file="workspaces.tsx">
      <StyledFlex gap="0" alignItems="start" flexWrap="no-wrap" data-sentry-element="StyledFlex" data-sentry-source-file="workspaces.tsx">
        <SideSectionTitle data-sentry-element="SideSectionTitle" data-sentry-source-file="workspaces.tsx">Check Out Workspaces!</SideSectionTitle>
        <StyledSideSectionCloseBtn onClick={dismiss} aria-label="close" data-sentry-element="StyledSideSectionCloseBtn" data-sentry-source-file="workspaces.tsx">
          <FontAwesomeIcon icon={faXmark} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="workspaces.tsx" />
        </StyledSideSectionCloseBtn>
      </StyledFlex>
      <StyledSideSectionBody data-sentry-element="StyledSideSectionBody" data-sentry-source-file="workspaces.tsx">
        <div>
          <StyledDescription data-sentry-element="StyledDescription" data-sentry-source-file="workspaces.tsx">
            Join workspaces to collaboratively hack with your team and access features like team leader boards and more.
          </StyledDescription>
          <StyledButton as={Link} to="/workspace" color="add" variant="solid" data-sentry-element="StyledButton" data-sentry-source-file="workspaces.tsx">
            Join Workspaces
          </StyledButton>
        </div>
        <SideSectionImage src={currentImage} alt="Workspaces" data-sentry-element="SideSectionImage" data-sentry-source-file="workspaces.tsx" />
      </StyledSideSectionBody>
    </StyledSectionBox>;
};