import { StyledButton, StyledFlex } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import accessNetworksPNG from 'src/images/pngs/dashboard/access-networks.png';
import { StyledSectionBox } from 'src/features/dashboard/components/commons';
import { StyledDescription } from '../commons/description';
import { StyledSideSectionCloseBtn } from '../commons/close-btn';
import { SideSectionImage, StyledSideSectionBody, SideSectionTitle, LeftContainer } from './access-networks.styles';
export const AccessNetworks = ({
  dismiss
}: {
  dismiss: () => void;
}) => <StyledSectionBox data-sentry-element="StyledSectionBox" data-sentry-component="AccessNetworks" data-sentry-source-file="access-networks.tsx">
    <StyledFlex gap="0" alignItems="start" flexWrap="no-wrap" data-sentry-element="StyledFlex" data-sentry-source-file="access-networks.tsx">
      <SideSectionTitle data-sentry-element="SideSectionTitle" data-sentry-source-file="access-networks.tsx">Access Networks with your streak</SideSectionTitle>
      <StyledSideSectionCloseBtn onClick={dismiss} aria-label="close" data-sentry-element="StyledSideSectionCloseBtn" data-sentry-source-file="access-networks.tsx">
        <FontAwesomeIcon icon={faXmark} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="access-networks.tsx" />
      </StyledSideSectionCloseBtn>
    </StyledFlex>
    <StyledSideSectionBody data-sentry-element="StyledSideSectionBody" data-sentry-source-file="access-networks.tsx">
      <LeftContainer data-sentry-element="LeftContainer" data-sentry-source-file="access-networks.tsx">
        <StyledDescription data-sentry-element="StyledDescription" data-sentry-source-file="access-networks.tsx">
          Did you know that you can access networks as a reward for keeping a streak?
        </StyledDescription>
        <StyledButton as={Link} to="/hacktivities" color="secondary" variant="outlined" data-sentry-element="StyledButton" data-sentry-source-file="access-networks.tsx">
          Unlock Networks
        </StyledButton>
      </LeftContainer>
      <SideSectionImage src={accessNetworksPNG} alt="Networks" data-sentry-element="SideSectionImage" data-sentry-source-file="access-networks.tsx" />
    </StyledSideSectionBody>
  </StyledSectionBox>;