import { apiSlice } from 'src/features/api/api-slice';
import { RTKQueryTag } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';
import {
  RoomsData,
  GetRoomsQueryParams,
  PresignedS3UrlData,
  ManageRoomQueryParams,
} from 'src/common/interfaces/my-rooms';

import { CreateRoomFormValues } from './components/create-room-modal/create-room-modal.constants';

export const roomsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRooms: builder.query<ApiResponse<RoomsData>, GetRoomsQueryParams>({
      query: ({ type, searchText, isRoomCompleted = true, page, limit = 10 }: GetRoomsQueryParams) => ({
        url: 'rooms/my-rooms',
        params: {
          searchText,
          page,
          type,
          limit,
          filterCompleted: isRoomCompleted ? 'true' : undefined,
        },
      }),
      providesTags: [RTKQueryTag.UserRooms],
    }),

    getManageRooms: builder.query<ApiResponse<RoomsData>, ManageRoomQueryParams>({
      query: ({ page, searchText, limit = 10 }: ManageRoomQueryParams) => ({
        url: 'rooms/manage',
        params: {
          page,
          searchText,
          limit,
        },
      }),
      providesTags: [RTKQueryTag.ManageRooms],
    }),

    removeSavedRoom: builder.mutation({
      query: (roomCode: string) => ({
        url: `users/saved-rooms?code=${roomCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKQueryTag.UserRooms],
    }),

    addSavedRoom: builder.mutation({
      query: (roomCode: string) => ({
        url: `users/saved-rooms?code=${roomCode}`,
        method: 'PUT',
      }),
    }),

    getRoomIconPresignedUrl: builder.mutation<ApiResponse<PresignedS3UrlData>, { filename: string; type?: string }>({
      query: ({ filename, type = 'room-icons' }: { filename: string; type?: string }) => ({
        url: 'rooms/manage/room-icon-url',
        params: {
          filename,
          type,
        },
        method: 'GET',
      }),
    }),

    uploadIconToS3: builder.mutation({
      query: ({ file, url }: { file: File; url: string }) => ({
        url,
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      }),
    }),

    createNewRoom: builder.mutation({
      query: (payload: CreateRoomFormValues) => ({
        url: 'rooms/manage/create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [RTKQueryTag.ManageRooms],
    }),
  }),
});

export const {
  useGetRoomsQuery,
  useGetManageRoomsQuery,
  useRemoveSavedRoomMutation,
  useAddSavedRoomMutation,
  useGetRoomIconPresignedUrlMutation,
  useUploadIconToS3Mutation,
  useCreateNewRoomMutation,
} = roomsApiSlice;
