import { pointWithTitles } from 'src/common/helpers';

const pointWithTitleMap = new Map<number, { rank: string; level: number }>(
  Object.entries(pointWithTitles).map(([key, value]) => [Number(key), value]),
);

export const getLevelByTotalPoints = (points: number): { rank: string; level: number; levelPoints: number } => {
  let titleLevelAndLevelPoints = { rank: '', level: 0, levelPoints: 0 };

  const sortedPoints = [...pointWithTitleMap.keys()].sort((a, b) => a - b);

  for (const point of sortedPoints) {
    const titleWithLevel = pointWithTitleMap.get(point);
    if (titleWithLevel && points >= point) {
      titleLevelAndLevelPoints = { ...titleWithLevel, levelPoints: point };
    }
  }

  return titleLevelAndLevelPoints;
};

export const getProgressToNextLevel = (points: number) => {
  const currentLevel = getLevelByTotalPoints(points);
  let nextLevelPoints = 0;
  const nextLevel = currentLevel.level + 1;

  for (const [point, titleWithLevel] of pointWithTitleMap.entries()) {
    if (titleWithLevel.level === nextLevel) {
      nextLevelPoints = point;
      break;
    }
  }

  const currentLevelPoints =
    [...pointWithTitleMap.keys()].find((point) => pointWithTitleMap.get(point)?.level === currentLevel.level) || 0;

  const progress = ((points - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100;

  return Math.min(Math.floor(progress), 100);
};
