import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

import { UserTheme } from '../constants/user';

export const ThemeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateTheme: builder.mutation<ApiResponse<null>, UserTheme>({
      query: (theme: UserTheme) => ({
        url: '/users/theme',
        method: 'PATCH',
        body: { theme },
      }),
    }),
  }),
});

export const { useUpdateThemeMutation } = ThemeApiSlice;
