import { useState, useRef } from 'react';
import { useOnClickOutside } from '@tryhackme/thm-ui-components';
export const useTooltip = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const handleTouchStart = () => {
    setTooltipVisible(!tooltipVisible);
  };
  const handleClickOutside = () => {
    setTooltipVisible(false);
  };
  useOnClickOutside({
    ref: tooltipRef,
    handler: handleClickOutside
  });
  return {
    tooltipVisible,
    handleTouchStart,
    tooltipRef
  };
};