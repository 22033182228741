import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { StyledModalIcon } from 'src/common/components/standard-action-modal/standard-action-modal.styles';
import { OptionsModalProps } from 'src/features/room/room.types';
import { StyledModalBodyText } from 'src/features/room/components/commons';
const iconComponent = <StyledModalIcon data-icon="error" icon={faCircleExclamation} />;
const modalBodyText = <StyledModalBodyText>Your machine has expired and terminated.</StyledModalBodyText>;
export const ExpiredMachineModal = ({
  open,
  onOpenChange,
  onCloseCallback
}: Omit<OptionsModalProps, 'roomCode'> & {
  onCloseCallback?: () => void;
}) => <StandardActionModal modalTitleText="Expired machine" modalBodyText={modalBodyText} dismissButtonTitle="Close" showIcon iconComponent={iconComponent} open={open} onOpenChange={onOpenChange} showActionButton={false} {...onCloseCallback && {
  handleCloseButtonAction: onCloseCallback
}} data-sentry-element="StandardActionModal" data-sentry-component="ExpiredMachineModal" data-sentry-source-file="expired-machine-modal.tsx" />;