import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AoCInternalPromotionalBannerImage from 'src/images/pngs/christmas-2024/aoc-internal-banner.png';
import AoCInternalPromotionalBannerMobileImage from 'src/images/pngs/christmas-2024/aoc-internal-banner-mobile.png';
import AoCInternalPromotionalBannerTabletImage from 'src/images/pngs/christmas-2024/aoc-internal-banner-tablet.png';
import GrinchedFont from 'src/features/christmas-page-2024/components/banner/grinched.otf';

export const StyledAoCInternalPromotionalBannerContainer = styled.div`
  width: auto;
  height: fit-content;
  background: radial-gradient(50% 50% at 50% 50%, rgba(21, 28, 43, 1) 0%, rgba(26, 35, 52, 1) 100%);
`;

export const StyledAoCInternalPromotionalBannerBackground = styled.div`
  min-height: 29.4rem;
  background-image: url(${AoCInternalPromotionalBannerMobileImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    background-image: url(${AoCInternalPromotionalBannerTabletImage});
    min-height: 22.6rem;
    background-position: right;
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    background-image: url(${AoCInternalPromotionalBannerImage});
    background-position: center;
    min-height: 20rem;
  }
`;

export const StyledAoCInternalPromotionalBannerWrapper = styled.div`
  @font-face {
    font-family: 'GrinchedFont';
    font-weight: normal;
    src: url(${GrinchedFont}) format('opentype');
  }

  display: flex;
  padding: 3.2rem 1.8rem;

  p,
  h1 {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    margin-bottom: 1.2rem;
  }

  p {
    margin-bottom: 2.4rem;
  }

  h1 > span {
    color: ${({ theme }) => theme.colors.primary.main};
    text-align: center;
    display: block;
  }

  h1 {
    font-family: 'GrinchedFont', sans-serif;
    font-size: 4.6rem;
    margin-bottom: 1.2rem;
  }

  button {
    width: 100%;

    span {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
    }
  }

  & > div:last-child {
    position: absolute;
    right: 2.9rem;

    svg {
      position: absolute;
      top: -20px;
      right: -14px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: 3rem 3.2rem;

    & > div:first-child {
      flex: 2;
    }

    & > div:last-child {
      position: relative;
      display: flex;
      justify-content: end;
      flex: 1;

      svg {
        right: -40px;
        top: -10px;
      }
    }

    span,
    h1,
    p {
      text-align: left;
    }

    h1 > span {
      display: inline;
    }

    button {
      width: auto;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    padding: 2.75rem 1.2rem 2.85rem 1.2rem;
    max-width: 1320px;
    margin: 0 auto;

    & > div:last-child {
      position: absolute;
      right: 2.9rem;

      svg {
        right: 0;
        top: -3px;
      }
    }
  }
`;

export const StyledAoCInternalPromotionalBannerCloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  height: 2.23rem;
  width: 2.23rem;
  color: ${({ theme }) => theme.colors.grey[500]};

  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
