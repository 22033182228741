import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Image } from '@tryhackme/thm-ui-components';

export const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    margin-bottom: 1.6rem;
  }
`;

export const StyledAvatar = styled(Image)`
  width: 4rem;
  height: 4rem;
  border-radius: 3.2rem;
  object-fit: cover;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledRoomTitle = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.text.main};

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.small};
  }
`;

export const StyledRoomTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    gap: 0.4rem;
  }
`;
